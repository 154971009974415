import React from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { Row, Col, Button, FormGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../../../forms/inputFields/CustomFormField/CustomFormField.component'
import { contractUpdateListFilters, contractGetListDataStart, contractUpdateListPage } from '../../../../redux/contract/contract.actions'
import { setQueryString } from './utils'

const ValidatorFilters = ({ apiUrl }) => {
  const dispatch = useDispatch()

  //Submit filters form
  const onSubmit = async values => {
    const queryParams = setQueryString(values)
    const filters = queryParams.length ? queryParams.join('&') : ""
    dispatch(contractUpdateListFilters(filters))
    //On form submit go back to page 1
    dispatch(contractUpdateListPage(1))
    dispatch(contractGetListDataStart({ apiUrl }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        values: true
      }}
    >
      {({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            className="filters-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="filter" className="mr-2" />
              Applica filtri
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={4}>
                  <CustomFormField
                    id="name"
                    name="name"
                    type="text"
                    fieldLabel="Nome"
                    placeholder="Nome"
                    noValidationMessage={true}
                  />
                </Col>
                <Col md={4}>
                  <CustomFormField
                    id="code"
                    name="code"
                    type="text"
                    placeholder="Codice"
                    fieldLabel="Codice"
                    noValidationMessage={true}
                  />
                </Col>
                <Col md={4}>
                  <CustomFormField
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Username"
                    fieldLabel="Username"
                    noValidationMessage={true}
                  />
                </Col>
                <Col md={4}>
                  <CustomFormField
                    id="email"
                    name="email"
                    type="text"
                    placeholder="E-mail"
                    fieldLabel="E-mail"
                    noValidationMessage={true}
                    style={{ textTransform: 'lowercase' }}
                  />
                </Col>
                <Col md={4}>
                  <CustomFormField
                    id="personalEmail"
                    name="personalEmail"
                    type="text"
                    placeholder="Email personale"
                    fieldLabel="Email personale"
                    noValidationMessage={true}
                    style={{ textTransform: 'lowercase' }}
                  />
                </Col>
              </Row>
              <Row form className="justify-content-end">
                <Col md={3} className="align-self-end">
                  <FormGroup className="filters-submit">
                    <Button
                      color="warning"
                      className="mr-1"
                      outline
                      onClick={() => form.reset()}
                    >
                      <FontAwesomeIcon icon="undo" className="mr-2" />
                      Reset
                    </Button>
                    <Button className="ab-button">
                      <FontAwesomeIcon icon="filter" className="mr-2" />
                      Filtra
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

export default ValidatorFilters
