import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Field } from 'react-final-form'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Spinner,
  Button, FormGroup
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RadioInput from '../forms/inputFields/RadioInput/RadioInput.component'
import { selectIsSubmitting, selectEntityName } from '../../redux/contract/contract.selectors'
import { contractUpdateItemStatusStart } from '../../redux/contract/contract.actions'
import { isActiveUserContract } from '../../redux/contract/contract.utils'

const SupplyPointStatusUpdate = ({ actionUrl, itemId, statusId, supplyType = null, cadastralDataId = null, otherClasses, buttonProps }) => {
  const dispatch = useDispatch()
  const entityName = useSelector(selectEntityName)
  const changingStatus = useSelector(state => selectIsSubmitting('itemStatus')(state))
  const [ visibility, setVisibility ] = useState(false)
  const toggle = () => setVisibility(!visibility)

  const [ itemIdStatus, setItemIdStatus ] = useState(statusId?.toString() || "")
  useEffect(() => {
    setItemIdStatus(statusId?.toString() || "")
  }, [statusId])

  const confirmStatusChange = values => {
    dispatch(contractUpdateItemStatusStart({ submitUrl: actionUrl, values, itemId, supplyType, cadastralDataId }))
    toggle()
  }

  return(
    <div
      className={`action-modal${otherClasses ? ` ${otherClasses}` : ''}`}
    >
      <Button
        title={buttonProps.title}
        onClick={toggle}
        size="sm"
        color={buttonProps ? buttonProps.color : 'primary'}
        outline
      >
        <FontAwesomeIcon size="sm" icon="exchange-alt" />{' '}
        {buttonProps && buttonProps.label &&
          <span className="btn-label">{buttonProps.label}</span>
        }
      </Button>
      <Modal className="status-modal" isOpen={visibility} toggle={toggle}>
        <Form
          onSubmit={confirmStatusChange}
          subscription={{
            values: true
          }}
          initialValues={{ status: itemIdStatus }}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <ModalHeader toggle={toggle}>
                <FontAwesomeIcon className="mr-2" icon="exchange-alt" />
                Modifica lo stato
              </ModalHeader>
              <ModalBody>
                <FormGroup tag="fieldset">
                  <FormGroup check>
                    <Field
                      name="status"
                      type="radio"
                      fieldLabel="Inserito"
                      noValidationMessage={true}
                      value="1"
                    >
                      {(fieldState) => (
                        <RadioInput {...fieldState} />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup check>
                    <Field
                      name="status"
                      type="radio"
                      fieldLabel="In lavorazione"
                      noValidationMessage={true}
                      value="2"
                    >
                      {(fieldState) => (
                        <RadioInput {...fieldState} />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup check disabled>
                    <Field
                      name="status"
                      type="radio"
                      fieldLabel="Validato"
                      noValidationMessage={true}
                      value="3"
                    >
                      {(fieldState) => (
                        <RadioInput {...fieldState} />
                      )}
                    </Field>
                  </FormGroup>
                  <FormGroup check disabled>
                    <Field
                      name="status"
                      type="radio"
                      fieldLabel="Bloccato"
                      noValidationMessage={true}
                      value="4"
                    >
                      {(fieldState) => (
                        <RadioInput {...fieldState} />
                      )}
                    </Field>
                  </FormGroup>
                  {isActiveUserContract(entityName) &&
                    <FormGroup check disabled>
                      <Field
                        name="status"
                        type="radio"
                        fieldLabel="Sincronizzato"
                        noValidationMessage={true}
                        value="5"
                        defaultValue={itemIdStatus}
                      >
                        {(fieldState) => (
                          <RadioInput {...fieldState} />
                        )}
                      </Field>
                    </FormGroup>
                  }
                  <FormGroup check disabled>
                    <Field
                      name="status"
                      type="radio"
                      fieldLabel="K.O."
                      noValidationMessage={true}
                      value="6"
                    >
                      {(fieldState) => (
                        <RadioInput {...fieldState} />
                      )}
                    </Field>
                  </FormGroup>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  disabled={changingStatus}
                  className="ab-button"
                  type="submit"
                >
                  {changingStatus &&
                    <Spinner color="light" size="sm" className="mr-2" />
                  }
                  {!changingStatus &&
                    <FontAwesomeIcon icon="exchange-alt" className="mr-2" />
                  }
                  {' '}
                  <span>Modifica</span>
                </Button>
                <Button color="secondary" onClick={toggle}>Annulla</Button>
              </ModalFooter>
            </form>
          )}
        </Form>
      </Modal>
    </div>
  )
}

export default SupplyPointStatusUpdate
