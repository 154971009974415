export function isPreviousHolderRequired(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isNewHolderRequired(entityName) {
  return (
    entityName === ''
  ) ? true : false
}

export function isNewHolderTitleNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isMeterReadingNeeded(entityName) {
  return (
    entityName === 'transfer'
  ) ? true : false
}

export function isAccuntHolderAtecoCodeNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isSupplyStartDateNeeded(entityName) {
  return (
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isOldAccountHolderPersonTypeNeeded(entityName) {
  return (
    entityName === 'contextualTransferGas'
  ) ? true : false  
}

export function isOldAccountHolderPhoneNeeded(entityName) {
  return (
    entityName === 'contextualTransferGas'
  ) ? true : false  
}

export function isOldAccountHolderClientCodeNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function getCustomerType(requestedCustomerType, contractCustomerType) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

export function calculateDaysBeforeNow(days) {
  const dateToCalculate = new Date()
  dateToCalculate.setDate(dateToCalculate.getDate() - days)
  return dateToCalculate
}

const CustomerTypes = {
  DOMESTIC_CUSTOMER: 1,
  BUSINESS_CUSTOMER: 2
}

export default CustomerTypes
