export const fetchData = (fetchUrl, accessToken, signal) => {
  const fetchPromise = fetch(
    fetchUrl,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      signal: signal
    }
  )
  .then(response => {
    const contentType = response.headers.get("content-type")
    if(contentType && contentType.includes("application/json")) {
      return response.json()
    }
    return response
  })
  .catch(error => { throw new Error(error) })

  return fetchPromise
}

export const debounce = (func, wait, immediate) => {
	var timeout
	return function() {
		var context = this, args = arguments
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args)
		}
		var callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}
