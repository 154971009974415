import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DomesticRealEstateInstructions = ({ isOpen, toggle }) => (
  <Modal className="utility-modal" isOpen={isOpen} toggle={toggle} size="lg">
    <ModalHeader toggle={toggle}>
      <FontAwesomeIcon className="mr-2" icon="info-circle" />
      Indicazioni per la compilazione
    </ModalHeader>
    <ModalBody>
      <h6 className="strong">Modulo istanza - usi domestici</h6>
      <p>Esempi:</p>
      <ul>
        <li>
          in base a domanda di permesso/domanda di concessione (legge 47/85 e successive modificazioni) in sanatoria presentata al Comune di …………. in data …………., allegata in copia alla presente, unitamente alla copia dei prescritti versamenti effettuati. Al riguardo dichiara di avere regolarmente ottemperato a tutti gli adempimenti previsti dalla normativa statale e regionale in materia e che l’immobile per cui richiede la fornitura non presenta caratteristiche ostative all’ottenimento della concessione/permesso in sanatoria;
        </li>
        <li>
          In  base  a  denuncia  di  inizio  attività  (D.I.A.)  presentata  ai  sensi  dell’art.22,  comma  3  del  DPR  380/01  e  successive modificazioni/art.4, comma 7 della Legge 493/93 e successive modificazioni” al Comune di …………. in data …………. in relazione alla quale è intervenuto il silenzio-assenso del predetto comune, non essendo necessaria per l’esecuzione di dette opere né concessione edilizia né autorizzazione;
        </li>
        <li>in base ad autorizzazione n. …………., del …………., rilasciata da ………….</li>
        <li>prima della data del 30.01.1977</li>
      </ul>
    </ModalBody>
  </Modal>
)

export default DomesticRealEstateInstructions