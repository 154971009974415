import ContractTypes from './contract.types'
import UserTypes from '../user/user.types'
import { ContractTabsMap, ContractStatusMap } from './contract.utils'
import {
  deleteItemById, updateItemStatus, updateUtilityModal, updateUtilityToast,
  getFormStep, getActiveUserFormStep, updateSupplyPointItemStatus, AsyncStatusEnum
} from './contract.utils'

const INITIAL_STATE = {
  isFetching: [null, AsyncStatusEnum.IDLE],
  isSubmitting: [null, AsyncStatusEnum.IDLE], //Form submitting tuple [<entity>, <status>]
  isDeleting: [null, AsyncStatusEnum.IDLE], //Delete item bool
  entityName: '', //Contract slug
  selectedContractId: null,
  selectedContractStatusId: null, //Contract status id => see ContractStatusMap in utils
  customerType: null, //Domestic(1) or Business(2) user type
  formStep: ContractTabsMap.PROPOSAL_STEP, //Bigger form step id available
  selectedStep: ContractTabsMap.PROPOSAL_STEP, //Form selected step id => see ContractTabsMap
  formFilters: null, //Lists filters
  formFieldsValues: null, //Forms values (coming from API)
  formDefaultValues: null, //Form default values if editing
  userData: null,
  customerData: undefined,
  proposalData: undefined,
  bankAccountDetailModalVisibility: false,
  bankAccountData: undefined,
  bankAccountDetailData: undefined,
  cadastralData: undefined,
  cadastralDetailModalVisibility: false,
  cadastralDetailData: undefined,
  supplyPoints: undefined,
  supplyPointModalVisibility: false,
  activeUserSupplyPointData: undefined,
  podDetailModalVisibility: false,
  podData: undefined,
  pdrDetailModalVisibility: false,
  pdrData: undefined,
  attachmentsQueue: [],
  attachmentsData: null,
  activeUserAttachmentsData: undefined,
  contractPods: [],
  contractPdrs: [],
  contractSupplyPointCorrelations: [],
  selectedSignatureType: null,
  contractFinalizationModalVisibility: false,
  otpData: null,
  otpSignerId: null,
  otpSignatureModalVisibility: false,
  attachmentsIntegrationModalVisibility: false,
  closeAttachmentsIntegrationModalVisibility: false,
  contractPdfUrl: null,
  selectedListUrl: null,
  selectedList: { //Lists values & params
    items: null,
    pages: 0,
    count: 0,
    page: 1,
    filters: "",
    sorts: "sorts=-insertDate",
  },
  contractReports: [],
  contractNotes: [],
  itemHistoryData: [],
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  },
  utilityToast: {
    isOpen: false,
    toastColor: '',
    toastHeader: '',
    toastBody: '',
    toastIcon: ['fas', 'check-circle']
  },
  error: null,
}

const contractReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case ContractTypes.TOGGLE_CONTRACT_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          visibility: !state.utilityModal.visibility
        }
      }

    case ContractTypes.TOGGLE_CONTRACT_UTILITY_TOAST_VISIBILITY:
      return {
        ...state,
        utilityToast: {
          ...state.utilityToast,
          isOpen: !state.utilityToast.isOpen
        }
      }

    case ContractTypes.CONTRACT_SHOW_ERROR_MESSAGE:
      return {
        ...state,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_RESET:
      return INITIAL_STATE

    case ContractTypes.CONTRACT_SET_ENTITY_NAME:
      return {
        ...state,
        entityName: action.payload
      }

    case ContractTypes.CONTRACT_CHANGE_FORM_STEP:
      return {
        ...state,
        selectedStep: action.payload
      }

    case ContractTypes.CONTRACT_GET_FORM_FIELDS_VALUES_START:
      return {
        ...state,
        formFieldsValues: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_FORM_FIELDS_VALUES_SUCCESS:
      return {
        ...state,
        formFieldsValues: action.payload
      }

    case ContractTypes.CONTRACT_GET_FORM_FIELDS_VALUES_FAILURE:
      return {
        ...state,
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_USER_SUBMIT_FORM_START:
      return {
        ...state,
        error: null,
        isSubmitting: ['user', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_USER_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: ['user', AsyncStatusEnum.SUCCESS],
        selectedContractId: action.payload.userData.id,
        userData: action.payload.userData,
        utilityToast: updateUtilityToast({
          toastHeader: 'Utente',
          toastBody: 'Utente aggiornato con successo!'
        })
      }

    case ContractTypes.CONTRACT_USER_SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isSubmitting: ['user', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_ITEM_USER_DATA_START:
      return {
        ...state,
        selectedContractId: action.payload,
        userData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_ITEM_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload
      }

    case ContractTypes.CONTRACT_GET_ITEM_USER_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_USER_MASSIVE_CREATION_START:
      return {
        ...state,
        error: null,
        isSubmitting: ['user', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_USER_MASSIVE_CREATION_SUCCESS:
      return {
        ...state,
        isSubmitting: ['user', AsyncStatusEnum.SUCCESS],
        utilityToast: updateUtilityToast({
          toastHeader: 'Creazione massiva',
          toastBody: 'Creazione massiva utenti avviata!'
        })
      }

    case ContractTypes.CONTRACT_USER_MASSIVE_CREATION_FAILURE:
      return {
        ...state,
        isSubmitting: ['user', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_CADASTRAL_DATA_START:
      return {
        ...state,
        isFetching: ['cadastral', AsyncStatusEnum.LOADING],
        cadastralData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_CADASTRAL_DATA_SUCCESS:
      return {
        ...state,
        isFetching: ['cadastral', AsyncStatusEnum.SUCCESS],
        cadastralData: action.payload.cadastralData,
        bankAccountData: action.payload.bankAccountData.items
      }

    case ContractTypes.CONTRACT_GET_CADASTRAL_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['cadastral', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_CADASTRAL_DETAIL_START:
      return {
        ...state,
        isFetching: [`cadastralDetail-${action.payload.cadastralDetailId}`, AsyncStatusEnum.LOADING],
        cadastralDetailData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_CADASTRAL_DETAIL_SUCCESS:
      return {
        ...state,
        cadastralDetailData: action.payload,
        isFetching: [`cadastralDetail-${action.payload.id}`, AsyncStatusEnum.SUCCESS],
        cadastralDetailModalVisibility: true
      }

    case ContractTypes.CONTRACT_GET_CADASTRAL_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: ['cadastralDetail', AsyncStatusEnum.FAILURE],
        utilityModal: updateUtilityModal(action.payload)
      }


    case ContractTypes.CONTRACT_CADASTRAL_SUBMIT_FORM_START:
      return {
        ...state,
        isSubmitting: ['cadastral', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_CADASTRAL_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: ['cadastral', AsyncStatusEnum.SUCCESS],
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni utenze',
          toastBody: 'Dati salvati con successo!',
          toastColor: 'success'
        }),
        proposalData: {
          ...state.proposalData,
          links: action.payload.proposalData.links //TODELETE?
        },
        cadastralData: action.payload.cadastralData,
        cadastralDetailModalVisibility: false
      }

    case ContractTypes.CONTRACT_CADASTRAL_SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isSubmitting: ['cadastral', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_DELETE_CADASTRAL_DATA_START:
      return {
        ...state,
        isDeleting: ['cadastral', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_DELETE_CADASTRAL_DATA_SUCCESS:
      return {
        ...state,
        isDeleting: ['cadastral', AsyncStatusEnum.SUCCESS],
        cadastralData: action.payload.cadastralData,
        formStep: getFormStep(action.payload.proposalData.steps),
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni utenze',
          toastBody: 'Dati eliminati con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_DELETE_CADASTRAL_DATA_FAILURE:
      return {
        ...state,
        isDeleting: ['cadastral', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_POD_DATA_START:
      return {
        ...state,
        isFetching: [`pod-${action.payload.podId}`, AsyncStatusEnum.LOADING],
        cadastralDetailData: action.payload.cadastralItem,
        podData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_POD_DATA_SUCCESS:
      return {
        ...state,
        isFetching: [`pod-${action.payload.id}`, AsyncStatusEnum.SUCCESS],
        podData: action.payload,
        podDetailModalVisibility: true
      }

    case ContractTypes.CONTRACT_GET_POD_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['pod', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_POD_SUBMIT_FORM_START:
      return {
        ...state,
        isSubmitting: ['pod', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_POD_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: ['pod', AsyncStatusEnum.SUCCESS],
        podDetailModalVisibility: false,
        cadastralData: action.payload.cadastralData,
        formStep: getFormStep(action.payload.proposalData.steps),
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni utenze',
          toastBody: 'POD salvato con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_POD_SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isSubmitting: ['pod', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_DELETE_POD_START:
      return {
        ...state,
        isDeleting: [`pod-${action.payload.itemId}`, AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_DELETE_POD_SUCCESS:
      return {
        ...state,
        isDeleting: ['pod', AsyncStatusEnum.SUCCESS],
        formStep: getFormStep(action.payload.proposalData.steps),
        cadastralData: action.payload.cadastralData,
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni utenze',
          toastBody: 'POD eliminato con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_DELETE_POD_FAILURE:
      return {
        ...state,
        isDeleting: ['pod', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_PDR_DATA_START:
      return {
        ...state,
        isFetching: [`pdr-${action.payload.pdrId}`, AsyncStatusEnum.LOADING],
        cadastralDetailData: action.payload.cadastralItem,
        pdrData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_PDR_DATA_SUCCESS:
      return {
        ...state,
        isFetching: [`pdr-${action.payload.id}`, AsyncStatusEnum.SUCCESS],
        pdrData: action.payload,
        pdrDetailModalVisibility: true
      }

    case ContractTypes.CONTRACT_GET_PDR_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['pdr', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_PDR_SUBMIT_FORM_START:
      return {
        ...state,
        isSubmitting: ['pdr', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_PDR_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: ['pdr', AsyncStatusEnum.SUCCESS],
        pdrDetailModalVisibility: false,
        cadastralData: action.payload.cadastralData,
        formStep: getFormStep(action.payload.proposalData.steps),
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni utenze',
          toastBody: 'PDR salvato con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_PDR_SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isSubmitting: ['pdr', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_DELETE_PDR_START:
      return {
        ...state,
        isDeleting: [`pdr-${action.payload.itemId}`, AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_DELETE_PDR_SUCCESS:
      return {
        ...state,
        isDeleting: ['pdr', AsyncStatusEnum.SUCCESS],
        formStep: getFormStep(action.payload.proposalData.steps),
        cadastralData: action.payload.cadastralData,
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni utenze',
          toastBody: 'PDR eliminato con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_DELETE_PDR_FAILURE:
      return {
        ...state,
        isDeleting: ['pdr', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_RESET_POD_DATA:
      return {
        ...state,
        podData: null
      }

    case ContractTypes.CONTRACT_TOGGLE_ACTIVE_USER_SUPPLY_POINT_MODAL:
      return {
        ...state,
        supplyPointModalVisibility: !state.supplyPointModalVisibility
      }

    case ContractTypes.CONTRACT_TOGGLE_POD_DETAIL_MODAL:
      return {
        ...state,
        podDetailModalVisibility: !state.podDetailModalVisibility,
        cadastralDetailData: action.payload || null
      }

    case ContractTypes.CONTRACT_RESET_PDR_DATA:
      return {
        ...state,
        pdrData: null
      }

    case ContractTypes.CONTRACT_TOGGLE_PDR_DETAIL_MODAL:
      return {
        ...state,
        pdrDetailModalVisibility: !state.pdrDetailModalVisibility,
        cadastralDetailData: action.payload || null
      }

    case ContractTypes.CONTRACT_BLOCK_ALL_SUPPLY_POINTS_START:
    case ContractTypes.CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_START:
      return {
        ...state,
        isSubmitting: ['allSupplyPoints', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_BLOCK_ALL_SUPPLY_POINTS_SUCCESS:
    case ContractTypes.CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_SUCCESS:
      return {
        ...state,
        isSubmitting: ['allSupplyPoints', AsyncStatusEnum.SUCCESS],
        cadastralData: action.payload.cadastralData
      }

    case ContractTypes.CONTRACT_BLOCK_ALL_SUPPLY_POINTS_FAILURE:
    case ContractTypes.CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_FAILURE:
      return {
        ...state,
        isSubmitting: ['allSupplyPoints', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_TOGGLE_CADASTRAL_DETAIL_MODAL:
      return {
        ...state,
        cadastralDetailModalVisibility: !state.cadastralDetailModalVisibility,
        cadastralDetailData: action.payload || null
      }

    case ContractTypes.CONTRACT_RESET_CADASTRAL_DATA:
      return {
        ...state,
        cadastralDetailData: null
      }

    case ContractTypes.CONTRACT_GET_ATTACHMENTS_DATA_START:
      return {
        ...state,
        isFetching: ['attachment', AsyncStatusEnum.LOADING],
        attachmentsData: null,
        attachmentsQueue: [],
        contractPods: [],
        contractPdrs: [],
        error: null
      }

    case ContractTypes.CONTRACT_GET_ATTACHMENTS_DATA_SUCCESS:
      return {
        ...state,
        isFetching: ['attachment', AsyncStatusEnum.SUCCESS],
        attachmentsData: action.payload.attachmentsDataAsObject,
        contractSupplyPointCorrelations: action.payload.contractSupplyPointCorrelations?.data ?? [],
        contractPods: action.payload.contractPods,
        contractPdrs: action.payload.contractPdrs
      }

    case ContractTypes.CONTRACT_GET_ATTACHMENTS_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['attachment', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_ADD_NEW_ATTACHMENT_TO_QUEUE:
      return {
        ...state,
        attachmentsQueue: state.attachmentsQueue.concat(action.payload)
      }

    case ContractTypes.CONTRACT_REMOVE_ATTACHMENT_FROM_QUEUE:
      return {
        ...state,
        attachmentsQueue: state.attachmentsQueue.filter(item => item.tempId !== action.payload)
      }

    case ContractTypes.CONTRACT_SUBMIT_ATTACHMENT_FORM_START:
      return {
        ...state,
        isSubmitting: ['attachment', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_SUBMIT_ATTACHMENT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: ['attachment', AsyncStatusEnum.SUCCESS],
        attachmentsData: action.payload,
        attachmentsQueue: [],
        utilityToast: updateUtilityToast({
          toastHeader: 'Allegati',
          toastBody: 'Allegato caricato con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_SUBMIT_ATTACHMENT_FORM_FAILURE:
      return {
        ...state,
        attachmentsQueue: [],
        isSubmitting: ['attachment', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_START:
      return {
        ...state,
        isFetching: ['activeUserAttachments', AsyncStatusEnum.LOADING],
        activeUserAttachmentsData: null
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        isFetching: ['activeUserAttachments', AsyncStatusEnum.SUCCESS],
        activeUserAttachmentsData: action.payload.activeUserAttachmentsDataAsObject,
        attachmentsData: action.payload.attachmentsDataAsObject,
        contractSupplyPointCorrelations: action.payload.contractSupplyPointCorrelations?.data ?? [],
        contractPods: action.payload.contractPods,
        contractPdrs: action.payload.contractPdrs
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_FAILURE:
      return {
        ...state,
        isFetching: ['activeUserAttachments', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_START:
      return {
        ...state,
        isSubmitting: ['activeUserAttachments', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        isSubmitting: ['activeUserAttachments', AsyncStatusEnum.SUCCESS],
        activeUserAttachmentsData: action.payload.activeUserAttachmentsDataAsObject,
        formStep: getActiveUserFormStep(action.payload.proposalData.steps),
        utilityToast: updateUtilityToast({
          toastHeader: 'Allegati',
          toastBody: 'Allegati caricati con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_FAILURE:
      return {
        ...state,
        isSubmitting: ['activeUserAttachments', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_DELETE_ATTACHMENT_START:
      return {
        ...state,
        isDeleting: ['attachment', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isDeleting: ['attachment', AsyncStatusEnum.SUCCESS],
        attachmentsData: deleteItemById(state.attachmentsData, action.payload),
        utilityToast: updateUtilityToast({
          toastHeader: 'Allegati',
          toastBody: 'Dati eliminati con successo!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_DELETE_ATTACHMENT_FAILURE:
      return {
        ...state,
        isDeleting: ['attachment', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case UserTypes.USER_GET_SIGNATURE_TYPES_SUCCESS:
      return {
        ...state,
        selectedSignatureType: null
      }

    case ContractTypes.CONTRACT_SET_SELECTED_SIGNATURE_TYPE:
      return {
        ...state,
        selectedSignatureType: action.payload.signatureType,
        contractFinalizationModalVisibility: true
      }

    case ContractTypes.CONTRACT_TOGGLE_CONTRACT_FINALIZATION_MODAL_VISIBILITY:
      return {
        ...state,
        contractFinalizationModalVisibility: !state.contractFinalizationModalVisibility,
        otpData: null,
        selectedSignatureType: null
      }

    case ContractTypes.CONTRACT_GET_OTP_DATA_START:
      return {
        ...state,
        isFetching: ['otpData', AsyncStatusEnum.LOADING],
        otpData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_OTP_DATA_SUCCESS:
      return {
        ...state,
        isFetching: ['otpData', AsyncStatusEnum.SUCCESS],
        otpData: action.payload.otpData,
        attachmentsData: action.payload.attachmentsDataAsObject,
        contractSupplyPointCorrelations: action.payload.contractSupplyPointCorrelations?.data ?? [],
        contractPods: action.payload.contractPods,
        contractPdrs: action.payload.contractPdrs
      }

    case ContractTypes.CONTRACT_GET_OTP_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['otpData', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_OTP_CODE_START:
      return {
        ...state,
        isSubmitting: ['otpCode', AsyncStatusEnum.LOADING],
        otpSignerId: action.payload.values.signerId,
        error: null
      }

    case ContractTypes.CONTRACT_GET_OTP_CODE_SUCCESS:
      return {
        ...state,
        isSubmitting: ['otpCode', AsyncStatusEnum.SUCCESS],
        otpSignatureModalVisibility: true
      }

    case ContractTypes.CONTRACT_GET_OTP_CODE_FAILURE:
      return {
        ...state,
        isSubmitting: ['otpCode', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_SUBMIT_SIGNATURE_START:
      return {
        ...state,
        isSubmitting: ['signature', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_SUBMIT_SIGNATURE_SUCCESS:
      return {
        ...state,
        isSubmitting: ['signature', AsyncStatusEnum.SUCCESS],
        otpData: action.payload.otpData,
        utilityToast: updateUtilityToast({
          toastHeader: 'Firma OTP',
          toastBody: 'Firma avvenuta con successo!',
          toastColor: 'success'
        }),
        selectedContractStatusId: action.payload.proposalData.statusId,
        selectedStep: action.payload.proposalData.statusId > ContractStatusMap.ONGOING_STATUS ? (
          ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP
        ) : (
          state.selectedStep
        ),
        formStep: getFormStep(action.payload.proposalData.steps),
        contractFinalizationModalVisibility: action.payload.proposalData.statusId > ContractStatusMap.ONGOING_STATUS ? (
          false
        ) : (
          state.contractFinalizationModalVisibility
        ),
        otpSignatureModalVisibility: false,
        proposalData: {
          ...state.proposalData,
          links: action.payload.proposalData.links,
          signingDate: action.payload.proposalData.signingDate
        },
      }

    case ContractTypes.CONTRACT_SUBMIT_SIGNATURE_FAILURE:
      return {
        ...state,
        isSubmitting: ['signature', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_SUBMIT_PAPER_SIGNATURE_START:
      return {
        ...state,
        isSubmitting: ['signature', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_SUBMIT_PAPER_SIGNATURE_SUCCESS:
      return {
        ...state,
        attachmentsQueue: [],
        isSubmitting: ['signature', AsyncStatusEnum.SUCCESS],
        utilityToast: updateUtilityToast({
          toastHeader: 'Finalizzazione contratto',
          toastBody: 'Finalizzazione del contratto avvenuta con successo!',
          toastColor: 'success'
        }),
        selectedStep: ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP,
        formStep: getFormStep(action.payload.proposalData.steps),
        selectedContractStatusId: action.payload.proposalData.statusId,
        contractFinalizationModalVisibility: false,
        proposalData: {
          ...state.proposalData,
          links: action.payload.proposalData.links,
          signingDate: action.payload.proposalData.signingDate
        }
      }

    case ContractTypes.CONTRACT_SUBMIT_PAPER_SIGNATURE_FAILURE:
      return {
        ...state,
        attachmentsQueue: [],
        isSubmitting: ['signature', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_PDF_URL_START:
      return {
        ...state,
        contractPdfUrl: null,
        isFetching: ['pdfUrl', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_GET_PDF_URL_SUCCESS:
      return {
        ...state,
        contractPdfUrl: action.payload.signedContractLink,
        isFetching: ['pdfUrl', AsyncStatusEnum.SUCCESS],
      }

    case ContractTypes.CONTRACT_GET_PDF_URL_FAILURE:
      return {
        ...state,
        isFetching: ['pdfUrl', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_TOGGLE_OTP_SIGNATURE_MODAL_VISIBILITY:
      return {
        ...state,
        otpSignatureModalVisibility: !state.otpSignatureModalVisibility
      }

    case ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_START:
      return {
        ...state,
        isFetching: ['bankAccount', AsyncStatusEnum.LOADING],
        bankAccountData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        isFetching: ['bankAccount', AsyncStatusEnum.SUCCESS],
        bankAccountData: action.payload.items
      }

    case ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: ['bankAccount', AsyncStatusEnum.FAILURE],
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_TOGGLE_BANK_ACCOUNT_DETAIL_MODAL:
      return {
        ...state,
        bankAccountDetailModalVisibility: !state.bankAccountDetailModalVisibility
      }

    case ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_START:
      return {
        ...state,
        bankAccountDetailData: null,
        isFetching: ['bankAccountDetail', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        bankAccountDetailData: action.payload,
        isFetching: ['bankAccountDetail', AsyncStatusEnum.SUCCESS],
        bankAccountDetailModalVisibility: true
      }

    case ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: ['bankAccountDetail', AsyncStatusEnum.FAILURE],
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_START:
      return {
        ...state,
        isSubmitting: ['bankAccount', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: ['bankAccount', AsyncStatusEnum.SUCCESS],
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni di pagamento',
          toastBody: 'Dati salvati con successo!',
          toastColor: 'success'
        }),
        bankAccountData: action.payload.bankAccountData.items,
        formStep: getFormStep(action.payload.proposalData.steps),
        bankAccountDetailModalVisibility: false
      }

    case ContractTypes.CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isSubmitting: ['bankAccount', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_RESET_BANK_ACCOUNT_DATA:
      return {
        ...state,
        bankAccountDetailData: null
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_START:
      return {
        ...state,
        selectedContractId: action.payload.contractId,
        proposalData: null,
        customerData: null,
        isFetching: ['proposal', AsyncStatusEnum.LOADING],
        entityName: action.payload.entityName || state.entityName,
        error: null
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_SUCCESS:
      return {
        ...state,
        proposalData: action.payload.proposalData,
        customerData: action.payload.customerData,
        isFetching: ['proposal', AsyncStatusEnum.SUCCESS],
        selectedStep: ContractTabsMap.PROPOSAL_STEP,
        formStep: getActiveUserFormStep(action.payload.proposalData.steps),
        selectedContractStatusId: action.payload.proposalData.statusId,
        customerType: action.payload.proposalData.customerType
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['proposal', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_ITEM_PROPOSAL_DATA_START:
      return {
        ...state,
        selectedContractId: action.payload.contractId,
        proposalData: null,
        isFetching: ['proposal', AsyncStatusEnum.LOADING],
        entityName: action.payload.entityName || state.entityName,
        error: null
      }

    case ContractTypes.CONTRACT_GET_ITEM_PROPOSAL_DATA_SUCCESS:
      return {
        ...state,
        proposalData: action.payload,
        isFetching: ['proposal', AsyncStatusEnum.SUCCESS],
        selectedStep: ContractTabsMap.PROPOSAL_STEP,
        formStep: getFormStep(action.payload.steps),
        selectedContractStatusId: action.payload.statusId,
        customerType: action.payload.customerType
      }

    case ContractTypes.CONTRACT_GET_ITEM_PROPOSAL_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['proposal', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_PROPOSAL_SUBMIT_FORM_START:
      return {
        ...state,
        isSubmitting: ['proposal', AsyncStatusEnum.LOADING],
        error: null,
      }

    case ContractTypes.CONTRACT_PROPOSAL_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: ['proposal', AsyncStatusEnum.SUCCESS],
        utilityToast: updateUtilityToast({
          toastHeader: 'Proposta',
          toastBody: 'Dati salvati con successo!',
          toastColor: 'success'
        }),
        proposalData: action.payload || undefined,
        selectedContractId: action.payload?.id || null,
        formStep: action.payload ? getFormStep(action.payload.steps) : ContractTabsMap.PROPOSAL_STEP,
        // selectedStep: action.payload ? ContractTabsMap.PAYMENT_STEP : state.selectedStep,
        selectedContractStatusId: action.payload?.statusId || null,
        customerType: action.payload?.customerType || null
      }

    case ContractTypes.CONTRACT_PROPOSAL_SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isSubmitting: ['proposal', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_CUSTOMER_DATA_START:
      return {
        ...state,
        customerData: null,
        isFetching: ['customer', AsyncStatusEnum.LOADING],
        entityName: action.payload.entityName,
        error: null
    }

    case ContractTypes.CONTRACT_GET_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: ['customer', AsyncStatusEnum.SUCCESS],
        customerData: action.payload,
        selectedStep: ContractTabsMap.PROPOSAL_STEP,
        selectedContractStatusId: action.payload.statusId,
      }

    case ContractTypes.CONTRACT_GET_CUSTOMER_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['customer', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_START:
      return {
        ...state,
        isFetching: ['supplyPoints', AsyncStatusEnum.LOADING],
        supplyPoints: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_SUCCESS:
      return {
        ...state,
        isFetching: ['supplyPoints', AsyncStatusEnum.SUCCESS],
        supplyPoints: action.payload.supplyPoints
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_FAILURE:
      return {
        ...state,
        isFetching: ['supplyPoints', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_START:
      return {
        ...state,
        isDeleting: [`supplyPointTechOp-${action.payload.itemId}`, AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_SUCCESS:
      return {
        ...state,
        isDeleting: ["supplyPointTechOp", AsyncStatusEnum.SUCCESS],
        formStep: getActiveUserFormStep(action.payload.proposalData.steps),
        supplyPoints: action.payload.supplyPoints,
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni punto di fornitura',
          toastBody: 'Operazione annullata con successo!',
          toastColor: 'success'
        }),
        error: null
      }
      
    case ContractTypes.CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_FAILURE:
      return {
        ...state,
        isDeleting: ['supplyPointTechOp', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_START:
    case ContractTypes.CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_START:
      return {
        ...state,
        isSubmitting: ['allSupplyPoints', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS:
    case ContractTypes.CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS:
        return {
        ...state,
        isSubmitting: ['allSupplyPoints', AsyncStatusEnum.SUCCESS],
        supplyPoints: action.payload.supplyPoints
      }

    case ContractTypes.CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE:
    case ContractTypes.CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE:
        return {
        ...state,
        isSubmitting: ['allSupplyPoints', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_START:
      return {
        ...state,
        isFetching: [`supplyPointTechOp-${action.payload.id}`, AsyncStatusEnum.LOADING],
        activeUserSupplyPointData: null,
        error: null
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS:
      return {
        ...state,
        isFetching: [`supplyPointTechOp-${action.payload.id}`, AsyncStatusEnum.SUCCESS],
        activeUserSupplyPointData: action.payload,
        supplyPointModalVisibility: true
      }

    case ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['supplyPointTechOp', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_START:
      return {
        ...state,
        isSubmitting: ['supplyPointTechOp', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS:
      return {
        ...state,
        isSubmitting: ['supplyPointTechOp', AsyncStatusEnum.SUCCESS],
        supplyPointModalVisibility: false,
        formStep: getActiveUserFormStep(action.payload.proposalData.steps),
        supplyPoints: action.payload.supplyPoints,
        utilityToast: updateUtilityToast({
          toastHeader: 'Informazioni punto di fornitura',
          toastBody: 'Modifiche salvate correttamente!',
          toastColor: 'success'
        })
      }

    case ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE:
      return {
        ...state,
        isSubmitting: ['supplyPointTechOp', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_UPDATE_ITEM_STATUS_START:
      return {
        ...state,
        isSubmitting: ['itemStatus', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_UPDATE_ITEM_STATUS_SUCCESS:
      return {
        ...state,
        isSubmitting: ['itemStatus', AsyncStatusEnum.SUCCESS],
        selectedList: {
          ...state.selectedList,
          items: state.selectedList?.items ? updateItemStatus(action.payload, state.selectedList.items) : {}
        },
        cadastralData: action.payload.cadastralDataId ? ({
          ...state.cadastralData,
          canValidateAll: action.payload.canValidateAll,
          canBlockAll: action.payload.canBlockAll,
          items: updateSupplyPointItemStatus(state.cadastralData, action.payload)
        }) : null,
        supplyPoints: action.payload.supplyPoints || null
      }

    case ContractTypes.CONTRACT_UPDATE_ITEM_STATUS_FAILURE:
      return {
        ...state,
        isSubmitting: ['itemStatus', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_ITEM_HISTORY_DATA_START:
      return {
        ...state,
        isFetching: ['historyData', AsyncStatusEnum.LOADING],
        itemHistoryData: []
      }

    case ContractTypes.CONTRACT_GET_ITEM_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        isFetching: ['historyData', AsyncStatusEnum.SUCCESS],
        itemHistoryData: action.payload,
        error: null
      }

    case ContractTypes.CONTRACT_GET_ITEM_HISTORY_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['historyData', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_LIST_FILTERS_START:
      return {
        ...state,
        formFilters: null
      }

    case ContractTypes.CONTRACT_GET_LIST_FILTERS_SUCCESS:
      return {
        ...state,
        error: null,
        formFilters: action.payload
      }

    case ContractTypes.CONTRACT_GET_LIST_FILTERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_NOTES_START:
      return {
        ...state,
        isFetching: ['notes', AsyncStatusEnum.LOADING],
        contractNotes: []
      }

    case ContractTypes.CONTRACT_GET_NOTES_SUCCESS:
      return {
        ...state,
        isFetching: ['notes', AsyncStatusEnum.SUCCESS],
        contractNotes: action.payload
      }

    case ContractTypes.CONTRACT_GET_NOTES_FAILURE:
      return {
        ...state,
        isFetching: ['notes', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_SUBMIT_NOTE_START:
      return {
        ...state,
        isSubmitting: ['note', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_SUBMIT_NOTE_SUCCESS:
      return {
        ...state,
        isSubmitting: ['note', AsyncStatusEnum.SUCCESS],
        contractNotes: action.payload.notes,
        selectedList: {
          ...state.selectedList,
          items: action.payload.items
        },
        error: null
      }

    case ContractTypes.CONTRACT_SUBMIT_NOTE_FAILURE:
      return {
        ...state,
        isSubmitting: ['note', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_REPORTS_START:
      return {
        ...state,
        isFetching: ['reports', AsyncStatusEnum.LOADING],
        contractReports: []
      }

    case ContractTypes.CONTRACT_GET_REPORTS_SUCCESS:
      return {
        ...state,
        isFetching: ['reports', AsyncStatusEnum.SUCCESS],
        contractReports: action.payload,
        error: null
      }

    case ContractTypes.CONTRACT_GET_REPORTS_FAILURE:
      return {
        ...state,
        isFetching: ['reports', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_SUBMIT_REPORT_START:
      return {
        ...state,
        isSubmitting: ['report', AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_SUBMIT_REPORT_SUCCESS:
      return {
        ...state,
        isSubmitting: ['report', AsyncStatusEnum.SUCCESS],
        contractReports: action.payload.reports,
        selectedList: {
          ...state.selectedList,
          items: action.payload.items
        },
        error: null
      }

    case ContractTypes.CONTRACT_SUBMIT_REPORT_FAILURE:
      return {
        ...state,
        isSubmitting: ['report', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_GET_LIST_DATA_START:
      return {
        ...state,
        isFetching: ['list', AsyncStatusEnum.LOADING],
        selectedListUrl: action.payload.apiUrl,
        selectedList: {
          ...INITIAL_STATE.selectedList,
          filters: state.selectedList.filters,
          sorts: state.selectedList.sorts,
          page: state.selectedList.page,
        },
        entityName: action.payload.entityName || state.entityName
      }

    case ContractTypes.CONTRACT_GET_LIST_DATA_SUCCESS:
      return {
        ...state,
        isFetching: ['list', AsyncStatusEnum.SUCCESS],
        selectedList: {
          ...state.selectedList,
          ...action.payload
        },
        error: null
      }

    case ContractTypes.CONTRACT_GET_LIST_DATA_FAILURE:
      return {
        ...state,
        isFetching: ['list', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_DELETE_ITEM_START:
      return {
        ...state,
        isDeleting: [`${action.payload.itemId || 'deleteItem'}`, AsyncStatusEnum.LOADING]
      }

    case ContractTypes.CONTRACT_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        isDeleting: ['deleteItem', AsyncStatusEnum.SUCCESS],
        error: null,
        selectedList: {
          ...state.selectedList,
          ...action.payload
        },
        utilityToast: updateUtilityToast({
          toastHeader: 'Eliminazione',
          toastBody: 'Elemento eliminato con successo!'
        })
      }

    case ContractTypes.CONTRACT_DELETE_ITEM_FAILURE:
      return {
        ...state,
        isDeleting: ['deleteItem', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_UPDATE_LIST_FILTERS:
      return {
        ...state,
        selectedList: {
          ...state.selectedList,
          filters: action.payload
        }
      }

    case ContractTypes.CONTRACT_UPDATE_LIST_PAGE:
      return {
        ...state,
        selectedList: {
          ...state.selectedList,
          page: action.payload
        }
      }

    case ContractTypes.CONTRACT_UPDATE_LIST_SORTS:
      return {
        ...state,
        selectedList: {
          ...state.selectedList,
          sorts: action.payload
        }
      }

    case ContractTypes.CONTRACT_RESET_LIST:
      return {
        ...state,
        selectedListUrl: null,
        selectedList: {
          ...INITIAL_STATE.selectedList
        }
      }

    case ContractTypes.CONTRACT_TOGGLE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY:
      return {
        ...state,
        attachmentsIntegrationModalVisibility: !state.attachmentsIntegrationModalVisibility
      }

    case ContractTypes.CONTRACT_TOGGLE_CLOSE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY:
      return {
        ...state,
        closeAttachmentsIntegrationModalVisibility: !state.closeAttachmentsIntegrationModalVisibility
      }

    case ContractTypes.CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_START:
      return {
        ...state,
        isSubmitting: ['attachmentsIntegration', AsyncStatusEnum.LOADING],
        error: null        
      }

    case ContractTypes.CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_SUCCESS:
      return {
        ...state,
        isSubmitting: ['attachmentsIntegration', AsyncStatusEnum.SUCCESS],
        utilityToast: updateUtilityToast({
          toastHeader: 'Allegati',
          toastBody: 'Richiesta di integrazione allegati inviata correttamente!'
        }),
        proposalData: action.payload.proposalData
      }

    case ContractTypes.CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_FAILURE:
      return {
        ...state,
        isSubmitting: ['attachmentsIntegration', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_START:
      return {
        ...state,
        isSubmitting: ['attachmentsIntegration', AsyncStatusEnum.LOADING],
        error: null
      }

    case ContractTypes.CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_SUCCESS:
      return {
        ...state,
        isSubmitting: ['attachmentsIntegration', AsyncStatusEnum.SUCCESS],
        utilityToast: updateUtilityToast({
          toastHeader: 'Allegati',
          toastBody: 'Chiusura integrazione allegati eseguita correttamente!'
        }),
        proposalData: action.payload.proposalData,
        closeAttachmentsIntegrationModalVisibility: false
      }

    case ContractTypes.CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_FAILURE:
      return {
        ...state,
        isSubmitting: ['attachmentsIntegration', AsyncStatusEnum.FAILURE],
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    default:
      return state
  }
}

export default contractReducer
