import { combineReducers } from 'redux'

import userReducer from './user/user.reducer'
import contractReducer from './contract/contract.reducer'
import salesOffersReducer from './salesOffers/salesOffers.reducer'

const rootReducer = combineReducers({
  user: userReducer,
  contract: contractReducer,
  salesOffers: salesOffersReducer,
})

export default rootReducer
