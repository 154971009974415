import React from 'react'
import { Button } from 'reactstrap'

const DomesticText = ({ toggleModal }) => (
  <p className="text">
    Ai sensi e per gli effetti degli articoli 1341 e 1342 Cod. Civ. il Cliente dichiara di aver preso visione e di approvare specificatamente le seguenti clausole di cui alle &nbsp;
    <Button color="link" className="link-button" onClick={toggleModal} size="sm">Condizioni Generali di Contratto</Button>: Art. 3 (Conclusione ed efficacia del Contratto); Art. 4 (Decorrenza della fornitura, durata del Contratto e recesso); Art. 5 (Clausola risolutiva espressa, risoluzione e sospensione della fornitura); Art. 7 (Variazione delle condizioni di fornitura - Recesso dal rapporto con il precedente fornitore); Art. 8 (Cessione del Contratto - Cessione, affitto e usufrutto d’azienda - Cessione del credito); Art. 9 (Condizioni economiche, corrispettivi); Art. 10 (Fatturazione e pagamenti, calcolo dei corrispettivi); Art. 13 (Garanzie); Art. 16 (Limitazione di Responsabilità di ABenergie); Art. 17 (Utilizzo della fornitura e uso Improprio); Art. 18 (Foro Competente e fonti normative); Art. 19 (Elezione di domicilio e comunicazioni).
  </p>
)

export default DomesticText
