import { getAPIUrl, fetchData, submitRequest } from '../../api'

export function setUserMustChangePasswordValue(apiUrl, values) {
  return submitRequest(apiUrl, '', values, 'PATCH', false)
    .then(response => response)
}

export function sendResetPasswordEmail(values) {
  return submitRequest(`${getAPIUrl('abStorePortal')}/users/send-reset-email`, '', values, 'POST', false)
    .then(response => response.data)
}

export function checkEmail(email) {
  return fetchData(`${getAPIUrl('abStorePortal')}/users/must-change-pwd?username=${email}`, '', false)
      .then(response => response.data)
}

export function fetchSignatureTypes(contractId, openingCausal, accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/users/signature-methods?contractId=${contractId}&openingCausal=${openingCausal}`, accessToken)
    .then(response => response)
}

export function abLogin(accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/login`, accessToken)
    .then(response => {
      response.accessToken = accessToken
      return response
    })
}

export function getUserEntities(accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/login/entities`, accessToken)
    .then(data => data.entities)
}

export function updateUtilityModal(modalData) {
  return {
    visibility: true,
    modalTitle: "Si è verificato un problema",
    modalIcon: ['fas', 'exclamation-triangle'],
    modalDescription: modalData.message || ""
  }
}

export function translateErrorMessage(payload) {
  return {
    ...payload,
    message: FirebaseTranslations[payload.code] || payload.message
  }
}

export const FirebaseTranslations = {
  'auth/wrong-password': "La password non è valida oppure l'utente non ne ha una impostata.",
  'auth/user-disabled': "L'utente è stato disabilitato dall'amministratore.",
  'auth/too-many-requests': "L'accesso a questo account è stato temporaneamente sospeso a causa di troppi tentativi di accesso falliti. Puoi ripristinarlo immediatamente facendo il reset della password oppure riprovare più tardi."
}

export const SignatureTypes = {
  OTP_SIGNATURE: 1,
  PAPER_SIGNATURE: 2,
}

export const AsyncStatusEnum = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const LoginSteps = {
  USER_EMAIL_STEP: 1,
  USER_PASSWORD_STEP: 2
}

export const UserRoles = {
  USER_ROLE_ADMIN: 'admin',
  USER_ROLE_VERIFIER: 'validatore',
  USER_ROLE_READER: 'consultatore',
  USER_ROLE_SUPPORT: 'supporto',
  USER_ROLE_SHOP: 'negozio',
  USER_ROLE_RESPONSIBLE: 'responsabile',
  USER_ROLE_AGENCY: 'agenzia',
}

export const MAX_LOGIN_ATTEMPTS = 10
