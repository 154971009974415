import { useSelector } from 'react-redux'
import { Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import infoAllegati from './informativa-allegati.json'
import { selectEntityName } from '../../../redux/contract/contract.selectors'

const ContractAttachmentsInfoAlert = () => {
  const entityName = useSelector(selectEntityName)

  return (
    <>
      {infoAllegati[entityName] &&
        <Alert color="warning" className="mb-4">
          <h5 className="alert-heading">
            <FontAwesomeIcon icon="warning" className="mr-2" /> 
            Non dimenticare
          </h5>
          <div dangerouslySetInnerHTML={{__html: infoAllegati[entityName]}}></div>
        </Alert>
      }
    </>
  )
}

export default ContractAttachmentsInfoAlert