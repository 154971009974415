import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'
import { Button, Spinner, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AttachmentsIntegrationAlert from '../../AttachmentsIntegrationAlert/AttachmentsIntegrationAlert.component'
import AttachmentsDropzone from '../AttachmentsDropzone/AttachmentsDropzone.component'
import { getAPIUrl } from '../../../api'
import { 
  selectContract, selectPermissions, selectAttachmentsDataAsArray, 
  selectIsSubmitting, selectAttachmentsIntegratinHistory 
} from '../../../redux/contract/contract.selectors'
import { contractCloseAttachmentsIntegrationStart, contractToggleCloseAttachmentsIntegrationModalVisibility, contractSubmitAttachmentFormStart } from '../../../redux/contract/contract.actions'
import { getEntityUriName } from '../../../redux/contract/contract.utils'
import ScrollToTop from '../../../hooks/navigation/useScrollToTop'
import CanI from '../../auth/CanI/CanI.component'
import { canI } from '../../auth/CanI/utils'
import { setUpSubmitValues } from './utils'
import AttachmentsFormStyle from './style'

//Focus on firt field with an error
const focusOnError = createDecorator()

const AttachmentsForm = () => {
  const dispatch = useDispatch()
  const { selectedContractId, entityName, closeAttachmentsIntegrationModalVisibility } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('attachment')(state))
  const permissions = useSelector(selectPermissions)
  const attachmentsData = useSelector(selectAttachmentsDataAsArray)
  const attachmentsIntegrationHistory = useSelector(selectAttachmentsIntegratinHistory)
  const { abilityCheck: attachmentsIntegrationVisibility } = canI(`${entityName}AttachmentsIntegrationRequestCREATE`, permissions)

  const onSubmit = useCallback(values => {
    //Submit other attachments
    if(values.attachments?.length) {
      const submitValues = values.attachments.map((attachment, i) => {
        const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/attachments${attachmentsData[i].id ? `/${attachmentsData[i].id}` : ''}`
        const method = attachmentsData[i].id ? 'PUT' : 'POST'
        return setUpSubmitValues(entityName, attachment, { encodedFile: attachmentsData[i].encoded, contractId: values.contractId, method, apiUrl })
      })
      return dispatch(contractSubmitAttachmentFormStart({ values: submitValues }))
    }
  }, [dispatch, entityName, attachmentsData])

  return (
    <>
      {(attachmentsIntegrationVisibility || !!attachmentsIntegrationHistory.length) &&
        <AttachmentsIntegrationAlert />
      }
      <AttachmentsFormStyle className="loader-container">
        <ScrollToTop />
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators
          }}
          decorators={[focusOnError]}
          initialValues={{
            contractId: selectedContractId
          }}
        >
          {({ handleSubmit, form: { mutators: { push, pop } }, submitting, pristine, values, reset }) => {
            return (
              <form
                className="two-cols-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="grouped">
                  <div className="grouped-header">
                    <FontAwesomeIcon icon="file" className="mr-2" />
                    Aggiungi allegati al contratto
                  </div>
                  <div className="grouped-body">
                    <Row form>
                      <Col md={12}>
                        <AttachmentsDropzone
                          inputName="attachments"
                          files={attachmentsData}
                          mutators={{
                            push,
                            pop
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="text-right">
                  <CanI doWhat="CREATE" withPermissions={permissions} entityName={`${entityName}Attachment`}>
                    {({ action }) => (
                      <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <Spinner size="sm" color="light" className="mr-2" />
                        ) : (
                          <FontAwesomeIcon icon="upload" className="mr-2" />
                        )}
                        <span>Carica allegati</span>
                      </Button>
                    )}
                  </CanI>
                  <CanI
                    doWhat="CREATE"
                    withPermissions={permissions}
                    entityName={`${entityName}AttachmentsIntegrationClosure`}
                  >
                    {({ action }) => (
                      <>
                        <Button 
                          disabled={isSubmitting}
                          className="ml-2"
                          color="warning"
                          onClick={() => dispatch(contractToggleCloseAttachmentsIntegrationModalVisibility())}
                          data-testid="closure-btn"
                        >
                          <FontAwesomeIcon icon="square-check" className="mr-2" />
                          <span>Concludi integrazione allegati</span>
                        </Button>
                        <Modal 
                          isOpen={closeAttachmentsIntegrationModalVisibility} 
                          toggle={() => dispatch(contractToggleCloseAttachmentsIntegrationModalVisibility())} 
                          className="utility-modal"
                        >
                          <ModalHeader toggle={() => dispatch(contractToggleCloseAttachmentsIntegrationModalVisibility())}>
                            <FontAwesomeIcon size="lg" icon="paperclip" className="mr-2" />
                            Integrazione allegati
                          </ModalHeader>
                          <ModalBody>
                            <p className="desc">
                              Confermando verrà inviata a Logistica un'e-mail di avvenuta integrazione degli 
                              allegati affinché possa verificare che tutto il materiale richiesto sia stato 
                              caricato e si possa quindi procedere con l'elaborazione del contratto.
                            </p>
                            <div className="d-flex justify-content-end mt-5">
                              <Button
                                className="ab-button"
                                onClick={() => 
                                  dispatch(contractCloseAttachmentsIntegrationStart({ 
                                    apiUrl: action,
                                    contractLink: window.location.href
                                  }))
                                }
                              >
                                {isSubmitting ? (
                                  <Spinner size="sm" color="light" className="mr-2" />
                                ) : (
                                  <FontAwesomeIcon icon="paper-plane" className="mr-2" />
                                )}
                                <span>Conferma chiusura</span>
                              </Button>
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    )}
                  </CanI>
                </div>
              </form>
            )
          }}
        </Form>
      </AttachmentsFormStyle>
    </>
  )
}

export default AttachmentsForm
