import React from 'react'
import { useSelector } from 'react-redux'

import { selectIsUserLoggedIn } from '../../redux/user/user.selectors'
import LandingPage from '../LandingPage/LandingPage.component'
import LoginPage from '../LoginPage/LoginPage.component'

const HomePage = () => {
  const userLoggedIn = useSelector(selectIsUserLoggedIn)

  return(
    <div className="page">
      {userLoggedIn ?
        <LandingPage />
        :
        <LoginPage />
      }
    </div>
  )
}

export default HomePage
