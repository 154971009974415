import { Alert, Button, ListGroup, ListGroupItem } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import AttachmentsIntegrationAlertStyle from './style'
import CanI from '../auth/CanI/CanI.component'
import AttachmentsIntegrationModal from '../forms/AttachmentsIntegrationModal/AttachmentsIntegrationModal.component'
import { contractToggleAttachmentsIntegrationModal } from '../../redux/contract/contract.actions'
import { selectAttachmentsIntegratinHistory, selectEntityName, selectPermissions } from '../../redux/contract/contract.selectors'

const AttachmentsIntegrationAlert = () => {
  const dispatch = useDispatch()
  const entityName = useSelector(selectEntityName)
  const permissions = useSelector(selectPermissions)
  const attachmentsIntegrationHistory = useSelector(selectAttachmentsIntegratinHistory)

  return (
    <>
      <AttachmentsIntegrationAlertStyle>
        <Alert color="warning">
          <h4 className="alert-heading">Richiesta di integrazione allegati</h4>
          <CanI 
            doWhat="CREATE" 
            withPermissions={permissions} 
            entityName={`${entityName}AttachmentsIntegrationRequest`}
          >
            {({ action }) => (
              <>
                <p className="alert-description">
                  La richiesta di integrazione allegati verrà inviata tramite e-mail all'agente, il quale 
                  potrà effettuare il caricamento dei file mancanti e "chiudere" la procedura di integrazione.
                </p>
                <Button
                  className="ab-button"
                  onClick={() => dispatch(contractToggleAttachmentsIntegrationModal())}
                >
                  Procedi
                </Button>
                <AttachmentsIntegrationModal requestUrl={action} />
              </>
            )}
          </CanI>
          {!!attachmentsIntegrationHistory.length &&
            <ListGroup className="my-3">
              <h4 className="integration-history-title">Storico richieste di integrazione</h4>
              {attachmentsIntegrationHistory.map((({ insertDate, requestingUser, integrationStatus, expirationDate, integrationCauses }, i) => 
                <ListGroupItem key={i}>
                  <p>
                    Il <strong>{moment(insertDate).format('DD/MM/YYYY HH:mm')}</strong>{' '}
                    l'utente <strong>{requestingUser}</strong> ha richiesto un'integrazione degli allegati con la seguente motivazione:
                    <br />
                    <strong>{integrationCauses}</strong>
                  </p>
                  <p className="mb-0">
                    Stato richiesta: <strong>{integrationStatus.label}</strong>
                    {!!expirationDate &&
                      <>
                        <br />
                        In scadenza il <strong>{moment(expirationDate).format('DD/MM/YYYY HH:mm')}</strong>
                      </>
                    }
                  </p>
                </ListGroupItem>
              ))}
            </ListGroup>
          }
        </Alert>
      </AttachmentsIntegrationAlertStyle>
    </>
  )
}

export default AttachmentsIntegrationAlert