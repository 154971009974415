import { createSelector } from 'reselect'

import { AsyncStatusEnum } from './salesOffers.utils'

export const selectSalesOffers = state => state.salesOffers

export const selectIsFetchingData = searchedEntityName => createSelector(
  [selectSalesOffers],
  salesOffers => {
    const [ fetchingEntityName, asyncStatus ] = salesOffers.isFetching
    return searchedEntityName === fetchingEntityName && asyncStatus === AsyncStatusEnum.LOADING ? true : false
  }
)

export const selectSalesOffersList = createSelector(
  [selectSalesOffers],
  salesOffers => salesOffers.salesOffersList || []
)

export const selectUtilityModal = createSelector(
  [selectSalesOffers],
  salesOffers => salesOffers.utilityModal
)