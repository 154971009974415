import moment from 'moment'
import _ from 'lodash'

import { getAPIUrl } from '../../../../api'
import { UserRoles } from '../../../../redux/user/user.utils'

export function parseDate(value) {
  return moment(value).format('YYYY-MM-DD')
}

export function setQueryString(values) {
  let queryParams = []

  _.forEach(values, (value, key) => {
    if(key === 'customerEmail' || key === 'agent' || key === 'customerName') {
      queryParams.push(`${key}@=${value}`)
    }
    else if(key === 'signingDateStart' || key === 'signingDateEnd') {
      if(key === 'signingDateStart') {
        queryParams.push(`signingDate>=${parseDate(value)}`)
      }
      if(key === 'signingDateEnd') {
        queryParams.push(`signingDate<=${parseDate(value)} 23:59:59.999`)
      }
    }
    else if(key === 'insertDateStart' || key === 'insertDateEnd') {
      if(key === 'insertDateStart') {
        queryParams.push(`insertDate>=${parseDate(value)}`)
      }
      if(key === 'insertDateEnd') {
        queryParams.push(`insertDate<=${parseDate(value)} 23:59:59.999`)
      }
    }
    else {
      queryParams.push(`${key}==${value}`)
    }
  })

  return queryParams
}

export function buildFetchUrl(itemsPerPage) {
  return `${getAPIUrl('abStorePortal')}/contracts?`
}

export function buildExcelExportUrl(itemsPerPage) {
  return `${getAPIUrl('abStorePortal')}/contracts/excels?pageSize=${itemsPerPage}`
}

export function buildPdpExcelExportUrl(entityName, itemsPerPage) {
  return `${getAPIUrl('abStorePortal')}/contracts/excels-pdp?pageSize=${itemsPerPage}`
}

export function isReportColNeeded(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER ||
    userRole === UserRoles.USER_ROLE_READER ||
    userRole === UserRoles.USER_ROLE_SUPPORT
  ) ? true : false
}

export function isUserScoreNeeded(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER
  ) ? true : false
}