import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import './assets/sass/main.scss'
import App from './App'
import store from './redux/store'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <App tab="home" />
  </Provider>
)