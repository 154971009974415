import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import { clientCodeCheck } from '../validations'
import { 
  selectContract, selectIsFetchingData 
} from '../../../redux/contract/contract.selectors'
import { contractGetCustomerDataStart } from '../../../redux/contract/contract.actions'

const CustomerCodeSearchForm = () => {
  const dispatch = useDispatch()
  const isFetchingCustomerData = useSelector(state => selectIsFetchingData('customer')(state))
  const { entityName, proposalData, customerData } = useSelector(selectContract)

  const onSubmit = values => {
    dispatch(contractGetCustomerDataStart({ entityName, customerCode: values.customerCode }))
  }

  return (
    <Form
      onSubmit={onSubmit}
    >
      {({ handleSubmit, pristine, values }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="barcode-read" className="mr-2" />
              Ricerca codice cliente
            </div>
            <div className="grouped-body">
              <Row form>
                <Col>
                  <CustomFormField
                    name="customerCode"
                    noValidationMessage
                    fieldLabel="Inserisci il codice cliente per visualizzare i suoi dati"
                    placeholder="Ad esempio: D123456"
                    fieldDescription="Il codice cliente deve iniziare con la lettera D o con la lettera B, seguita da 6 numeri."
                    readOnly={proposalData?.id ? true : false}
                    initialValue={customerData?.customerCode || null}
                  />
                </Col>
              </Row>
              <div className="text-right my-2">
                <Button
                  type="submit"
                  title={
                    clientCodeCheck(values.customerCode) !== undefined ? (
                      "Il codice cliente deve iniziare con la lettera D o con la lettera B, seguita da 6 numeri."
                    ) : ""
                  }
                  disabled={
                    pristine || isFetchingCustomerData || 
                    proposalData?.id || clientCodeCheck(values.customerCode) !== undefined
                  }
                >
                  {isFetchingCustomerData ? (
                    <Spinner size="sm" color="light" className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon="search" className="mr-2" />
                  )}
                  <span>Cerca</span>
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

export default CustomerCodeSearchForm