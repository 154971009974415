import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import 'react-loading-skeleton/dist/skeleton.css'

import { withRouter } from '../../AppRouter/utils'
import { getAPIUrl } from '../../../api'
import AgentData from './AgentData.component'
import { ActiveUserProposalFormStyle } from './style'
import CustomerData from './CustomerData.component'
import ApplicantData from './ApplicantData.component'
import CustomerCodeSearchForm from './CustomerCodeSearchForm.component'
import { selectUserData } from '../../../redux/user/user.selectors'
import { selectContract, selectProposalFormFieldsValues } from '../../../redux/contract/contract.selectors'
import { getEntityUriName } from '../../../redux/contract/contract.utils'
import { contractProposalSubmitFormStart } from '../../../redux/contract/contract.actions'
import { getDefaultOption } from './utils'
import { getTodayDate } from '../../../utils'

const ActiveUserProposalForm = ({ router: { navigate, location } }) => {
  const dispatch = useDispatch()
  const formValues = useSelector(selectProposalFormFieldsValues)
  const { entityName, selectedContractId, customerData, proposalData } = useSelector(selectContract)
  const { name, code } = useSelector(selectUserData)
  const defaultUserCode = useMemo(() => ({ value: code, label: name }), [code, name])
  const defaultLanguageValue = getDefaultOption(formValues.language) || null
  const defaultCustomerBirthCountry = useMemo(() => ({ label: "Italia", value: "Italia" }), [])
  
  const onSubmit = values => {
    const method = selectedContractId ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}${selectedContractId ? `/${selectedContractId}` : ''}`
    dispatch(contractProposalSubmitFormStart({ apiUrl, values, method, navigate, location }))
  }

  return (
    <ActiveUserProposalFormStyle>
      <CustomerCodeSearchForm />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          customerCode: customerData?.customerCode || null,
          customerType: customerData?.customerType || null,
          fullName: customerData?.fullName || null,
          fiscalCode: customerData?.fiscalCode || null,
          vatNumber: customerData?.vatNumber || null,
          email: customerData?.email || null,
          customerBirthDate: customerData?.customerBirthDate || null,
          customerBirthCountry: customerData?.customerBirthCountry || defaultCustomerBirthCountry,
          customerBirthPlace: customerData?.customerBirthPlace || null,
          userLogged: proposalData?.userLogged || name,
          userCode: proposalData?.userCode || defaultUserCode,
          subscriptionTownName: proposalData?.subscriptionTownName || null,
          language: proposalData?.language || defaultLanguageValue,
          compilationDate: proposalData?.compilationDate || getTodayDate(),
          signingDate: proposalData?.signingDate || null,
          ...proposalData
        }}
      >
        {({ handleSubmit, pristine, values }) => (
          <form
            className="two-cols-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <CustomerData 
              initialValues={customerData}
              values={values}
            />
            <AgentData />
            <ApplicantData 
              pristine={pristine} 
              values={values}
            />
          </form>
        )}
      </Form>
    </ActiveUserProposalFormStyle>
  )
}

export default withRouter(ActiveUserProposalForm)