export function isAttachmentsFormNeeded(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'takeover' ||
    entityName === 'transfer' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas' 
  )
}