import { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Spinner } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectUserToken } from "../../redux/user/user.selectors"
import { downloadFile } from "./utils"

const SalesOffersDownloadBtn = ({ saleOfferId }) => {
  const accessToken = useSelector(selectUserToken)
  const [ isLoading, setIsLoading ] = useState(false)
  const downloadSaleOffer = async () => {
    setIsLoading(true)
    try {
      await downloadFile(accessToken, saleOfferId)
    }
    catch(error) {
      console.log(error)
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <Button 
      outline
      color="secondary"
      onClick={downloadSaleOffer}
    >
      {isLoading === true ? (
        <>
          <Spinner size="sm" className="mr-2" />
          Download in corso...
        </>
      ) : (
        <>
          <FontAwesomeIcon 
            icon="download"
            className="mr-2"
          />
          Scarica
        </>
      )}
    </Button>
  )
}

export default SalesOffersDownloadBtn