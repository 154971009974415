import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SupplyPointsList from './SupplyPointsList.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingData } from '../../../redux/contract/contract.selectors'
import { contractGetActiveUserSupplyPointsStart } from '../../../redux/contract/contract.actions'

const SupplyPointsListWithSpinner = WithSpinner(SupplyPointsList)

const SupplyPointsListContainer = () => {
  const dispatch = useDispatch()
  const isFetchingSupplyPoints = useSelector(state => selectIsFetchingData('supplyPoints')(state))

  useEffect(() => {
    dispatch(contractGetActiveUserSupplyPointsStart())
  }, [dispatch])

  return (
    <SupplyPointsListWithSpinner isLoading={isFetchingSupplyPoints} />
  )
}

export default SupplyPointsListContainer
