import styled from 'styled-components'

const ActiveUserAttachmentsFormStyle = styled.div`
  .alert-heading {
    font-size: 1rem;
    font-weight: bold;
  }

  .alert-description {
    font-size: .85rem;
  }

  .dropzone-listgroup {
    &.single-file {
      .form-group:not(.m-0) {
        margin-bottom: .75rem !important;
      }
    }
  }

  .grouped { 
    .grouped-header {
      background-color: #337ab7;
      color: #fff;
    }

    .grouped-body {
      padding: 1rem 1.5rem;
    }
  }
`

export default ActiveUserAttachmentsFormStyle
