import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PaperSignatureForm from './PaperSignatureForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFecthingAttachmentsData } from '../../../redux/contract/contract.selectors'
import { contractGetAttachmentsDataStart } from '../../../redux/contract/contract.actions'

const PaperSignatureFormWithSpinner = WithSpinner(PaperSignatureForm)

const PaperSignatureFormContainer = () => {
  const dispatch = useDispatch()
  const isFetchingAttachementsData = useSelector(selectIsFecthingAttachmentsData)

  //Get form data
  useEffect(() => {
     dispatch(contractGetAttachmentsDataStart())
  }, [dispatch])

  return (
    <PaperSignatureFormWithSpinner isLoading={isFetchingAttachementsData} />
  )
}

export default PaperSignatureFormContainer
