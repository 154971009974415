import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PdrForm from '../PdrForm/PdrForm.component'
import { selectPdrDetailModalvisibility } from '../../../redux/contract/contract.selectors'
import { contractTogglePdrDetailModal } from '../../../redux/contract/contract.actions'

const PdrDetailModal = ({ cadastralItem, item }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectPdrDetailModalvisibility)
  const toggle = () => dispatch(contractTogglePdrDetailModal())

  return(
    <Modal 
      className="status-modal" 
      size="lg" 
      backdrop="static" 
      isOpen={isOpen} 
      toggle={toggle}
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="flame" className="mr-2" />
        PDR
      </ModalHeader>
      <ModalBody>
        <PdrForm />
      </ModalBody>
    </Modal>
  )
}

export default PdrDetailModal
