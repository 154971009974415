export const validateForm = (supplyType, values) => {
  if(
    supplyType === 'POD' &&
    !values.electricUseTypeId &&
    !values.requestedPowerValue &&
    !values.requestedConnectionVoltageId
  ) {
    return {
      noFieldsCompiled: 'Non è stato inserito nessun valore!'
    }
  }

  if(supplyType === 'PDR') {
    if(
      !values.supplyUseType && 
      !values.gasUseTypeId &&
      !values.requestedTotalPower && 
      !values.requestedTotalPowerMesureUnit &&
      !values.actualTotalPower && 
      !values.actualTotalPowerMesureUnit
    ) {
      return {
        noFieldsCompiled: 'Non è stato inserito nessun valore!'
      }
    }

    const dependentFields = [
      "requestedTotalPower", 
      "requestedTotalPowerMesureUnit", 
      "actualTotalPower", 
      "actualTotalPowerMesureUnit"
    ]
    //Check if all fields are blank
    const fieldGroupErrors = dependentFields.reduce((accumulator, item) => {
      if(!values[item]) {
        accumulator[item] = "Campo obbligatorio"
      }
      
      return accumulator
    }, {})

    //if fieldGroupErrors length equals errors length, no error is shown for the fields group
    return Object.keys(fieldGroupErrors).length === dependentFields.length ? {} : fieldGroupErrors
  }
}

export const hideValidationMessage = values => {
  return (
    values.actualTotalPowerMesureUnit || 
    values.actualTotalPower ||
    values.requestedTotalPower || 
    values.requestedTotalPowerMesureUnit
  ) ? false : true
}