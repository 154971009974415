import { all, call, takeLatest, put, select } from 'redux-saga/effects'

import { userShowRefreshTokenToastVisibility } from '../user/user.actions'
import SalesOffersTypes from './salesOffers.types'
import * as salesOffersActions from './salesOffers.actions'
import { fetchSalesOffersList } from './salesOffers.utils'
import { errorMessages } from '../../api/utils'
import { selectUserData, selectFirebaseUser } from '../user/user.selectors'
import { refreshFirebaseToken } from '../../firebase/firebase-utils'

export function* getActiveSalesOffersList() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const { id: userId } = yield select(selectUserData)
    const response = yield call(fetchSalesOffersList, accessToken, userId)
    yield put(salesOffersActions.salesOffersGetActiveListSuccess(response))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(salesOffersActions.salesOffersGetActiveListFailure(error))
  }
}

export function* onSalesOffersGetActiveListStart() {
  yield takeLatest(
    SalesOffersTypes.SALES_OFFERS_GET_ACTIVE_LIST_START,
    getActiveSalesOffersList
  )
}

export function* salesOffersSagas() {
  yield all([
    call(onSalesOffersGetActiveListStart),
  ])
}