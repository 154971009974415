import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withRouter } from '../../AppRouter/utils'
import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import ExtendedAutocompleteFormField from '../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import WhenFieldChanges from '../WhenFieldChanges/WhenFieldChanges.component'
import { fetchUserParent } from './utils'
import { required, emailCheck, requiredArray } from '../validations'
import { getAPIUrl } from '../../../api'
import ScrollToTop from '../../../hooks/navigation/useScrollToTop'
import { canI } from '../../auth/CanI/utils'
import { getEntityUriName } from '../../../redux/contract/contract.utils'
import { contractUserSubmitFormStart } from '../../../redux/contract/contract.actions'
import {
  selectContractId, selectUserData, selectUserFormFieldsValues,
  selectEntityName, selectIsSubmitting
} from '../../../redux/contract/contract.selectors'
import UserFormStyle from './style'

//Focus on firt field with an error
const focusOnError = createDecorator()

const UserForm = ({ router: { navigate, location } }) => {
  const dispatch = useDispatch()
  const selectedContractId = useSelector(selectContractId)
  const entityName = useSelector(selectEntityName)
  const formValues = useSelector(selectUserFormFieldsValues)
  const formIsSubmitting = useSelector(state => selectIsSubmitting('user')(state))

  //Check if user can edit the form fields
  const userData = useSelector(selectUserData)
  const canEdit = userData ? canI(`${entityName}UPDATE`, userData.links || []).abilityCheck : true

  //Submit form
  const onSubmit = async values => {
    const method = selectedContractId ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}${selectedContractId ? `/${selectedContractId}` : ''}`
    dispatch(contractUserSubmitFormStart({ apiUrl, values, method, navigate, location }))
  }

  return (
    <UserFormStyle className="loader-container">
      <ScrollToTop />
      <Form
        onSubmit={onSubmit}
        subscription={{
          values: true
        }}
        decorators={[focusOnError]}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            className="two-cols-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="user-cog" className="mr-2" />
                Dati utente
              </div>
              <div className="grouped-body">
                <Row form>
                  <Col md={6}>
                    <ExtendedSelectField
                      id="enabled"
                      name="enabled"
                      isDisabled={canEdit ? false : true}
                      options={formValues?.enabled || []}
                      fieldLabel="Utente abilitato*"
                      placeholder="Utente abilitato*"
                      validate={required}
                      defaultValue={userData?.enabled || null}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="name"
                      name="name"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Nome*"
                      fieldLabel="Nome*"
                      validate={required}
                      maxLength="80"
                      defaultValue={userData?.name || null}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="code"
                      name="code"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Codice*"
                      fieldLabel="Codice*"
                      validate={required}
                      maxLength="80"
                      defaultValue={userData?.code || null}
                    />
                  </Col>
                  {/* <Col md={6}>
                    <CustomFormField
                      id="username"
                      name="username"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Username*"
                      fieldLabel="Username*"
                      validate={required}
                      maxLength="80"
                      defaultValue={userData?.username || null}
                    />
                  </Col> */}
                  <Col md={6}>
                    <ExtendedSelectField
                      id="companyId"
                      name="companyId"
                      isDisabled={canEdit ? false : true}
                      options={formValues?.company || []}
                      placeholder="Rete di vendita*"
                      fieldLabel="Rete di vendita*"
                      validate={required}
                      defaultValue={userData?.companyId || null}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="email"
                      name="email"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Email*"
                      fieldLabel="Email*"
                      validate={emailCheck(true)}
                      maxLength="80"
                      defaultValue={userData?.email || null}
                      style={{ textTransform: 'lowercase' }}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="personalEmail"
                      name="personalEmail"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Email personale*"
                      fieldLabel="Email personale*"
                      validate={emailCheck(true)}
                      maxLength="80"
                      defaultValue={userData?.personalEmail || null}
                      style={{ textTransform: 'lowercase' }}
                    />
                  </Col>
                  <Col md={6}>
                    <ExtendedSelectField
                      id="language"
                      name="language"
                      isDisabled={canEdit ? false : true}
                      options={formValues?.language || []}
                      placeholder="Lingua*"
                      fieldLabel="Lingua*"
                      validate={required}
                      defaultValue={userData?.language || null}
                    />
                  </Col>
                  <Col md={6}>
                    <ExtendedSelectField
                      id="roleId"
                      name="roleId"
                      isDisabled={canEdit ? false : true}
                      options={formValues?.role || []}
                      placeholder="Tipologia utente*"
                      fieldLabel="Tipologia utente*"
                      validate={required}
                      defaultValue={userData?.roleId || null}
                    />
                  </Col>
                  <Col md={12}>
                    <ExtendedSelectField
                      name="offerCode"
                      placeholder="Gruppo visibilità offerta*"
                      fieldLabel="Gruppo visibilità offerta*"
                      validate={required}
                      options={formValues?.offerCode || []}
                      defaultValue={userData?.offerCode || null}
                      isDisabled={canEdit ? false : true}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <ExtendedSelectField
                      name="usersVisibility"
                      placeholder="Visibilità utenti*"
                      fieldLabel="Visibilità utenti*"
                      validate={required}
                      options={formValues?.usersVisibility || []}
                      defaultValue={userData?.usersVisibility || null}
                      isDisabled={canEdit ? false : true}
                    />
                    <WhenFieldChanges 
                      field="usersVisibility"
                      set="parentId"
                      to={null}
                    />
                  </Col>
                  {parseInt(values.usersVisibility?.value) === 2 &&
                    <Col md={12}>
                      <ExtendedAutocompleteFormField
                        name="parentId"
                        fieldLabel="Utente genitore"
                        placeholder="Utente genitore"
                        fetchFunction={fetchUserParent}
                        defaultValue={userData?.parentId || null}
                        isDisabled={canEdit ? false : true}
                        noOptionsMessage={() => 
                          <span className="autocomplete-suggestion">Indica le prime lettere dell'utente genitore per trovarlo nella lista</span>
                        }
                      />
                    </Col>
                  }
                </Row>
                <Row form>
                  <Col>
                    <ExtendedSelectField
                      name="signatureMethod"
                      fieldLabel="Metodi di firma utilizzabili*"
                      validate={requiredArray}
                      isMulti={true}
                      options={formValues?.signatureMethod}
                      placeholder="Metodi di firma utilizzabili*"
                      defaultValue={userData?.signatureMethod || null}
                      isDisabled={canEdit ? false : true}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <ExtendedSelectField
                      name="permissionGroup"
                      fieldLabel="Permessi utente*"
                      validate={requiredArray}
                      isMulti={true}
                      isDisabled={canEdit ? false : true}
                      placeholder="Permessi utente*"
                      options={formValues?.permissionGroup}
                      defaultValue={userData?.permissionGroup || null}
                      getOptionLabel={option =>
                        <>
                          <strong>{option.label}</strong>: {option.description}
                        </>
                      }
                      customFilterFunction={(candidate, input) => {
                        if (input) {
                          const lowerCaseCandidate = candidate.data.label.toLowerCase()
                          return lowerCaseCandidate.includes(input.toLowerCase())
                        }
                        return true
                      }}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col>
                    <ExtendedSelectField
                      name="openingCausal"
                      fieldLabel="Contratti/O.T. visibili*"
                      validate={requiredArray}
                      isMulti={true}
                      placeholder="Contratti/O.T. visibili*"
                      options={formValues?.openingCausal}
                      defaultValue={userData?.openingCausal || null}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            {canEdit &&
              <div className="text-right">
                <Button
                  type="submit"
                  disabled={formIsSubmitting}
                >
                  {formIsSubmitting ? (
                    <Spinner size="sm" color="light" className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon="save" className="mr-2" />
                  )}
                  <span>Salva</span>
                </Button>
              </div>
            }
          </form>
        )}
      </Form>
    </UserFormStyle>
  )
}

export default withRouter(UserForm)
