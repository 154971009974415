import WhenFieldChanges from "../../WhenFieldChanges/WhenFieldChanges.component"

const RealEstateFieldChangesConditions = () => (
  <>
    <WhenFieldChanges
      field="contractType"
      set="realEstateUseType"
      to={null}
    />
    <WhenFieldChanges
      field="contractType"
      set="realEstateAuthType"
      to={null}
    />         
    <WhenFieldChanges
      field="contractType"
      set="authNo"
      to={null}
    />
    <WhenFieldChanges
      field="contractType"
      set="authDate"
      to={null}
    />
    <WhenFieldChanges
      field="contractType"
      set="authTownName"
      to={null}
    />
    <WhenFieldChanges
      field="contractType"
      set="instructions"
      to={null}
    />  
    <WhenFieldChanges
      field="contractType"
      set="plantAuthNo"
      to={null}
    />  
    <WhenFieldChanges
      field="contractType"
      set="plantAuthDate"
      to={null}
    />  
    <WhenFieldChanges
      field="contractType"
      set="plantAuthTownName"
      to={null}
    />  

  </>
)

export default RealEstateFieldChangesConditions