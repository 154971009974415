import React from 'react'
import { Col } from 'reactstrap'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import { required } from '../../validations'
import { isSupplyEqualToResidence } from './utils'

const PodResidenceSelfDeclaration = ({ entityName, podData, values, canEdit, formValues }) => (
  <>
    <Col md={6}>
      <CustomDatePicker
        id="from"
        name="from"
        type="date"
        fieldLabel="Residente dal*"
        placeholder="Residente dal*"
        adjustDateOnChange
        dateFormat="dd/MM/yyyy"
        className="form-control"
        maxDate={new Date()}
        defaultValue={podData?.from || null}
        validate={required}
      />
    </Col>
    <Col md={6}>
      <ExtendedSelectField
        name="supplyEqualToResidence"
        isMulti={false}
        fieldLabel="L'indirizzo di fornitura corrisponde a quello di residenza*"
        placeholder="L'indirizzo di fornitura corrisponde a quello di residenza*"
        options={formValues?.supplyEqualToResidence || []}
        defaultValue={podData?.supplyEqualToResidence || null}
        isDisabled={canEdit ? false : true}
        validate={required}
      />
    </Col>
    {values && parseInt(values.supplyEqualToResidence?.value) === 2 &&
      <>
        <Col md={3}>
          <ExtendedSelectField
            name="supplyToponym"
            isMulti={false}
            fieldLabel="Toponimo*"
            placeholder="Toponimo*"
            options={formValues?.toponymIc || []}
            defaultValue={podData?.supplyToponym || null}
            isDisabled={canEdit ? false : true}
            validate={required}
          />
        </Col>
        <Col md={7}>
          <CustomFormField
            id="supplyAddress"
            name="supplyAddress"
            type="text"
            maxLength="128"
            disabled={canEdit ? false : true}
            placeholder="Indirizzo*"
            fieldLabel="Indirizzo*"
            validate={required}
            defaultValue={podData?.supplyAddress || null}
          />
        </Col>
        <Col md={2}>
          <CustomFormField
            id="supplyCivicNo"
            name="supplyCivicNo"
            type="text"
            maxLength="4"
            disabled={canEdit ? false : true}
            placeholder="Civico*"
            fieldLabel="Civico*"
            validate={required}
            defaultValue={podData?.supplyCivicNo || null}
          />
        </Col>
      </>
    }
    {isSupplyEqualToResidence(entityName) &&
      values &&
      parseInt(values.electricUseTypeId?.value) === 1 &&
      parseInt(values.isResident?.value) === 1 &&
      <>
        <Col md={6}>
          <CustomFormField
            id="supplyScale"
            name="supplyScale"
            type="text"
            disabled={canEdit ? false : true}
            fieldLabel="Scala"
            defaultValue={podData?.supplyScale || null}
          />
        </Col>
        <Col md={6}>
          <CustomFormField
            id="supplyFloor"
            name="supplyFloor"
            type="text"
            disabled={canEdit ? false : true}
            fieldLabel="Piano"
            defaultValue={podData?.supplyFloor || null}
          />
        </Col>
        <Col md={6}>
          <CustomFormField
            id="supplyApartmentNumber"
            name="supplyApartmentNumber"
            type="text"
            disabled={canEdit ? false : true}
            fieldLabel="Interno"
            defaultValue={podData?.supplyApartmentNumber || null}
          />
        </Col>
      </>
    }
  </>
)

export default PodResidenceSelfDeclaration
