export function canI(action, permissions) {
  let actionUrl = ''
  let abilityCheck = false

  if(permissions[action]) {
    actionUrl = permissions[action].href
    abilityCheck = true
  }

  return {
    actionUrl,
    abilityCheck
  }
}

export function abilityCheck(action, permissions) {
  const { abilityCheck } = canI(action, permissions)
  return abilityCheck
}
