import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectEntityName, selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractValidateAllActiveUserSupplyPointsStart, contractValidateAllSupplyPointsStart } from '../../../redux/contract/contract.actions'
import { isActiveUserContract } from '../../../redux/contract/contract.utils'

const CadastralValidateAllModal = () => {
  const dispatch = useDispatch()
  const entityName = useSelector(selectEntityName)
  const isSubmitting = useSelector(state => selectIsSubmitting('allSupplyPoints')(state))
  const [ visibility, setVisibility ] = useState(false)
  const toggle = () => setVisibility(!visibility)

  const editSupplyPointsStatus = () => {
    if(isActiveUserContract(entityName)) {
      dispatch(contractValidateAllActiveUserSupplyPointsStart())
    }
    else {
      dispatch(contractValidateAllSupplyPointsStart())
    }
  }

  return(
    <>
      <Button
        color="success"
        onClick={toggle}
        disabled={isSubmitting}
        data-status-id="3"
        size="sm"
      >
        <FontAwesomeIcon icon="check-circle" className="mr-1" />
        {' '}
        Valida tutti i punti di fornitura
      </Button>

      <Modal isOpen={visibility} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon icon="exclamation-triangle" />
        </ModalHeader>
        <ModalBody>
            Vuoi davvero validare tutti i punti di fornitura di questo contratto?
        </ModalBody>
        <ModalFooter>
          <Button
            className="ab-button"
            onClick={editSupplyPointsStatus}
            disabled={isSubmitting}
          >
            {isSubmitting ?
              <Spinner color="light" size="sm" className="mr-1" />
              :
              <FontAwesomeIcon icon="check-circle" className="mr-1" />
            }
            Valida
          </Button>
          <Button color="secondary" onClick={toggle}>Annulla</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CadastralValidateAllModal
