const SalesOffersTypes = {
  TOGGLE_SALES_OFFERS_UTILITY_MODAL_VISIBILITY: 'TOGGLE_SALES_OFFERS_UTILITY_MODAL_VISIBILITY',

  SALES_OFFERS_GET_ACTIVE_LIST_START: 'SALES_OFFERS_GET_ACTIVE_LIST_START',
  SALES_OFFERS_GET_ACTIVE_LIST_SUCCESS: 'SALES_OFFERS_GET_ACTIVE_LIST_SUCCESS',
  SALES_OFFERS_GET_ACTIVE_LIST_FAILURE: 'SALES_OFFERS_GET_ACTIVE_LIST_FAILURE',

  SALES_OFFERS_GET_FILE_BASE_64: 'SALES_OFFERS_GET_FILE_BASE_64',
  SALES_OFFERS_RESET: 'SALES_OFFERS_RESET',
}

export default SalesOffersTypes