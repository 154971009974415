import styled from 'styled-components'

export const ThankYouItemStyle = styled.div`
  .jumbotron {
    background-color: transparent !important;
    padding: 0;

    .title {
      font-size: 1.75rem;
    }

    .description {
      font-size: 1rem;
    }

    .ab-button:hover {
      background-color: transparent;
      color: #337ab7;
      border: 1px solid #337ab7;
    }
  }
`
