//!IMPORTANT This component presumes you're using react-final-form
import React, { useEffect, useRef } from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, FormFeedback, Label, FormText } from 'reactstrap'
import classnames from 'classnames'

import Emoji from '../../../Emoji/Emoji.component'

const CustomFormField = ({ children, focusOnMount, ...props }) => {
  const inputRef = useRef(null)
  useEffect(() => {
    if(focusOnMount) inputRef.current.focus()
  }, [focusOnMount, inputRef])

  return (
    <FormGroup className={classnames({'m-0': props.type === 'hidden'})}>
      <Field {...props}>
        {({ fieldLabel, fieldDescription, noValidationMessage, input, meta: { error, touched }, ...otherProps }) => (
          <>
            {fieldLabel &&
              <Label for={input.name}>{fieldLabel}</Label>
            }
            <Input
              innerRef={inputRef}
              invalid={error && touched && !noValidationMessage ? true : false}
              valid={!error && touched && !noValidationMessage ? true : false}
              {...input}
              {...otherProps}
            />
            {fieldDescription &&
              <FormText color="muted">
                {fieldDescription}
              </FormText>
            }
            {(!noValidationMessage && props.type !== 'hidden') &&
              <>
              {error && touched ?
                <FormFeedback>
                  {error}
                  {' '}
                  <Emoji
                    label="police officer"
                    symbol="👮"
                  />
                </FormFeedback>
                :
                <FormFeedback valid>
                  {fieldLabel}
                  {' '}
                  <Emoji
                    label="thumb up"
                    symbol="👍🏻"
                  />
                </FormFeedback>
              }
              </>
            }
          </>
        )}
      </Field>
      {children}
    </FormGroup>
  )
}

export default CustomFormField
