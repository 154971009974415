import SalesOffersTypes from "./salesOffers.types"

export const salesOffersGetActiveListStart = () => ({
  type: SalesOffersTypes.SALES_OFFERS_GET_ACTIVE_LIST_START
})

export const salesOffersGetActiveListSuccess = data => ({
  type: SalesOffersTypes.SALES_OFFERS_GET_ACTIVE_LIST_SUCCESS,
  payload: data
})

export const salesOffersGetActiveListFailure = error => ({
  type: SalesOffersTypes.SALES_OFFERS_GET_ACTIVE_LIST_FAILURE,
  payload: error
})

export const salesOffersReset = () => ({
  type: SalesOffersTypes.SALES_OFFERS_RESET
})

export const toggleUtilityModalVisibility = () => ({
  type: SalesOffersTypes.TOGGLE_SALES_OFFERS_UTILITY_MODAL_VISIBILITY
})