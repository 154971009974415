import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectResetPasswordModalVisibility, selectIsSubmitting, selectIsResetEmailSent } from '../../../redux/user/user.selectors'
import { userToggleResetPasswordModalVisibility, userSendResetPasswordEmailStart } from '../../../redux/user/user.actions'

const ResetPasswordModal = ({ otherClasses }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectResetPasswordModalVisibility)
  const isSubmitting = useSelector(selectIsSubmitting)
  const isResetEmailSent = useSelector(selectIsResetEmailSent)
  const toggle = () => dispatch(userToggleResetPasswordModalVisibility())

  return (
    <Modal modalClassName="signature-type-modal" size="lg" backdrop="static" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="key" className="mr-2" />
        Reset password
      </ModalHeader>
      <ModalBody>
        {isResetEmailSent ? (
          <p>
            A breve riceverai un'email all'indirizzo che ci hai indicato.<br />
            Potrai quindi modificare la tua password ed effettuare nuovamente l'accesso.
          </p>
        ) : (
          <p>
            Se confermi, ti invieremo un'email contenente un link che ti porterà alla pagina di reset
            della password, dalla quale potrai inserirne una nuova.
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        {isResetEmailSent ? (
          <Button onClick={() => toggle()}>
            Chiudi
          </Button>
        ) : (
          <Button
            onClick={() => dispatch(userSendResetPasswordEmailStart())}
            className="ab-button"
          >
            <span>Reset password</span>
            {isSubmitting ? (
              <Spinner size="sm" color="light" className="ml-1" />
            ) : (
              <FontAwesomeIcon icon="check" className="ml-1" />
            )}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default ResetPasswordModal
