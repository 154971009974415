import { getAPIUrl } from '../../../api'

export function fetchUserParent(searchedValue, accessToken) {
  const fetchPromise = fetch(
    `${getAPIUrl('abStorePortal')}/users/parent?username=${searchedValue}`,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }
  )
  .then(response => {
    const contentType = response.headers.get("content-type")
    if(contentType && contentType.includes("application/json")) {
      return response.json()
    }
    return response
  })
  .then(({ data }) => data.map(item => item))
  .catch(error => { throw new Error(error) })

  return fetchPromise
}
