import { Container, Row, Col } from 'reactstrap'

import PageHeader from "../../components/PageHeader/PageHeader.component"
import ScrollToTop from "../../hooks/navigation/useScrollToTop"
import SalesOffersListContainer from '../../components/SalesOffersList/SalesOffersListContainer.component'

const SalesOffersListPage = () => (
  <div className="page">
    <ScrollToTop />
    <PageHeader pageTitle="Documenti" icon="list" />
    <Container className="loader-container">
      <Row>
        <Col>
          <h1 className="main-title">Listini delle offerte disponibili</h1>
        </Col>
      </Row>
      <SalesOffersListContainer />
    </Container>
  </div>
)

export default SalesOffersListPage