import React from 'react'

import { withRouter } from '../../components/AppRouter/utils'
import PageHeader from '../../components/PageHeader/PageHeader.component'
import ListContainer from '../../components/lists/contract-list/ListContainer/ListContainer.component'
import ScrollToTop from '../../hooks/navigation/useScrollToTop'
import { getEntityLabelFromPath } from '../../redux/contract/contract.utils'

const ContractsList = ({ router: { params: { pathEntityName } } }) => {
  const entityLabel = getEntityLabelFromPath(pathEntityName)

  return (
    <div className="page">
      <ScrollToTop />
      <PageHeader pageTitle={entityLabel} icon="list" />
      <ListContainer />
    </div>
  )
}

export default withRouter(ContractsList)
