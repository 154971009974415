import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import ExtendedAutocompleteFormField from '../../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import { selectUserData } from '../../../../redux/user/user.selectors'
import { selectEntityName } from '../../../../redux/contract/contract.selectors'
import { required, noFutureDate, mustBeAdult, fiscalCodeCheck } from '../../validations'
import { fetchTowns } from '../../../../api'
import { isRealEstateTownNameRequired } from './utils'

const PropertyDeclaration = ({ canEdit, values, cadastralDetailData, formValues }) => {
  const { accessToken } = useSelector(selectUserData)
  const entityName = useSelector(selectEntityName)

  return (
    <>
      <Row form>
        <Col md={12}>
          <ExtendedSelectField
            name="supplyEqualToProperty"
            isMulti={false}
            fieldLabel="L'indirizzo di fornitura corrisponde a quello dell'immobile*"
            placeholder="L'indirizzo di fornitura corrisponde a quello dell'immobile*"
            options={formValues?.supplyEqualToProperty || []}
            defaultValue={cadastralDetailData?.supplyEqualToProperty || null}
            isDisabled={canEdit ? false : true}
            validate={required}
          />
        </Col>
        {
          isRealEstateTownNameRequired(entityName) &&
          parseInt(values?.supplyEqualToProperty?.value) === 2 &&
          <Col md={12}>
            <ExtendedAutocompleteFormField
              name="realEstateTownName"
              fieldLabel="Comune*"
              placeholder="Comune*"
              fetchFunction={fetchTowns(accessToken)}
              defaultValue={cadastralDetailData?.realEstateTownName || null}
              disabled={canEdit ? false : true}
              validate={required}
              maxLength="80"
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => ({ town: option.town, province: option.province })}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
        }
        {values && parseInt(values.supplyEqualToProperty?.value) === 2 &&
          <>
            <Col md={3}>
              <ExtendedSelectField
                name="propertyToponym"
                isMulti={false}
                fieldLabel="Toponimo*"
                placeholder="Toponimo*"
                options={formValues?.supplyToponym || []}
                defaultValue={cadastralDetailData?.propertyToponym || null}
                isDisabled={canEdit ? false : true}
                validate={required}
              />
            </Col>
            <Col md={7}>
              <CustomFormField
                id="propertyAddress"
                name="propertyAddress"
                type="text"
                maxLength="128"
                disabled={canEdit ? false : true}
                placeholder="Indirizzo*"
                fieldLabel="Indirizzo*"
                validate={required}
                defaultValue={cadastralDetailData?.propertyAddress || null}
              />
            </Col>
            <Col md={2}>
              <CustomFormField
                id="propertyStreetNo"
                name="propertyStreetNo"
                type="text"
                maxLength="4"
                disabled={canEdit ? false : true}
                placeholder="Civico*"
                fieldLabel="Civico*"
                validate={required}
                defaultValue={cadastralDetailData?.propertyStreetNo || null}
              />
            </Col>
          </>
        }
      </Row>

      <Row form>
        <Col md={4}>
          <CustomFormField
            id="stair"
            name="stair"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Scala"
            fieldLabel="Scala"
            defaultValue={cadastralDetailData?.stair || null}
          />
        </Col>
        <Col md={4}>
          <CustomFormField
            id="floor"
            name="floor"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Piano"
            fieldLabel="Piano"
            defaultValue={cadastralDetailData?.floor || null}
          />
        </Col>
        <Col md={4}>
          <CustomFormField
            id="indoor"
            name="indoor"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Interno"
            fieldLabel="Interno"
            defaultValue={cadastralDetailData?.indoor || null}
          />
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <ExtendedSelectField
            options={formValues?.realEstatePropertyType || []}
            id="realEstatePropertyType"
            name="realEstatePropertyType"
            isDisabled={canEdit ? false : true}
            fieldLabel="Il sottoscritto in qualità di:"
            placeholder="Il sottoscritto in qualità di:"
            defaultValue={cadastralDetailData?.realEstatePropertyType || null}
          />
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <CustomFormField
            id="administrationTown"
            name="administrationTown"
            type="text"
            maxLength="80"
            disabled={canEdit ? false : true}
            placeholder="Comune amministrativo"
            fieldLabel="Comune amministrativo"
            defaultValue={cadastralDetailData?.administrationTown || null}
          />
        </Col>
        <Col md={6}>
          <CustomFormField
            id="cadastralTown"
            name="cadastralTown"
            type="text"
            maxLength="80"
            disabled={canEdit ? false : true}
            placeholder="Comune catastale (se diverso da quello ammin.)"
            fieldLabel="Comune catastale (se diverso da quello ammin.)"
            defaultValue={cadastralDetailData?.cadastralTown || null}
          />
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <CustomFormField
            id="cadastralTownCode"
            name="cadastralTownCode"
            type="text"
            maxLength="80"
            disabled={canEdit ? false : true}
            placeholder="Cod. Comune catastale"
            fieldLabel="Cod. Comune catastale"
            defaultValue={cadastralDetailData?.cadastralTownCode || null}
            fieldDescription="Indicare il codice del Comune ai fini catastali: il codice è alfanumerico ed è reperibile sul sito www.comuni-italiani.it."
          />
        </Col>
        <Col md={6}>
          <ExtendedSelectField
            options={formValues?.realEstateUnitType || []}
            id="realEstateUnitType"
            name="realEstateUnitType"
            isDisabled={canEdit ? false : true}
            placeholder="Tipologia Unità Immobiliare"
            fieldLabel="Tipologia Unità Immobiliare"
            defaultValue={cadastralDetailData?.realEstateUnitType || null}
          />
        </Col>
      </Row>
      <Row form>
        <Col md={3}>
          <CustomFormField
            id="cadastralDataSection"
            name="cadastralDataSection"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Sez."
            fieldLabel="Sez."
            defaultValue={cadastralDetailData?.cadastralDataSection || null}
          />
        </Col>
        <Col md={3}>
          <CustomFormField
            id="cadastralDataSheet"
            name="cadastralDataSheet"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Foglio*"
            fieldLabel="Foglio*"
            validate={required}
            defaultValue={cadastralDetailData?.cadastralDataSheet || null}
          />
        </Col>
        <Col md={3}>
          <CustomFormField
            id="cadastralDataPart"
            name="cadastralDataPart"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Part/Map*"
            fieldLabel="Part/Map*"
            validate={required}
            defaultValue={cadastralDetailData?.cadastralDataPart || null}
          />
        </Col>
        <Col md={3}>
          <CustomFormField
            id="cadastralDataSubalt"
            name="cadastralDataSubalt"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Subalt."
            fieldLabel="Subalt."
            defaultValue={cadastralDetailData?.cadastralDataSubalt || null}
          />
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <CustomFormField
            id="particleExtension"
            name="particleExtension"
            type="text"
            maxLength="50"
            disabled={canEdit ? false : true}
            placeholder="Estensione Particella"
            fieldLabel="Estensione Particella"
            defaultValue={cadastralDetailData?.particleExtension || null}
          />
        </Col>
        <Col md={6}>
          <ExtendedSelectField
            options={formValues?.particleType || []}
            id="particleType"
            name="particleType"
            isDisabled={canEdit ? false : true}
            placeholder="Tipo particella"
            fieldLabel="Tipo particella"
            defaultValue={cadastralDetailData?.particleType || null}
          />
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <ExtendedSelectField
            options={formValues?.propertyType || []}
            id="propertyType"
            name="propertyType"
            isDisabled={canEdit ? false : true}
            fieldLabel="In conformità all’art. 5 comma 1 del DL 28/03/14, n 47, convertito in L. 80/14, di occupare l’immobile in virtù del seguente titolo:*"
            validate={required}
            defaultValue={cadastralDetailData?.propertyType || null}
          />
        </Col>
      </Row>

      {values && parseInt(values.propertyType?.value) === 2 &&
        <>
          <Row form>
            <Col md="12">
              <legend className="col-form-label">Con atto registrato presso l’Agenzia delle Entrate:</legend>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <ExtendedAutocompleteFormField
                id="rentRegisteredBy"
                name="rentRegisteredBy"
                fieldLabel="Agenzia delle Entrate di*"
                placeholder="Agenzia delle Entrate di*"
                fetchFunction={fetchTowns(accessToken)}
                defaultValue={cadastralDetailData?.rentRegisteredBy || null}
                disabled={canEdit ? false : true}
                validate={required}
                maxLength="80"
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => ({ town: option.town, province: option.province })}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
            <Col md={4}>
              <CustomDatePicker
                id="rentRegisteredDate"
                name="rentRegisteredDate"
                className="form-control"
                type="date"
                dateFormat="dd/MM/yyyy"
                placeholder="In data*"
                fieldLabel="In data*"
                adjustDateOnChange
                maxDate={new Date()}
                defaultValue={cadastralDetailData?.rentRegisteredDate || null}
                disabled={canEdit ? false : true}
                validate={noFutureDate(true)}
              />
            </Col>
            <Col md={2}>
              <CustomFormField
                id="rentRegisteredAt"
                name="rentRegisteredAt"
                type="text"
                maxLength="50"
                disabled={canEdit ? false : true}
                placeholder="Al n.*"
                fieldLabel="Al n.*"
                validate={required}
                defaultValue={cadastralDetailData?.rentRegisteredAt || null}
              />
            </Col>
          </Row>
        </>
      }

      {values && parseInt(values.propertyType?.value) === 3 &&
        <Row form>
          <Col md={12}>
            <CustomFormField
              id="usufructDescription"
              name="usufructDescription"
              type="text"
              maxLength="128"
              disabled={canEdit ? false : true}
              placeholder="Specificare uso, usufrutto o abitazione"
              fieldLabel="Specificare uso, usufrutto o abitazione"
              defaultValue={cadastralDetailData?.usufructDescription || null}
            />
          </Col>
        </Row>
      }

      {values && parseInt(values.propertyType?.value) === 4 &&
        <Row form>
          <Col md={12}>
            <CustomFormField
              id="otherType"
              name="otherType"
              type="text"
              maxLength="128"
              disabled={canEdit ? false : true}
              placeholder="Altro (specificare)*"
              fieldLabel="Altro (specificare)*"
              validate={required}
              defaultValue={cadastralDetailData?.otherType || null}
            />
          </Col>
        </Row>
      }

      {values && (parseInt(values.propertyType?.value) === 3 || parseInt(values.propertyType?.value) === 4) &&
        <>
          <Row form>
            <Col md="12">
              <legend className="col-form-label">Schema di autorizzazione (delega):</legend>
              <p className="small">
                Lo schema di autorizzazione/delega va compilato nei casi in cui il Cliente che richiede la fornitura,
                non ha direttamente il titolo registrato sull’immobile. In questi casi è necessaria l’autorizzazione dell’avente titolo
                (es.: proprietario, locatario, etc.) che dichiari di aver messo a disposizione l’immobile al sottoscrittore del Contratto
                di fornitura (es.: Il proprietario dell’immobile è uno dei coniugi e il contratto è sottoscritto dall’altro).</p>
                <legend className="col-form-label">Il sottoscritto:</legend>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <CustomFormField
                id="authName"
                name="authName"
                type="text"
                maxLength="128"
                disabled={canEdit ? false : true}
                placeholder="Nome*"
                fieldLabel="Nome*"
                validate={required}
                defaultValue={cadastralDetailData?.authName || null}
              />
            </Col>
            <Col md={6}>
              <CustomFormField
                id="authSurname"
                name="authSurname"
                type="text"
                maxLength="128"
                disabled={canEdit ? false : true}
                placeholder="Cognome*"
                fieldLabel="Cognome*"
                validate={required}
                defaultValue={cadastralDetailData?.authSurname || null}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <ExtendedAutocompleteFormField
                id="authBirthPlace"
                name="authBirthPlace"
                placeholder="Nato a*"
                fieldLabel="Nato a*"
                fetchFunction={fetchTowns(accessToken)}
                defaultValue={cadastralDetailData?.authBirthPlace || null}
                disabled={canEdit ? false : true}
                validate={required}
                maxLength="80"
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => ({ town: option.town, province: option.province })}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
            <Col md={6}>
              <CustomDatePicker
                id="authBirthDate"
                name="authBirthDate"
                className="form-control"
                dateFormat="dd/MM/yyyy"
                type="date"
                placeholder="Data di nascita*"
                fieldLabel="Data di nascita*"
                adjustDateOnChange
                defaultValue={cadastralDetailData?.authBirthDate || null}
                disabled={canEdit ? false : true}
                validate={mustBeAdult}
              />
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <CustomFormField
                id="authFiscalCode"
                name="authFiscalCode"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Codice fiscale*"
                fieldLabel="Codice fiscale*"
                style={{ textTransform: 'uppercase' }}
                maxLength="20"
                defaultValue={cadastralDetailData?.authFiscalCode || null}
                validate={fiscalCodeCheck(true)}
              />
            </Col>
            <Col md={6}>
              <ExtendedAutocompleteFormField
                id="authResidenceTown"
                name="authResidenceTown"
                placeholder="Residente a*"
                fieldLabel="Residente a*"
                fetchFunction={fetchTowns(accessToken)}
                defaultValue={cadastralDetailData?.authResidenceTown || null}
                disabled={canEdit ? false : true}
                validate={required}
                maxLength="80"
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => ({ town: option.town, province: option.province })}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <ExtendedSelectField
                options={formValues?.authPropertyType || []}
                id="authPropertyType"
                name="authPropertyType"
                isDisabled={canEdit ? false : true}
                placeholder="Titolare del diritto di*"
                fieldLabel="Titolare del diritto di*"
                validate={required}
                defaultValue={cadastralDetailData?.authPropertyType || null}
              />
            </Col>
            {values && parseInt(values.authPropertyType?.value) === 3 &&
              <Col md={6}>
                <CustomFormField
                  id="authOtherType"
                  name="authOtherType"
                  type="text"
                  maxLength="128"
                  disabled={canEdit ? false : true}
                  placeholder="Altro (specificare)*"
                  fieldLabel="Altro (specificare)*"
                  validate={required}
                  defaultValue={cadastralDetailData?.authOtherType || null}
                />
              </Col>
            }
          </Row>

          {values && parseInt(values.authPropertyType?.value) === 2 &&
            <>
              <Row form>
                <Col md="12">
                  <legend className="col-form-label">Con atto registrato presso l’Agenzia delle Entrate:</legend>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <ExtendedAutocompleteFormField
                    id="authRentRegisteredBy"
                    name="authRentRegisteredBy"
                    placeholder="Agenzia delle Entrate di*"
                    fieldLabel="Agenzia delle Entrate di*"
                    fetchFunction={fetchTowns(accessToken)}
                    defaultValue={cadastralDetailData?.authRentRegisteredBy || null}
                    disabled={canEdit ? false : true}
                    validate={required}
                    maxLength="80"
                    getOptionLabel={option => `${option.town} (${option.province})`}
                    getOptionValue={option => ({ town: option.town, province: option.province })}
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                    }
                  />
                </Col>
                <Col md={4}>
                  <CustomDatePicker
                    id="authRentRegisteredDate"
                    name="authRentRegisteredDate"
                    className="form-control"
                    type="date"
                    placeholder="In data*"
                    fieldLabel="In data*"
                    adjustDateOnChange
                    maxDate={new Date()}
                    defaultValue={cadastralDetailData?.authRentRegisteredDate || null}
                    disabled={canEdit ? false : true}
                    validate={noFutureDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
                <Col md={2}>
                  <CustomFormField
                    id="authRentRegisteredAt"
                    name="authRentRegisteredAt"
                    type="text"
                    maxLength="50"
                    disabled={canEdit ? false : true}
                    placeholder="Al n.*"
                    fieldLabel="Al n.*"
                    validate={required}
                    defaultValue={cadastralDetailData?.authRentRegisteredAt || null}
                  />
                </Col>
              </Row>
            </>
          }

          <Row form>
            <Col md="12">
              <legend className="col-form-label">DICHIARO di aver messo a disposizione il suddetto immobile al Sig./Sig.ra</legend>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <CustomFormField
                id="beneficiaryName"
                name="beneficiaryName"
                type="text"
                maxLength="128"
                disabled={canEdit ? false : true}
                placeholder="Nome*"
                fieldLabel="Nome*"
                validate={required}
                defaultValue={cadastralDetailData?.beneficiaryName || null}
              />
            </Col>
            <Col md={6}>
              <CustomFormField
                id="beneficiarySurname"
                name="beneficiarySurname"
                type="text"
                maxLength="128"
                disabled={canEdit ? false : true}
                placeholder="Cognome*"
                fieldLabel="Cognome*"
                validate={required}
                defaultValue={cadastralDetailData?.beneficiarySurname || null}
              />
            </Col>
          </Row>
          {/* <Row form>
            <Col md={6}>
              <ExtendedAutocompleteFormField
                id="beneficiaryTown"
                name="beneficiaryTown"
                placeholder="Nato a*"
                fieldLabel="Nato a*"
                fetchFunction={fetchTowns(accessToken)}
                defaultValue={cadastralDetailData?.beneficiaryTown || null}
                disabled={canEdit ? false : true}
                validate={required}
                maxLength="80"
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => ({ town: option.town, province: option.province })}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
            </Col>
            <Col md={6}>
              <CustomDatePicker
                id="beneficiaryBirthDate"
                name="beneficiaryBirthDate"
                className="form-control"
                type="date"
                dateFormat="dd/MM/yyyy"
                placeholder="Data di nascita*"
                fieldLabel="Data di nascita*"
                adjustDateOnChange
                defaultValue={cadastralDetailData?.beneficiaryBirthDate || null}
                disabled={canEdit ? false : true}
                validate={noFutureDate(true)}
              />
            </Col>
          </Row> */}
          <Row form>
            <Col md={6}>
              <CustomFormField
                id="beneficiaryFiscalCode"
                name="beneficiaryFiscalCode"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Codice fiscale*"
                fieldLabel="Codice fiscale*"
                style={{ textTransform: 'uppercase' }}
                maxLength="20"
                defaultValue={cadastralDetailData?.beneficiaryFiscalCode || null}
                validate={fiscalCodeCheck(true)}
              />
            </Col>
          </Row>
        </>
      }
    </>
  )
}

export default PropertyDeclaration
