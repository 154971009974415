import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, FormFeedback, Label } from 'reactstrap'

const CustomReadOnlyField = ({ fieldToListen, minChars = 2, ...props }) => (
  <FormGroup>
    <Field {...props}>
      {({ fieldLabel, form, input: { onChange, value }, input, meta: { error, touched }, ...otherProps }) => (
        <>
          {fieldLabel &&
            <Label for={input.name}>{fieldLabel}</Label>
          }
          <Input readOnly {...input} {...otherProps} value={value}  />
          <FormFeedback>{error}</FormFeedback>
        </>
      )}
    </Field>
  </FormGroup>
)

export default CustomReadOnlyField
