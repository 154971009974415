import { getAPIUrl, fetchData } from '../../../api'
import { codiceFISCALE, vatNumberRegExpCheck } from '../../../utils'

export const boolFiscalCodeCheck = value => {
  return value && codiceFISCALE(value) ? true : false
}

export const boolVatNumberCheck = value => {
  return value && vatNumberRegExpCheck(value) ? true : false
}

export const fetchUserScore = (value, accessToken) => {
  return fetchData(`${getAPIUrl('abStorePortal')}/customers/score-check?fiscalCode=${value}`, accessToken)
}

export const fetchRelatedChangeSupplier = accessToken => searchedValue => {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/change-suppliers/proposal-codes?proposalCode=${searchedValue}`,
    accessToken
  )
}

export function getDefaultOption(options) {
  if(options) {
    const defaultOption = options.filter(opt => opt.default === true)
    return defaultOption.length ? defaultOption[0] : null
  }
}

export const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

export function getCustomerType(requestedCustomerType, contractCustomerType) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

export function relatedSwitchVisibility(entityName) {
  return entityName === 'transfer' ? true : false
}

export function extendedCompanyDataVisibility(entityName) {
  return (
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function businessDataFieldsVisibility(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function residentFieldsVisibility(entityName) {
  return (
    entityName === ''
  ) ? true : false
}

export function propertyTypeVisibility(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function referentVisibility(entityName) {
  return (
    entityName !== 'changeSupplier'
  ) ? true : false
}

export function reconsiderationVisiblity(entityName) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}

export function isMortisCausaVisibility(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function electronicInvoiceRecipientCodeVisibility(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function singleInvoicePodVisibility(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function singleInvoicePdrVisibility(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover'
  ) ? true : false
}

const CustomerTypes = {
  DOMESTIC_CUSTOMER: 1,
  BUSINESS_CUSTOMER: 2
}

export default CustomerTypes
