import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { getItemUrls, getEntityPluralName } from '../../redux/contract/contract.utils'
import { newLabelVisible, getTemplateDownloadPath } from './utils'
import CanI from '../auth/CanI/CanI.component'
import { selectUserData } from '../../redux/user/user.selectors'
import { contractReset } from '../../redux/contract/contract.actions'

const EntityRow = ({ code }) => {
  const dispatch = useDispatch()
  const { links } = useSelector(selectUserData)
  const { name, description, icon, entityName, inEvidenceUntil} = code
  const { create, list } = getItemUrls(entityName)
  const [ visibility, setVisibility ] = useState(false)
  const toggle = () => setVisibility(!visibility)

  return (
    <div className="entity-row">
      <div className="text-container">
        <FontAwesomeIcon size="lg" className="mr-2" icon={icon} />{' '}
        {name}
        {newLabelVisible(inEvidenceUntil) &&
          <sup>Nuovo</sup>
        }
        <div className="more-info">
          <Button 
            onClick={toggle} 
            type="button" 
            color="link"
          >
            Di cosa si tratta?
          </Button>
          <Modal 
            className="entity-info-modal" 
            toggle={toggle}
            isOpen={visibility}
          >
            <ModalBody>
              <div className="icon">
                <FontAwesomeIcon icon={['fa', 'info-circle']} />
              </div>
              <h1 className="title"><FontAwesomeIcon icon={icon} /> {name}</h1>
              <p className="desc">{description}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                outline
                onClick={toggle}
              >
                <FontAwesomeIcon icon={['fa', 'times-circle']} className="mr-1" />
                Chiudi
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
      <div className="actions-container">
        <CanI doWhat={"GET"} withPermissions={links} entityName={getEntityPluralName(entityName)}>
          {() => (
            <Link className="btn btn-outline-secondary outline" to={`/${list}/`}>
              <FontAwesomeIcon icon="list" className="mr-1" />{' '}
              Vedi tutti
            </Link>
          )}
        </CanI>
        <CanI doWhat={"CREATE"} withPermissions={links} entityName={entityName}>
          {() => (
            <Link 
              onClick={() => dispatch(contractReset())}
              className="btn btn-secondary" 
              to={`/${create}`}
            >
              <FontAwesomeIcon icon="plus-circle" className="mr-1" />{' '}
              Crea nuovo
            </Link>
          )}
        </CanI>
        <CanI doWhat="GET" withPermissions={links} entityName={`${entityName}PaperDocument`}>
          {({ action }) => (
            <Button 
              href={`${getTemplateDownloadPath('abStorePortal')}${action}`}
              target="_blank"
            >
              <FontAwesomeIcon icon={['fa', 'file-arrow-down']} className="mr-1" />
              Scarica documento
            </Button>
          )}
        </CanI>
      </div>
    </div>
  )
}
  
export default EntityRow