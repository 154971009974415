import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import { required, fiscalCodeCheck, ibanCode } from '../../validations'
import { canI } from '../../../auth/CanI/utils'
import { getAPIUrl } from '../../../../api'
import { selectContract, selectProposalFormFieldsValues, selectIsSubmitting, selectPermissions }from '../../../../redux/contract/contract.selectors'
import { contractBankAccountSubmitFormStart } from '../../../../redux/contract/contract.actions'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'

//Focus on first field with an error
const focusOnError = createDecorator()

const BankAccountForm = () => {
  const dispatch = useDispatch()
  const permissions = useSelector(selectPermissions) 
  const { entityName, selectedContractId, bankAccountDetailData } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('bankAccount')(state))
  const formValues = useSelector(selectProposalFormFieldsValues) //Bank account shares the only two dynamic fields with proposal form fields
  let canEdit = canI(`${entityName}BankAccountDetailCREATE`, permissions).abilityCheck
  if(bankAccountDetailData) {
    canEdit = canI(`${entityName}BankAccountDetailUPDATE`, bankAccountDetailData.links).abilityCheck
  }

  const onSubmit = values => {
    const method = bankAccountDetailData && canEdit === true ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/bank-account-details${bankAccountDetailData ? `/${bankAccountDetailData.id}` : ''}`
    dispatch(contractBankAccountSubmitFormStart({ apiUrl, values, method }))
  }

  return(
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
    >
      {({ handleSubmit, form, submitting, pristine, values, reset }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <CustomFormField
            name="contractId"
            type="hidden"
            defaultValue={selectedContractId}
          />
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="credit-card" className="mr-2" />
              Metodo di pagamento
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={6}>
                  <ExtendedSelectField
                    name="paymentModeId"
                    isMulti={false}
                    fieldLabel="Modalità*"
                    placeholder="Modalità*"
                    options={formValues?.paymentMode || []}
                    defaultValue={bankAccountDetailData?.paymentModeId || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    id="fiscalCode"
                    name="fiscalCode"
                    type="text"
                    maxLength="20"
                    disabled={canEdit ? false : true}
                    placeholder="Codice fiscale Titolare/Delegato*"
                    fieldLabel="Codice fiscale Titolare/Delegato*"
                    defaultValue={bankAccountDetailData?.fiscalCode || null}
                    validate={fiscalCodeCheck(true)}
                    style={{ textTransform: 'uppercase' }}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <CustomFormField
                    id="name"
                    name="name"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Nome Titolare/Delegato*"
                    fieldLabel="Nome Titolare/Delegato*"
                    maxLength="80"
                    validate={required}
                    defaultValue={bankAccountDetailData?.name || null}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    id="surname"
                    name="surname"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Cognome Titolare/Delegato*"
                    fieldLabel="Cognome Titolare/Delegato*"
                    maxLength="80"
                    validate={required}
                    defaultValue={bankAccountDetailData?.surname || null}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <ExtendedSelectField
                    name="billingTimeId"
                    isMulti={false}
                    fieldLabel="Tempistica di fatturazione*"
                    placeholder="Tempistica di fatturazione*"
                    options={formValues?.billingTime || []}
                    defaultValue={bankAccountDetailData?.billingTimeId || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    id="iban"
                    name="iban"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="IBAN*"
                    fieldLabel="IBAN*"
                    validate={ibanCode(true)}
                    defaultValue={bankAccountDetailData?.iban || null}
                  />
                </Col>
              </Row>
            </div>
          </div>

          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || canEdit === false}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-1" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default BankAccountForm
