import { AsyncStatusEnum, updateUtilityModal } from "../salesOffers/salesOffers.utils"
import SalesOffersTypes from "./salesOffers.types"

const INITIAL_STATE = {
  isFetching: [null, AsyncStatusEnum.IDLE],
  isSubmitting: [null, AsyncStatusEnum.IDLE], //Form submitting tuple [<entity>, <status>]
  isDeleting: [null, AsyncStatusEnum.IDLE], //Delete item bool
  salesOffersList: null,
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  },
  error: null,
}

const salesOffersReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case SalesOffersTypes.TOGGLE_SALES_OFFERS_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          visibility: !state.utilityModal.visibility
        }
      }

    case SalesOffersTypes.SALES_OFFERS_GET_ACTIVE_LIST_START:
      return {
        ...state,
        isFetching: ['salesOffers', AsyncStatusEnum.LOADING],
        salesOffersList: null,
        error: null,
      }

    case SalesOffersTypes.SALES_OFFERS_GET_ACTIVE_LIST_SUCCESS:
      return {
        ...state,
        isFetching: ['salesOffers', AsyncStatusEnum.SUCCESS],
        salesOffersList: action.payload.data
      }

    case SalesOffersTypes.SALES_OFFERS_GET_ACTIVE_LIST_FAILURE:
      return {
        ...state,
        isFetching: ['salesOffers', AsyncStatusEnum.FAILURE],
        utilityModal: updateUtilityModal(action.payload),
        error: action.payload
      }

    case SalesOffersTypes.SALES_OFFERS_RESET:
      return INITIAL_STATE

    default:
      return state
  }
}

export default salesOffersReducer