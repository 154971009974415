import React from 'react'
import { Row, Col } from 'reactstrap'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import { required } from '../../validations'

const PdrOtherAttachementsDataForm = ({ pdrData, formValues,  canEdit }) => (
  <Row form>
    <Col md={6}>
      <CustomFormField
        id="cciaa"
        name="cciaa"
        type="text"
        disabled={canEdit ? false : true}
        placeholder="Cciaa*"
        fieldLabel="Cciaa*"
        validate={required}
        maxLength="80"
        defaultValue={pdrData?.cciaa || null}
      />
    </Col>
    <Col md={6}>
      <ExtendedSelectField
        name="section"
        isMulti={false}
        fieldLabel="Sezione*"
        placeholder="Sezione*"
        options={formValues?.section || []}
        defaultValue={pdrData?.section || null}
        isDisabled={canEdit ? false : true}
        validate={required}
      />
    </Col>
    <Col md={4}>
      <CustomFormField
        id="rea"
        name="rea"
        type="text"
        disabled={canEdit ? false : true}
        placeholder="Rea*"
        fieldLabel="Rea*"
        validate={required}
        maxLength="80"
        defaultValue={pdrData?.rea || null}
      />
    </Col>
    <Col md={4}>
      <CustomFormField
        id="atecoCode"
        name="atecoCode"
        type="text"
        disabled={canEdit ? false : true}
        placeholder="Codice Ateco*"
        fieldLabel="Codice Ateco*"
        validate={required}
        maxLength="8"
        defaultValue={pdrData?.atecoCode || null}
      />
    </Col>
    <Col md={4}>
      <CustomFormField
        id="tableYear"
        name="tableYear"
        type="text"
        disabled={canEdit ? false : true}
        placeholder="Tabella anno*"
        fieldLabel="Tabella anno*"
        maxLength="80"
        validate={required}
        defaultValue={pdrData?.tableYear || null}
      />
    </Col>
  </Row>
)

export default PdrOtherAttachementsDataForm
