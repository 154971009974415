import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CanI from '../../../auth/CanI/CanI.component'
import TdTable from '../../TdTable/TdTable.component'
import DeleteItem from '../../../DeleteItem/DeleteItem.component'
import { selectIsSubmitting, selectEntityName } from '../../../../redux/contract/contract.selectors'
import { contractDeleteItemStart } from '../../../../redux/contract/contract.actions'
import { getEntityPath } from '../../../../redux/contract/contract.utils'

const UserTableRows = ({
  id,
  code,
  email,
  links,
  name,
  personalEmail,
  username,
  reRender
}) => {
  const isSubmitting = useSelector(state => selectIsSubmitting('deleteItem')(state))
  const entityName = useSelector(selectEntityName)

  return(
    <tr key={id}>
      <TdTable value={name} />
      <TdTable value={code} />
      <TdTable value={username} />
      <TdTable value={email} />
      <TdTable value={personalEmail} />
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          <CanI doWhat={"GET"} withPermissions={links} entityName="user">
            {({ action }) => (
              <Link
                title="Vedi dettaglio"
                className="btn btn-outline-secondary btn-sm mx-1"
                to={`/${getEntityPath(entityName)}/${id}/`}
              >
                <FontAwesomeIcon size="sm" icon="eye" />
              </Link>
            )}
          </CanI>
          <CanI doWhat={"DELETE"} withPermissions={links} entityName="user">
            {({ action }) => (
              <DeleteItem
                itemId={id}
                actionUrl={action}
                actionFunction={contractDeleteItemStart}
                isDeleting={isSubmitting}
              />
            )}
          </CanI>
        </div>
      </TdTable>
    </tr>
  )
}

export default UserTableRows
