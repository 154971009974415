import classnames from 'classnames'

const TdTable = ({ value, activeSortParam = "", sortingName, otherClasses = [], children}) => {
  const otherClassNames = otherClasses.reduce((accumulator, item) => {
    accumulator += `${item} `
    return accumulator
  }, "")

  return (
    <td
      className={
        classnames({
          "active-col": activeSortParam.indexOf(sortingName) !== -1,
          [otherClassNames]: true
        })
      }
    >
      {value || children}
    </td>
  )
}

export default TdTable