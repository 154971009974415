import React from 'react'
import { useSelector } from 'react-redux'
import { ListGroup } from 'reactstrap'

import { canI } from '../../../auth/CanI/utils'
import BankAccountItem from '../BankAccountItem/BankAccountItem.component'
import { selectBankAccountDataAsArray, selectContract, selectPermissions } from '../../../../redux/contract/contract.selectors'

const CadastralItemsList = () => {
  const bankAccountData = useSelector(selectBankAccountDataAsArray)
  const { entityName } = useSelector(selectContract)
  const permissions = useSelector(selectPermissions)
  const canEdit = canI(`${entityName}BankAccountDetailCREATE`, permissions).abilityCheck

  return(
    <>
      {
        canEdit === false && 
        !bankAccountData.length ? (
          <strong>Non è necessario inserire o modificare i metodi di pagamento perché risulta già presente un Rid bancario di riferimento.</strong>
        ) : (
          <>
            {bankAccountData.length ?
              <ListGroup>
                {bankAccountData.map(item => (
                  <BankAccountItem key={item.id} item={item} />
                ))}
              </ListGroup>
            :
              <p>Nessuna <strong>opzione di pagamento</strong> presente</p>
            }
          </>
        )
      }
    </>
  )
}

export default CadastralItemsList
