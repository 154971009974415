import React from 'react'

import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import { composeValidators, positiveNumber, integerNumber } from '../validations'

const PotentialityRow = ({ pdrData, canEdit, fieldName, fieldLabel, inputRequired = false }) => (
  <tr>
    <td>
      <CustomFormField
        name={`${fieldName}No`}
        type="number"
        maxLength="20"
        min="0"
        disabled={canEdit ? false : true}
        placeholder="n."
        fieldLabel="n."
        validate={composeValidators(positiveNumber(false), integerNumber(false))}
        noValidationMessage
        defaultValue={pdrData?.[`${fieldName}No`] || null}
      />
    </td>
    <td>
      {inputRequired ? (
        <CustomFormField
          name={fieldName}
          type="text"
          maxLength="80"
          disabled={canEdit ? false : true}
          placeholder="Altro (specificare)"
          fieldLabel="Altro (specificare)"
          noValidationMessage
          defaultValue={pdrData?.fieldName || null}
        />
      ) : (
        <>{fieldLabel}</>
      )}
    </td>
    <td>
      <CustomFormField
        name={`${fieldName}Potentiality`}
        type="number"
        maxLength="20"
        disabled={canEdit ? false : true}
        placeholder="kW"
        fieldLabel="kW"
        validate={positiveNumber(false)}
        noValidationMessage
        min="0"
        defaultValue={pdrData?.[`${fieldName}Potentiality`] || null}
      />
    </td>
    <td>
      <CustomFormField
        name={`${fieldName}TotalPotentiality`}
        type="number"
        readOnly
        placeholder="kW"
        fieldLabel="kW"
        defaultValue={pdrData?.[`${fieldName}TotalPotentiality`] || null}
      />
    </td>
  </tr>
)

export default PotentialityRow