const UserTypes = {
  TOGGLE_USER_UTILITY_MODAL_VISIBILITY: 'TOGGLE_USER_UTILITY_MODAL_VISIBILITY',
  USER_TOGGLE_RESET_PASSWORD_MODAL_VISIBILITY: 'USER_TOGGLE_RESET_PASSWORD_MODAL_VISIBILITY',
  USER_CHANGE_LOGIN_STEP: 'USER_CHANGE_LOGIN_STEP',
  USER_SET_LOGIN_DATA: 'USER_SET_LOGIN_DATA',
  USER_SHOW_REFRESH_TOKEN_TOAST_VISIBILITY: 'USER_SHOW_REFRESH_TOKEN_TOAST_VISIBILITY',

  USER_CHECK_EMAIL_START: 'USER_CHECK_EMAIL_START',
  USER_CHECK_EMAIL_SUCCESS: 'USER_CHECK_EMAIL_SUCCESS',
  USER_CHECK_EMAIL_MUST_CHANGE_PASSWORD: 'USER_CHECK_EMAIL_MUST_CHANGE_PASSWORD',
  USER_CHECK_EMAIL_FAILURE: 'USER_CHECK_EMAIL_FAILURE',

  USER_LOGIN_START: 'USER_LOGIN_START',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  USER_LOGOUT_START: 'USER_LOGOUT_START',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

  USER_SEND_RESET_PASSWORD_EMAIL_START: 'USER_SEND_RESET_PASSWORD_EMAIL_START',
  USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS: 'USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS',
  USER_SEND_RESET_PASSWORD_EMAIL_FAILURE: 'USER_SEND_RESET_PASSWORD_EMAIL_FAILURE',
  USER_SET_NEW_PASSWORD_START: 'USER_SET_NEW_PASSWORD_START',
  USER_SET_NEW_PASSWORD_SUCCESS: 'USER_SET_NEW_PASSWORD_SUCCESS',
  USER_SET_NEW_PASSWORD_FAILURE: 'USER_SET_NEW_PASSWORD_FAILURE',

  USER_CHECK_SESSION_START: 'USER_CHECK_SESSION_START',
  USER_CHECK_SESSION_SUCCESS: 'USER_CHECK_SESSION_SUCCESS',
  USER_CHECK_SESSION_NO_USER: 'USER_CHECK_SESSION_NO_USER',
  USER_CHECK_SESSION_FAILURE: 'USER_CHECK_SESSION_FAILURE',

  USER_REFRESH_TOKEN_START: 'USER_REFRESH_TOKEN_START',
  USER_REFRESH_TOKEN_SUCCESS: 'USER_REFRESH_TOKEN_SUCCESS',
  USER_REFRESH_TOKEN_FAILURE: 'USER_REFRESH_TOKEN_FAILURE',

  USER_GET_ACTIVE_ENTITIES_START: 'USER_GET_ACTIVE_ENTITIES_START',
  USER_GET_ACTIVE_ENTITIES_SUCCESS: 'USER_GET_ACTIVE_ENTITIES_SUCCESS',
  USER_GET_ACTIVE_ENTITIES_FAILURE: 'USER_GET_ACTIVE_ENTITIES_FAILURE',

  USER_GET_SIGNATURE_TYPES_START: 'USER_GET_SIGNATURE_TYPES_START',
  USER_GET_SIGNATURE_TYPES_SUCCESS: 'USER_GET_SIGNATURE_TYPES_SUCCESS',
  USER_GET_SIGNATURE_TYPES_FAILURE: 'USER_GET_SIGNATURE_TYPES_FAILURE',

  USER_MASSIVE_CREATION_START: 'USER_MASSIVE_CREATION_START',
  USER_MASSIVE_CREATION_SUCCESS: 'USER_MASSIVE_CREATION_SUCCESS',
  USER_MASSIVE_CREATION_FAILURE: 'USER_MASSIVE_CREATION_FAILURE',

  USER_UPDATE_MAINTENANCE_STATE: 'USER_UPDATE_MAINTENANCE_STATE',
}

export default UserTypes
