import { UserRoles } from "../../../../redux/user/user.utils"

export const getContractStatus = (status) => {
  switch(status) {
    case 2:
      return "signature"

    case 3:
      return "cogs"

    case 4:
      return "sync"

    case 5:
      return "hand-paper"

    case 6:
      return "adjust"

    case 7:
      return "times-circle"

    default:
      return "door-open"
  }
}

export function isActiveUserEntity(entityName) {
  return (
    entityName === 'deactivation'
  ) ? true : false
}

export function isReportColNeeded(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER ||
    userRole === UserRoles.USER_ROLE_READER ||
    userRole === UserRoles.USER_ROLE_SUPPORT
  ) ? true : false
}

export function isUserScoreNeeded(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER
  ) ? true : false
}