import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { Spinner, Table, Container, Row, Col } from 'reactstrap'

import PdrFilters from '../PdrFilters/PdrFilters.component'
import PodPdrLegend from '../../PodPdrLegend/PodPdrLegend.component'
import DownloadExcelButton from '../../../DownloadExcelButton/DownloadExcelButton.component'
import ThTable from '../../ThTable/ThTable.component'
import PdrTableRows from '../PdrTableRows/PdrTableRows.component'
import ListPagination from '../../ListPagination/ListPagination.component'
import { buildFetchUrl, buildExcelExportUrl } from './utils'
import {
  contractGetListFiltersStart, contractGetListDataStart, contractResetList,
  contractUpdateListSorting
} from '../../../../redux/contract/contract.actions'
import {
  selectList, selectIsFetchingData,
  selectListExcelExportUrl, selectItemsListAsArray
} from '../../../../redux/contract/contract.selectors'
import {
  LIST_DEFAULT_ITEMS_PER_PAGE as itemsPerPage,
  LIST_DEFAULT_MAX_PAGINATION_NUMBER as maxPaginationNumbers
} from '../../../../pages/utils'

const PdrList = () => {
  const dispatch = useDispatch()
  const apiUrl = useMemo(() => buildFetchUrl(itemsPerPage), [])
  const excelExportUrl = useSelector(state => selectListExcelExportUrl(buildExcelExportUrl(itemsPerPage))(state))

  //Populate filters calling API
  //Populate filters and populate list on mount
  useEffect(() => {
    dispatch(contractResetList())
    dispatch(contractUpdateListSorting('sorts=-insertDate'))
    dispatch(contractGetListFiltersStart())
    dispatch(contractGetListDataStart({ apiUrl }))
    // eslint-disable-next-line
  }, [])

  const isFetchingData = useSelector(state => selectIsFetchingData('list')(state))
  const { count } = useSelector(selectList)
  const items = useSelector(selectItemsListAsArray)

  //Handle sorting
  const [ sortParam, setSortParam ] = useState('-insertDate')

  //Build table rows
  const tableRows = items.map(item => {
    return(
      <PdrTableRows
        key={item.id}
        {...item}
        sortParam={sortParam}
      />
    )
  })

  return(
    <Container className="mb-5">
      <Row form>
        <Col>
          <PdrFilters
            apiUrl={apiUrl}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <PodPdrLegend />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12}>
          <div className={classnames({'table-container': true, 'loading': isFetchingData})}>
            {isFetchingData &&
              <div className="element-loader-container">
                <Spinner color="secondary" />
              </div>
            }
            <Table responsive>
              <thead>
                <tr>
                <ThTable 
                    isSortable={false}
                    isActive={sortParam.indexOf('statusId') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="statusId"
                    label="Status"
                    apiUrl={apiUrl}
                  />
                  <ThTable label="Tipologia contratto" />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('proposalCode') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="proposalCode"
                    label="Codice proposta"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('customerCode') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="customerCode"
                    label="Codice cliente"
                    apiUrl={apiUrl}
                  />
                  <ThTable label="Nome cliente" />
                  <ThTable 
                    isSortable={false}
                    isActive={sortParam.indexOf('customerEmail') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="customerEmail"
                    label="Email cliente"
                    apiUrl={apiUrl}
                  />
                  <ThTable label="N° PDR" />
                  <ThTable 
                    isSortable={false}
                    isActive={sortParam.indexOf('agent') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="agent"
                    label="Nome agente"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('signingDate') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="signingDate"
                    label="Data firma"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('insertDate') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="insertDate"
                    label="Data caricamento"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('remiCode') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="remiCode"
                    label="Codice REMI"
                    apiUrl={apiUrl}
                  />
                  <ThTable label="Offerta" />
                  <ThTable label="" />
                </tr>
              </thead>
              <tbody>
                {tableRows.length ?
                  tableRows
                  :
                  null
                }
                {!tableRows.length && !isFetchingData ?
                  <tr>
                    <td colSpan="7" className="text-center">
                      <strong>Nessun elemento trovato</strong>
                    </td>
                  </tr>
                  :
                  null
                }
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col xs={9}>
          <ListPagination
            totalItems={count}
            maxPaginationNumbers={maxPaginationNumbers}
            itemsPerPage={itemsPerPage}
            apiUrl={apiUrl}
          />
        </Col>
        <Col xs={3}>
          <p className="total-items"><strong>Elementi totali:</strong> {count}</p>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <DownloadExcelButton
          downloadLink={excelExportUrl}
          label="Esporta excel"
        />
      </Row>
    </Container>
  )
}

export default PdrList
