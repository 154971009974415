import createDecorator from 'final-form-calculate'

export let totalPower = 0

export const potentialityCalculators = createDecorator(
  {
    field: /(heatingNo)?(heatingPotentiality)?/,
    updates: {
      heatingTotalPotentiality: (ignoredValue, allValues) => {
        const no = allValues?.heatingNo || 0
        const potentiality = allValues?.heatingPotentiality || 0
        const rowSum = parseInt(no) * parseInt(potentiality)
        return rowSum
      }
    }
  },
  {
    field: /(hotWaterNo)?(hotWaterPotentiality)?/,
    updates: {
      hotWaterTotalPotentiality: (ignoredValue, allValues) => {
        const no = allValues?.hotWaterNo || 0
        const potentiality = allValues?.hotWaterPotentiality || 0
        return parseInt(no) * parseInt(potentiality)
      }
    }
  },
  {
    field: /(hobNo)?(hobPotentiality)?/,
    updates: {
      hobTotalPotentiality: (ignoredValue, allValues) => {
        const no = allValues?.hobNo || 0
        const potentiality = allValues?.hobPotentiality || 0
        return parseInt(no) * parseInt(potentiality)
      }
    }
  },
  {
    field: /(hobWithHovenNo)?(hobWithHovenPotentiality)?/,
    updates: {
      hobWithHovenTotalPotentiality: (ignoredValue, allValues) => {
        const no = allValues?.hobWithHovenNo || 0
        const potentiality = allValues?.hobWithHovenPotentiality || 0
        return parseInt(no) * parseInt(potentiality)
      }
    }
  },
  {
    field: /(hovenNo)?(hovenPotentiality)?/,
    updates: {
      hovenTotalPotentiality: (ignoredValue, allValues) => {
        const no = allValues?.hovenNo || 0
        const potentiality = allValues?.hovenPotentiality || 0
        return parseInt(no) * parseInt(potentiality)
      }
    }
  },
  {
    field: /(hotAirNo)?(hotAirPotentiality)?/,
    updates: {
      hotAirTotalPotentiality: (ignoredValue, allValues) => {
        const no = allValues?.hotAirNo || 0
        const potentiality = allValues?.hotAirPotentiality || 0
        return parseInt(no) * parseInt(potentiality)
      }
    }
  },
  {
    field: /(otherDeviceNo)?(otherDevicePotentiality)?/,
    updates: {
      otherDeviceTotalPotentiality: (ignoredValue, allValues) => {
        const no = allValues?.otherDeviceNo || 0
        const potentiality = allValues?.otherDevicePotentiality || 0
        return parseInt(no) * parseInt(potentiality)
      }
    }
  },
  {
    field: /TotalPotentiality?/,
    updates: {
      totalPower: (ignoredValue, allValues) => {
        const totalPower = (
          parseInt(allValues?.heatingTotalPotentiality) +
          parseInt(allValues?.hotWaterTotalPotentiality) +
          parseInt(allValues?.hobTotalPotentiality) +
          parseInt(allValues?.hobWithHovenTotalPotentiality) +
          parseInt(allValues?.hovenTotalPotentiality) +
          parseInt(allValues?.hotAirTotalPotentiality) +
          parseInt(allValues?.otherDeviceTotalPotentiality)
        )
        return totalPower || allValues.totalPower
      }
    }
  },
)