import styled from 'styled-components'

const InputLoaderStyle = styled.div`
  > span {
    font-size: .75rem;
    margin-left: .25rem;
    font-weight: 500;
    color: #666;
  }
`

export default InputLoaderStyle