import moment from 'moment'
import _ from 'lodash'

export function parseDate(value) {
  return moment(value).format('YYYY-MM-DD')
}

export function setQueryString(values) {
  let queryParams = []

  _.forEach(values, (value, key) => {
    if(key === 'signingDateStart' || key === 'insertDateStart') {
      queryParams.push(`${key}=${parseDate(value)}`)
    }
    else if(key === 'signingDateEnd' || key === 'insertDateEnd') {
      queryParams.push(`${key}=${parseDate(value)} 23:59:59.999`)
    }
    else {
      queryParams.push(`${key}=${encodeURIComponent(value)}`)
    }
  })

  return queryParams
}
