import UserTypes from './user.types'
import ContractTypes from '../contract/contract.types'
import { updateUtilityModal, AsyncStatusEnum, LoginSteps, MAX_LOGIN_ATTEMPTS, translateErrorMessage } from './user.utils'

const INITIAL_STATE = {
  maintenanceState: undefined,
  asyncStatus: AsyncStatusEnum.IDLE, //Fetch async status enum
  submitStatus: AsyncStatusEnum.IDLE, //Form submit status enum
  loginStep: LoginSteps.USER_EMAIL_STEP,
  loginData: null,
  loginAttempts: 0,
  userData: null,
  firebaseUser: null,
  userEntities: [],
  userSignatureTypes: [],
  error: null,
  resetPasswordModalVisibility: false,
  refreshUserTokenToastVisibility: false,
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  }
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case UserTypes.USER_CHANGE_LOGIN_STEP:
      return {
        ...state,
        loginStep: action.payload
      }

    case UserTypes.TOGGLE_USER_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          visibility: !state.utilityModal.visibility
        }
      }

    case UserTypes.USER_TOGGLE_RESET_PASSWORD_MODAL_VISIBILITY:
      return {
        ...state,
        resetPasswordModalVisibility: !state.resetPasswordModalVisibility,
        submitStatus: AsyncStatusEnum.IDLE
      }

    case UserTypes.USER_SET_LOGIN_DATA:
      return {
        ...state,
        loginData: {
          ...state.loginData,
          ...action.payload
        }
      }

    case UserTypes.USER_SET_NEW_PASSWORD_START:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.LOADING,
        error: null
      }

    case UserTypes.USER_SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.SUCCESS
      }

    case UserTypes.USER_SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.FAILURE,
        utilityModal: updateUtilityModal(action.payload),
        error: action.payload
      }

    case UserTypes.USER_SEND_RESET_PASSWORD_EMAIL_START:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.LOADING
      }

    case UserTypes.USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.SUCCESS
      }

    case UserTypes.USER_SEND_RESET_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.FAILURE,
        utilityModal: updateUtilityModal(action.payload)
      }

    case UserTypes.USER_GET_SIGNATURE_TYPES_START:
      return {
        ...state,
        asyncStatus: AsyncStatusEnum.LOADING,
        userSignatureTypes: []
      }

    case UserTypes.USER_GET_SIGNATURE_TYPES_SUCCESS:
      return {
        ...state,
        userSignatureTypes: action.payload.data,
        asyncStatus: AsyncStatusEnum.SUCCESS
      }

    case UserTypes.USER_GET_SIGNATURE_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        asyncStatus: AsyncStatusEnum.FAILURE,
        utilityModal: updateUtilityModal(action.payload)
      }

    case UserTypes.USER_CHECK_EMAIL_START:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.LOADING,
        loginData: action.payload,
        error: null
      }

    case UserTypes.USER_CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.SUCCESS,
        loginStep: LoginSteps.USER_PASSWORD_STEP,
        loginData: action.payload
      }

    case UserTypes.USER_CHECK_EMAIL_MUST_CHANGE_PASSWORD:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.SUCCESS,
        utilityModal: {
          visibility: true,
          modalTitle: "Reset password",
          modalIcon: ['fas', 'exclamation-triangle'],
          modalDescription: action.payload.message
        }
      }

    case UserTypes.USER_CHECK_EMAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        submitStatus: AsyncStatusEnum.FAILURE,
        utilityModal: updateUtilityModal(action.payload)
      }

    case UserTypes.USER_LOGIN_START:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.LOADING,
        loginAttempts: state.loginAttempts === MAX_LOGIN_ATTEMPTS ? 1 : state.loginAttempts + 1
      }

    case UserTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        submitStatus: AsyncStatusEnum.SUCCESS,
        firebaseUser: action.payload.firebaseUser,
        userData: action.payload.userData,
        userEntities: action.payload.userEntities,
        maintenanceState: action.payload.maintenanceState,
        loginAttempts: 0,
        error: null
      }

    case UserTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        utilityModal: updateUtilityModal(translateErrorMessage(action.payload)),
        submitStatus: AsyncStatusEnum.FAILURE,
      }

    case UserTypes.USER_LOGOUT_START:
      return {
        ...state,
        asyncStatus: AsyncStatusEnum.LOADING
      }

    case UserTypes.USER_REFRESH_TOKEN_FAILURE:
    case UserTypes.USER_LOGOUT_FAILURE:
      return {
        ...state,
        asyncStatus: AsyncStatusEnum.FAILURE,
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case UserTypes.USER_LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        asyncStatus: AsyncStatusEnum.SUCCESS
      }

    case UserTypes.USER_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshUserTokenToastVisibility: false,
        userData: {
          ...state.userData,
          accessToken: action.payload.newToken
        }
      }

    case UserTypes.USER_CHECK_SESSION_START:
      return {
        ...state,
        asyncStatus: AsyncStatusEnum.LOADING,
        userData: null,
        error: null
      }

    case UserTypes.USER_CHECK_SESSION_NO_USER:
      return {
        ...state,
        asyncStatus: AsyncStatusEnum.SUCCESS
      }

    case UserTypes.USER_CHECK_SESSION_SUCCESS:
      return {
        ...state,
        asyncStatus: AsyncStatusEnum.SUCCESS,
        userData: action.payload.userData,
        firebaseUser: action.payload.firebaseUser,
        userEntities: action.payload.userEntities,
        maintenanceState: action.payload.maintenanceState,
        error: null
      }

    case UserTypes.USER_CHECK_SESSION_FAILURE:
      return {
        ...state,
        asyncStatus: AsyncStatusEnum.FAILURE,
        error: action.payload,
        utilityModal: updateUtilityModal(action.payload)
      }

    case ContractTypes.CONTRACT_USER_SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        userData: action.payload.loggedInUser,
        userEntities: action.payload.userEntities
      }

    case UserTypes.USER_SHOW_REFRESH_TOKEN_TOAST_VISIBILITY:
      return {
        ...state,
        refreshUserTokenToastVisibility: true
      }

    case UserTypes.USER_UPDATE_MAINTENANCE_STATE:
      return {
        ...state,
        maintenanceState: action.payload.maintenanceState
      }

    default:
      return state
  }
}

export default userReducer
