import { UserRoles } from "../../../../redux/user/user.utils"

export function isActiveUserEntity(entityName) {
  return (
    entityName === 'deactivation'
  ) ? true : false
}

export function isReportColNeeded(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER ||
    userRole === UserRoles.USER_ROLE_READER ||
    userRole === UserRoles.USER_ROLE_SUPPORT
  ) ? true : false
}

export function isUserScoreNeeded(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER
  ) ? true : false
}