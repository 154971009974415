import React from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'

import LoginEmailForm from '../../components/forms/LoginEmailForm/LoginEmailForm.component'
import LoginPasswordForm from '../../components/forms/LoginPasswordForm/LoginPasswordForm.component'
import LoginPageStyle from './style'
import ResetPasswordModal from '../../components/forms/ResetPasswordModal/ResetPasswordModal.component'
import { selectLoginStep } from '../../redux/user/user.selectors'
import { LoginSteps } from '../../redux/user/user.utils'

const LoginPage = () => {
  const loginStep = useSelector(selectLoginStep)

  return (
    <LoginPageStyle>
      <div className="form-container">
        <Container fluid>
          {loginStep === LoginSteps.USER_EMAIL_STEP &&
            <LoginEmailForm />
          }
          {loginStep === LoginSteps.USER_PASSWORD_STEP &&
            <LoginPasswordForm />
          }
          <ResetPasswordModal />
        </Container>
      </div>
    </LoginPageStyle>
  )
}

export default LoginPage
