import React from 'react'
import { ListGroup } from 'reactstrap'

import Note from './Note.component'

//Note list container
function NotesList({ items }) {
  return(
    <>
    {items && items.length ?
    <ListGroup>
        {items.map((note, i) => (
            <Note
              key={i}
              {...note}
            />
          )
        )}
    </ListGroup>
    :
      <span>Nessuna nota presente</span>
    }
    </>
  )
}

export default NotesList
