import { getAPIUrl, fetchData } from '../../../api'
import { getEntityUriName } from '../../../redux/contract/contract.utils'

export const fetchDistributors = (entityName, cadastralDataId, accessToken) => searchedValue => {
  if(searchedValue.length > 1) {
    return fetchData(
      `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pdrs/distributors?cadastralDataId=${cadastralDataId}&distributorName=${searchedValue}&limit=20`,
      accessToken
    )
    .then(({ data }) => data)
    .catch(error => console.error(error))
  }
}

export const fetchVendors = (entityName, accessToken) => searchedValue => {
  if(searchedValue.length > 1) {
    return fetchData(
      `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pdrs/vendors?vendorName=${searchedValue}&limit=20`,
      accessToken
    )
    .then(({ data }) => data)
    .catch(error => console.error(error))
  }
}

export function fetchGasUseTypeId(contractTypeId, entityName, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/use-types?OpeningCausal=${entityName}&supplyPointType=gas&ContractType=${contractTypeId}`,
    accessToken
  )
}

export function fetchRemiCodes(cadastralDetailDataId, entityName, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pdrs/remi-codes/${cadastralDetailDataId}`,
    accessToken
  )
}

export function fetchContractPriceList(entityName, contractId, pdrId, gasUseTypeId, logicalPoint, email, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/offers?FornitureType=G&pdrId=${pdrId || ''}&GasUseType=${gasUseTypeId}&remiCode=${logicalPoint}&userEmail=${encodeURIComponent(email)}&openingCausal=${entityName}&contractId=${contractId}`,
    accessToken
  )
}

export function fetchVatClassList(customerType, entityName, useType, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/available-vats?customerType=${customerType}&openingCausal=${entityName}&useType=${useType}&atecoCode=&supplyPointType=gas`,
    accessToken
  )
}

export function setUpDataForSubmit(values) {
  values.isInvoiceDifferentEmail = parseInt(values.isInvoiceDifferentEmail)
  return values
}

export function getCustomerType(requestedCustomerType, contractCustomerType) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

export function isGasExciseClassIdNeeded(entityName, values) {
  if(entityName === 'changeSupplier') {
    return values.gasUseTypeId &&
      parseInt(values.gasUseTypeId.value) !== 1 &&
      parseInt(values.gasUseTypeId.value) !== 2 &&
      parseInt(values.gasUseTypeId.value) !== 8
  }

  return (
    (
      entityName === 'transfer' ||
      entityName === 'contextualTransferGas' ||
      entityName === 'takeover' ||
      entityName === 'newActivation' ||
      entityName === 'newConnection'
    ) && (
      values.contractType &&
      parseInt(values.contractType.value) !== 1 &&
      parseInt(values.contractType.value) !== 2 &&
      parseInt(values.contractType.value) !== 3
    )
  ) ? true : false
}

export function isUseTypeNeeded(entityName) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}

export function isResidentNeeded(entityName) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}

export function isVendorNameNeeded(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isServiceStartDateNeeded(entityName) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isMeterReadNumberNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isOriginMarketNeeded(entityName) {
  return (
    entityName === 'newActivation'
  ) ? true : false
}

export function isPublicAuthorityNeeded(entityName) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isSupplyFormNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isFAttachmentRequired(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isEAttachmentRequired(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function noValidationRequired(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'transfer' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation'
  ) ? true : false
}

export function isPotentialityNeeded(entityName) {
  return (
    entityName === 'takeover' ||
    entityName === 'newActivation'
  ) ? true : false
}

export const DefaultSelectValues = {
  GAS_USE_TYPE_ID: { label: "Domestico", value: "1" },
  GAS_EXCISE_CLASS_ID: { label: "No", value: "13"},
  IS_RESIDENT: { value: "1", label: "Sì" },
  IS_NOT_RESIDENT: { value: "2", label: "No" },
  IS_INVOICE_DIFFERENT_EMAIL: { value: "2", label: "No" },
  BILLING_SHIPPING_ADDRESS: { value: "1", label: "No" }
}

const CustomerTypes = {
  DOMESTIC_CUSTOMER: 1,
  BUSINESS_CUSTOMER: 2
}

export default CustomerTypes
