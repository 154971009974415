import moment from 'moment'

import confJson from '../../api/configuration.json'

export const newLabelVisible = value => {
  const newUntil = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'])
  const now = moment()

  if(value) {
    return now.diff(newUntil, 'days') > 0 ? false : true
  }

  return false
}

export const getTemplateDownloadPath = endPoint => {
  const { endPoints, env } = confJson
  const { protocol, host } = endPoints[endPoint][env]

  return `${protocol}://${host}`
}