import React from 'react'
import { ListGroup } from 'reactstrap'

import Communication from './Communication.component'

function CommunicationsList({ items }) {
  return(
    <>
    {items && items.length ?
    <ListGroup>
        {items.map((report, i) => (
            <Communication
              key={i}
              {...report}
            />
          )
        )}
    </ListGroup>
    :
      <span>Nessuna comunicazione presente</span>
    }
    </>
  )
}

export default CommunicationsList
