import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ResetPasswordStyle from './style'
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm.component'
import { selectResetPasswordDone } from '../../../redux/user/user.selectors'

const FirebaseAuthPage = () => {
  const resetPasswordDone = useSelector(selectResetPasswordDone)

  return (
    <ResetPasswordStyle>
      <div className="form-container">
        <Container fluid>
          <div className="login-icon">
            <FontAwesomeIcon icon="key" />
          </div>
          <p className="lead">Inserisci la nuova password per procedere:</p>
          {resetPasswordDone ? (
            <div className="reset-feedback">
              <p>Ora puoi tornare alla <Link to="/">home page</Link> ed effettuare il log in con la nuova password.</p>
            </div>
          ) : (
            <ResetPasswordForm />
          )}
        </Container>
      </div>
    </ResetPasswordStyle>
  )
}

export default FirebaseAuthPage
