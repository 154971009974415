import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CanI from '../../../auth/CanI/CanI.component'
import { contractResetBankAccountData, contractToggleBankAccountDetailModal } from '../../../../redux/contract/contract.actions'
import { selectContract, selectPermissions } from '../../../../redux/contract/contract.selectors'

const BankAccountDashboardControlButtons = () => {
  const dispatch = useDispatch()
  const { entityName } = useSelector(selectContract)
  const permissions = useSelector(selectPermissions)

  const handleClick = () => {
    dispatch(contractResetBankAccountData())
    dispatch(contractToggleBankAccountDetailModal())
  }

  return(
    <div className="control-buttons bank-account">
      <CanI doWhat={"CREATE"} withPermissions={permissions} entityName={`${entityName}BankAccountDetail`}>
        {({ action }) => (
          <Button
            size="md"
            color="secondary"
            outline
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={['fas', 'plus-circle']} className="mr-2" />
            Aggiungi metodo di pagamento
          </Button>
        )}
      </CanI>
    </div>
  )
}

export default BankAccountDashboardControlButtons
