import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Field } from "react-final-form"

import GeneralConditionsCheckbox from "../../inputFields/GeneralConditionsCheckbox/GeneralConditionsCheckbox.component"
import CodCivCheckbox from "../../inputFields/CodCivCheckbox/CodCivCheckbox.component"
import { selectContract } from "../../../../redux/contract/contract.selectors"
import { required } from "../../validations"

const GeneralConditions = ({ canEdit, values }) => {
  const { proposalData } = useSelector(selectContract)

  return (
    <>
      {values.customerType !== null &&
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="clipboard-check" className="mr-2" />
            Condizioni Generali di Contratto
          </div>
          <div className="grouped-body privacy">
            <Field
              name="generalConditions"
              type="checkbox"
              disabled={canEdit ? false : true}
              fieldLabel="Condizioni Generali di Contratto*"
              validate={required}
              initialValue={proposalData?.generalConditions || null}
            >
              {(fieldState) => (
                <GeneralConditionsCheckbox
                  customerType={values.customerType}
                  {...fieldState}
                />
              )}
            </Field>
          </div>
        </div>
      }

      {values.customerType !== null &&
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="user-lock" className="mr-2" />
            Presa visione degli articoli 1341 e 1342 Cod. Civ.
          </div>
          <div className="grouped-body privacy">
            <Field
              name="civilCode"
              type="checkbox"
              disabled={canEdit ? false : true}
              fieldLabel="Privacy policy*"
              validate={required}
              initialValue={proposalData?.civilCode || null}
            >
              {(fieldState) => (
                <CodCivCheckbox
                  customerType={values.customerType}
                  {...fieldState}
                />
              )}
            </Field>
          </div>
        </div>
      }
    </>
  )
}

export default GeneralConditions