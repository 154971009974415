import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SignContractAlert from '../../SignContractAlert/SignContractAlert.component'
import { selectContract } from '../../../redux/contract/contract.selectors'
import { contractChangeFormStep } from '../../../redux/contract/contract.actions'
import { ContractStatusMap, ContractTabsMap } from '../../../redux/contract/contract.utils'
import { isTabDisabled } from './utils'

const ContractTabs = () => {
  const dispatch = useDispatch()
  const { formStep, selectedStep, selectedContractStatusId } = useSelector(selectContract)

  return (
    <>
      <Nav tabs className="subscription-nav">
        <NavItem>
          <NavLink
            onClick={e => dispatch(contractChangeFormStep(ContractTabsMap.PROPOSAL_STEP))}
            className={classnames({ active: selectedStep === ContractTabsMap.PROPOSAL_STEP })}
          >
            <FontAwesomeIcon icon="address-card" className="mr-2" />
            Proposta
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(ContractTabsMap.PAYMENT_STEP, formStep)}
            onClick={e => dispatch(contractChangeFormStep(ContractTabsMap.PAYMENT_STEP))}
            className={classnames({ active: selectedStep === ContractTabsMap.PAYMENT_STEP })}
          >
            <FontAwesomeIcon icon="money-bill-wave" className="mr-2" />
            Dati pagamento
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={isTabDisabled(ContractTabsMap.CADASTRAL_STEP, formStep)}
            onClick={e => dispatch(contractChangeFormStep(ContractTabsMap.CADASTRAL_STEP))}
            className={classnames({ active: selectedStep === ContractTabsMap.CADASTRAL_STEP })}
          >
            <FontAwesomeIcon icon="home" className="mr-2" />
            Utenze
          </NavLink>
        </NavItem>
        {selectedContractStatusId >= ContractStatusMap.DONE_STATUS &&
          <>
            <NavItem>
              <NavLink
                disabled={isTabDisabled(ContractTabsMap.ATTACHMENTS_STEP, formStep)}
                onClick={e => dispatch(contractChangeFormStep(ContractTabsMap.ATTACHMENTS_STEP))}
                className={classnames({ active: selectedStep === ContractTabsMap.ATTACHMENTS_STEP })}
              >
                <FontAwesomeIcon icon="paperclip" className="mr-2" />
                Documenti
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={e => dispatch(contractChangeFormStep(ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP))}
                className={classnames({ active: selectedStep === ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP })}
              >
                <FontAwesomeIcon icon="file-contract" className="mr-2" />
                Contratto firmato
              </NavLink>
            </NavItem>
          </>
        }
      </Nav>
      {formStep >= ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP && selectedContractStatusId === ContractStatusMap.ONGOING_STATUS &&
        <SignContractAlert />
      }
    </>
  )
}

export default ContractTabs
