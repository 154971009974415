import React from 'react'
import { Row, Col } from 'reactstrap'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import { required } from '../../validations'
import { isAsNeeded } from './utils'

const PdrEAttachmentForm = ({ formValues, values, canEdit, pdrData, entityName }) => (
  <>
    {isAsNeeded(entityName) && 
      <Row form>
        <Col md={12}>
          <ExtendedSelectField
            name="as"
            isMulti={false}
            fieldLabel="Il richiedente in qualità di*"
            placeholder="Il richiedente in qualità di*"
            options={formValues?.as || []}
            defaultValue={pdrData?.as || null}
            isDisabled={canEdit ? false : true}
            validate={required}
          />
        </Col>
        {values && parseInt(values.as?.value) === 6 &&
          <Col md={12}>
            <CustomFormField
              id="asOther"
              name="asOther"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="Altro (specificare)*"
              fieldLabel="Altro (specificare)*"
              maxLength="14"
              validate={required}
              defaultValue={pdrData?.asOther || null}
            />
          </Col>
        }
      </Row>
    }
    <Row form>
      <Col md={4}>
        <CustomFormField
          id="registeredAt"
          name="registeredAt"
          type="text"
          maxLength="80"
          disabled={canEdit ? false : true}
          placeholder="Ditta/Società iscritta presso*"
          fieldLabel="Ditta/Società iscritta presso*"
          validate={required}
          defaultValue={pdrData?.registeredAt || null}
          fieldDescription="Dichiara che la Ditta/Società qui rappresentata, risulta iscritta presso l'Ufficio Registro delle Imprese della Camera di Commercio, Industria, Artigianato e Agricoltura di..."
        />
      </Col>
      <Col md={4}>
        <ExtendedSelectField
          options={formValues?.asEAttachment || []}
          name="asEAttachment"
          type="select"
          disabled={canEdit ? false : true}
          fieldLabel="In qualità di*"
          placeholder="In qualità di*"
          validate={required}
          defaultValue={pdrData?.asEAttachment || null}
        />
      </Col>
      <Col md={4}>
        <CustomFormField
          id="activityCarriedOut"
          name="activityCarriedOut"
          type="text"
          maxLength="128"
          disabled={canEdit ? false : true}
          placeholder="Attività esercitata*"
          fieldLabel="Attività esercitata*"
          validate={required}
          defaultValue={pdrData?.activityCarriedOut || null}
        />
      </Col>
    </Row>
    <Row form>
      <Col md="12">
        <legend className="col-form-label">Sotto la propria personale responsabilità, ai sensi dell’art. 47 del D.P.R. 28.12.2000 n. 445 - dichiarazioni sostitutive dell’atto di notorietà – che la Ditta/Società qui rappresentata utilizza in tutto o in parte il gas naturale nella propria attività riconducibile all’attività industriale.</legend>
      </Col>
    </Row>
    <Row form>
      <Col md={6}>
        <ExtendedSelectField
          options={formValues?.sector || []}
          name="sector"
          type="select"
          disabled={canEdit ? false : true}
          placeholder="Settore d'uso del gas*"
          fieldLabel="Settore d'uso del gas*"
          validate={required}
          defaultValue={pdrData?.sector || null}
        />
      </Col>
      {values && parseInt(values.sector?.value) === 7 &&
        <Col md={6}>
          <CustomFormField
            id="atecofinCode"
            name="atecofinCode"
            type="text"
            maxLength="8"
            disabled={canEdit ? false : true}
            placeholder="Codice ATECOFIN 2004*"
            fieldLabel="Codice ATECOFIN 2004*"
            validate={required}
            defaultValue={pdrData?.atecofinCode || null}
          />
        </Col>
      }
    </Row>
    {values && (parseInt(values.sector?.value) === 6 || parseInt(values.sector?.value) === 7 || parseInt(values.sector?.value) === 8) &&
      <Row form>
        <Col md={4}>
          <CustomFormField
            id="officeCustomAgency"
            name="officeCustomAgency"
            type="text"
            maxLength="128"
            disabled={canEdit ? false : true}
            placeholder="Autorizzata dall'Ufficio dell’Agenzia delle Dogane di*"
            fieldLabel="Autorizzata dall'Ufficio dell’Agenzia delle Dogane di*"
            validate={required}
            defaultValue={pdrData?.officeCustomAgency || null}
          />
        </Col>
        <Col md={4}>
          <CustomFormField
            id="protocolLetter"
            name="protocolLetter"
            type="text"
            maxLength="128"
            disabled={canEdit ? false : true}
            placeholder="Con lettera prot*"
            fieldLabel="Con lettera prot*"
            validate={required}
            defaultValue={pdrData?.protocolLetter || null}
          />
        </Col>
        <Col md={4}>
          <CustomDatePicker
            id="of"
            name="of"
            className="form-control"
            type="date"
            placeholder="Del*"
            fieldLabel="Del*"
            adjustDateOnChange
            validate={required}
            defaultValue={pdrData?.of || null}
            disabled={canEdit ? false : true}
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
          />
        </Col>
      </Row>
    }

    {values && parseInt(values.sector?.value) === 8 &&
      <Row form>
        <Col md={6}>
          <CustomFormField
            id="percentageIndustrialGas"
            name="percentageIndustrialGas"
            type="text"
            maxLength="128"
            disabled={canEdit ? false : true}
            placeholder="% ad uso industriale*"
            fieldLabel="% ad uso industriale*"
            validate={required}
            defaultValue={pdrData?.percentageIndustrialGas || null}
          />
        </Col>
        <Col md={6}>
          <CustomFormField
            id="percentageCivilGas"
            name="percentageCivilGas"
            type="text"
            maxLength="128"
            disabled={canEdit ? false : true}
            placeholder="% ad uso civile*"
            fieldLabel="% ad uso civile*"
            validate={required}
            defaultValue={pdrData?.percentageCivilGas || null}
          />
        </Col>
      </Row>
    }
  </>
)

export default PdrEAttachmentForm
