import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import OtpForm from './OtpForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingData } from '../../../redux/contract/contract.selectors'
import { contractGetOtpDataStart } from '../../../redux/contract/contract.actions'

const OtpFormWithSpinner = WithSpinner(OtpForm)

const OtpFormContainer = () => {
  const dispatch = useDispatch()
  const loadingOtpSigners = useSelector(state => selectIsFetchingData('otpData')(state))

  //Get form data
  useEffect(() => {
     dispatch(contractGetOtpDataStart())
  }, [dispatch])

  return (
    <OtpFormWithSpinner isLoading={loadingOtpSigners} />
  )
}

export default OtpFormContainer
