export const customStyles = {
  dropdownIndicator: (provided, state) => {
    const color = '#485056'
    const width = '2rem'
    const padding = '3px'
    const justifyContent = 'center'
    return { ...provided, color, width, padding, justifyContent }
  },
  placeholder: (provided, state) => {
    const fontSize = '.85rem'
    const opacity = '.3'
    return { ...provided, fontSize, opacity }
  },
  singleValue: (provided, state) => {
    const fontSize = '.85rem'
    return { ...provided, fontSize }
  },
  control: (provided) => {
    const borderColor = '#c6d7e6'
    const minHeight = '33px'
    return { ...provided, borderColor, minHeight }
  },
  valueContainer: (provided, state) => {
    const padding = '0 8px'
    const fontSize = '.85rem'
    return { ...provided, padding, fontSize }
  },
  clearIndicator: (provided, state) => {
    const padding = '3px'
    return { ...provided, padding }
  },
  option: (provided) => ({
    ...provided,
    fontSize: '.85rem'
  }),
  loadingMessage: (provided) => ({
    ...provided,
    fontSize: '.85rem'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '.85rem'
  })
}
