import React from 'react'
import { Row, Col } from 'reactstrap'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import WhenFieldChanges from '../../WhenFieldChanges/WhenFieldChanges.component'
import {
  required, euPhoneCheck, emailCheck, vatNumber, vatNumberAsFiscalCode, greaterOrEqualValue
} from '../../validations'
import CustomerTypes, { 
  getCustomerType, isPreviousHolderRequired, isNewHolderRequired, 
  calculateDaysBeforeNow, isMeterReadingNeeded, isNewHolderTitleNeeded,
  isAccuntHolderAtecoCodeNeeded, isSupplyStartDateNeeded, isOldAccountHolderPersonTypeNeeded,
  isOldAccountHolderClientCodeNeeded
} from './utils'

const PdrSupplyForm = ({ customerType, entityName, formValues, values, pdrData, canEdit }) => (
  <>
    <Row form>
      <Col md={12}>
        <ExtendedSelectField
          name="as"
          isMulti={false}
          fieldLabel="Il richiedente in qualità di*"
          placeholder="Il richiedente in qualità di*"
          options={formValues?.as || []}
          defaultValue={pdrData?.as || null}
          isDisabled={canEdit ? false : true}
          validate={required}
        />
      </Col>
      {values && parseInt(values.as?.value) === 6 &&
        <Col md={12}>
          <CustomFormField
            id="asOther"
            name="asOther"
            type="text"
            disabled={canEdit ? false : true}
            placeholder="Altro (specificare)*"
            fieldLabel="Altro (specificare)*"
            maxLength="14"
            validate={required}
            defaultValue={pdrData?.asOther || null}
          />
        </Col>
      }
    </Row>
    <Row form>
      <Col md="12">
        <legend className="col-form-label">Dati fornitura:</legend>
      </Col>
    </Row>
    <Row form>
      {isMeterReadingNeeded(entityName) &&
        <>
          <Col md={6}>
            <CustomFormField
              id="meterReading"
              name="meterReading"
              type="number"
              maxLength="80"
              disabled={canEdit ? false : true}
              min="0"
              placeholder="Lettura contatore (mc)*"
              fieldLabel="Lettura contatore (mc)*"
              validate={greaterOrEqualValue(0)}
              defaultValue={pdrData?.meterReading || null}
            />
          </Col>
          <Col md={6}>
            <CustomDatePicker
              id="meterReadingDate"
              name="meterReadingDate"
              className="form-control"
              type="date"
              placeholder="Data lettura contatore*"
              fieldLabel="Data lettura contatore*"
              adjustDateOnChange
              validate={required}
              maxDate={new Date()}
              minDate={calculateDaysBeforeNow(7)}
              defaultValue={pdrData?.meterReadingDate || null}
              disabled={canEdit ? false : true}
              dateFormat="dd/MM/yyyy"
            />
          </Col>
        </>
      }
      {isSupplyStartDateNeeded(entityName) &&
        <Col md={12}>
          <CustomDatePicker
            id="supplyStartDate"
            name="supplyStartDate"
            className="form-control"
            type="date"
            dateFormat="dd/MM/yyyy"
            fieldLabel="Fornitura in possesso da*"
            placeholder="Fornitura in possesso da*"
            adjustDateOnChange
            validate={required}
            maxDate={new Date()}
            defaultValue={pdrData?.supplyStartDate || null}
            disabled={canEdit ? false : true}
          />
        </Col>
      }
    </Row>

    {isPreviousHolderRequired(entityName) &&
      <>
        <Row form>
          <Col md="12">
            <legend className="col-form-label">Dati vecchio intestatario:</legend>
            <p className="small text-muted">
              A seconda dei dati inseriti alcuni campi saranno da compilare obbligatoriamente. 
              Ad esempio il campo "Partita IVA" diventa obbligatorio se si inserisce una "Ragione Sociale", 
              diventa invece NON obbligatorio inserendo "Nome" e "Cognome" del vecchio intestatario.
            </p>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <ExtendedSelectField
              name="oldAccountHolderCustomerType"
              fieldLabel="Tipologia cliente vecchio intestatario*"
              placeholder="Tiopologia cliente vecchio intestatario*"
              options={formValues?.oldAccountHolderCustomerType || []}
              defaultValue={pdrData?.oldAccountHolderCustomerType || null}
              isDisabled={canEdit ? false : true}
              validate={required}
            />
          </Col>
          {isOldAccountHolderClientCodeNeeded(entityName) &&
            <Col md={12}>
              <CustomFormField
                id="oldAccountHolderClientCode"
                name="oldAccountHolderClientCode"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Codice cliente"
                fieldLabel="Codice cliente"
                maxLength="20"
                defaultValue={pdrData?.oldAccountHolderClientCode || null}
              />
            </Col>
          }
          {parseInt(values?.oldAccountHolderCustomerType?.value) === 2 &&
            <>
              <Col md={6}>
                <CustomFormField
                  id="oldAccountHolderClientBusinessName"
                  name="oldAccountHolderClientBusinessName"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Ragione Sociale*"
                  fieldLabel="Ragione Sociale*"
                  maxLength="80"
                  validate={required}
                  defaultValue={pdrData?.oldAccountHolderClientBusinessName || null}
                />
                <WhenFieldChanges
                  field="oldAccountHolderClientBusinessName"
                  set="oldAccountHolderPersonType"
                  to={null}
                />
              </Col>
              <Col md={6}>
                <CustomFormField
                  id="oldAccountHolderVatNumber"
                  name="oldAccountHolderVatNumber"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Partita IVA*"
                  fieldLabel="Partita IVA*"
                  maxLength="11"
                  validate={vatNumber(true)}
                  defaultValue={pdrData?.oldAccountHolderVatNumber || null}
                />
              </Col>
            </>
          }
          {parseInt(values?.oldAccountHolderCustomerType?.value) === 1 &&
            <>
              <Col md={6}>
                <CustomFormField
                  id="oldAccountHolderName"
                  name="oldAccountHolderName"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Nome*"
                  fieldLabel="Nome*"
                  maxLength="80"
                  validate={required}
                  defaultValue={pdrData?.oldAccountHolderName || null}
                />
              </Col>
              <Col md={6}>
                <CustomFormField
                  id="oldAccountHolderSurname"
                  name="oldAccountHolderSurname"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Cognome*"
                  fieldLabel="Cognome*"
                  maxLength="80"
                  validate={required}
                  defaultValue={pdrData?.oldAccountHolderSurname || null}
                />
              </Col>
              <Col md={12}>
                <CustomFormField
                  id="oldAccountHolderFiscalCode"
                  name="oldAccountHolderFiscalCode"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Codice fiscale*"
                  fieldLabel="Codice fiscale*"
                  style={{ textTransform: 'uppercase' }}
                  maxLength="16"
                  validate={vatNumberAsFiscalCode(true)}
                  defaultValue={pdrData?.oldAccountHolderFiscalCode || null}
                />
              </Col>
            </>
          }
          {isOldAccountHolderPersonTypeNeeded(entityName) &&
            <>
              <Col md={6}>
                <ExtendedSelectField
                  name="oldAccountHolderPersonType"
                  isMulti={false}
                  fieldLabel="Il vecchio intestatario è*"
                  placeholder="Il vecchio intestatario è*"
                  options={values?.oldAccountHolderVatNumber ? (
                    (formValues?.oldAccountHolderPersonTypeBUS || [])
                  ) : (
                    (formValues?.oldAccountHolderPersonTypeDOM || [])
                  )}
                  defaultValue={pdrData?.oldAccountHolderPersonType || null}
                  isDisabled={canEdit ? false : true}
                  validate={required}
                />
              </Col>
              {parseInt(values?.oldAccountHolderPersonType?.value) === 1 &&
                <Col>
                  <CustomFormField
                    id="oldAccountHolderPhone"
                    name="oldAccountHolderPhone"
                    type="number"
                    className="phone-number"
                    disabled={canEdit ? false : true}
                    placeholder="Telefono*"
                    fieldLabel="Telefono*"
                    maxLength="20"
                    defaultValue={pdrData?.oldAccountHolderPhone || null}
                    validate={euPhoneCheck(true)}
                  />
                </Col>
              }
              {parseInt(values?.oldAccountHolderPersonType?.value) === 2 &&
                <Col>
                  <ExtendedSelectField
                    name="unavailabilityReason"
                    isMulti={false}
                    fieldLabel="Motivo irreperibilità*"
                    placeholder="Motivo irreperibilità*"
                    options={formValues?.unavailabilityReason || []}
                    defaultValue={pdrData?.unavailabilityReason || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
              }
            </>
          }

          {
            isOldAccountHolderPersonTypeNeeded(entityName) &&
            parseInt(values?.oldAccountHolderPersonType?.value) === 3 &&
              <>
                <Col md={6}>
                  <CustomDatePicker
                    id="deathDate"
                    name="deathDate"
                    className="form-control"
                    type="date"
                    dateFormat="dd/MM/yyyy"
                    placeholder="Data decesso*"
                    fieldLabel="Data decesso*"
                    adjustDateOnChange
                    validate={required}
                    maxDate={new Date()}
                    defaultValue={pdrData?.deathDate || null}
                    disabled={canEdit ? false : true}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    id="deathPlace"
                    name="deathPlace"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Luogo decesso*"
                    fieldLabel="Luogo decesso*"
                    maxLength="80"
                    defaultValue={pdrData?.deathPlace || null}
                    validate={required}
                  />
                </Col>
                <Col>
                  <ExtendedSelectField
                    name="deceasedTie"
                    isMulti={false}
                    fieldLabel="Legame con il deceduto*"
                    placeholder="Legame con il deceduto*"
                    options={formValues?.deceasedTie || []}
                    defaultValue={pdrData?.deceasedTie || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                {values?.deceasedTie?.label.toLowerCase() === 'altro' &&
                  <Col md={12}>
                    <CustomFormField
                      id="otherTie"
                      name="otherTie"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Altro legame*"
                      fieldLabel="Altro legame*"
                      maxLength="80"
                      defaultValue={pdrData?.otherTie || null}
                      validate={required}
                    />
                  </Col>
                }
              </>
          }
        </Row>
      </>
    }

    {isNewHolderRequired(entityName) &&
      <>
        <Row form>
          <Col md="12">
            {isNewHolderTitleNeeded(entityName) ? (
              <legend className="col-form-label">Dati nuovo intestatario:</legend>
            ) : (
              <legend className="col-form-label">Dati intestatario:</legend>
            )}
          </Col>
        </Row>
        <Row form>
          {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
            <Col md={6}>
              <CustomFormField
                id="accountHolderBusinessName"
                name="accountHolderBusinessName"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Ragione Sociale*"
                fieldLabel="Ragione Sociale*"
                maxLength="80"
                validate={required}
                defaultValue={pdrData?.accountHolderBusinessName || null}
              />
            </Col>
          }

          {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) &&
            <>
              <Col md={6}>
                <CustomFormField
                  id="accountHolderName"
                  name="accountHolderName"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Nome*"
                  fieldLabel="Nome*"
                  maxLength="80"
                  validate={required}
                  defaultValue={pdrData?.accountHolderName || null}
                />
              </Col>
              <Col md={6}>
                <CustomFormField
                  id="accountHolderSurname"
                  name="accountHolderSurname"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Cognome*"
                  fieldLabel="Cognome*"
                  maxLength="80"
                  validate={required}
                  defaultValue={pdrData?.accountHolderSurname || null}
                />
              </Col>
            </>
          }

          <Col md={6}>
            <CustomFormField
              id="accountHolderFiscalCode"
              name="accountHolderFiscalCode"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="Codice fiscale*"
              fieldLabel="Codice fiscale*"
              style={{ textTransform: 'uppercase' }}
              maxLength="80"
              validate={vatNumberAsFiscalCode(true)}
              defaultValue={pdrData?.accountHolderFiscalCode || null}
            />
          </Col>

          {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
            <Col md={6}>
              <CustomFormField
                id="accountHolderVatNumber"
                name="accountHolderVatNumber"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Partita IVA*"
                fieldLabel="Partita IVA*"
                maxLength="11"
                validate={vatNumber(true)}
                defaultValue={pdrData?.accountHolderVatNumber || null}
              />
            </Col>
          }

          <Col md={6}>
            <CustomFormField
              id="accountHolderPhone"
              name="accountHolderPhone"
              type="number"
              className="phone-number"
              disabled={canEdit ? false : true}
              placeholder="Telefono*"
              fieldLabel="Telefono*"
              maxLength="20"
              defaultValue={pdrData?.accountHolderPhone || null}
              validate={euPhoneCheck(true)}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="accountHolderEmail"
              name="accountHolderEmail"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="E-mail*"
              fieldLabel="E-mail*"
              maxLength="80"
              validate={emailCheck(true)}
              defaultValue={pdrData?.accountHolderEmail || null}
              style={{ textTransform: 'lowercase' }}
            />
          </Col>
        </Row>
      </>
    }

    {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
     isAccuntHolderAtecoCodeNeeded(entityName) &&
      <Row form>
        <Col md={12}>
          <CustomFormField
            id="accountHolderAtecoCode"
            name="accountHolderAtecoCode"
            type="text"
            disabled={canEdit ? false : true}
            placeholder="Codice ATECO 2007 (nuovo intestatario)*"
            fieldLabel="Codice ATECO 2007 (nuovo intestatario)*"
            maxLength="8"
            validate={required}
            defaultValue={pdrData?.accountHolderAtecoCode || null}
          />
        </Col>
      </Row>
    }

    <Row form>
      <Col md={6}>
        <ExtendedSelectField
          name="contractType"
          isMulti={false}
          fieldLabel="Tipologia contratto*"
          placeholder="Tipologia contratto*"
          options={formValues?.contractType || []}
          defaultValue={pdrData?.contractType || null}
          isDisabled={canEdit ? false : true}
          validate={required}
        />
        <WhenFieldChanges
          field="contractType"
          set="gasExciseClassId"
          to={null}
        />
      </Col>
      <Col md={6}>
        <ExtendedSelectField
          name="supplyUseType"
          isMulti={false}
          fieldLabel="Utilizzo della fornitura*"
          placeholder="Utilizzo della fornitura*"
          options={formValues?.supplyUseType || []}
          defaultValue={pdrData?.supplyUseType || null}
          isDisabled={canEdit ? false : true}
          validate={required}
        />
      </Col>
    </Row>
  </>
)

export default PdrSupplyForm
