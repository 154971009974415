import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Skeleton from 'react-loading-skeleton'

import CustomDatePicker from "../inputFields/CustomDatePicker/CustomDatePicker.component"
import CustomFormField from "../inputFields/CustomFormField/CustomFormField.component"
import ExtendedSelectField from "../inputFields/ExtendedSelectField/ExtendedSelectField.component"
import CustomReadOnlyField from "../inputFields/CustomReadOnlyField/CustomReadOnlyField.component"
import { CustomerTypes, getCustomerType, extendedCustomerDataVisibility } from './utils'
import { selectContract, selectIsFetchingData, selectProposalFormFieldsValues } from '../../../redux/contract/contract.selectors'
import { required, mustBeAdult } from "../validations"
import { canI } from "../../auth/CanI/utils"

const CustomerData = ({ initialValues, values }) => {
  const isFetchingCustomerData = useSelector(state => selectIsFetchingData('customer')(state))
  const { entityName, proposalData } = useSelector(selectContract)
  const formValues = useSelector(selectProposalFormFieldsValues)
  const canEdit = proposalData ? canI(`${entityName}UPDATE`, proposalData.links).abilityCheck : true

  return (
    <>
      {isFetchingCustomerData ? (
        <div className="my-5">
          <Skeleton count={5} />
        </div>
      ) : (
        <>
          {initialValues &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="address-book" className="mr-2" />
                Dati cliente
              </div>
              <div className="grouped-body">
                <Row form>
                  <Col>
                    <CustomReadOnlyField
                      id="fullName"
                      name="fullName"
                      type="text"
                      placeholder="Nominativo"
                      fieldLabel="Nominativo"
                      maxLength="80"
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, initialValues?.customerType) &&
                      <CustomReadOnlyField
                        id="fiscalCode"
                        name="fiscalCode"
                        type="text"
                        fieldLabel="Codice fiscale"
                        placeholder="Codice fiscale"
                        maxLength="20"
                        style={{ textTransform: 'uppercase' }}
                      />
                    }
                    {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, initialValues?.customerType) &&
                      <CustomReadOnlyField
                        id="vatNumber"
                        name="vatNumber"
                        type="text"
                        placeholder="Partita IVA"
                        fieldLabel="Partita IVA"
                        maxLength="11"
                      />
                    }
                  </Col>
                  <Col md={6}>
                    <CustomReadOnlyField
                      id="email"
                      name="email"
                      type="text"
                      placeholder="E-mail"
                      fieldLabel="E-mail"
                      maxLength="80"
                      style={{ textTransform: 'lowercase' }}
                    />
                  </Col>
                </Row>
                {extendedCustomerDataVisibility(entityName) &&
                  <Row form>
                    <Col md={6}>
                      <CustomDatePicker
                        className="form-control"
                        id="customerBirthDate"
                        name="customerBirthDate"
                        type="date"
                        maxDate={new Date()}
                        disabled={canEdit ? false : true}
                        placeholder="Data di nascita cliente/legale rappresentante*"
                        fieldLabel="Data di nascita cliente/legale rappresentante*"
                        dateFormat="dd/MM/yyyy"
                        validate={mustBeAdult}
                        adjustDateOnChange
                      />
                    </Col>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="customerBirthCountry"
                        isMulti={false}
                        fieldLabel="Nazionalità cliente/legale rappresentante*"
                        placeholder="Nazionalità cliente/legale rappresentante*"
                        validate={required}
                        options={formValues?.customerBirthCountry || []}
                        isDisabled={canEdit ? false : true}
                      />
                    </Col>
                    {values.customerBirthCountry?.value === "Italia" &&
                      <Col md={12}>
                        <CustomFormField
                          id="customerBirthPlace"
                          name="customerBirthPlace"
                          type="text"
                          disabled={canEdit ? false : true}
                          maxLength="80"
                          placeholder="Comune di nascita cliente/legale rappresentante*"
                          fieldLabel="Comune di nascita cliente/legale rappresentante*"
                          validate={required}
                        />
                      </Col>
                    }
                  </Row>
                }
              </div>
            </div>
          }
        </>
      )}
    </>
  )
}

export default CustomerData