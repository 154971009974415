const ContractTypes = {
  TOGGLE_CONTRACT_UTILITY_MODAL_VISIBILITY: 'TOGGLE_CONTRACT_UTILITY_MODAL_VISIBILITY',
  TOGGLE_CONTRACT_UTILITY_TOAST_VISIBILITY: 'TOGGLE_CONTRACT_UTILITY_TOAST_VISIBILITY',
  TOAST_COUNTDOWN_CLOSE_START: 'TOAST_COUNTDOWN_CLOSE_START',
  CONTRACT_SHOW_ERROR_MESSAGE: 'CONTRACT_SHOW_ERROR_MESSAGE',

  CONTRACT_RESET: 'CONTRACT_RESET',
  CONTRACT_SET_ENTITY_NAME: 'CONTRACT_SET_ENTITY_NAME',
  CONTRACT_CHANGE_FORM_STEP: 'CONTRACT_CHANGE_FORM_STEP',

  CONTRACT_GET_FORM_FIELDS_VALUES_START: 'CONTRACT_GET_FORM_FIELDS_VALUES_START',
  CONTRACT_GET_FORM_FIELDS_VALUES_SUCCESS: 'CONTRACT_GET_FORM_FIELDS_VALUES_SUCCESS',
  CONTRACT_GET_FORM_FIELDS_VALUES_FAILURE: 'CONTRACT_GET_FORM_FIELDS_VALUES_FAILURE',

  CONTRACT_GET_ITEM_USER_DATA_START: 'CONTRACT_GET_ITEM_USER_DATA_START',
  CONTRACT_GET_ITEM_USER_DATA_SUCCESS: 'CONTRACT_GET_ITEM_USER_DATA_SUCCESS',
  CONTRACT_GET_ITEM_USER_DATA_FAILURE: 'CONTRACT_GET_ITEM_USER_DATA_FAILURE',

  CONTRACT_GET_CUSTOMER_DATA_START: 'CONTRACT_GET_CUSTOMER_DATA_START',
  CONTRACT_GET_CUSTOMER_DATA_SUCCESS: 'CONTRACT_GET_CUSTOMER_DATA_SUCCESS',
  CONTRACT_GET_CUSTOMER_DATA_FAILURE: 'CONTRACT_GET_CUSTOMER_DATA_FAILURE',

  CONTRACT_GET_ITEM_PROPOSAL_DATA_START: 'CONTRACT_GET_ITEM_PROPOSAL_DATA_START',
  CONTRACT_GET_ITEM_PROPOSAL_DATA_SUCCESS: 'CONTRACT_GET_ITEM_PROPOSAL_DATA_SUCCESS',
  CONTRACT_GET_ITEM_PROPOSAL_DATA_FAILURE: 'CONTRACT_GET_ITEM_PROPOSAL_DATA_FAILURE',
  CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_START: 'CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_START',
  CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_SUCCESS: 'CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_SUCCESS',
  CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_FAILURE: 'CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_FAILURE',
  CONTRACT_PROPOSAL_SUBMIT_FORM_START: 'CONTRACT_PROPOSAL_SUBMIT_FORM_START',
  CONTRACT_PROPOSAL_SUBMIT_FORM_SUCCESS: 'CONTRACT_PROPOSAL_SUBMIT_FORM_SUCCESS',
  CONTRACT_PROPOSAL_SUBMIT_FORM_FAILURE: 'CONTRACT_PROPOSAL_SUBMIT_FORM_FAILURE',

  CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_START: 'CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_START',
  CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_SUCCESS: 'CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_SUCCESS',
  CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_FAILURE: 'CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_FAILURE',
  CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_START: 'CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_START',
  CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_SUCCESS: 'CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_SUCCESS',
  CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_FAILURE: 'CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_FAILURE',
  CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_START: 'CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_START',
  CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_SUCCESS: 'CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_SUCCESS',
  CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_FAILURE: 'CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_FAILURE',
  CONTRACT_RESET_BANK_ACCOUNT_DATA: 'CONTRACT_RESET_BANK_ACCOUNT_DATA',
  CONTRACT_TOGGLE_BANK_ACCOUNT_DETAIL_MODAL: 'CONTRACT_TOGGLE_BANK_ACCOUNT_DETAIL_MODAL',

  CONTRACT_TOGGLE_ACTIVE_USER_SUPPLY_POINT_MODAL: 'CONTRACT_TOGGLE_ACTIVE_USER_SUPPLY_POINT_MODAL',

  CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_START: 'CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_START',
  CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_SUCCESS: 'CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_SUCCESS',
  CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_FAILURE: 'CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_FAILURE',

  CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_START: 'CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_START',
  CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS: 'CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS',
  CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE: 'CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE',

  CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_START: 'CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_START',
  CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS: 'CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS',
  CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE: 'CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE',

  CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_START: 'CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_START',
  CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS: 'CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS',
  CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE: 'CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE',

  CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_START: 'CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_START',
  CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS: 'CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS',
  CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE: 'CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE',

  CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_START: 'CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_START',
  CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_SUCCESS: 'CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_SUCCESS',
  CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_FAILURE: 'CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_FAILURE',

  CONTRACT_GET_CADASTRAL_DATA_START: 'CONTRACT_GET_CADASTRAL_DATA_START',
  CONTRACT_GET_CADASTRAL_DATA_SUCCESS: 'CONTRACT_GET_CADASTRAL_DATA_SUCCESS',
  CONTRACT_GET_CADASTRAL_DATA_FAILURE: 'CONTRACT_GET_CADASTRAL_DATA_FAILURE',
  CONTRACT_GET_CADASTRAL_DETAIL_START: 'CONTRACT_GET_CADASTRAL_DETAIL_START',
  CONTRACT_GET_CADASTRAL_DETAIL_SUCCESS: 'CONTRACT_GET_CADASTRAL_DETAIL_SUCCESS',
  CONTRACT_GET_CADASTRAL_DETAIL_FAILURE: 'CONTRACT_GET_CADASTRAL_DETAIL_FAILURE',
  CONTRACT_CADASTRAL_SUBMIT_FORM_START: 'CONTRACT_CADASTRAL_SUBMIT_FORM_START',
  CONTRACT_CADASTRAL_SUBMIT_FORM_SUCCESS: 'CONTRACT_CADASTRAL_SUBMIT_FORM_SUCCESS',
  CONTRACT_CADASTRAL_SUBMIT_FORM_FAILURE: 'CONTRACT_CADASTRAL_SUBMIT_FORM_FAILURE',
  CONTRACT_DELETE_CADASTRAL_DATA_START: 'CONTRACT_DELETE_CADASTRAL_DATA_START',
  CONTRACT_DELETE_CADASTRAL_DATA_SUCCESS: 'CONTRACT_DELETE_CADASTRAL_DATA_SUCCESS',
  CONTRACT_DELETE_CADASTRAL_DATA_FAILURE: 'CONTRACT_DELETE_CADASTRAL_DATA_FAILURE',
  CONTRACT_POD_SUBMIT_FORM_START: 'CONTRACT_POD_SUBMIT_FORM_START',
  CONTRACT_POD_SUBMIT_FORM_SUCCESS: 'CONTRACT_POD_SUBMIT_FORM_SUCCESS',
  CONTRACT_POD_SUBMIT_FORM_FAILURE: 'CONTRACT_POD_SUBMIT_FORM_FAILURE',
  CONTRACT_DELETE_POD_START: 'CONTRACT_DELETE_POD_START',
  CONTRACT_DELETE_POD_SUCCESS: 'CONTRACT_DELETE_POD_SUCCESS',
  CONTRACT_DELETE_POD_FAILURE: 'CONTRACT_DELETE_POD_FAILURE',
  CONTRACT_GET_POD_DATA_START: 'CONTRACT_GET_POD_DATA_START',
  CONTRACT_GET_POD_DATA_SUCCESS: 'CONTRACT_GET_POD_DATA_SUCCESS',
  CONTRACT_GET_POD_DATA_FAILURE: 'CONTRACT_GET_POD_DATA_FAILURE',
  CONTRACT_RESET_POD_DATA: 'CONTRACT_RESET_POD_DATA',
  CONTRACT_TOGGLE_POD_DETAIL_MODAL: 'CONTRACT_TOGGLE_POD_DETAIL_MODAL',
  CONTRACT_PDR_SUBMIT_FORM_START: 'CONTRACT_PDR_SUBMIT_FORM_START',
  CONTRACT_PDR_SUBMIT_FORM_SUCCESS: 'CONTRACT_PDR_SUBMIT_FORM_SUCCESS',
  CONTRACT_PDR_SUBMIT_FORM_FAILURE: 'CONTRACT_PDR_SUBMIT_FORM_FAILURE',
  CONTRACT_GET_PDR_DATA_START: 'CONTRACT_GET_PDR_DATA_START',
  CONTRACT_GET_PDR_DATA_SUCCESS: 'CONTRACT_GET_PDR_DATA_SUCCESS',
  CONTRACT_GET_PDR_DATA_FAILURE: 'CONTRACT_GET_PDR_DATA_FAILURE',
  CONTRACT_DELETE_PDR_START: 'CONTRACT_DELETE_PDR_START',
  CONTRACT_DELETE_PDR_SUCCESS: 'CONTRACT_DELETE_PDR_SUCCESS',
  CONTRACT_DELETE_PDR_FAILURE: 'CONTRACT_DELETE_PDR_FAILURE',
  CONTRACT_RESET_PDR_DATA: 'CONTRACT_RESET_PDR_DATA',
  CONTRACT_TOGGLE_PDR_DETAIL_MODAL: 'CONTRACT_TOGGLE_PDR_DETAIL_MODAL',
  CONTRACT_BLOCK_ALL_SUPPLY_POINTS_START: 'CONTRACT_BLOCK_ALL_SUPPLY_POINTS_START',
  CONTRACT_BLOCK_ALL_SUPPLY_POINTS_SUCCESS: 'CONTRACT_BLOCK_ALL_SUPPLY_POINTS_SUCCESS',
  CONTRACT_BLOCK_ALL_SUPPLY_POINTS_FAILURE: 'CONTRACT_BLOCK_ALL_SUPPLY_POINTS_FAILURE',
  CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_START: 'CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_START',
  CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_SUCCESS: 'CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_SUCCESS',
  CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_FAILURE: 'CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_FAILURE',

  CONTRACT_TOGGLE_CADASTRAL_DETAIL_MODAL: 'CONTRACT_TOGGLE_CADASTRAL_DETAIL_MODAL',
  CONTRACT_RESET_CADASTRAL_DATA: 'CONTRACT_RESET_CADASTRAL_DATA',

  CONTRACT_GET_ATTACHMENTS_DATA_START: 'CONTRACT_GET_ATTACHMENTS_DATA_START',
  CONTRACT_GET_ATTACHMENTS_DATA_SUCCESS: 'CONTRACT_GET_ATTACHMENTS_DATA_SUCCESS',
  CONTRACT_GET_ATTACHMENTS_DATA_FAILURE: 'CONTRACT_GET_ATTACHMENTS_DATA_FAILURE',
  CONTRACT_ADD_NEW_ATTACHMENT_TO_QUEUE: 'CONTRACT_ADD_NEW_ATTACHMENT_TO_QUEUE',
  CONTRACT_REMOVE_ATTACHMENT_FROM_QUEUE: 'CONTRACT_REMOVE_ATTACHMENT_FROM_QUEUE',
  CONTRACT_SUBMIT_ATTACHMENT_FORM_START: 'CONTRACT_SUBMIT_ATTACHMENT_FORM_START',
  CONTRACT_SUBMIT_ATTACHMENT_FORM_SUCCESS: 'CONTRACT_SUBMIT_ATTACHMENT_FORM_SUCCESS',
  CONTRACT_SUBMIT_ATTACHMENT_FORM_FAILURE: 'CONTRACT_SUBMIT_ATTACHMENT_FORM_FAILURE',
  CONTRACT_DELETE_ATTACHMENT_START: 'CONTRACT_DELETE_ATTACHMENT_START',
  CONTRACT_DELETE_ATTACHMENT_SUCCESS: 'CONTRACT_DELETE_ATTACHMENT_SUCCESS',
  CONTRACT_DELETE_ATTACHMENT_FAILURE: 'CONTRACT_DELETE_ATTACHMENT_FAILURE',

  CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_START: 'CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_START',
  CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_SUCCESS: 'CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_SUCCESS',
  CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_FAILURE: 'CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_FAILURE',
  CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_START: 'CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_START',
  CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_SUCCESS: 'CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_SUCCESS',
  CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_FAILURE: 'CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_FAILURE',

  CONTRACT_TOGGLE_CONTRACT_FINALIZATION_MODAL_VISIBILITY: 'CONTRACT_TOGGLE_CONTRACT_FINALIZATION_MODAL_VISIBILITY',
  CONTRACT_SET_SELECTED_SIGNATURE_TYPE: 'CONTRACT_SET_SELECTED_SIGNATURE_TYPE',

  CONTRACT_GET_OTP_DATA_START: 'CONTRACT_GET_OTP_DATA_START',
  CONTRACT_GET_OTP_DATA_SUCCESS: 'CONTRACT_GET_OTP_DATA_SUCCESS',
  CONTRACT_GET_OTP_DATA_FAILURE: 'CONTRACT_GET_OTP_DATA_FAILURE',
  CONTRACT_GET_OTP_CODE_START: 'CONTRACT_GET_OTP_CODE_START',
  CONTRACT_GET_OTP_CODE_SUCCESS: 'CONTRACT_GET_OTP_CODE_SUCCESS',
  CONTRACT_GET_OTP_CODE_FAILURE: 'CONTRACT_GET_OTP_CODE_FAILURE',
  CONTRACT_SUBMIT_SIGNATURE_START: 'CONTRACT_SUBMIT_SIGNATURE_START',
  CONTRACT_SUBMIT_SIGNATURE_SUCCESS: 'CONTRACT_SUBMIT_SIGNATURE_SUCCESS',
  CONTRACT_SUBMIT_SIGNATURE_FAILURE: 'CONTRACT_SUBMIT_SIGNATURE_FAILURE',
  CONTRACT_GET_PDF_URL_START: 'CONTRACT_GET_PDF_URL_START',
  CONTRACT_GET_PDF_URL_SUCCESS: 'CONTRACT_GET_PDF_URL_SUCCESS',
  CONTRACT_GET_PDF_URL_FAILURE: 'CONTRACT_GET_PDF_URL_FAILURE',
  CONTRACT_TOGGLE_OTP_SIGNATURE_MODAL_VISIBILITY: 'CONTRACT_TOGGLE_OTP_SIGNATURE_MODAL_VISIBILITY',

  CONTRACT_SUBMIT_PAPER_SIGNATURE_START: 'CONTRACT_SUBMIT_PAPER_SIGNATURE_START',
  CONTRACT_SUBMIT_PAPER_SIGNATURE_SUCCESS: 'CONTRACT_SUBMIT_PAPER_SIGNATURE_SUCCESS',
  CONTRACT_SUBMIT_PAPER_SIGNATURE_FAILURE: 'CONTRACT_SUBMIT_PAPER_SIGNATURE_FAILURE',

  CONTRACT_USER_SUBMIT_FORM_START: 'CONTRACT_USER_SUBMIT_FORM_START',
  CONTRACT_USER_SUBMIT_FORM_SUCCESS: 'CONTRACT_USER_SUBMIT_FORM_SUCCESS',
  CONTRACT_USER_SUBMIT_FORM_FAILURE: 'CONTRACT_USER_SUBMIT_FORM_FAILURE',

  CONTRACT_GET_LIST_FILTERS_START: 'CONTRACT_GET_LIST_FILTERS_START',
  CONTRACT_GET_LIST_FILTERS_SUCCESS: 'CONTRACT_GET_LIST_FILTERS_SUCCESS',
  CONTRACT_GET_LIST_FILTERS_FAILURE: 'CONTRACT_GET_LIST_FILTERS_FAILURE',

  CONTRACT_GET_LIST_DATA_START: 'CONTRACT_GET_LIST_DATA_START',
  CONTRACT_GET_LIST_DATA_SUCCESS: 'CONTRACT_GET_LIST_DATA_SUCCESS',
  CONTRACT_GET_LIST_DATA_FAILURE: 'CONTRACT_GET_LIST_DATA_FAILURE',

  CONTRACT_UPDATE_LIST_FILTERS: 'CONTRACT_UPDATE_LIST_FILTERS',
  CONTRACT_UPDATE_LIST_PAGE: 'CONTRACT_UPDATE_LIST_PAGE',
  CONTRACT_UPDATE_LIST_SORTS: 'CONTRACT_UPDATE_LIST_SORTS',
  CONTRACT_RESET_LIST: 'CONTRACT_RESET_LIST',

  CONTRACT_DELETE_ITEM_START: 'CONTRACT_DELETE_ITEM_START',
  CONTRACT_DELETE_ITEM_SUCCESS: 'CONTRACT_DELETE_ITEM_SUCCESS',
  CONTRACT_DELETE_ITEM_FAILURE: 'CONTRACT_DELETE_ITEM_FAILURE',

  CONTRACT_GET_REPORTS_START: 'CONTRACT_GET_REPORTS_START',
  CONTRACT_GET_REPORTS_SUCCESS: 'CONTRACT_GET_REPORTS_SUCCESS',
  CONTRACT_GET_REPORTS_FAILURE: 'CONTRACT_GET_REPORTS_FAILURE',

  CONTRACT_SUBMIT_REPORT_START: 'CONTRACT_SUBMIT_REPORT_START',
  CONTRACT_SUBMIT_REPORT_SUCCESS: 'CONTRACT_SUBMIT_REPORT_SUCCESS',
  CONTRACT_SUBMIT_REPORT_FAILURE: 'CONTRACT_SUBMIT_REPORT_FAILURE',

  CONTRACT_GET_NOTES_START: 'CONTRACT_GET_NOTES_START',
  CONTRACT_GET_NOTES_SUCCESS: 'CONTRACT_GET_NOTES_SUCCESS',
  CONTRACT_GET_NOTES_FAILURE: 'CONTRACT_GET_NOTES_FAILURE',

  CONTRACT_SUBMIT_NOTE_START: 'CONTRACT_SUBMIT_NOTE_START',
  CONTRACT_SUBMIT_NOTE_SUCCESS: 'CONTRACT_SUBMIT_NOTE_SUCCESS',
  CONTRACT_SUBMIT_NOTE_FAILURE: 'CONTRACT_SUBMIT_NOTE_FAILURE',

  CONTRACT_GET_ITEM_HISTORY_DATA_START: 'CONTRACT_GET_ITEM_HISTORY_DATA_START',
  CONTRACT_GET_ITEM_HISTORY_DATA_SUCCESS: 'CONTRACT_GET_ITEM_HISTORY_DATA_SUCCESS',
  CONTRACT_GET_ITEM_HISTORY_DATA_FAILURE: 'CONTRACT_GET_ITEM_HISTORY_DATA_FAILURE',

  CONTRACT_UPDATE_ITEM_STATUS_START: 'CONTRACT_UPDATE_ITEM_STATUS_START',
  CONTRACT_UPDATE_ITEM_STATUS_SUCCESS: 'CONTRACT_UPDATE_ITEM_STATUS_SUCCESS',
  CONTRACT_UPDATE_ITEM_STATUS_FAILURE: 'CONTRACT_UPDATE_ITEM_STATUS_FAILURE',

  CONTRACT_USER_MASSIVE_CREATION_START: 'CONTRACT_USER_MASSIVE_CREATION_START',
  CONTRACT_USER_MASSIVE_CREATION_SUCCESS: 'CONTRACT_USER_MASSIVE_CREATION_SUCCESS',
  CONTRACT_USER_MASSIVE_CREATION_FAILURE: 'CONTRACT_USER_MASSIVE_CREATION_FAILURE',

  CONTRACT_TOGGLE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY: 'CONTRACT_TOGGLE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY',

  CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_START: 'CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_START',
  CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_SUCCESS: 'CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_SUCCESS',
  CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_FAILURE: 'CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_FAILURE',

  CONTRACT_TOGGLE_CLOSE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY: 'CONTRACT_TOGGLE_CLOSE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY',

  CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_START: 'CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_START',
  CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_SUCCESS: 'CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_SUCCESS',
  CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_FAILURE: 'CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_FAILURE',
}

export default ContractTypes
