import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

import { withRouter } from '../../components/AppRouter/utils'
import PageHeader from '../../components/PageHeader/PageHeader.component'
import ProposalFormContainer from '../../components/forms/ProposalForm/ProposalFormContainer.component'
import BankAccountDashboard from '../../components/forms/BankAccountDashboard/BankAccountDashboard.component'
import CadastralDashboard from '../../components/forms/CadastralDashboard/CadastralDashboard.component'
import AttachmentsFormContainer from '../../components/forms/AttachmentsForm/AttachmentsFormContainer.component'
import ThankYouItemContainer from '../../components/ThankYouItem/ThankYouItemContainer.component'
import ContractFinalizationModal from '../../components/forms/ContractFinalizationModal/ContractFinalizationModal.component'
import { ContractTabsMap } from '../../redux/contract/contract.utils'
import ContractTabs from '../../components/forms/ContractTabs/ContractTabs.component'
import {
  contractSetEntityName, contractGetFormFieldsValuesStart,
  contractGetItemProposalDataStart
} from '../../redux/contract/contract.actions'
import { selectFormSelectedStep } from '../../redux/contract/contract.selectors'
import { getEntityNameFromPath, getEntityLabelFromPath } from '../../redux/contract/contract.utils'

const ContractsFormPage = ({ router: { params: { pathEntityName, contractId } } }) => {
  const dispatch = useDispatch()
  const entityName = getEntityNameFromPath(pathEntityName)
  const entityLabel = getEntityLabelFromPath(pathEntityName)
  const selectedStep = useSelector(selectFormSelectedStep)
  useEffect(() => {
    dispatch(contractSetEntityName(entityName))
    dispatch(contractGetFormFieldsValuesStart({ entityName }))
    if(contractId) {
      dispatch(contractGetItemProposalDataStart({ entityName, contractId }))
    }
  }, [dispatch, entityName, contractId])

  return (
    <div className="page">
      <PageHeader pageTitle={entityLabel} />
      <Container className="loader-container">
        <Row className="justify-content-center">
          <Col xs={12} lg={12}>
            <ContractTabs />
            {selectedStep === ContractTabsMap.PROPOSAL_STEP &&
              <ProposalFormContainer />
            }
            {selectedStep === ContractTabsMap.PAYMENT_STEP &&
              <BankAccountDashboard />
            }
            {selectedStep === ContractTabsMap.CADASTRAL_STEP &&
              <CadastralDashboard />
            }
            {selectedStep === ContractTabsMap.ATTACHMENTS_STEP &&
              <AttachmentsFormContainer />
            }
            {selectedStep === ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP &&
              <ThankYouItemContainer />
            }
          </Col>
        </Row>
      </Container>
      <ContractFinalizationModal />
    </div>
  )
}

export default withRouter(ContractsFormPage)
