export function getCustomerType(requestedCustomerType, contractCustomerType) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

export const CustomerTypes = {
  DOMESTIC_CUSTOMER: 1,
  BUSINESS_CUSTOMER: 2
}

export function getDefaultOption(options) {
  if(options) {
    const defaultOption = options.filter(opt => opt.default === true)
    return defaultOption.length ? defaultOption[0] : null
  }
}

export function extendedCustomerDataVisibility(entityName) {
  return (
    entityName === 'contractualChanges'
  ) ? true : false
} 