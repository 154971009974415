import React, { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Form } from "react-final-form"
import createDecorator from "final-form-focus"
import { OnChange } from "react-final-form-listeners"
import { Button, Spinner, Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CustomFormField from "../inputFields/CustomFormField/CustomFormField.component"
import CustomDatePicker from "../inputFields/CustomDatePicker/CustomDatePicker.component"
import ExtendedSelectField from "../inputFields/ExtendedSelectField/ExtendedSelectField.component"
import ExtendedCustomZipCodeSelect from "../inputFields/ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component"
import ExtendedAutocompleteFormField from "../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component"
import WhenFieldChanges from "../WhenFieldChanges/WhenFieldChanges.component"
import PodSupplyForm from "./PodSupplyForm/PodSupplyForm.component"
import PodFAttachmentForm from "./PodFAttachmentForm/PodFAttachmentForm.component"
import PodResidenceSelfDeclaration from "./PodResidenceSelfDeclaration/PodResidenceSelfDeclaration.component"
import PodOtherAttachmentsDataForm from "./PodOtherAttachmentsDataForm/PodOtherAttachmentsDataForm.component"
import { ReactComponent as PensoInVerdeIcon } from "../../../assets/images/penso-verde.svg"
import { canI } from "../../auth/CanI/utils"
import { required, emailCheck, requiredLength, positiveNumber, noPastDate, minValue } from "../validations"
import { getAPIUrl, fetchTowns } from "../../../api"
import { getEntityUriName } from "../../../redux/contract/contract.utils"
import { selectUserData } from "../../../redux/user/user.selectors"
import { 
  selectContract, selectPodFormFieldsValues, selectBankAccountDataAsArray, selectIsSubmitting,
} from "../../../redux/contract/contract.selectors"
import { contractPodSubmitFormStart, contractShowErrorMessage } from "../../../redux/contract/contract.actions"
import CustomerTypes, {
  getCustomerType, isServiceStartDateNeeded, isResidenceSelfDeclarationNeeded, isFAttachmentRequired, isSupplyFormNeeded,
  isResidentNeeded, isCurrentSupplierNeeded, isOriginMarketNeeded, isUseTypeNeeded, isEneltelNeeded, isRequestTypeNeeded,
  fetchElectricUseTypeId, fetchContractPriceList, fetchVatClassList, fetchExciseClassList, DefaultSelectValues, isNoDisabled,
  isEneltelDisabled, getRequestTypeLabel, isConnectionVoltageIdNeeded, isRealEstateGroupNeeded, isEneltelRequired, isPodNoRequired
} from "./utils"
import RealEstateFieldChangesConditions from './RealEstateFieldChangesConditions/RealEstateFieldChangesConditions.component'
import PodDomesticUseRealEstateForm from "./PodDomesticUseRealEstateForm/PodDomesticUseRealEstateForm.component"
import PodOtherUseRealEstateForm from './PodOtherUseRealEstateForm/PodOtherUseRealEstateForm.component'
import PodWorkSiteRealEstateForm from "./PodWorkSiteRealEstateForm/PodWorkSiteRealEstateForm.component"

//Focus on firt field with an error
const focusOnError = createDecorator()

//Mutator for updating a form value
const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

const PodForm = () => {
  const dispatch = useDispatch()
  const { email, accessToken, role } = useSelector(selectUserData)
  const {
    cadastralDetailData,
    podData,
    entityName,
    customerType,
    selectedContractId,
    podDetailModalVisibility,
  } = useSelector(selectContract)
  const isSubmitting = useSelector((state) => selectIsSubmitting("pod")(state))
  const bankAccountData = useSelector(selectBankAccountDataAsArray)
  const formValues = useSelector(selectPodFormFieldsValues)
  let canEdit = true
  if (podData) {
    canEdit = canI(`${entityName}PodUPDATE`, podData.links).abilityCheck
  }

  //Handle async api calls (NO REDUX) errors
  const handleError = useCallback(
    (error) => dispatch(contractShowErrorMessage({ message: error.message })),
    [dispatch]
  )

  //Fetch contract price list from API on values change
  const [contractPriceList, setContractPriceList] = useState([])
  useEffect(() => {
    if (
      podData &&
      podData.electricUseTypeId &&
      podDetailModalVisibility === true
    ) {
      fetchContractPriceList(
        entityName,
        selectedContractId,
        podData.id,
        podData.electricUseTypeId.value,
        email,
        accessToken
      )
        .then((response) => setContractPriceList(response.data))
        .catch(handleError)
    }
  }, [
    podData,
    email,
    accessToken,
    entityName,
    selectedContractId,
    podDetailModalVisibility,
    handleError,
  ])

  //Fetch contract vat class list from API on values change
  const [useType, setUseType] = useState(
    podData?.electricUseTypeId?.value || ""
  )
  const [atecoCode, setAtecoCode] = useState(
    podData?.accountHolderAtecoCode || ""
  )
  const [vatClassList, setVatClassList] = useState(formValues?.vatClass || [])
  useEffect(() => {
    if (useType && podDetailModalVisibility === true) {
      fetchVatClassList(
        customerType.value,
        entityName,
        useType,
        atecoCode,
        accessToken
      )
        .then((response) => setVatClassList(response.data))
        .catch(handleError)
    }
  }, [
    customerType,
    entityName,
    useType,
    atecoCode,
    accessToken,
    podDetailModalVisibility,
    handleError,
  ])

  //Fetch contract excise list from API on values change
  const [vatClass, setVatClass] = useState(podData?.vatClassId?.value || "")
  const [exciseClassList, setExciseClassList] = useState(
    formValues?.electricExciseClass || []
  )
  useEffect(() => {
    if (useType && atecoCode && vatClass && podDetailModalVisibility === true) {
      fetchExciseClassList(
        customerType.value,
        entityName,
        useType,
        atecoCode,
        vatClass,
        accessToken
      )
        .then((response) => setExciseClassList(response.data))
        .catch(handleError)
    }
  }, [
    customerType,
    entityName,
    useType,
    atecoCode,
    vatClass,
    accessToken,
    podDetailModalVisibility,
    handleError,
  ])

  //Submit form
  const onSubmit = (values) => {
    const method = podData && canEdit === true ? "PUT" : "POST"
    const apiUrl = `${getAPIUrl("abStorePortal")}/${getEntityUriName(
      entityName
    )}/pods${podData ? `/${podData.id}` : ""}`
    dispatch(contractPodSubmitFormStart({ apiUrl, values, method }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ updateFieldValue }}
      decorators={[focusOnError]}
      initialValues={{
        cadastralDataId: cadastralDetailData?.id || null,
        bankAccountDetailId: bankAccountData.length
          ? bankAccountData[0].id
          : null,
      }}
    >
      {({ handleSubmit, form, values }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <WhenFieldChanges field="town" set="distributor" to={null} />
          <WhenFieldChanges
            field="electricUseTypeId"
            set="contractPriceListCode"
            to={null}
          />
          <WhenFieldChanges
            field="electricUseTypeId"
            set="vatClassId"
            to={null}
          />
          <WhenFieldChanges
            field="accountHolderAtecoCode"
            set="vatClassId"
            to={null}
          />
          <WhenFieldChanges
            field="electricUseTypeId"
            set="electricExciseClassId"
            to={null}
          />

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="file" className="mr-2" />
              Dati generali
            </div>
            <div className="grouped-body">
              <Row form>
                <Col>
                  <CustomFormField
                    id="no"
                    name="no"
                    type="text"
                    disabled={isNoDisabled(entityName, role, values, canEdit)}
                    placeholder={`IT + 12 caratteri${isPodNoRequired(entityName, values) ? '*' : ''}`}
                    fieldLabel={`Codice POD${isPodNoRequired(entityName, values) ? '*' : ''}`}
                    maxLength="14"
                    validate={isPodNoRequired(entityName, values) ? requiredLength(14) : undefined}
                    defaultValue={podData?.no || null}
                  />
                </Col>
                {isEneltelNeeded(entityName) && (
                  <Col>
                    <CustomFormField
                      id="eneltel"
                      name="eneltel"
                      type="text"
                      disabled={isEneltelDisabled(role, values, canEdit)}
                      placeholder={`Codice formato da 9 caratteri${isEneltelRequired(entityName, values) ? '*' : ''}`}
                      fieldLabel={`Codice Eneltel${isEneltelRequired(entityName, values) ? '*' : ''}`}
                      maxLength="9"
                      validate={isEneltelRequired(entityName, values) ? requiredLength(9) : undefined}
                      defaultValue={podData?.eneltel || null}
                    />
                  </Col>
                )}
                {isRequestTypeNeeded(entityName) && (
                  <>
                    <Col md="12">
                      <legend className="col-form-label">
                        {getRequestTypeLabel(entityName).title}
                      </legend>
                    </Col>
                    <Col md="12">
                      <ExtendedSelectField
                        name="requestType"
                        isMulti={false}
                        fieldLabel={getRequestTypeLabel(entityName).label}
                        placeholder={getRequestTypeLabel(entityName).label}
                        options={formValues?.requestType || []}
                        defaultValue={podData?.requestType || null}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                      />
                    </Col>
                  </>
                )}
                {isConnectionVoltageIdNeeded(entityName, values) &&
                  <>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="connectionVoltageId"
                        isMulti={false}
                        fieldLabel="Tensione*"
                        placeholder="Tensione*"
                        options={formValues?.connectionVoltage || []}
                        defaultValue={podData?.connectionVoltageId || null}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                      />
                    </Col>
                    {entityName === "changeSupplier" ? (
                      <Col>
                        <CustomFormField
                          id="powerValue"
                          name="powerValue"
                          type="number"
                          step=".01"
                          maxLength="80"
                          disabled={canEdit ? false : true}
                          min="0"
                          placeholder="Potenza disponibile in KW*"
                          fieldLabel="Potenza disponibile in KW*"
                          validate={positiveNumber(true)}
                          defaultValue={podData?.powerValue || null}
                        />
                      </Col>
                    ) : (
                      <>
                        <Col>
                          <ExtendedSelectField
                            name="powerValue"
                            isMulti={false}
                            fieldLabel="Potenza disponibile in KW*"
                            placeholder="Potenza disponibile in KW*"
                            options={formValues?.powerValue || []}
                            defaultValue={podData?.powerValue || null}
                            isDisabled={canEdit ? false : true}
                            validate={required}
                          />
                        </Col>
                        {parseInt(values.powerValue?.value) === 23 && (
                          <Col md={6}>
                            <CustomFormField
                              id="customPower"
                              name="customPower"
                              type="number"
                              step=".01"
                              maxLength="80"
                              disabled={canEdit ? false : true}
                              min="0"
                              placeholder="Specificare la potenza in KW*"
                              fieldLabel="Specificare la potenza in KW*"
                              validate={minValue(30)}
                              defaultValue={podData?.customPower || null}
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </>
                }

                {isServiceStartDateNeeded(entityName) && (
                  <Col md={12}>
                    <CustomDatePicker
                      id="serviceStartDate"
                      name="serviceStartDate"
                      className="form-control"
                      type="date"
                      dateFormat="dd/MM/yyyy"
                      placeholder="Da non eseguire prima del"
                      fieldLabel="Da non eseguire prima del"
                      adjustDateOnChange
                      defaultValue={podData?.serviceStartDate || null}
                      disabled={canEdit ? false : true}
                      validate={noPastDate(false)}
                    />
                  </Col>
                )}

                {
                  (
                    isOriginMarketNeeded(entityName) ||
                    isCurrentSupplierNeeded(entityName)
                  ) && (
                  <>
                    {isOriginMarketNeeded(entityName) && (
                      <Col md={6}>
                        <ExtendedSelectField
                          name="originMarket"
                          isMulti={false}
                          fieldLabel="Mercato di provenienza*"
                          placeholder="Mercato di provenienza*"
                          options={formValues?.originMarket || []}
                          defaultValue={podData?.originMarket || null}
                          isDisabled={canEdit ? false : true}
                          validate={required}
                        />
                      </Col>
                    )}
                    {isCurrentSupplierNeeded(entityName) && (
                      <Col md={6}>
                        <CustomFormField
                          id="currentSupplier"
                          name="currentSupplier"
                          type="text"
                          maxLength="80"
                          disabled={canEdit ? false : true}
                          placeholder="Attuale fornitore*"
                          fieldLabel="Attuale fornitore*"
                          validate={required}
                          defaultValue={podData?.currentSupplier || null}
                        />
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </div>
          </div>

          {isSupplyFormNeeded(entityName) && (
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="list" className="mr-2" />
                Fornitura EE
              </div>
              <div className="grouped-body">
                <PodSupplyForm
                  formValues={formValues}
                  values={values}
                  podData={podData}
                  canEdit={canEdit}
                  customerType={customerType}
                />
              </div>
            </div>
          )}

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="list" className="mr-2" />
              Configurazione offerta
            </div>
            <div className="grouped-body">
              <Row form>
                <>
                  {isUseTypeNeeded(entityName) ? (
                    <Col md={6}>
                      <ExtendedSelectField
                        name="electricUseTypeId"
                        isMulti={false}
                        fieldLabel="Tipo uso*"
                        placeholder="Tipo uso*"
                        options={formValues?.electricUseType || []}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                        defaultValue={podData?.electricUseTypeId || null}
                      />
                    </Col>
                  ) : (
                    <>
                      <CustomFormField
                        name="electricUseTypeId"
                        type="hidden"
                        defaultValue={podData?.electricUseTypeId || null}
                      />
                      <OnChange name="contractType">
                        {(objValue) => {
                          //API call to get green energy value on price list change
                          if (objValue) {
                            fetchElectricUseTypeId(
                              objValue.value,
                              entityName,
                              accessToken
                            ).then((response) =>
                              form.mutators.updateFieldValue(
                                "electricUseTypeId",
                                response.data
                              )
                            )
                          }
                        }}
                      </OnChange>
                    </>
                  )}
                  <Col md={6}>
                    <ExtendedSelectField
                      name="contractPriceListCode"
                      isMulti={false}
                      fieldLabel="Offerta*"
                      placeholder="Offerta*"
                      options={contractPriceList}
                      defaultValue={podData?.contractPriceListCode || null}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                      getOptionLabel={(option) => (
                        <>
                          {option.hasGreenEnergy === true && (
                            <PensoInVerdeIcon className="penso-in-verde-icon" />
                          )}
                          <span>{option.label}</span>
                        </>
                      )}
                    />
                    <p className="small text-muted">
                      <PensoInVerdeIcon className="penso-in-verde-icon" />{" "}
                      Indica elettricità 100% verde.
                    </p>
                    <OnChange name="electricUseTypeId">
                      {(objValue) => {
                        if (objValue) {
                          setUseType(objValue.value)
                          fetchContractPriceList(
                            entityName,
                            selectedContractId,
                            podData?.id,
                            objValue.value,
                            email,
                            accessToken
                          )
                            .then((response) =>
                              setContractPriceList(response.data)
                            )
                            .catch((error) => console.error(error))
                        }
                      }}
                    </OnChange>
                  </Col>
                  <Col md={6}>
                    <ExtendedSelectField
                      name="vatClassId"
                      isMulti={false}
                      fieldLabel="Classe IVA*"
                      placeholder="Classe IVA*"
                      options={vatClassList}
                      defaultValue={podData?.vatClassId || null}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                    />
                    <OnChange name="vatClassId">
                      {(objValue) => setVatClass(objValue?.value || null)}
                    </OnChange>
                    <OnChange name="accountHolderAtecoCode">
                      {(value) => setAtecoCode(value)}
                    </OnChange>
                  </Col>
                  {getCustomerType(
                    CustomerTypes.BUSINESS_CUSTOMER,
                    customerType
                  ) &&
                    values.electricUseTypeId &&
                    parseInt(values.electricUseTypeId.value) !== 1 &&
                    exciseClassList.length !== 0 && (
                      <Col md={6}>
                        <ExtendedSelectField
                          name="electricExciseClassId"
                          isMulti={false}
                          fieldLabel="Classe accise*"
                          placeholder="Classe accise*"
                          options={exciseClassList}
                          defaultValue={
                            podData?.electricExciseClassId ||
                            exciseClassList[0] ||
                            null
                          }
                          isDisabled={canEdit ? false : true}
                          validate={required}
                        />
                      </Col>
                    )}
                </>

                {getCustomerType(
                  CustomerTypes.DOMESTIC_CUSTOMER,
                  customerType
                ) &&
                  isResidentNeeded(entityName) && (
                    <Col md={6}>
                      <ExtendedSelectField
                        name="isResident"
                        isMulti={false}
                        fieldLabel="Residente*"
                        placeholder="Residente*"
                        options={formValues?.podResident || []}
                        defaultValue={
                          podData?.isResident ||
                          DefaultSelectValues.IS_NOT_RESIDENT
                        }
                        isDisabled={canEdit ? false : true}
                        validate={required}
                      />
                    </Col>
                  )}
                {isResidenceSelfDeclarationNeeded(entityName) &&
                  parseInt(values.contractType?.value) === 1 && (
                    <PodResidenceSelfDeclaration
                      formValues={formValues}
                      podData={podData}
                      canEdit={canEdit}
                      values={values}
                      entityName={entityName}
                    />
                  )}
              </Row>
            </div>
          </div>

          {
            isFAttachmentRequired(entityName) &&
            parseInt(values.vatClassId?.value) === 2 &&
            (
              getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) ||
              (getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) &&
              values.electricUseTypeId &&
              parseInt(values.electricUseTypeId?.value) !== 1)
            ) && (
              <>
                <div className="grouped">
                  <div className="grouped-header">
                    <FontAwesomeIcon icon="list" className="mr-2" />
                    Allegato F
                  </div>
                  <div className="grouped-body">
                    <PodFAttachmentForm
                      formValues={formValues}
                      values={values}
                      podData={podData}
                      canEdit={canEdit}
                    />
                  </div>
                </div>
                {
                  parseInt(values.contractType?.value) === 4 &&
                  getCustomerType(
                    CustomerTypes.DOMESTIC_CUSTOMER,
                    customerType
                  ) ? null : (
                    <div className="grouped">
                      <div className="grouped-header">
                        <FontAwesomeIcon icon="list" className="mr-2" />
                        Altri dati per allegati E ed F
                      </div>
                      <div className="grouped-body">
                        <PodOtherAttachmentsDataForm
                          podData={podData}
                          formValues={formValues}
                          canEdit={canEdit}
                        />
                      </div>
                    </div>
                  )
                }
              </>
            )
          }

          {isRealEstateGroupNeeded(entityName) && values?.contractType &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="house" className="mr-2" />
                Immobile
              </div>
              <div className="grouped-body">
                <RealEstateFieldChangesConditions />
                <Row form>
                  {
                    (
                      parseInt(values?.contractType?.value) === 1 ||
                      parseInt(values?.contractType?.value) === 2
                    ) &&
                    <PodDomesticUseRealEstateForm
                      podData={podData}
                      formValues={formValues}
                      values={values}
                      extendedPodDataNeeded={cadastralDetailData?.extendedPodDataNeeded}
                      canEdit={canEdit}
                    />
                  }
                  {
                    (
                      parseInt(values?.contractType?.value) === 3 ||
                      parseInt(values?.contractType?.value) === 4
                    ) &&
                    <>
                      <Col md={12}>
                        <ExtendedSelectField
                          name="realEstateUseType"
                          fieldLabel="Tipologia uso immobile*"
                          placeholder="Tipologia uso immobile*"
                          options={formValues?.realEstateUseType || []}
                          defaultValue={podData?.realEstateUseType || null}
                          isDisabled={canEdit ? false : true}
                          validate={required}
                        />
                      </Col>
                      {parseInt(values?.realEstateUseType?.value) === 1 &&
                        <PodOtherUseRealEstateForm 
                          podData={podData}
                          formValues={formValues}
                          values={values}
                          extendedPodDataNeeded={cadastralDetailData?.extendedPodDataNeeded}
                          canEdit={canEdit}
                        />
                      }
                      {parseInt(values?.realEstateUseType?.value) === 2 &&
                        <PodWorkSiteRealEstateForm 
                          podData={podData}
                          formValues={formValues}
                          values={values}
                          canEdit={canEdit}
                        />
                      }
                    </>
                  }
                </Row>
              </div>
            </div>
          }

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="gift" className="mr-2" />
              Promozioni
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={12}>
                  <CustomFormField
                    id="podFriend"
                    name="podFriend"
                    type="text"
                    maxLength="14"
                    disabled={canEdit ? false : true}
                    placeholder="POD amico"
                    fieldLabel="POD amico"
                    defaultValue={podData?.podFriend || null}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <CustomFormField
                    id="customerFriend"
                    name="customerFriend"
                    type="text"
                    maxLength="80"
                    disabled={canEdit ? false : true}
                    placeholder="Cliente amico"
                    fieldLabel="Cliente amico"
                    defaultValue={podData?.customerFriend || null}
                  />
                </Col>
              </Row>
            </div>
          </div>

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="map-marker-alt" className="mr-2" />
              Recapito fatture e comunicazioni
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={6}>
                  <ExtendedSelectField
                    name="isInvoiceDifferentEmail"
                    isMulti={false}
                    fieldLabel="Vuoi ricevere la fattura ad un altro indirizzo email?*"
                    placeholder="Vuoi ricevere la fattura ad un altro indirizzo email?*"
                    options={formValues?.isInvoiceDifferentEmail || []}
                    defaultValue={
                      podData?.isInvoiceDifferentEmail ||
                      DefaultSelectValues.IS_INVOICE_DIFFERENT_EMAIL
                    }
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                {parseInt(values.isInvoiceDifferentEmail?.value) === 1 && (
                  <Col md={6}>
                    <CustomFormField
                      id="invoiceDifferentEmail"
                      name="invoiceDifferentEmail"
                      type="text"
                      maxLength="80"
                      disabled={canEdit ? false : true}
                      fieldLabel="E-mail*"
                      validate={emailCheck(true)}
                      defaultValue={podData?.invoiceDifferentEmail || null}
                      style={{ textTransform: "lowercase" }}
                    />
                  </Col>
                )}
              </Row>

              <Row form>
                <Col md={6}>
                  <ExtendedSelectField
                    name="billingShippingAddress"
                    isMulti={false}
                    fieldLabel="Desideri ricevere la fattura anche in formato cartaceo?*"
                    placeholder="Desideri ricevere la fattura anche in formato cartaceo?*"
                    options={formValues?.billingShippingAddress || []}
                    defaultValue={
                      podData?.billingShippingAddress ||
                      DefaultSelectValues.BILLING_SHIPPING_ADDRESS
                    }
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
              </Row>
              {parseInt(values.billingShippingAddress?.value) === 3 && (
                <>
                  <Row form>
                    <Col md={6}>
                      <CustomFormField
                        id="atName"
                        name="atName"
                        type="text"
                        maxLength="80"
                        disabled={canEdit ? false : true}
                        fieldLabel="Presso*"
                        validate={required}
                        defaultValue={podData?.atName || null}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={3}>
                      <ExtendedSelectField
                        name="toponymId"
                        isMulti={false}
                        fieldLabel="Toponimo*"
                        placeholder="Toponimo*"
                        options={formValues?.toponymIc || []}
                        defaultValue={podData?.toponymId || null}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                      />
                    </Col>
                    <Col md={7}>
                      <CustomFormField
                        id="streetName"
                        name="streetName"
                        type="text"
                        maxLength="35"
                        disabled={canEdit ? false : true}
                        placeholder="Indirizzo*"
                        fieldLabel="Indirizzo*"
                        validate={required}
                        defaultValue={podData?.streetName || null}
                      />
                    </Col>
                    <Col md={2}>
                      <CustomFormField
                        id="streetNo"
                        name="streetNo"
                        type="text"
                        maxLength="4"
                        disabled={canEdit ? false : true}
                        placeholder="Civico*"
                        fieldLabel="Civico*"
                        validate={required}
                        defaultValue={podData?.streetNo || null}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={10}>
                      <WhenFieldChanges
                        field="townName"
                        set="postalCode"
                        to={null}
                      />
                      <ExtendedAutocompleteFormField
                        id="townName"
                        name="townName"
                        fieldLabel="Comune*"
                        placeholder="Comune*"
                        fetchFunction={fetchTowns(accessToken)}
                        disabled={canEdit ? false : true}
                        defaultValue={podData?.townName || null}
                        validate={required}
                        getOptionLabel={(option) =>
                          `${option.town} (${option.province})`
                        }
                        getOptionValue={(option) => ({
                          town: option.town,
                          province: option.province,
                        })}
                        noOptionsMessage={() => (
                          <span className="autocomplete-suggestion">
                            Indica le prime lettere del comune per trovarlo
                            nella lista
                          </span>
                        )}
                      />
                    </Col>
                    <Col md={2}>
                      <ExtendedCustomZipCodeSelect
                        name="postalCode"
                        isMulti={false}
                        placeholder="CAP*"
                        fieldLabel="CAP*"
                        validate={required}
                        defaultValue={podData?.postalCode || null}
                        isDisabled={canEdit ? false : true}
                        fieldToListen="townName"
                        fieldToListenDefaultValue={podData?.townName || null}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>

          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || !canEdit}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-2" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-2" />
              )}
              <span>Salva POD</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default PodForm
