import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DropdownItem, Button } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withRouter } from '../AppRouter/utils'
import CanI from '../auth/CanI/CanI.component'
import { selectUserData } from '../../redux/user/user.selectors'
import { getItemUrls, getEntityPluralName } from '../../redux/contract/contract.utils'
import { contractReset } from '../../redux/contract/contract.actions'
import { getTemplateDownloadPath } from './utils'

const MenuItem = ({ 
  item: { name, entityName, icon }, 
  router: { location: { pathname } } 
}) => {
  const dispatch = useDispatch()
  const user = useSelector(selectUserData)
  const { create, list } = getItemUrls(entityName)

  return (
    <div className="dropdown-group">
      <DropdownItem header>
        <FontAwesomeIcon icon={icon} />
        {name}
      </DropdownItem>
      <CanI doWhat={"CREATE"} withPermissions={user.links} entityName={entityName}>
        {({ action }) => (
          <DropdownItem tag="div">
            <NavLink 
              onClick={() => {
                if(pathname !== `/${create}`) {
                  dispatch(contractReset())}
                }
              }
              end
              className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
              to={`/${create}`}
            >
              Nuovo
            </NavLink>
          </DropdownItem>
        )}
      </CanI>
      <CanI doWhat={"GET"} withPermissions={user.links} entityName={getEntityPluralName(entityName)}>
        {({ action }) => (
          <DropdownItem tag="div">
            <NavLink 
              onClick={() => {
                if(pathname !== `/${list}/`) {
                  dispatch(contractReset())}
                }
              }
              className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
              to={`/${list}/`}
            >
              Vedi tutti
            </NavLink>
          </DropdownItem>
        )}
      </CanI>
      <CanI doWhat="GET" withPermissions={user.links} entityName={`${entityName}PaperDocument`}>
          {({ action }) => (
            <DropdownItem tag="div">
              <Button 
                href={`${getTemplateDownloadPath('abStorePortal')}${action}`}
                target="_blank"
                color="link"
              >
                Scarica documento
              </Button>
            </DropdownItem>
          )}
        </CanI>
    </div>
  )
}

export default withRouter(MenuItem)
