import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavbarToggler, Collapse, Nav, NavItem, Button } from 'reactstrap'
import { NavLink } from 'react-router-dom'

import { withRouter } from '../AppRouter/utils'
import { buildNewCustomerMenuItems, buildOngoingCustomerMenuItems, buildUserMenuItems } from './utils'
import { userLogoutStart } from '../../redux/user/user.actions'
import {
  selectUserData, selectUsername, selectUserAvailableContracts, selectUserAvailableOt,
  selectUserAvailableNewCustomerEntities, selectUserAvailableOngoingCustomerEntities
} from '../../redux/user/user.selectors'
import { contractReset } from '../../redux/contract/contract.actions'

const NavMenu = props => {
  const dispatch = useDispatch()
  const user = useSelector(selectUserData)
  const username = useSelector(selectUsername)
  const userContracts = useSelector(selectUserAvailableContracts)
  const userOt = useSelector(selectUserAvailableOt)
  const newCustomerEntities = useSelector(selectUserAvailableNewCustomerEntities)
  const ongoingCustomerEntites = useSelector(selectUserAvailableOngoingCustomerEntities)

  const handleLogout = () => {
    props.router.navigate('/')
    dispatch(userLogoutStart())
  }
  const [ isOpen, setIsOpen ] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  function handleMenuClick(menuTarget) {
    if(menuTarget.classList.contains('active-link') && document.querySelector(".navbar-collapse.show")) {
      toggle()
    }
  }

  return(
    <>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar onClick={e => handleMenuClick(e.target)}>
          <NavItem>
            <NavLink 
              end
              className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
              to="/"
            >
              <FontAwesomeIcon icon="home" />{' '}
              home
            </NavLink>
          </NavItem>
          <>
            {buildUserMenuItems(user.links, props.router.location.pathname, dispatch)}
          </>
          <>
            {(userContracts.length + userOt.length) > 1 &&
              <NavItem>
                <NavLink 
                  end
                  onClick={() => dispatch(contractReset())}
                  className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                  to="/contratti-ot/all"
                >
                  <FontAwesomeIcon icon="list" />{' '}
                  contratti e o.t.
                </NavLink>
              </NavItem>
            }
          </>
          <>
            {buildNewCustomerMenuItems(newCustomerEntities)}
          </>
          <>
            {buildOngoingCustomerMenuItems(ongoingCustomerEntites)}
          </>
          <NavItem>
            <NavLink 
              end 
              className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
              to="/documenti"
            >
              <FontAwesomeIcon icon="file-download" />{' '}
              documenti
            </NavLink>
          </NavItem>
          <NavItem>
            <Button title="Effettua il log out" color="link" className="nav-link active-link" onClick={handleLogout}>
              <FontAwesomeIcon icon="sign-out-alt" />
              <span className="username">
                {username}
              </span>
            </Button>
          </NavItem>
        </Nav>
      </Collapse>
    </>
  )
}

export default withRouter(NavMenu)
