import React from 'react'
import moment from 'moment-timezone'
import { ListGroupItem } from 'reactstrap'

//Single note
function Note({ sender, message, sendingDate }) {
  return(
    <ListGroupItem>
      <p>
        Nota inserita il <strong>{ moment.utc(sendingDate).tz('Europe/Rome').format('DD-MM-YYYY HH:mm') }</strong>
        <br />
        da <strong>{sender}</strong>
      </p>
      <blockquote>
        "{message}"
      </blockquote>
    </ListGroupItem>
  )
}

export default Note
