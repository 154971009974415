import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ThankYouItem from './ThankYouItem.component'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import { selectIsFetchingData } from '../../redux/contract/contract.selectors'
import { contractGetPdfUrlStart } from '../../redux/contract/contract.actions'

const ThankYouItemWithSpinner = WithSpinner(() => (
  <ThankYouItem
    title="Operazione completata!"
    message="Grazie per aver scelto ABenergie, di seguito il link per visualizzare il contratto in formato PDF."
    buttonLabel="Vedi pdf"
    icon="file-pdf"
  />
))

const ThankYouItemContainer = () => {
  const dispatch = useDispatch()
  const isFecthingPdfUrl = useSelector(state => selectIsFetchingData('pdfUrl')(state))

  //Get form data
  useEffect(() => {
     dispatch(contractGetPdfUrlStart())
  }, [dispatch])

  return (
    <ThankYouItemWithSpinner isLoading={isFecthingPdfUrl} />
  )
}

export default ThankYouItemContainer
