import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectCurrentEntity } from '../../../redux/user/user.selectors'
import { selectActiveUserSupplyPointModalvisibility, selectEntityName } from '../../../redux/contract/contract.selectors'
import { contractToggleActiveUserSupplyPointModal } from '../../../redux/contract/contract.actions'
import DeactivationForm from './DeactivationForm/DeactivationForm.component'
import PriceListSwitchForm from './PriceListSwitchForm/PriceListSwitchForm.component'
import ContractualChangesForm from './ContractualChangesForm/ContractualChangesForm.component'
import MeasureGroupShiftForm from './MeasureGroupShiftForm/MeasureGroupShiftForm.component'
import PlantVerifyForm from './PlantVerifyForm/PlantVerifyForm.component'

const ActiveUserSupplyPointModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectActiveUserSupplyPointModalvisibility)
  const entityName = useSelector(selectEntityName)
  const entityData = useSelector(state => selectCurrentEntity(entityName)(state))
  const toggle = () => dispatch(contractToggleActiveUserSupplyPointModal())

  return (
    <Modal 
      className="status-modal" 
      size="lg"
      backdrop="static" 
      isOpen={isOpen} 
      toggle={toggle}
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={entityData.icon} className="mr-2" />
        {entityData.name}
      </ModalHeader>
      <ModalBody>
        {entityName === 'deactivation' &&
          <DeactivationForm />
        }
        {entityName === 'priceListSwitch' &&
          <PriceListSwitchForm />
        }
        {entityName === 'contractualChanges' &&
          <ContractualChangesForm />
        }
        {entityName === 'measureGroupShift' &&
          <MeasureGroupShiftForm />
        }
        {entityName === 'plantVerify' &&
          <PlantVerifyForm />
        }
      </ModalBody>
    </Modal>
  )
}

export default ActiveUserSupplyPointModal
