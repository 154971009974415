import React from 'react'
import { Row, Col } from 'reactstrap'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import { required } from '../../validations'

const PdrFAttachmentForm = ({ formValues, values, pdrData, canEdit }) => (
  <>
    <Row form>
      <Col md={6}>
        <ExtendedSelectField
          options={formValues?.scope || []}
          name="scope"
          type="select"
          disabled={canEdit ? false : true}
          fieldLabel="Finalità uso gas*"
          placeholder="Finalità uso gas*"
          validate={required}
          defaultValue={pdrData?.scope || null}
        />
      </Col>
    </Row>

    {values && parseInt(values.scope?.value) === 1 &&
      <Row form>
        <Col md={6}>
          <CustomFormField
            id="atecoCodeCompany"
            name="atecoCodeCompany"
            type="text"
            maxLength="8"
            disabled={canEdit ? false : true}
            placeholder="Codice Ateco*"
            fieldLabel="Codice Ateco*"
            validate={required}
            defaultValue={pdrData?.atecoCodeCompany || null}
          />
        </Col>
        <Col md={6}>
          <ExtendedSelectField
            options={formValues?.atecoCategoryId || []}
            name="atecoCategoryId"
            type="select"
            disabled={canEdit ? false : true}
            fieldLabel="Categoria codice Ateco*"
            placeholder="Categoria codice Ateco*"
            validate={required}
            defaultValue={pdrData?.atecoCategoryId || null}
          />
        </Col>
        {values && parseInt(values.atecoCategoryId?.value) === 5 &&
          <Col>
            <CustomFormField
              id="otherText"
              name="otherText"
              type="text"
              maxLength="128"
              disabled={canEdit ? false : true}
              placeholder="Altro (specificare)*"
              fieldLabel="Altro (specificare)*"
              defaultValue={pdrData?.otherText || null}
              validate={required}
            />
          </Col>
        }
      </Row>
    }

    <Row form>
      <Col md={6}>
        <CustomFormField
          id="otherGroup"
          name="otherGroup"
          type="text"
          maxLength="128"
          disabled={canEdit ? false : true}
          placeholder="Gruppo"
          fieldLabel="Gruppo"
          defaultValue={pdrData?.otherGroup || null}
        />
      </Col>
      <Col md={6}>
        <CustomFormField
          id="otherSpecies"
          name="otherSpecies"
          type="text"
          maxLength="128"
          disabled={canEdit ? false : true}
          placeholder="Specie"
          fieldLabel="Specie"
          defaultValue={pdrData?.otherSpecies || null}
        />
      </Col>
    </Row>

    {values && parseInt(values.scope?.value) === 2 &&
      <>
        <Row form>
          <Col md={6}>
            <CustomFormField
              id="electricLicenseNo"
              name="electricLicenseNo"
              type="text"
              maxLength="128"
              disabled={canEdit ? false : true}
              placeholder="N. licenza officina elettrica*"
              fieldLabel="N. licenza officina elettrica*"
              validate={required}
              defaultValue={pdrData?.electricLicenseNo || null}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <legend className="col-form-label">Per la determinazione della quantità di gas impiegata per la produzione di energia elettrica:</legend>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <ExtendedSelectField
              options={formValues?.customAgency || []}
              name="customAgency"
              type="select"
              disabled={canEdit ? false : true}
              placeholder="Modalità determinazione quantità gas per produzione EE*"
              fieldLabel="Modalità determinazione quantità gas per produzione EE*"
              validate={required}
              defaultValue={pdrData?.customAgency || null}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="officeTown"
              name="officeTown"
              type="text"
              maxLength="128"
              disabled={canEdit ? false : true}
              placeholder="Ufficio Tecnico di Finanza di*"
              fieldLabel="Ufficio Tecnico di Finanza di*"
              validate={required}
              defaultValue={pdrData?.officeTown || null}
            />
          </Col>
        </Row>
      </>
    }

    {values && parseInt(values.scope?.value) === 2 && parseInt(values.customAgency?.value) === 1 &&
      <Row form>
        <Col md={6}>
          <CustomFormField
            id="reportProtocol"
            name="reportProtocol"
            type="text"
            maxLength="128"
            disabled={canEdit ? false : true}
            placeholder="Verbale protocollo*"
            fieldLabel="Verbale protocollo*"
            validate={required}
            defaultValue={pdrData?.reportProtocol || null}
          />
        </Col>
        <Col md={6}>
          <CustomDatePicker
            id="reportDate"
            name="reportDate"
            className="form-control"
            type="date"
            adjustDateOnChange
            placeholder="Data firma*"
            fieldLabel="Data firma*"
            validate={required}
            defaultValue={pdrData?.reportDate || null}
            disabled={canEdit ? false : true}
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </Row>
    }
  </>
)

export default PdrFAttachmentForm
