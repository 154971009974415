import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroupItem, Row, Col, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CanI from '../../../auth/CanI/CanI.component'
import DeleteItem from '../../../DeleteItem/DeleteItem.component'
import PodItem from '../../PodItem/PodItem.component'
import PdrItem from '../../PdrItem/PdrItem.component'
import { contractGetCadastralDetailStart, contractDeleteCadastralDataStart } from '../../../../redux/contract/contract.actions'
import { selectContract, selectIsDeleting, selectIsFetchingData } from '../../../../redux/contract/contract.selectors'

const CadastralItem = ({ item }) => {
  const dispatch = useDispatch()
  const { entityName } = useSelector(selectContract)
  const isFetching = useSelector(state => selectIsFetchingData(`cadastralDetail-${item.id}`)(state))
  const isDeleting = useSelector(state => selectIsDeleting(`cadastral-${item.id}`)(state))
  const { pod, pdr } = item

  return(
    <>
      <ListGroupItem className="cadastral" key={item.id} tag="div" action>
        <div className="cadastral-info">
          <div>
            <p>
              <FontAwesomeIcon icon="map-marker-alt" className="mr-2" />
              {item.address}, {item.postalCode} {item.town} ({item.province})
            </p>
          </div>
          <div className="context-menu">
            <CanI doWhat="GET" withPermissions={item.links} entityName={`${entityName}CadastralData`}>
              {({ action }) => (
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => dispatch(contractGetCadastralDetailStart({ cadastralDetailId: item.id }))}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <Spinner size="sm" className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon={['fas', 'eye']} className="mr-2" />
                  )}
                  Dettagli
                </Button>
              )}
            </CanI>
            <CanI doWhat="DELETE" withPermissions={item.links} entityName={`${entityName}CadastralData`}>
              {({ action }) => (
                <DeleteItem
                  buttonLabel="Elimina"
                  actionUrl={action}
                  actionFunction={contractDeleteCadastralDataStart}
                  itemId={item.id}
                  isDeleting={isDeleting}
                />
              )}
            </CanI>
          </div>
        </div>
        <div className="sub-items">
          <Row>
            <Col sm={6}>
              <PodItem cadastralItem={item} item={pod} />
            </Col>
            <Col sm={6}>
              <PdrItem cadastralItem={item} item={pdr} />
            </Col>
          </Row>
        </div>
      </ListGroupItem>
    </>
  )
}

export default CadastralItem
