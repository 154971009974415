import { useSelector } from "react-redux"
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CustomFormField from "../../inputFields/CustomFormField/CustomFormField.component"
import ExtendedSelectField from "../../inputFields/ExtendedSelectField/ExtendedSelectField.component"
import { required } from "../../validations"
import CustomerTypes, { 
  singleInvoicePodVisibility, singleInvoicePdrVisibility, propertyTypeVisibility,
  getCustomerType, isMortisCausaVisibility
} from "../utils"
import { selectContract, selectProposalFormFieldsValues } from "../../../../redux/contract/contract.selectors"

const OtherFields = ({ canEdit, values }) => {
  const { proposalData, entityName } = useSelector(selectContract)
  const formValues = useSelector(selectProposalFormFieldsValues)

  return (
    <>
      <div className="grouped">
        <div className="grouped-header">
          <FontAwesomeIcon icon="layer-group" className="mr-2" />
          Tipologia fattura
        </div>
        <div className="grouped-body">
          <Row form>
            {singleInvoicePodVisibility(entityName) &&
              <Col>
                <ExtendedSelectField
                  name="singleInvoicePod"
                  isMulti={false}
                  fieldLabel="Fattura unica per tutti i POD che aggiungerai?*"
                  placeholder="Fattura unica per tutti i POD che aggiungerai?*"
                  options={formValues?.singleInvoicePod || []}
                  initialValue={proposalData?.singleInvoicePod || null}
                  isDisabled={canEdit ? false : true}
                  validate={required}
                />
              </Col>
            }
            {singleInvoicePdrVisibility(entityName) &&
              <Col>
                <ExtendedSelectField
                  name="singleInvoicePdr"
                  isMulti={false}
                  fieldLabel="Fattura unica per tutti i PDR che aggiungerai?*"
                  placeholder="Fattura unica per tutti i PDR che aggiungerai?*"
                  options={formValues?.singleInvoicePdr || []}
                  initialValue={proposalData?.singleInvoicePdr || null}
                  isDisabled={canEdit ? false : true}
                  validate={required}
                />
              </Col>
            }
          </Row>
        </div>
      </div>

      {propertyTypeVisibility(entityName) &&
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="home" className="mr-2" />
            Tipologia occupazione immobile
          </div>
          <div className="grouped-body">
            <Row form>
              <Col md={6}>
                <ExtendedSelectField
                  name="propertyType"
                  isMulti={false}
                  fieldLabel="Tipo proprietà*"
                  placeholder="Tipo proprietà*"
                  options={formValues?.propertyType || []}
                  initialValue={proposalData?.propertyType || null}
                  isDisabled={canEdit ? false : true}
                  validate={required}
                />
              </Col>
              {parseInt(values.propertyType?.value) === 4 &&
                <Col md={6}>
                  <CustomFormField
                    id="otherPropertyType"
                    name="otherPropertyType"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Altro (specificare)*"
                    fieldLabel="Altro (specificare)*"
                    maxLength="80"
                    initialValue={proposalData?.otherPropertyType || null}
                    validate={required}
                  />
                </Col>
              }
            </Row>
          </div>
        </div>
      }

      { 
        getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, values.customerType) &&
        isMortisCausaVisibility(entityName) &&
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="question-circle" className="mr-2" />
            Mortis causa
          </div>
          <div className="grouped-body">
            <Row form>
              <Col md={6}>
                <ExtendedSelectField
                  name="isMortisCausa"
                  isMulti={false}
                  fieldLabel="Mortis causa*"
                  placeholder="Mortis causa*"
                  options={formValues?.isMortisCausa || []}
                  initialValue={proposalData?.isMortisCausa || null}
                  isDisabled={canEdit ? false : true}
                  validate={required}
                />
              </Col>
            </Row>
          </div>
        </div>
      }

      {(entityName === 'transfer' || 
        entityName === 'takeover') && 
        getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) ? (
        null
      ) : (
        <div className="grouped">
          <div className="grouped-header">
            <FontAwesomeIcon icon="gavel" className="mr-2" />
            Diritto di ripensamento
          </div>
          <div className="grouped-body">
            <Row form>
              <Col md={6}>
                <ExtendedSelectField
                  name="hasReconsideration"
                  isMulti={false}
                  fieldLabel="Diritto ripensamento*"
                  placeholder="Diritto ripensamento*"
                  options={formValues?.reconsideration || []}
                  initialValue={proposalData?.hasReconsideration || null}
                  isDisabled={canEdit ? false : true}
                  validate={required}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}

export default OtherFields