import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { contractGetListDataStart, contractUpdateListSorting } from '../../../redux/contract/contract.actions'

const ThTable = ({ 
  apiUrl,
  activeSortParam, 
  setSortParam,
  label, 
  isSortable = false, 
  isActive = false, 
  sortingName
}) => {
  const dispatch = useDispatch()

  const handleSorting = useCallback(param => {
    const newParam = activeSortParam === param ? `-${param}` : param
    const newSorts = `sorts=${newParam}`
    setSortParam(newParam)
    dispatch(contractUpdateListSorting(newSorts))
    dispatch(contractGetListDataStart({ apiUrl }))
  }, [dispatch, apiUrl, setSortParam, activeSortParam])

  return (
    <th
      className={
        classnames({
          'sortable-column': isSortable,
          'active-col': isActive
        })
      }
      onClick={() => isSortable && handleSorting(sortingName)}
    >
      {label}
      {isSortable &&
        <>
          {' '}
          {activeSortParam.indexOf(sortingName) !== -1 ?
            <FontAwesomeIcon
              icon={classnames({ 'sort-up': activeSortParam.indexOf("-") === -1, 'sort-down': activeSortParam.indexOf('-') !== -1 })}
            />
            :
            <FontAwesomeIcon icon="sort" />
          }
        </>
      }
    </th>
  )
}

export default ThTable