import styled from 'styled-components'

const AttachmentsIntegrationAlertStyle = styled.div`
  margin: 1.5rem 0;

  .alert-heading {
    font-size: 1.15rem;
    font-weight: bold;
  }

  .alert-description {
    font-size: .9rem;
  }

  .signature-types-loader {
    display: flex;
    align-items: center;

    .loader-text {
      font-weight: bold;
    }
  }

  .signature-type-container {
    display: flex;

    p { 
      margin: 0;
    }

    button {
      margin-right: .5rem;
    }
  }

  .integration-history-title {
    font-size: 1rem;
  }
`

export default AttachmentsIntegrationAlertStyle