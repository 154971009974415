import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageHeaderStyle from './style'

const PageHeader = (props) => {
  const { pageTitle, icon } = props

  return(
    <PageHeaderStyle>
      <Container>
        <Row>
          <Col xs={12}>
            <h1 className="page-title">
              <FontAwesomeIcon className="mr-1" icon={icon ? icon : 'file-signature'} />{' '}
              {pageTitle}
            </h1>
          </Col>
        </Row>
      </Container>
    </PageHeaderStyle>
  )
}

export default PageHeader
