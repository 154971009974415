import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CustomFormField from "../../inputFields/CustomFormField/CustomFormField.component"
import WhenFieldChanges from '../../WhenFieldChanges/WhenFieldChanges.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import ExtendedAutocompleteFormField from '../../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import ExtendedCustomZipCodeSelect from '../../inputFields/ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import { selectProposalFormFieldsValues, selectContract } from '../../../../redux/contract/contract.selectors'
import { selectUserData } from '../../../../redux/user/user.selectors'
import { fetchTowns } from '../../../../api'
import { 
  required, fiscalCodeCheck, vatNumberAsFiscalCode, mustBeAdult, 
  vatNumber, emailCheck, euPhoneCheck, noFutureDate 
} from "../../validations"
import CustomerTypes, { 
  getCustomerType, electronicInvoiceRecipientCodeVisibility, 
  extendedCompanyDataVisibility, residentFieldsVisibility 
} from "../utils"

const CustomerData = ({ canEdit, values }) => {
  const { accessToken } = useSelector(selectUserData)
  const { entityName, selectedContractId, proposalData } = useSelector(selectContract)
  const formValues = useSelector(selectProposalFormFieldsValues)
  const defaultCustomerBirthCountry = useMemo(() => ({ label: "Italia", value: "Italia" }), [])

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="address-book" className="mr-2" />
        Dati cliente
      </div>
      <div className="grouped-body">
        <Row form>
          {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) &&
            <Col>
              <CustomFormField
                id="customerBusinessName"
                name="customerBusinessName"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Ragione sociale*"
                fieldLabel="Ragione sociale*"
                validate={required}
                maxLength="45"
                initialValue={proposalData?.customerBusinessName || null}
              />
            </Col>
          }
          {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, values.customerType) &&
            <>
              <Col md={5}>
                <CustomFormField
                  id="customerName"
                  name="customerName"
                  type="text"
                  validate={required}
                  disabled={canEdit ? false : true}
                  maxLength="80"
                  placeholder="Nome*"
                  fieldLabel="Nome*"
                  initialValue={proposalData?.customerName || null}
                />
              </Col>
              <Col md={5}>
                <CustomFormField
                  id="customerSurname"
                  name="customerSurname"
                  type="text"
                  validate={required}
                  disabled={canEdit ? false : true}
                  maxLength="80"
                  placeholder="Cognome*"
                  fieldLabel="Cognome*"
                  initialValue={proposalData?.customerSurname || null}
                />
              </Col>
              <Col md={2}>
                <ExtendedSelectField
                  name="customerSex"
                  isMulti={false}
                  fieldLabel="Sesso*"
                  placeholder="Sesso*"
                  options={formValues?.customerSex || []}
                  initialValue={proposalData?.customerSex || null}
                  validate={required}
                  isDisabled={canEdit ? false : true}
                />
              </Col>
            </>
          }
        </Row>
        <Row form>
          <Col>
            <CustomFormField
              id="customerFiscalCode"
              name="customerFiscalCode"
              type="text"
              disabled={canEdit ? false : true}
              fieldLabel="Codice fiscale*"
              placeholder="Codice fiscale*"
              maxLength="20"
              style={{ textTransform: 'uppercase' }}
              initialValue={proposalData?.customerFiscalCode || null}
              validate={getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, values.customerType) ? (
                  fiscalCodeCheck(true)
                ) : (
                  vatNumberAsFiscalCode(true)
                )}
            />
          </Col>
          {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, values.customerType) &&
            <Col md={6}>
              <CustomDatePicker
                className="form-control"
                id="customerBirthDate"
                name="customerBirthDate"
                type="date"
                disabled={canEdit ? false : true}
                placeholder="Data di nascita*"
                fieldLabel="Data di nascita*"
                dateFormat="dd/MM/yyyy"
                validate={mustBeAdult}
                adjustDateOnChange
                initialValue={proposalData?.customerBirthDate || null}
              />
            </Col>
          }
          {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) &&
            <Col md={6}>
              <CustomFormField
                id="customerVatNumber"
                name="customerVatNumber"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Partita IVA*"
                fieldLabel="Partita IVA*"
                maxLength="11"
                validate={vatNumber(true)}
                initialValue={proposalData?.customerVatNumber || null}
              />
            </Col>
          }
          <WhenFieldChanges
            field="customerType"
            set="customerVatNumber"
            to={null}
          />
          {
            getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) &&
            electronicInvoiceRecipientCodeVisibility(entityName) &&
            <>
              <Col md={6}>
                <ExtendedSelectField
                  name="hasPublicAdministration"
                  isMulti={false}
                  fieldLabel="Pubblica amministrazione*"
                  placeholder="Pubblica amministrazione*"
                  validate={required}
                  options={formValues?.hasPublicAdministration || []}
                  initialValue={proposalData?.hasPublicAdministration || null}
                  isDisabled={canEdit ? false : true}
                  fieldDescription="La pubblica amministrazione è l'insieme degli enti pubblici che concorrono all'esercizio e alle funzioni dell'amministrazione di uno Stato nelle materie di sua competenza."
                />
              </Col>
              <Col md={6}>
                <ExtendedSelectField
                  name="hasSplitPayment"
                  isMulti={false}
                  fieldLabel="Split payment*"
                  placeholder="Split payment*"
                  validate={required}
                  options={formValues?.hasSplitPayment || []}
                  initialValue={proposalData?.hasSplitPayment || null}
                  isDisabled={canEdit ? false : true}
                  customFilterFunction={(candidate, input) => {
                    if(parseInt(values.hasPublicAdministration?.value) === 1) {
                      return parseInt(candidate.data.value) === 1
                    }
                    return true
                  }}
                  fieldDescription="Lo split payment, o scissione dei pagamenti , è un regime particolare che stabilisce che il debitore dell’IVA sia il cessionario/committente anziché, come avviene normalmente, il cedente/prestatore."
                />
              </Col>
              <WhenFieldChanges
                field="hasPublicAdministration"
                set="hasSplitPayment"
                to={null}
              />
              <Col md={12}>
                <CustomFormField
                  id="electronicInvoiceRecipientCode"
                  name="electronicInvoiceRecipientCode"
                  type="text"
                  maxLength="80"
                  disabled={canEdit ? false : true}
                  placeholder="Codice destinatario fatturazione elettronica*"
                  fieldLabel="Codice destinatario fatturazione elettronica*"
                  validate={required}
                  initialValue={proposalData?.electronicInvoiceRecipientCode || null}
                  fieldDescription="Il codice univoco destinatario è un codice alfanumerico di 6 cifre con il quale il Sistema di Interscambio identifica l'ente destinatario della fattura elettronica."
                />
              </Col>
            </>
          }
        </Row>
        {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, values.customerType) &&
          <Row form>
            <Col>
              <ExtendedSelectField
                name="customerBirthCountry"
                isMulti={false}
                fieldLabel="Nazionalità*"
                placeholder="Nazionalità*"
                validate={required}
                options={formValues?.customerBirthCountry || []}
                initialValue={proposalData?.customerBirthCountry || defaultCustomerBirthCountry}
                isDisabled={canEdit ? false : true}
              />
            </Col>
            <>
              {values.customerBirthCountry?.value === "Italia" &&
                <>
                  <Col md={6}>
                    <CustomFormField
                      id="customerBirthPlace"
                      name="customerBirthPlace"
                      type="text"
                      disabled={canEdit ? false : true}
                      maxLength="80"
                      placeholder="Comune di nascita*"
                      fieldLabel="Comune di nascita*"
                      validate={required}
                      initialValue={proposalData?.customerBirthPlace || null}
                    />
                  </Col>
                  <Col md={2}>
                    <CustomFormField
                      id="customerBirthProvince"
                      name="customerBirthProvince"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Provincia di nascita*"
                      fieldLabel="Provincia di nascita*"
                      validate={required}
                      maxLength="2"
                      initialValue={proposalData?.customerBirthProvince || null}
                    />
                  </Col>
                </>
              }
            </>
          </Row>
        }
        <Row form>
          <Col md={6}>
            <CustomFormField
              id="contactEmail"
              name="contactEmail"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="E-mail*"
              fieldLabel="E-mail*"
              maxLength="80"
              validate={emailCheck(true)}
              initialValue={proposalData?.contactEmail || null}
              style={{ textTransform: 'lowercase' }}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="contactPec"
              name="contactPec"
              type="text"
              disabled={canEdit ? false : true}
              placeholder={`${getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) ? 'E-mail PEC*' : 'E-mail PEC'}`}
              fieldLabel={`${getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) ? 'E-mail PEC*' : 'E-mail PEC'}`}
              maxLength="80"
              validate={getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) ? emailCheck(true) : emailCheck(false)}
              initialValue={proposalData ? proposalData.contactPec : null}
              style={{ textTransform: 'lowercase' }}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <CustomFormField
              id="contactMobileNumber"
              name="contactMobileNumber"
              type="text"
              className="phone-number"
              disabled={canEdit ? false : true}
              placeholder="Cellulare*"
              fieldLabel="Cellulare*"
              maxLength="20"
              initialValue={proposalData?.contactMobileNumber || null}
              validate={euPhoneCheck(true)}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="contactPhoneNumber"
              name="contactPhoneNumber"
              type="text"
              className="phone-number"
              maxLength="20"
              disabled={canEdit ? false : true}
              placeholder="Telefono fisso"
              fieldLabel="Telefono fisso"
              validate={euPhoneCheck(false)}
              initialValue={proposalData?.contactPhoneNumber || null}
            />
          </Col>
        </Row>
        {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) &&
          <>
            <Row form>
              <Col md="12">
                <legend className="col-form-label">Indirizzo sede legale:</legend>
              </Col>
            </Row>
            <Row form>
              <Col md={3}>
                <ExtendedSelectField
                  name="customerToponymId"
                  isMulti={false}
                  fieldLabel="Toponimo*"
                  placeholder="Toponimo*"
                  validate={required}
                  options={formValues?.customerToponym || []}
                  initialValue={proposalData?.customerToponymId || null}
                  isDisabled={canEdit ? false : true}
                />
              </Col>
              <Col md={7}>
                <CustomFormField
                  id="customerAddress"
                  name="customerAddress"
                  type="text"
                  maxLength="35"
                  validate={required}
                  disabled={canEdit ? false : true}
                  placeholder="Indirizzo*"
                  fieldLabel="Indirizzo*"
                  initialValue={proposalData?.customerAddress || null}
                />
              </Col>
              <Col md={2}>
                <CustomFormField
                  id="customerStreetNumber"
                  name="customerStreetNumber"
                  type="text"
                  validate={required}
                  disabled={canEdit ? false : true}
                  placeholder="Civico*"
                  fieldLabel="Civico*"
                  maxLength="4"
                  initialValue={proposalData?.customerStreetNumber || null}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={10}>
                <WhenFieldChanges
                  field="customerTownName"
                  set="customerPostalCode"
                  to={null}
                />
                <ExtendedAutocompleteFormField
                  id="customerTownName"
                  name="customerTownName"
                  fieldLabel="Comune*"
                  placeholder="Comune*"
                  fetchFunction={fetchTowns(accessToken)}
                  disabled={canEdit ? false : true}
                  initialValue={proposalData?.customerTownName || null}
                  validate={required}
                  getOptionLabel={option => `${option.town} (${option.province})`}
                  getOptionValue={option => ({ town: option.town, province: option.province })}
                  noOptionsMessage={() => 
                    <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                  }
                />
              </Col>
              <Col md={2}>
                <ExtendedCustomZipCodeSelect
                  name="customerPostalCode"
                  isMulti={false}
                  placeholder="CAP*"
                  fieldLabel="CAP*"
                  validate={required}
                  initialValue={proposalData?.customerPostalCode || null}
                  isDisabled={canEdit ? false : true}
                  fieldToListen="customerTownName"
                  fieldToListenDefaultValue={proposalData?.customerTownName || null}
                />
              </Col>
            </Row>
          </>
        }

        {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) &&
          <>
            <Row form>
              <Col md="12">
                <legend className="col-form-label">Legale rappresentante:</legend>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <CustomFormField
                  id="identityName"
                  name="identityName"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Nome*"
                  fieldLabel="Nome*"
                  validate={required}
                  maxLength="80"
                  initialValue={proposalData?.identityName || null}
                />
              </Col>
              <Col md={6}>
                <CustomFormField
                  id="identitySurname"
                  name="identitySurname"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Cognome*"
                  fieldLabel="Cognome*"
                  validate={required}
                  maxLength="80"
                  initialValue={proposalData?.identitySurname || null}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <CustomDatePicker
                  className="form-control"
                  id="identityBirthDate"
                  name="identityBirthDate"
                  type="date"
                  disabled={canEdit ? false : true}
                  placeholder="Data di nascita*"
                  fieldLabel="Data di nascita*"
                  dateFormat="dd/MM/yyyy"
                  adjustDateOnChange
                  validate={selectedContractId ? undefined : mustBeAdult}
                  initialValue={proposalData?.identityBirthDate || null}
                />
              </Col>
              <Col md={6}>
                <ExtendedSelectField
                  name="identityBirthCountry"
                  isMulti={false}
                  fieldLabel="Nazionalità*"
                  placeholder="Nazionalità*"
                  validate={required}
                  options={formValues?.customerBirthCountry || []}
                  initialValue={proposalData?.identityBirthCountry || defaultCustomerBirthCountry}
                  isDisabled={canEdit ? false : true}
                />
              </Col>
            </Row>
            {values.identityBirthCountry?.value === "Italia" &&
              <Row form>
                <Col md={10}>
                  <CustomFormField
                    id="identityTownName"
                    name="identityTownName"
                    type="text"
                    disabled={canEdit ? false : true}
                    maxLength="80"
                    placeholder="Comune di nascita*"
                    fieldLabel="Comune di nascita*"
                    validate={selectedContractId ? undefined : required}
                    initialValue={proposalData?.identityTownName || null}
                  />
                </Col>
                <Col md={2}>
                  <CustomFormField
                    id="identityProvince"
                    name="identityProvince"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Provincia di nascita*"
                    fieldLabel="Provincia di nascita*"
                    validate={selectedContractId ? undefined : required}
                    maxLength="2"
                    initialValue={proposalData?.identityProvince || null}
                  />
                </Col>
              </Row>
            }
            <Row form>
              <Col md={2}>
                <ExtendedSelectField
                  name="customerSex"
                  isMulti={false}
                  fieldLabel="Sesso*"
                  placeholder="Sesso*"
                  validate={required}
                  options={formValues?.customerSex || []}
                  initialValue={proposalData?.customerSex || null}
                  isDisabled={canEdit ? false : true}
                />
              </Col>
              <Col md={10}>
                <CustomFormField
                  id="identityFiscalCode"
                  name="identityFiscalCode"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Codice fiscale*"
                  fieldLabel="Codice fiscale*"
                  maxLength="20"
                  style={{ textTransform: 'uppercase' }}
                  initialValue={proposalData?.identityFiscalCode || null}
                  validate={fiscalCodeCheck(true)}
                />
              </Col>
            </Row>
          </>
        }
        <Row form>
          <Col md={6}>
            <ExtendedSelectField
              name="identityDocumentType"
              isMulti={false}
              fieldLabel="Tipo documento*"
              placeholder="Tipo documento*"
              options={formValues?.identityDocumentType || []}
              initialValue={proposalData?.identityDocumentType || null}
              isDisabled={canEdit ? false : true}
              validate={required}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="identityDocumentNumber"
              name="identityDocumentNumber"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="Numero documento*"
              fieldLabel="Numero documento*"
              maxLength="20"
              validate={required}
              initialValue={proposalData?.identityDocumentNumber || null}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <CustomDatePicker
              className="form-control"
              id="identityDocumentReleaseDate"
              name="identityDocumentReleaseDate"
              fieldLabel="Rilasciato il*"
              placeholder="Rilasciato il*"
              disabled={canEdit ? false : true}
              adjustDateOnChange
              validate={noFutureDate}
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              initialValue={proposalData?.identityDocumentReleaseDate || null}
            />
          </Col>
          <Col md={6}>
            <ExtendedSelectField
              name="identityDocumentEntity"
              isMulti={false}
              fieldLabel="Rilasciato da*"
              placeholder="Rilasciato da*"
              options={formValues?.identityDocumentEntity || []}
              initialValue={proposalData?.identityDocumentEntity || null}
              isDisabled={canEdit ? false : true}
              validate={required}
            />
          </Col>
        </Row>
        {
          getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) &&
          extendedCompanyDataVisibility(entityName) &&
          <Row form>
            <Col md={6}>
              <ExtendedSelectField
                name="companyType"
                fieldLabel="Tipologia azienda*"
                placeholder="Tipologia azienda*"
                options={formValues?.companyType || []}
                initialValue={proposalData?.companyType || null}
                isDisabled={canEdit ? false : true}
                validate={required}
              />
            </Col>
            <Col md={6}>
              <ExtendedSelectField
                name="cciaaRegistered"
                fieldLabel="Iscritta CCIAA*"
                placeholder="Iscritta CCIAA*"
                options={formValues?.cciaaRegistered || []}
                initialValue={proposalData?.cciaaRegistered || null}
                isDisabled={canEdit ? false : true}
                validate={required}
              />
            </Col>
            {parseInt(values?.cciaaRegistered?.value) === 1 &&
              <>
                <Col md={6}>
                  <ExtendedAutocompleteFormField
                    name="cciaaTownName"
                    fieldLabel="Comune CCIAA*"
                    placeholder="Comune CCIAA*"
                    fetchFunction={fetchTowns(accessToken)}
                    disabled={canEdit ? false : true}
                    initialValue={proposalData?.cciaaTownName || null}
                    validate={required}
                    getOptionLabel={option => `${option.town} (${option.province})`}
                    getOptionValue={option => ({ town: option.town, province: option.province })}
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                    }
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    name="cciaaSection"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Sezione CCIAA*"
                    fieldLabel="Sezione CCIAA*"
                    maxLength="80"
                    initialValue={proposalData?.cciaaSection || null}
                    validate={required}
                  />
                </Col>
                <Col md={12}>
                  <CustomFormField
                    name="rea"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="REA*"
                    fieldLabel="REA*"
                    maxLength="80"
                    initialValue={proposalData?.rea || null}
                    validate={required}
                  />
                </Col>
              </>
            }
          </Row>
        }
        {
          getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, values.customerType) && 
          residentFieldsVisibility(entityName) &&
          <>
            <Row form>
              <Col md="12">
                <legend className="col-form-label">Residente in:</legend>
              </Col>
            </Row>
            <Row form>
              <Col md={3}>
                <ExtendedSelectField
                  name="residentToponymId"
                  isMulti={false}
                  fieldLabel="Toponimo*"
                  placeholder="Toponimo*"
                  options={formValues?.customerToponym || []}
                  initialValue={proposalData?.residentToponymId || null}
                  isDisabled={canEdit ? false : true}
                  validate={required}
                />
              </Col>
              <Col md={7}>
                <CustomFormField
                  id="residentAddress"
                  name="residentAddress"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Indirizzo*"
                  fieldLabel="Indirizzo*"
                  maxLength="35"
                  initialValue={proposalData?.residentAddress || null}
                  validate={required}
                />
              </Col>
              <Col md={2}>
                <CustomFormField
                  id="residentStreetNumber"
                  name="residentStreetNumber"
                  type="text"
                  validate={required}
                  disabled={canEdit ? false : true}
                  placeholder="Civico*"
                  fieldLabel="Civico*"
                  maxLength="4"
                  initialValue={proposalData?.residentStreetNumber || null}
                />
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <WhenFieldChanges
                  field="residentTownName"
                  set="residentPostalCode"
                  to={null}
                />
                <ExtendedAutocompleteFormField
                  id="residentTownName"
                  name="residentTownName"
                  fieldLabel="Comune*"
                  placeholder="Comune*"
                  fetchFunction={fetchTowns(accessToken)}
                  disabled={canEdit ? false : true}
                  initialValue={proposalData?.residentTownName || null}
                  validate={required}
                  getOptionLabel={option => `${option.town} (${option.province})`}
                  getOptionValue={option => ({ town: option.town, province: option.province })}
                  noOptionsMessage={() => 
                    <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                  }
                />
              </Col>
              <Col md={3}>
                <ExtendedCustomZipCodeSelect
                  name="residentPostalCode"
                  isMulti={false}
                  placeholder="CAP*"
                  fieldLabel="CAP*"
                  validate={required}
                  initialValue={proposalData?.residentPostalCode || null}
                  isDisabled={canEdit ? false : true}
                  fieldToListen="residentTownName"
                  fieldToListenDefaultValue={proposalData?.residentTownName || null}
                />
              </Col>
            </Row>
          </>
        }
      </div>
    </div>
  )
}

export default CustomerData