import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CanI from '../../../auth/CanI/CanI.component'
import { contractResetCadastralData, contractToggleCadastralDetailModal } from '../../../../redux/contract/contract.actions'
import { selectContract, selectPermissions } from '../../../../redux/contract/contract.selectors'

const CadastralDashboardControlButtons = () => {
  const dispatch = useDispatch()
  const { entityName } = useSelector(selectContract)
  const permissions = useSelector(selectPermissions)

  const handleClick = () => {
    dispatch(contractResetCadastralData())
    dispatch(contractToggleCadastralDetailModal())
  }

  return(
    <div className="control-buttons">
      <CanI doWhat={"CREATE"} withPermissions={permissions} entityName={`${entityName}CadastralData`}>
        {({ action }) => (
          <Button
            size="md"
            color="secondary"
            outline
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={['fas', 'plus-circle']} className="mr-2" />
            Aggiungi punto di fornitura
          </Button>
        )}
      </CanI>
    </div>
  )
}

export default CadastralDashboardControlButtons
