import { Form } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import createDecorator from 'final-form-focus'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import { abilityCheck } from '../../../auth/CanI/utils'
import { 
  selectContract, selectPodFormFieldsValues, selectPdrFormFieldsValues, selectIsSubmitting 
} from '../../../../redux/contract/contract.selectors'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'
import { contractSubmitActiveUserSupplyPointDataStart } from '../../../../redux/contract/contract.actions'
import { capitalizeWord } from '../../../../utils'
import { getAPIUrl } from '../../../../api'
import { required } from '../../validations'

const focusOnError = createDecorator()

const MeasureGroupShiftForm = () => {
  const dispatch = useDispatch()
  const podFormValues = useSelector(selectPodFormFieldsValues)
  const pdrFormValues = useSelector(selectPdrFormFieldsValues)
  const { entityName, activeUserSupplyPointData, selectedContractId } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('supplyPointTechOp')(state))

  let canEdit = true
  if(activeUserSupplyPointData?.id) {
    canEdit = abilityCheck(`${entityName}${capitalizeWord(activeUserSupplyPointData.supplyType)}UPDATE`, activeUserSupplyPointData.links)
  }

  const onSubmit = values => {
    const method = activeUserSupplyPointData?.id && canEdit === true ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl()}/${getEntityUriName(entityName)}/${activeUserSupplyPointData.supplyType.toLowerCase()}s${activeUserSupplyPointData?.id ? `/${activeUserSupplyPointData.id}` : ''}`
    dispatch(contractSubmitActiveUserSupplyPointDataStart({ apiUrl, values, method }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={{
        ...activeUserSupplyPointData,
        measureGroupShiftId: selectedContractId
      }}
    >
      {({ handleSubmit, values }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="grouped">
            <div className="grouped-header">
              {activeUserSupplyPointData.supplyType === 'POD' &&
                <FontAwesomeIcon icon="bolt" className="mr-2" />
              }
              {activeUserSupplyPointData.supplyType === 'PDR' &&
                <FontAwesomeIcon icon="fire" className="mr-2" />
              }
              Spostamento gruppo di misura
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={12}>
                  <ExtendedSelectField
                    name="requestedPowerShiftDistance"
                    isMulti={false}
                    fieldLabel="Richiesta spostamento*"
                    placeholder="Richiesta spostamento*"
                    options={
                      activeUserSupplyPointData.supplyType === 'POD' ? (
                        podFormValues?.powerShiftDistance || []
                      ) : (
                        pdrFormValues?.powerShiftDistance || []
                      )
                    }
                    isDisabled={canEdit ? false : true}
                    validate={required}
                    defaultValue={activeUserSupplyPointData?.requestedPowerShiftDistance || null}
                  />
                </Col>
                {activeUserSupplyPointData.supplyType === 'PDR' &&
                  <>
                    <Col md={12}>
                      <legend className="col-form-label">Potenzialità attuale installata:</legend>
                    </Col>
                    <Col md={6}>
                      <CustomFormField
                        id="actualTotalPower"
                        name="actualTotalPower"
                        type="number"
                        maxLength="80"
                        disabled={canEdit ? false : true}
                        min="0"
                        validate={required}
                        placeholder="Potenzialità attuale"
                        fieldLabel="Potenzialità attuale*"
                        defaultValue={activeUserSupplyPointData?.actualTotalPower || null}
                      />
                    </Col>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="actualTotalPowerMesureUnit"
                        isMulti={false}
                        isClearable
                        fieldLabel="Unità di misura*"
                        placeholder="Unità di misura"
                        validate={required}
                        options={pdrFormValues?.powerMesureUnit || []}
                        isDisabled={canEdit ? false : true}
                        defaultValue={activeUserSupplyPointData?.actualTotalPowerMesureUnit || null}
                      />
                    </Col>
                  </>
                }
              </Row> 
            </div>
          </div>
          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || canEdit === false}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-1" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default MeasureGroupShiftForm