import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectUtilityModal } from '../../redux/salesOffers/salesOffers.selectors'
import { toggleUtilityModalVisibility } from '../../redux/salesOffers/salesOffers.actions'

const SalesOffersModal = ({ utilityModal: { visibility, modalTitle, modalDescription, modalIcon }, toggle }) => (
  <Modal isOpen={visibility} toggle={toggle} className="utility-modal">
    <ModalHeader toggle={toggle}>
      {modalIcon &&
        <FontAwesomeIcon size="lg" icon={modalIcon} className="mr-2" />
      }
      {modalTitle}
    </ModalHeader>
    <ModalBody>
      {modalDescription}
    </ModalBody>
  </Modal>
)

const mapStateToProps = createStructuredSelector({
  utilityModal: selectUtilityModal,
})

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleUtilityModalVisibility())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesOffersModal)
