import _ from 'lodash'

import { getAPIUrl } from '../../../../api'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'

export function isActiveUserEntity(entityName) {
  return (
    entityName === 'deactivation'
  ) ? true : false
}

export function setQueryString(values) {
  let queryParams = []
  _.forEach(values, (value, key) => {
    if(key !== 'statusId') {
      if(key === 'offer') {
        value = _.replace(value, new RegExp('#', 'g'), '')
      }
      queryParams.push(`${key}@=${value}`)
    }
    else {
      queryParams.push(`${key}==${value}`)
    }
  })

  return queryParams
}

export function buildFetchUrl(entityName, itemsPerPage) {
  return `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pods?`
}

export function buildExcelExportUrl(entityName, itemsPerPage) {
  return `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pods/excels?pageSize=${itemsPerPage}`
}
