import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import queryString from 'query-string'

import { withRouter } from '../../components/AppRouter/utils'
import FirebaseAuthPageStyle from './style'
import ResetPassword from '../../components/firebase-auth/ResetPassword/ResetPassword.component'
import { userSetLoginData } from '../../redux/user/user.actions'

const FirebaseAuthPage = ({ router: { location: { search } } }) => {
  const dispatch = useDispatch()
  const { mode, oobCode, continueUrl } = queryString.parse(search)
  
  useEffect(() => {
    if(oobCode) dispatch(userSetLoginData({ oobCode, continueUrl: decodeURIComponent(continueUrl) }))
  }, [oobCode, dispatch, continueUrl])

  return (
    <FirebaseAuthPageStyle className="page">
      <Routes>
        <Route
          path="*"
          element={
            <>
              {!mode && <Navigate to="/" />}
              {mode === 'resetPassword' &&
                <ResetPassword />
              }
            </>
          }
        />
      </Routes>
    </FirebaseAuthPageStyle>
  )
}

export default withRouter(FirebaseAuthPage)
