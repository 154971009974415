import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, Spinner, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { Form, Field } from 'react-final-form'

import NotesList from './NotesList.component'
import CanI from '../auth/CanI/CanI.component'
import LabelledTextInput from '../forms/inputFields/LabelledTextInput/LabelledTextInput.component'
import { required } from '../forms/validations'
import { selectContract, selectIsFetchingData, selectIsSubmitting } from '../../redux/contract/contract.selectors'
import { contractGetNotesStart, contractSubmitNoteStart } from '../../redux/contract/contract.actions'
import { buildFetchUrl } from './utils'

const NotesModal = ({ itemId, itemType, links, notesLength, otherClasses, actionUrl }) => {
  const dispatch = useDispatch()
  const { contractNotes, entityName } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('note')(state))
  const isFetching = useSelector(state => selectIsFetchingData('notes')(state))
  const fetchListApiUrl = buildFetchUrl(entityName)

  const [ visibility, setVisibility ] = useState(false)
  const toggle = () => setVisibility(!visibility)

  useEffect(() => {
    if(visibility === true) dispatch(contractGetNotesStart({ apiUrl: actionUrl }))
  }, [visibility, actionUrl, dispatch])

  const sendMessage = (submitUrl, values) => {
    dispatch(contractSubmitNoteStart({ submitUrl, values, fetchUrl: actionUrl, fetchListApiUrl }))
  }

  return (
    <div
      className={`action-modal${otherClasses ? ` ${otherClasses}` : ''}`}
    >
      <Button title="Note" onClick={toggle} size="sm" color="link" outline className="mx-1">
        <FontAwesomeIcon
          icon="comments"
          className={classnames({ 'active': notesLength === true })}
        />
      </Button>
      <Modal className="form-modal" size="lg" isOpen={visibility} toggle={toggle}>
        <div className={classnames({'loading': isFetching})}>
          {isFetching &&
            <div className="element-loader-container">
              <Spinner color="secondary" />
            </div>
          }
          <ModalHeader toggle={toggle}>
            <FontAwesomeIcon className="mr-2" icon="comments" />
            Elenco delle note
          </ModalHeader>

          <ModalBody className="notes">
            <NotesList
              items={contractNotes}
            />
            <CanI doWhat={"CREATE"} withPermissions={links} entityName={`${itemType}Note`}>
              {({ action }) => (
                <Form
                  onSubmit={values => sendMessage(action, values)}
                  subscription={{
                    values: true
                  }}
                >
                  {({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                      autoComplete="off"
                      onSubmit={event => {
                        handleSubmit(event)
                        form.restart()
                      }}
                    >
                      <Field
                        name="contractId"
                        type="hidden"
                        initialValue={itemId}
                      >
                        {({ input }) => (
                          <Input {...input} />
                        )}
                      </Field>
                      <Field
                        name="description"
                        type="textarea"
                        fieldLabel="Inserisci una nuova nota"
                        validate={required}
                      >
                        {(fieldState) => (
                          <LabelledTextInput {...fieldState} />
                        )}
                      </Field>

                      <div className="text-right">
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          className="ab-button"
                          type="submit"
                        >
                          {isSubmitting &&
                            <Spinner color="light" size="sm" />
                          }
                          {!isSubmitting &&
                            <FontAwesomeIcon icon="paper-plane" className="mr-1" />
                          }
                          {' '}
                          Inserisci
                        </Button>
                      </div>
                    </form>
                  )}
                </Form>
              )}
            </CanI>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default NotesModal
