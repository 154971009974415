import React, { useState } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PrivacyText from './PrivacyText.component'

const PrivacyPolicyModal = () => {
  //Modal state
  const [ modalVisibility, setModalVisibility ] = useState(false)
  const toggleModal = () => setModalVisibility(!modalVisibility)

  return(
    <>
      <label className="d-block mb-2">
        Dichiaro di aver preso visione dell' 
        <Button color="link" className="link-button" onClick={toggleModal} size="sm">
          informativa sulla privacy
        </Button>{' '}.
      </label>

      <Modal className="form-modal privacy-modal" size="lg" backdrop="static" isOpen={modalVisibility} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <FontAwesomeIcon icon="clipboard-check" className="mr-2" />
          NOTA INFORMATIVA PRIVACY
        </ModalHeader>
        <ModalBody>
          <PrivacyText />
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal}>Chiudi</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default PrivacyPolicyModal
