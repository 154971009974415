export const buildPodOptions = items => {
  return items.map((pod, i) => (
    {
      label: `POD-${pod.no}`,
      value: pod.id
    }
  ))
}

export const buildPdrOptions = items => {
  return items.map(pdr => (
    {
      label: `PDR-${pdr.no}`,
      value: pdr.id
    }
  ))
}

export const buildSupplyPointCorrelationOptions = items => {
  return items.map(supplyPoint => (
    {
      ...supplyPoint,
      label: `${supplyPoint.supplyPointType.label.toUpperCase()}-${supplyPoint.label}`,
      value: supplyPoint.value
    }
  ))
}

export function isCorrelatonNeeded(entityName) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}