import React from 'react'
import { Container } from 'reactstrap'
import { useLocation } from 'react-router'

const NoMatchPage = () => {
  const location = useLocation()

  return (
    <div className="page page-404">
      <Container>
        <h3 className="title">
          La pagina <code>{location.pathname}</code> non esiste!
        </h3>
      </Container>
    </div>
  );
}

export default NoMatchPage
