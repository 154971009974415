import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { isUserScoreNeeded, isReportColNeeded } from './utils'
import TdTable from '../../TdTable/TdTable.component'
import CanI from '../../../auth/CanI/CanI.component'
import DeleteItem from '../../../DeleteItem/DeleteItem.component'
import NotesModal from '../../../NotesModal/NotesModal.component'
import ChatModal from '../../../ChatModal/ChatModal.component'
import { selectUserRole } from '../../../../redux/user/user.selectors'
import { selectIsDeleting, selectEntityName } from '../../../../redux/contract/contract.selectors'
import { contractDeleteItemStart } from '../../../../redux/contract/contract.actions'
import { isActiveUserContract, getEntityPath, getContractStatusIcon, getUserScoreLabel } from '../../../../redux/contract/contract.utils'

const ContractTableRows = ({
  id,
  proposalCode,
  contractStatusId,
  contractStatusName,
  customerType,
  customerCode,
  userScore,
  customerName,
  customerEmail,
  userAgentName,
  signingDate,
  links,
  notes,
  insertDate,
  validatedPods,
  totalPods,
  validatedPdrs,
  totalPdrs,
  reports,
  sortParam,
  attachmentsIntegrationStatus,
  attachmentsIntegrationExpirationDate
}) => {
  const isDeleting = useSelector(state => selectIsDeleting(id)(state))
  const entityName = useSelector(selectEntityName)
  const role = useSelector(selectUserRole)

  return(
    <tr key={id} className={`${isDeleting === true ? 'is-deleting' : ''}`}>
      <TdTable 
        activeSortParam={sortParam}
        sortingName="statusId"
        otherClasses={['text-center', 'status']}
      >
        <FontAwesomeIcon size="lg" className="mr-2" icon={getContractStatusIcon(contractStatusId)} title={contractStatusName} />
      </TdTable>  
      <TdTable 
        value={proposalCode}
        activeSortParam={sortParam}
        sortingName="proposalCode"
      />
      {isActiveUserContract(entityName) &&
        <TdTable 
          value={customerCode}
          activeSortParam={sortParam}
          sortingName="customerCode"
        />
      }
      {!isActiveUserContract(entityName) &&
        <TdTable value={customerType} />
      }
      {
        !isActiveUserContract(entityName) &&
        isUserScoreNeeded(role) &&
        <TdTable otherClasses={['score-circle-container']}>
          <span 
            title={getUserScoreLabel(userScore?.passed)}
            className={classnames({
              "score-circle": true,
              "bad": userScore?.passed === false,
              "good": userScore?.passed === true,
              "unknown": userScore?.passed === null || userScore?.passed === undefined
            })}
          />
        </TdTable>
      }
      <TdTable value={customerName} />
      <TdTable 
        value={customerEmail}
        activeSortParam={sortParam}
        sortingName="customerEmail"
        otherClasses={['break-word']}
      />
      <TdTable 
        value={userAgentName}
        activeSortParam={sortParam}
        sortingName="agent"
      />
      <TdTable 
        value={signingDate ? moment(signingDate).format('DD-MM-YYYY') : ""}
        activeSortParam={sortParam}
        sortingName="signingDate"
      />
      <TdTable 
        value={insertDate ? moment(insertDate).format('DD-MM-YYYY') : ""}
        activeSortParam={sortParam}
        sortingName="insertDate"
      />
      <TdTable>
        <span title={`POD validati ${validatedPods} su ${totalPods}`}><strong>POD:</strong> {validatedPods}/{totalPods}</span>
        <br />
        <span title={`PDR validati ${validatedPdrs} su ${totalPdrs}`}><strong>PDR:</strong> {validatedPdrs}/{totalPdrs}</span>
      </TdTable>
      <TdTable otherClasses={['score-circle-container']}>
       <span 
          title={`${attachmentsIntegrationStatus?.label || "Nessuna richiesta di integrazione"}${attachmentsIntegrationExpirationDate ? ", data scadenza richiesta: " + moment(attachmentsIntegrationExpirationDate).format('DD/MM/YYYY') : ""}`}
          className={classnames({
            "score-circle": true,
            "ongoing": parseInt(attachmentsIntegrationStatus?.value) === 1,
            "good": parseInt(attachmentsIntegrationStatus?.value) === 2,
            "bad": parseInt(attachmentsIntegrationStatus?.value) === 3,
            "unknown": attachmentsIntegrationStatus === null || attachmentsIntegrationStatus === undefined
          })}
        />
      </TdTable>
      {isReportColNeeded(role) &&
        <TdTable>
          <div className="communication-actions-container">
            <CanI doWhat={"GET"} withPermissions={links} entityName={`${entityName}Note`}>
              {({ action }) => (
                <NotesModal
                  itemId={id}
                  itemType={entityName}
                  notesLength={notes}
                  actionUrl={action}
                  links={links}
                />
              )}
            </CanI>
          </div>
        </TdTable>
      }
      <TdTable>
        <div className="communication-actions-container">
          <CanI doWhat={"GET"} withPermissions={links} entityName={`${entityName}Report`}>
            {({ action }) => (
              <ChatModal
                itemId={id}
                itemType={entityName}
                reportsLength={reports}
                actionUrl={action}
                links={links}
              />
            )}
          </CanI>
        </div>
      </TdTable>
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          <CanI doWhat={"GET"} withPermissions={links} entityName={entityName}>
            {({ action }) => (
              <Link
                target="_blank"
                title="Vedi dettaglio"
                className="btn btn-outline-secondary btn-sm mx-1"
                to={`/${getEntityPath(entityName)}/${id}/`}
              >
                <FontAwesomeIcon size="sm" icon="eye" />
              </Link>
            )}
          </CanI>
          <CanI doWhat={"DELETE"} withPermissions={links} entityName={entityName}>
            {({ action }) => (
              <DeleteItem
                itemId={id}
                actionUrl={action}
                actionFunction={contractDeleteItemStart}
                isDeleting={isDeleting}
              />
            )}
          </CanI>
        </div>
      </TdTable>
    </tr>
  )
}

export default ContractTableRows
