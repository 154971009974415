import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { Row, Col, Button, FormGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../../../forms/inputFields/CustomFormField/CustomFormField.component'
import CustomSelectField from '../../../forms/inputFields/CustomSelectField/CustomSelectField.component'
import CustomDatePicker from '../../../forms/inputFields/CustomDatePicker/CustomDatePicker.component'
import { selectFormFilters, selectEntityName } from '../../../../redux/contract/contract.selectors'
import { contractUpdateListFilters, contractGetListDataStart, contractUpdateListPage } from '../../../../redux/contract/contract.actions'
import { setQueryString, isActiveUserEntity, isValidatorUser, isResponsibleUser } from './utils'
import { selectUserRole } from '../../../../redux/user/user.selectors'

const ProposalFilters = ({ apiUrl }) => {
  const dispatch = useDispatch()
  const formValues = useSelector(selectFormFilters)
  const entityName = useSelector(selectEntityName)
  const role = useSelector(selectUserRole)

  //Submit filters form
  const onSubmit = async values => {
    const queryParams = setQueryString(values)
    const filters = queryParams.length ? queryParams.join('&') : ""
    dispatch(contractUpdateListFilters(filters))
    //On form submit go back to page 1
    dispatch(contractUpdateListPage(1))
    dispatch(contractGetListDataStart({ apiUrl }))
  }

  //Ref to the form component
  const formRef = useRef()
  useEffect(() => {
    return () => formRef.current.reset()
  }, [entityName])

  return (
    <Form
      onSubmit={onSubmit}
    >
      {({ handleSubmit, form, submitting, pristine, values }) => {
        formRef.current = form

        return (
          <form
            className="filters-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="filter" className="mr-2" />
              Applica filtri
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={3}>
                  <CustomSelectField
                    options={formValues?.statusId || []}
                    id="statusId"
                    name="statusId"
                    type="select"
                    fieldLabel="Status contratto"
                    noValidationMessage={true}
                  />
                </Col>
                <Col md={3}>
                  <CustomFormField
                    id="proposalCode"
                    name="proposalCode"
                    type="text"
                    placeholder="Codice proposta"
                    fieldLabel="Codice proposta"
                    noValidationMessage={true}
                  />
                </Col>
                {isActiveUserEntity(entityName) &&
                  <Col md={3}>
                    <CustomFormField
                      id="customerCode"
                      name="customerCode"
                      type="text"
                      placeholder="Codice cliente"
                      fieldLabel="Codice cliente"
                      noValidationMessage={true}
                    />
                  </Col>
                }
                <Col md={3}>
                  <CustomSelectField
                    options={formValues?.customerType || []}
                    id="customerType"
                    name="customerType"
                    type="select"
                    fieldLabel="Tipologia cliente"
                    noValidationMessage={true}
                  />
                </Col>
                <Col md={3}>
                  <CustomFormField
                    id="customerName"
                    name="customerName"
                    type="text"
                    placeholder="Nome cliente"
                    fieldLabel="Nome cliente"
                    noValidationMessage={true}
                  />
                </Col>
                <Col md={3}>
                  <CustomFormField
                    id="customerEmail"
                    name="customerEmail"
                    type="text"
                    placeholder="Email cliente"
                    fieldLabel="Email cliente"
                    noValidationMessage={true}
                    style={{ textTransform: 'lowercase' }}
                  />
                </Col>
                {(isValidatorUser(role) || isResponsibleUser(role)) &&
                  <>
                    <Col md={3}>
                      <CustomSelectField
                        options={formValues?.role || []}
                        id="roleId"
                        name="roleId"
                        type="select"
                        fieldLabel="Tipologia utente"
                        noValidationMessage={true}
                      />
                    </Col>
                    <Col md={3}>
                      <CustomSelectField
                        options={formValues?.company || []}
                        id="companyId"
                        name="companyId"
                        type="select"
                        fieldLabel="Rete di vendita"
                        noValidationMessage={true}
                      />
                    </Col>
                  </>
                }
                <Col md={3}>
                  <CustomDatePicker
                    id="signingDateStart"
                    name="signingDateStart"
                    type="date"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    placeholder="Data firma da"
                    fieldLabel="Data firma da"
                    adjustDateOnChange
                  />
                </Col>
                <Col md={3}>
                  <CustomDatePicker
                    id="signingDateEnd"
                    name="signingDateEnd"
                    type="date"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    placeholder="Data firma a"
                    fieldLabel="Data firma a"
                    adjustDateOnChange
                  />
                </Col>
                <Col md={3}>
                  <CustomDatePicker
                    id="insertDateStart"
                    name="insertDateStart"
                    type="date"
                    placeholder="Data caricamento da"
                    fieldLabel="Data caricamento da"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    adjustDateOnChange
                  />
                </Col>
                <Col md={3}>
                  <CustomDatePicker
                    id="insertDateEnd"
                    name="insertDateEnd"
                    type="date"
                    placeholder="Data caricamento a"
                    fieldLabel="Data caricamento a"
                    noValidationMessage={true}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    adjustDateOnChange
                  />
                </Col>
                <Col md={3}>
                  <CustomFormField
                    id="agent"
                    name="agent"
                    type="text"
                    placeholder="Nome agente"
                    fieldLabel="Nome agente"
                    noValidationMessage={true}
                  />
                </Col>
                {isValidatorUser(role) &&
                  <Col md={3}>
                    <CustomSelectField
                      options={formValues?.notes || []}
                      id="note"
                      name="note"
                      type="select"
                      fieldLabel="Note presenti"
                      noValidationMessage={true}
                    />
                  </Col>
                }
                <Col md={3}>
                  <CustomSelectField
                    options={formValues?.reports || []}
                    id="report"
                    name="report"
                    type="select"
                    fieldLabel="Comunicazioni"
                    noValidationMessage={true}
                  />
                </Col>
                {!isActiveUserEntity(entityName) &&
                  <Col md={3}>
                    <CustomSelectField
                      options={formValues?.hasReconsideration || []}
                      id="hasReconsideration"
                      name="hasReconsideration"
                      type="select"
                      fieldLabel="Diritto di ripensamento"
                      noValidationMessage={true}
                    />
                  </Col>
                }
                {entityName === 'changeSupplier' &&
                  <Col md={3}>
                    <CustomSelectField
                      options={formValues?.relatedTransfer || []}
                      id="relatedTransfer"
                      name="relatedTransfer"
                      type="select"
                      fieldLabel="Voltura associata"
                      noValidationMessage={true}
                    />
                  </Col>
                }
                {entityName === 'transfer' &&
                  <Col md={3}>
                    <CustomSelectField
                      options={formValues?.relatedChangeSupplier || []}
                      id="relatedChangeSupplier"
                      name="relatedChangeSupplier"
                      type="select"
                      fieldLabel="Cambio fornitore associato"
                      noValidationMessage={true}
                    />
                  </Col>
                }
                <Col md={3}>
                  <CustomSelectField
                    options={formValues?.attachmentsIntegrationStatus || []}
                    id="attachmentsIntegrationStatus"
                    name="attachmentsIntegrationStatus"
                    type="select"
                    fieldLabel="Stato integrazione allegati"
                    noValidationMessage={true}
                  />
                </Col>
              </Row>
              <Row form className="justify-content-end">
                <Col md={3} className="align-self-end">
                  <FormGroup className="filters-submit">
                    <Button
                      id="reset-filters"
                      color="warning"
                      className="mr-1"
                      outline
                      onClick={() => form.reset()}
                    >
                      <FontAwesomeIcon icon="undo" className="mr-2" />
                      Reset
                    </Button>
                    <Button className="ab-button">
                      <FontAwesomeIcon icon="filter" className="mr-2" />
                      Filtra
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      )}}
    </Form>
  )
}

export default ProposalFilters
