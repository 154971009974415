import React, { useState } from 'react'
import { Row, Col, Table, Collapse, Button } from 'reactstrap'

import PotentialityRow from './PotentialityRow.component'
import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import { positiveNumber } from '../validations'

const PdrPotentialityForm = props => {
  const [ isOpen, setIsOpen ] = useState(() => false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <Row form>
        <Col>
          <CustomFormField
            name="totalPower"
            type="number"
            maxLength="20"
            disabled={props.canEdit ? false : true}
            placeholder="Potenzialità totale installata (kW)*"
            fieldLabel="Potenzialità totale installata (kW)*"
            validate={positiveNumber(true)}
            min="0"
            initialValue={props.pdrData?.totalPower || null}
          />
          <div className="text-right">
            <Button
              size="sm"
              className="ml-auto"
              id="toggler"
              color="secondary"
              outline={isOpen ? false : true}
              onClick={toggle}
              style={{
                marginBottom: '1rem'
              }}
            >
              {isOpen ? "Nascondi tabella" : "Calcola"}
            </Button>
          </div>
        </Col>
      </Row>
      <Collapse isOpen={isOpen}>
        <Row form>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>N. apparecchi</th>
                  <th>Tipologia apparecchi</th>
                  <th>Potenzialità (kW)</th>
                  <th>Totale (kW)</th>
                </tr>
              </thead>
              <tbody>
                <PotentialityRow 
                  fieldName="heating"
                  fieldLabel="Caldaia riscaldamento"
                  {...props}
                />
                <PotentialityRow 
                  fieldName="hotWater"
                  fieldLabel="Caldaia con produzione acqua calda sanitaria"
                  {...props}
                />
                <PotentialityRow 
                  fieldName="hob"
                  fieldLabel="Piano cottura"
                  {...props}
                />
                <PotentialityRow 
                  fieldName="hobWithHoven"
                  fieldLabel="Piano cottura con forno a gas"
                  {...props}
                />
                <PotentialityRow 
                  fieldName="hoven"
                  fieldLabel="Forno gas"
                  {...props}
                />
                <PotentialityRow 
                  fieldName="hotAir"
                  fieldLabel="Generatore aria calda"
                  {...props}
                />
                <PotentialityRow 
                  fieldName="otherDevice"
                  fieldLabel="Altro (specificare)"
                  inputRequired
                  {...props}
                />
              </tbody>
            </Table>
          </Col>
        </Row>
      </Collapse>
    </>
  )
}

export default PdrPotentialityForm