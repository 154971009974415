import styled from 'styled-components'

const SupplyPointsListStyle = styled.div`
  .list-container {
    margin-bottom: 5rem;
  }

  .validate-block-all-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 1rem;

    button { 
      margin-left: .5rem;
    }
  }

  .section-title {
    font-size: 26px;
    font-weight: bold;
    color: #337ab7;
    margin: 0;
  }

  .supply-point-container {
    margin-top: 1.5rem;
    padding-left: 1.25rem;
    padding-top: 1rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: .25rem;
      left: 0;
      top: 0;
      background-color: #ffc525;
      height: 100%;
    }
  }
`

export default SupplyPointsListStyle