import moment from 'moment'

export function getFirstDayOfTheMonth() {
  const date = new Date()
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  return firstDay
}

export function getLastDayOfTheMonth() {
  const date = new Date()
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return lastDay
}

export function calculateDaysBeforeNow(days) {
  const dateToCalculate = new Date()
  dateToCalculate.setDate(dateToCalculate.getDate() - days)
  return dateToCalculate
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export function setUpPapercontractSubmitValues({ fileBase64, contractId, fileName, signingDate }) {
  const submitValues = {
    fileBase64,
    contractId,
    userFileName: fileName,
    signingDate: moment(signingDate).format('YYYY-MM-DD')
  }

  return submitValues
}

export function setUpSubmitValues(attachment, { encodedFile, contractId, apiUrl, method }) {
  let submitValues = {
    contractId,
    attachmentTypeId: attachment.attachmentTypeId,
    userFileName: attachment.fileName,
    fileBase64: attachment.id ? null : encodedFile,
    podId: null,
    pdrId: null,
    apiUrl,
    method
  }
  if(attachment.supplyPointId) {
    const supplyPointId = attachment.supplyPointId
    submitValues.podId = supplyPointId.label.indexOf('POD-') !== -1 ? supplyPointId : null
    submitValues.pdrId = supplyPointId.label.indexOf('PDR-') !== -1 ? supplyPointId : null
  }
  return submitValues
}

export function isAttachmentsFormNeeded(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'takeover' ||
    entityName === 'transfer' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}

export function isAlertDescriptionNeeded(entityName) {
  return (
    entityName === 'changeSupplier' ||
    entityName === 'takeover' ||
    entityName === 'transfer' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas'
  ) ? true : false
}