import { createSelector } from 'reselect'

import { AsyncStatusEnum, MAX_LOGIN_ATTEMPTS } from './user.utils'
import { canI } from '../../components/auth/CanI/utils'
import { getEntityPluralName } from '../contract/contract.utils'

export const selectUser = state => state.user

export const selectUtilityModal = createSelector(
  [selectUser],
  user => user.utilityModal
)

export const selectUserData = createSelector(
  [selectUser],
  user => user?.userData || null
)

export const selectUsername = createSelector(
  [selectUserData],
  userData => userData ? userData.email.slice(0, userData.email.indexOf('@')) : null
)

export const selectResetPasswordModalVisibility = createSelector(
  [selectUser],
  user => user.resetPasswordModalVisibility
)

export const selectRefreshUserTokenToastVisibility = createSelector(
  [selectUser],
  user => user.refreshUserTokenToastVisibility
)

export const selectFirebaseUser = createSelector(
  [selectUser],
  user => user.firebaseUser
)

export const selectUserEntities = createSelector(
  [selectUser],
  user => user.userEntities
)

export const selectUserActiveEntities = createSelector(
  [selectUserEntities],
  userEntities => userEntities.filter(({ active }) => active === true)
)

export const selectUserNewCustomerEntities = createSelector(
  [selectUserActiveEntities],
  activeEntities => activeEntities.filter(({ category }) => category === 'newCustomer')
)

export const selectUserOngoingCustomerEntities = createSelector(
  [selectUserActiveEntities],
  activeEntities => activeEntities.filter(({ category }) => category === 'ongoingCustomer')
)

export const selectAsyncStatus = createSelector(
  [selectUser],
  user => user.asyncStatus
)

export const selectSubmitStatus = createSelector(
  [selectUser],
  user => user.submitStatus
)

export const selectIsSubmitting = createSelector(
  [selectSubmitStatus],
  submitStatus => submitStatus === AsyncStatusEnum.LOADING ? true : false
)

export const selectIsLoading = createSelector(
  [selectUserData, selectAsyncStatus],
  (userData, asyncStatus) => 
    userData === null && 
    (
      asyncStatus === AsyncStatusEnum.LOADING || 
      asyncStatus === AsyncStatusEnum.IDLE
    )  ? true : false
)

export const selectIsUserLoggedIn = createSelector(
  [selectAsyncStatus, selectUserData],
  (asyncStatus, userData) => userData ? true : false
)

export const selectUserContracts = createSelector(
  [selectUserEntities],
  userEntities => userEntities.filter(({ type, active }) => type === 'C' && active === true)
)

export const selectUserOt = createSelector(
  [selectUserEntities],
  userEntities => userEntities.filter(({ type, active }) => type === 'OT' && active === true)
)

export const selectUserAvailableNewCustomerEntities = createSelector(
  [selectUserNewCustomerEntities, selectUserData],
  (newCustomerEntities, userData) => {
    const { links } = userData
    const availableEntities = newCustomerEntities.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck ||
      canI(`${item.entityName}PaperDocumentGET`, links).abilityCheck
    ))

    return availableEntities
  }
)

export const selectUserAvailableOngoingCustomerEntities = createSelector(
  [selectUserOngoingCustomerEntities, selectUserData],
  (ongoingCustomerEntities, userData) => {
    const { links } = userData
    const availableEntities = ongoingCustomerEntities.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck ||
      canI(`${item.entityName}PaperDocumentGET`, links).abilityCheck
    ))

    return availableEntities
  }
)

export const selectUserAvailableContracts = createSelector(
  [selectUserContracts, selectUserData],
  (userContracts, userData) => {
    const { links } = userData
    const availableContracts = userContracts.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck
    ))

    return availableContracts
  }
)

export const selectUserAvailableOt = createSelector(
  [selectUserOt, selectUserData],
  (userOt, userData) => {
    const { links } = userData
    const availableOt = userOt.filter(item => (
      canI(`${getEntityPluralName(item.entityName)}GET`, links).abilityCheck ||
      canI(`${item.entityName}CREATE`, links).abilityCheck
    ))

    return availableOt
  }
)

export const selectUserToken = createSelector(
  [selectUserData],
  userData => userData?.accessToken || null
)

export const selectUserRole = createSelector(
  [selectUserData],
  userData => userData.role
)

export const selectUserSignatureTypes = createSelector(
  [selectUser],
  user => user.userSignatureTypes
)

export const selectIsFetchingSignatureTypes = createSelector(
  [selectAsyncStatus, selectUserSignatureTypes],
  (asyncStatus, userSignatureTypes) => asyncStatus === AsyncStatusEnum.LOADING && !userSignatureTypes.length ? true : false
)

export const selectIsResetEmailSent = createSelector(
  [selectResetPasswordModalVisibility, selectSubmitStatus],
  (resetPasswordModalVisibility, submitStatus) => submitStatus === AsyncStatusEnum.SUCCESS && resetPasswordModalVisibility ? true : false
)

export const selectLoginStep = createSelector(
  [selectUser],
  user => user.loginStep
)

export const selectLoginData = createSelector(
  [selectUser],
  user => user.loginData
)

export const selectResetPasswordDone = createSelector(
  [selectLoginData, selectSubmitStatus],
  (loginData, submitStatus) => loginData?.oobCode && submitStatus === AsyncStatusEnum.SUCCESS ? true : false
)

export const selectWayneKnightGifVisibility = createSelector(
  [selectUser],
  user => user.loginAttempts === MAX_LOGIN_ATTEMPTS
)

export const selectUserMaintenanceState = createSelector(
  [selectUser],
  user => user.maintenanceState
)

export const selectCurrentEntity = entityName => createSelector(
  [selectUserEntities],
  userEntities => {
    let currentEntity
    userEntities.forEach(entity => {
      if(entity.entityName === entityName) {
        currentEntity = entity
      }
    })

    return currentEntity
  }
)