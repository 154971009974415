import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withRouter } from '../../AppRouter/utils'
import { selectContract, selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractProposalSubmitFormStart } from '../../../redux/contract/contract.actions'
import { getEntityUriName, ContractStatusMap } from '../../../redux/contract/contract.utils'
import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import ExtendedAutocompleteFormField from '../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import { getAPIUrl } from '../../../api'
import { selectUserData } from '../../../redux/user/user.selectors'
import ScrollToTop from '../../../hooks/navigation/useScrollToTop'
import { canI } from '../../auth/CanI/utils'
import { updateFieldValue, relatedSwitchVisibility, fetchRelatedChangeSupplier } from './utils'
import GeneralData from './GeneralData/GeneralData.component'
import CustomerData from './CustomerData/CustomerData.component'
import OtherFields from './OtherFields/OtherFields.component'
import GeneralConditions from './GeneralConditions/GeneralConditions.component'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.component'

//Focus on firt field with an error
const focusOnError = createDecorator()

const ProposalForm = ({ router: { navigate, location } }) => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector(selectUserData)
  const { entityName, selectedContractId, proposalData } = useSelector(selectContract)
  const formSubmitting = useSelector(state => selectIsSubmitting('proposal')(state))
  const canEdit = proposalData ? canI(`${entityName}UPDATE`, proposalData.links).abilityCheck : true

  //Submit button state
  const onSubmit = values => {
    //Form submit API
    const method = selectedContractId ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}${selectedContractId ? `/${selectedContractId}` : ''}`
    dispatch(contractProposalSubmitFormStart({ apiUrl, values, method, navigate, location }))
  }

  return (
    <div className="loader-container">
      <ScrollToTop />
      <Form
        onSubmit={onSubmit}
        mutators={{updateFieldValue}}
        decorators={[focusOnError]}
        initialValues={{
          commercialCommunication: proposalData?.commercialCommunication?.toString() || null,
          profiling: proposalData?.profiling?.toString() || null,
          thirdPartyCommunication: proposalData?.thirdPartyCommunication?.toString() || null,
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            className="two-cols-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {
              relatedSwitchVisibility(entityName) &&
              proposalData?.relatedChangeSupplier &&
              <div className="grouped">
                <div className="grouped-header">
                  <FontAwesomeIcon icon="exchange-alt" className="mr-2" />
                  Cambio fornitore associato
                </div>
                <div className="grouped-body">
                  <Row form>
                    <Col>
                      <ExtendedAutocompleteFormField
                        name="relatedChangeSupplier"
                        fieldLabel="Per associare un contratto di cambio fornitore inserire il relativo codice proposta"
                        placeholder="Codice cambio fornitore associato*"
                        fetchFunction={fetchRelatedChangeSupplier(accessToken)}
                        isDisabled={parseInt(proposalData?.statusId) >= ContractStatusMap.DONE_STATUS}
                        initialValue={proposalData?.relatedChangeSupplier || null}
                        noOptionsMessage={() => 
                          <span className="autocomplete-suggestion">Indica le prime lettere del codice cambio fornitore per trovarlo nella lista</span>
                        }
                      />
                      <p className="small text-muted">
                        **Attenzione: sono selezionabili solo ed esclusivamente i contratti "Cambio fornitore" <strong>già firmati</strong>.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            }
            <GeneralData 
              canEdit={canEdit}
              values={values}
            />
            <CustomerData 
              canEdit={canEdit}
              values={values}
            />
            <OtherFields 
              canEdit={canEdit}
              values={values}
            />
            <GeneralConditions 
              canEdit={canEdit}
              values={values}
            />
            <PrivacyPolicy 
              canEdit={canEdit}
            />
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="comments" className="mr-2" />
                Riferimento interno
              </div>
              <div className="grouped-body privacy">
                <Row form>
                  <Col md={12}>
                    <CustomFormField
                      id="reference"
                      name="reference"
                      type="textarea"
                      maxLength="512"
                      disabled={canEdit ? false : true}
                      placeholder="Riferimento interno"
                      fieldLabel="Riferimento interno"
                      initialValue={proposalData?.reference || null}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            {canEdit &&
              <div className="text-right">
                <Button
                  type="submit"
                  disabled={formSubmitting}
                >
                  {formSubmitting ? (
                    <Spinner size="sm" color="light" className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon="save" className="mr-2" />
                  )}
                  <span>Salva</span>
                </Button>
              </div>
            }
          </form>
        )}
      </Form>

      
    </div>
  )
}

export default withRouter(ProposalForm)
