import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { Spinner, Table, Container, Row, Col } from 'reactstrap'

import ValidatorFilters from '../ValidatorFilters/ValidatorFilters.component'
import ThTable from '../../ThTable/ThTable.component'
import UserTableRows from '../UserTableRows/UserTableRows.component'
import ListPagination from '../../ListPagination/ListPagination.component'
import { buildFetchUrl } from './utils'
import {
  contractGetListFiltersStart, contractGetListDataStart, contractResetList,
  contractUpdateListSorting
} from '../../../../redux/contract/contract.actions'
import { selectList, selectIsFetchingData, selectItemsListAsArray } from '../../../../redux/contract/contract.selectors'
import {
  LIST_DEFAULT_ITEMS_PER_PAGE as itemsPerPage,
  LIST_DEFAULT_MAX_PAGINATION_NUMBER as maxPaginationNumbers
} from '../../../../pages/utils'

const UserListValidator = () => {
  const dispatch = useDispatch()
  const apiUrl = useMemo(() => buildFetchUrl(itemsPerPage), [])

  //Populate filters and populate list on mount
  useEffect(() => {
    dispatch(contractResetList())
    dispatch(contractUpdateListSorting('sorts=-insertDate'))
    dispatch(contractGetListFiltersStart())
    dispatch(contractGetListDataStart({ apiUrl }))
  }, [dispatch, apiUrl])

  const isFetchingData = useSelector(state => selectIsFetchingData('list')(state))
  const { count } = useSelector(selectList)
  const items = useSelector(selectItemsListAsArray)

  //Build table rows
  const tableRows = items.map((item, i) => {
    return(
      <UserTableRows
        key={i}
        {...item}
      />
    )
  })

  return(
    <Container className="my-5">
      <Row form>
        <Col>
          <ValidatorFilters
            apiUrl={apiUrl}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12}>
          <div className={classnames({'table-container': true, 'loading': isFetchingData})}>
            {isFetchingData &&
              <div className="element-loader-container">
                <Spinner color="secondary" />
              </div>
            }
            <Table responsive>
              <thead>
                <tr>
                  <ThTable label="Nome" />
                  <ThTable label="Codice" />
                  <ThTable label="Username" />
                  <ThTable label="Email" />
                  <ThTable label="Email personale" />
                  <ThTable label="" />
                </tr>
              </thead>
              <tbody>
                {tableRows}

                {!tableRows.length && !isFetchingData &&
                  <tr>
                    <td colSpan="10" className="text-center">
                      <strong>Nessun elemento trovato</strong>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col xs={9}>
          <ListPagination
            totalItems={count}
            maxPaginationNumbers={maxPaginationNumbers}
            itemsPerPage={itemsPerPage}
            apiUrl={apiUrl}
          />
        </Col>
        <Col xs={3}>
          <p className="total-items"><strong>Elementi totali:</strong> {count}</p>
        </Col>
      </Row>
    </Container>
  )
}

export default UserListValidator
