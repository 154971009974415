import { getAPIUrl, fetchData } from '../../../../api'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'

export function isTemporaryConnectionNeeded(entityName) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isPreviousHolderRequired(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe'
  ) ? true : false
}

export function isNewHolderRequired(entityName) {
  return (
    entityName === ''
  ) ? true : false
}

export function isIndustrySectorRequired(entityName){
  return (
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isMeterReadingRequired(entityName) {
  return (
    entityName === 'transfer'
  ) ? true : false
}

export function isNewHolderTitleNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe'
  ) ? true : false
}

export function isAccountHolderAtecoCodeNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe'
  ) ? true : false
}

export function isAnnualConsumptionNeeded(entityName) {
  return (
    entityName === ''
  ) ? true : false
}

export function getCustomerType(requestedCustomerType, contractCustomerType) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

export function isVendorNameNeeded(entityName) {
  return (
    entityName === 'contextualTransferEe'
  ) ? true : false
}

export function isSupplyStartDateNeeded(entityName) {
  return (
    entityName === 'contextualTransferEe'
  ) ? true : false
}

export function isOldAccountHolderPersonTypeNeeded(entityName) {
  return (
    entityName === 'contextualTransferEe'
  ) ? true : false  
}

export function isOldAccountHolderPhoneNeeded(entityName) {
  return (
    entityName === 'contextualTransferEe'
  ) ? true : false  
}

export function isOldAccountHolderClientCodeNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function areFiscalDataRequired(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export const fetchVendors = (entityName, accessToken) => searchedValue => {
  if(searchedValue.length > 1) {
    return fetchData(
      `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pods/vendors?vendorName=${searchedValue}&limit=20`,
      accessToken
    )
    .then(({ data }) => data)
    .catch(error => console.error(error))
  }
}

const CustomerTypes = {
  DOMESTIC_CUSTOMER: 1,
  BUSINESS_CUSTOMER: 2
}

export default CustomerTypes
