export function setUpSubmitValues(entityName, attachment, { encodedFile, contractId, apiUrl, method }) {
  let submitValues = {
    contractId,
    attachmentTypeId: attachment.attachmentTypeId,
    userFileName: attachment.fileName,
    fileBase64: attachment.id ? null : encodedFile,
    podId: null,
    pdrId: null,
    supplyPointCorrelations: null,
    apiUrl,
    method
  }
  submitValues = setSupplyPointValues(entityName, attachment.supplyPointId, attachment.supplyPointCorrelations, submitValues)
  return submitValues
}

function setSupplyPointValues(entityName, supplyPointId, supplyPointCorrelations, submitValues) {
  if(entityName !== 'changeSupplier' && supplyPointId) {
    submitValues.podId = supplyPointId.label.indexOf('POD-') !== -1 ? supplyPointId : null
    submitValues.pdrId = supplyPointId.label.indexOf('PDR-') !== -1 ? supplyPointId : null
  } else if(supplyPointCorrelations?.length > 0){
    submitValues.supplyPointCorrelations = supplyPointCorrelations
  }
  return submitValues
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
