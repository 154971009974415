import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectUserRole, selectUserToken } from '../../redux/user/user.selectors'
import { downloadFile } from './utils'
import { fetchData } from '../../api'
import { UserRoles } from '../../redux/user/user.utils'

const DownloadExcelButton = ({ downloadLink, label }) => {
  const accessToken = useSelector(selectUserToken)
  const userRole = useSelector(selectUserRole)
  const [ fetching, setFetching ] = useState(false)
  //Handle excel download
  const downloadExcel = () => {
    if(
      userRole === UserRoles.USER_ROLE_VERIFIER ||
      userRole === UserRoles.USER_ROLE_ADMIN
    ) {
      setFetching(true)
      fetchData(downloadLink, accessToken)
        .then(({ body64, title }) => downloadFile(body64, title), error => alert(error))
        .finally(() => setFetching(false))
    }
  }

  return(
    <Button
      color="secondary"
      className="attachment-button"
      onClick={downloadExcel}
      title={label}
      disabled={fetching}
      outline
    >
      {fetching ? (
        <Spinner size="sm" className="mr-1" />
      ) : (
        <FontAwesomeIcon icon="file-excel" className="mr-2" />
      )}
      <small>{label}</small>
    </Button>
  )
}

export default DownloadExcelButton
