import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormGroup } from "reactstrap"
import { Field } from "react-final-form"

import PrivacyPolicyModal from "../../PrivacyPolicyModal/PrivacyPolicyModal.component"
import RadioInput from "../../inputFields/RadioInput/RadioInput.component"

const PrivacyPolicy = ({ canEdit }) => {
  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="bullhorn" className="mr-2" />
        Consensi
      </div>
      <div className="grouped-body privacy">
        <div className="mb-3">
          <FormGroup>
            <PrivacyPolicyModal />
          </FormGroup>
        </div>
        <div className="mb-3">
          <FormGroup>
            <p className="mb-2">
              Inoltre,  in  merito  al  trattamento  dei  miei  dati  personali  per  l’invio  di  comunicazioni  
              commerciali  direttamente  da  parte di ABenergie  (marketing diretto – finalità di cui alla lettera e.)    
              e/o da parte di società partner di ABenergie (marketing indiretto – finalità di cui alla lettera f.)
            </p>
          </FormGroup>                  
          <FormGroup check inline>
            <Field
              name="commercialCommunication"
              type="radio"
              fieldLabel="Presto il consenso"
              value="true"
              disabled={canEdit ? false : true}
            >
              {(fieldState) => (
                <RadioInput {...fieldState} />
              )}
            </Field>
          </FormGroup>
          <FormGroup check inline>
            <Field
              name="commercialCommunication"
              type="radio"
              fieldLabel="Nego il conenso"
              value="false"
              noValidationMessage={true}
              disabled={canEdit ? false : true}
            >
              {(fieldState) => (
                <RadioInput {...fieldState} />
              )}
            </Field>
          </FormGroup>
        </div>
        <div className="mb-3">
          <FormGroup>
            <p className="mb-2">
              Inoltre, in merito al trattamento dei miei dati personali per finalità di profilazione generica (profilazione – finalità di cui alla lettera g.) 
            </p>
          </FormGroup> 
          <FormGroup check inline>
            <Field
              name="profiling"
              type="radio"
              fieldLabel="Presto il consenso"
              value="true"
              disabled={canEdit ? false : true}
            >
              {(fieldState) => (
                <RadioInput {...fieldState} />
              )}
            </Field>
          </FormGroup>
          <FormGroup check inline>
            <Field
              name="profiling"
              type="radio"
              fieldLabel="Nego il consenso"
              value="false"
              noValidationMessage={true}
              disabled={canEdit ? false : true}
            >
              {(fieldState) => (
                <RadioInput {...fieldState} />
              )}
            </Field>
          </FormGroup>
        </div>
        <div className="mb-3">
          <FormGroup>
              <p className="mb-2">
                Inoltre,  in  merito  al  trattamento  dei  miei  dati  personali  per  la  comunicazione  a  società  partner  di  ABenergie  per  l’invio di loro comunicazioni commerciali (cessione di dati a terzi – finalità di cui alla lettera h.)
              </p>
            </FormGroup> 
            <FormGroup check inline>
              <Field
                name="thirdPartyCommunication"
                type="radio"
                fieldLabel="Presto il consenso"
                value="true"
                disabled={canEdit ? false : true}
              >
                {(fieldState) => (
                  <RadioInput {...fieldState} />
                )}
              </Field>
            </FormGroup>
            <FormGroup check inline>
              <Field
                name="thirdPartyCommunication"
                type="radio"
                fieldLabel="Nego il consenso"
                value="false"
                noValidationMessage={true}
                disabled={canEdit ? false : true}
              >
                {(fieldState) => (
                  <RadioInput {...fieldState} />
                )}
              </Field>
            </FormGroup>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy