import React from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'

import DropdownActiveCheck from '../DropdownActiveCheck/DropdownActiveCheck.component'
import MenuItem from '../MenuItem/MenuItem.component'
import CanI from '../auth/CanI/CanI.component'
import { contractReset } from '../../redux/contract/contract.actions'
import { getEntityPluralName } from '../../redux/contract/contract.utils'

export function buildNewCustomerMenuItems(contracts, mainLabel) {
  return (
    <>
      {contracts.length ?
        <DropdownActiveCheck searchedValue="nuovi-clienti/">
          {({ className }) => {
            return(
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className={className}>
                  <FontAwesomeIcon icon="file-contract" />{' '}
                  nuovi clienti
                </DropdownToggle>
                <DropdownMenu>
                  <div className="menu-items">
                    {contracts.map((contract, i) =>
                      <MenuItem
                        item={contract}
                        key={i}
                      />
                    )}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }}
        </DropdownActiveCheck>
      :
        null
      }
    </>
  )
}

export function buildOngoingCustomerMenuItems(contracts, mainLabel) {
  return (
    <>
      {contracts.length ?
        <DropdownActiveCheck searchedValue="clienti-attivi/">
          {({ className }) => {
            return(
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className={className}>
                  <FontAwesomeIcon icon="file-contract" />{' '}
                  clienti attivi
                </DropdownToggle>
                <DropdownMenu>
                  <div className="menu-items">
                    {contracts.map((contract, i) =>
                      <MenuItem
                        item={contract}
                        key={i}
                      />
                    )}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }}
        </DropdownActiveCheck>
      :
        null
      }
    </>
  )
}

export function buildContractMenuItems(contracts) {
  return (
    <>
      {contracts.length ?
        <DropdownActiveCheck searchedValue="contratti/">
          {({ className }) => {
            return(
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className={className}>
                  <FontAwesomeIcon icon="file-contract" />{' '}
                  contratti
                </DropdownToggle>
                <DropdownMenu>
                  {contracts.map((contract, i) =>
                    <MenuItem
                      item={contract}
                      key={i}
                    />
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }}
        </DropdownActiveCheck>
      :
        null
      }
    </>
  )
}

export function buildOtMenuItems(ots, links) {
  return (
    <>
      {ots.length ?
        <DropdownActiveCheck searchedValue="operazioni-tecniche/">
          {({ className }) => {
            return(
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className={className}>
                  <FontAwesomeIcon icon="file-contract" />{' '}
                  o.t.
                </DropdownToggle>
                <DropdownMenu>
                  {ots.map((ot, i) =>
                    <MenuItem
                      item={ot}
                      key={i}
                    />
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }}
        </DropdownActiveCheck>
      :
        null
      }
    </>
  )
}

export function buildUserMenuItems(userPermissions, pathname, dispatch) {
  return (
    <CanI doWhat={"GET"} withPermissions={userPermissions} entityName={getEntityPluralName("user")}>
      {({ action }) => (
        <DropdownActiveCheck searchedValue="utenti">
          {({ className }) => (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className={className}>
                <FontAwesomeIcon icon="users-cog" />{' '}
                utenti
              </DropdownToggle>
              <DropdownMenu>
                <div className="dropdown-group">
                  <CanI doWhat={"CREATE"} withPermissions={userPermissions} entityName="user">
                    {({ action }) => (
                      <DropdownItem tag="div">
                        <NavLink 
                          onClick={() => {
                            if(pathname !== `/utenti`) {
                              dispatch(contractReset())}
                            }
                          }
                          end
                          className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                          to="/utenti"
                        >
                            Crea nuovo
                        </NavLink>
                      </DropdownItem>
                    )}
                  </CanI>
                  <CanI doWhat={"CREATE"} withPermissions={userPermissions} entityName="user">
                    {({ action }) => (
                      <DropdownItem tag="div">
                        <NavLink
                          onClick={() => {
                            if(pathname !== `/utenti/creazione-massiva`) {
                              dispatch(contractReset())}
                            }
                          } 
                          end
                          className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                          to="/utenti/creazione-massiva"
                        >
                          Creazione massiva
                        </NavLink>
                      </DropdownItem>
                    )}
                  </CanI>
                  <DropdownItem tag="div">
                    <NavLink 
                      onClick={() => {
                        if(pathname !== `/utenti/all`) {
                          dispatch(contractReset())}
                        }
                      }
                      end
                      className={({ isActive }) => `nav-link active-link${isActive ? " active" : ""}`}
                      to="/utenti/all"
                    >
                      Vedi tutti
                    </NavLink>
                  </DropdownItem>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </DropdownActiveCheck>
      )}
    </CanI>
  )
}
