import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BankAccountForm from '../BankAccountForm/BankAccountForm.component'
import { selectBankAccountDetailModalVisibility } from '../../../../redux/contract/contract.selectors'
import { contractToggleBankAccountDetailModal } from '../../../../redux/contract/contract.actions'

const BankAccountDetailModal = ({ otherClasses }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectBankAccountDetailModalVisibility)
  const toggle = () => dispatch(contractToggleBankAccountDetailModal())

  return (
    <div className={`action-modal${otherClasses ? ` ${otherClasses}` : ''}`}>
      <Modal className="status-modal" size="lg" backdrop="static" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon icon="money-bill-wave" className="mr-2" />
          Dati pagamento
        </ModalHeader>
        <ModalBody>
          <BankAccountForm />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default BankAccountDetailModal
