import { getAPIUrl, fetchData } from '../../../api'
import { UserRoles } from '../../../redux/user/user.utils'

export function fetchElectricUseTypeId(contractTypeId, entityName, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/use-types?OpeningCausal=${entityName}&supplyPointType=ee&ContractType=${contractTypeId}`,
    accessToken
  )
}

export function fetchContractPriceList(entityName, contractId, podId, electricUseType, email, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/offers?FornitureType=E&podId=${podId || ''}&ElectricUseType=${electricUseType}&userEmail=${encodeURIComponent(email)}&openingCausal=${entityName}&contractId=${contractId}`,
    accessToken,
  )
}

export function fetchVatClassList(customerType, entityName, useType, atecoCode, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/available-vats?customerType=${customerType}&openingCausal=${entityName}&useType=${useType}&atecoCode=${atecoCode}&supplyPointType=ee`,
    accessToken
  )
}

export function fetchExciseClassList(customerType, entityName, useType, atecoCode, vatClass, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/available-excise?customerType=${customerType}&openingCausal=${entityName}&useType=${useType}&atecoCode=${atecoCode}&vatClass=${vatClass}&supplyPointType=ee`,
    accessToken
  )
}

export function getCustomerType(requestedCustomerType, contractCustomerType) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

export function isConnectionVoltageIdNeeded(entityName, values) {
  if(
      (
        entityName === "takeover" ||
        entityName === "newActivation"
      ) && parseInt(values.requestType?.value) !== 2
  ) {
    return false
  }
  return true
}

export function getRequestTypeLabel(entityName) {
  switch(entityName) {
    case 'newActivation': 
      return {
        title: "In riferimento al contratto stipulato con ABenergie fa richiesta di nuova attivazione:",
        label: "Condizioni nuova attivazione*"
      }

    case 'newConnection':
      return {
        title: "In riferimento al contratto stipulato con ABenergie fa richiesta di nuova connessione:",
        label: "Condizioni nuova connessione*"
      }

    default:
      return {
        title: "In riferimento al contratto stipulato con ABenergie fa richiesta di subentro:",
        label: "Condizioni di subentro*"
      }
  }
}

export function isNoDisabled(entityName, userRole, values, canEdit) {
  if(
      (
        entityName === 'takeover' || 
        entityName === 'newActivation' ||
        entityName === 'newConnection'
      ) && 
      userRole !== UserRoles.USER_ROLE_VERIFIER
  ) {    
    return (canEdit ? (values?.eneltel ? true : false) : true)
  }
  else {
    return (canEdit ? false : true)
  }
}

export function isEneltelDisabled(userRole, values, canEdit) {
  if(userRole !== UserRoles.USER_ROLE_VERIFIER) {
    return (canEdit ? (values?.no ? true : false) :  true)
  }
  else {
    return (canEdit ? false : true)
  }
}

export function isRequestTypeNeeded(entityName) {
  return (
    entityName === 'takeover' ||
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isEneltelNeeded(entityName) {
  return (
    entityName === 'takeover' ||
    entityName === 'newActivation'
  ) ? true : false
}

export function isPodNoRequired(entityName, values) {
  return (
    values?.eneltel || entityName === 'newConnection'
  ) ? false : true
}

export function isEneltelRequired(entityName, values) {
  return (
    isEneltelNeeded(entityName) &&
    (values?.no || entityName === 'newConnection')
  ) ? false : true
}

export function isUseTypeNeeded(entityName) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}

export function isResidentNeeded(entityName) {
  return (
    entityName === 'changeSupplier'
  ) ? true : false
}

export function isServiceStartDateNeeded(entityName) {
  return (
    entityName === 'newConnection'
  ) ? true : false
}

export function isOriginMarketNeeded(entityName) {
  return (
    entityName === ''
  ) ? true : false
}

export function isCurrentSupplierNeeded(entityName) {
  return (
    entityName === ''
  ) ? true : false
}

export function isSupplyFormNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isResidenceSelfDeclarationNeeded(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isFAttachmentRequired(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}

export function isRealEstateGroupNeeded(entityName) {
  return (
    entityName === 'newActivation' ||
    entityName === 'newConnection'
  ) ? true : false
}

export const DefaultSelectValues = {
  IS_RESIDENT: { value: "1", label: "Sì" },
  IS_NOT_RESIDENT: { value: "2", label: "No" },
  IS_INVOICE_DIFFERENT_EMAIL: { value: "2", label: "No" },
  BILLING_SHIPPING_ADDRESS: { value: "1", label: "No" },
  ELECTRIC_USE_TYPE_ID: { label: "Domestico", value: 1 }
}

const CustomerTypes = {
  DOMESTIC_CUSTOMER: 1,
  BUSINESS_CUSTOMER: 2
}

export default CustomerTypes
