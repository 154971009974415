import styled from 'styled-components'

export const UserMassiveCreationFormStyle = styled.div`
  .grouped-header {
    .step {
      margin-right: .75rem;
      font-weight: 700;
      position: relative;
      color: #fff;
      z-index: 1;
      padding: 0 .25rem;

      &:before {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #b1b1b1;

      }
    }
  }

  .grouped-body {
    font-size: .85rem;
    padding: 1rem .75rem;
  }

  .dropzone-listgroup {
    &.single-file {
      .form-group:not(.m-0) {
        margin-bottom: .75rem !important;
      }
    }
  }
`