import React from 'react'
import { useSelector } from 'react-redux'

import ProposalForm from './ProposalForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingProposalData } from '../../../redux/contract/contract.selectors'

const ProposalFormWithSpinner = WithSpinner(ProposalForm)

const ProposalFormContainer = () => {
  const isFetchingProposalData = useSelector(selectIsFetchingProposalData)

  return (
    <ProposalFormWithSpinner isLoading={isFetchingProposalData} />
  )
}

export default ProposalFormContainer
