import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ContractToastStyle } from './style'
import { toastCountdownCloseStart } from '../../redux/contract/contract.actions'
import { selectUtilityToast } from '../../redux/contract/contract.selectors'

const ContractToast = () => {
  const dispatch = useDispatch()
  const { isOpen, toastColor, toastHeader, toastBody, toastIcon } = useSelector(selectUtilityToast)
  useEffect(() => {
    if(isOpen === true) dispatch(toastCountdownCloseStart())
  }, [isOpen, dispatch])

  return (
    <ContractToastStyle>
      <Toast
        isOpen={isOpen}
        className={toastColor}
      >
        <ToastHeader>
          {toastIcon &&
            <FontAwesomeIcon icon={toastIcon} />
          }
          <span>{toastHeader}</span>
        </ToastHeader>
        <ToastBody>
          {toastBody}
        </ToastBody>
      </Toast>
    </ContractToastStyle>
  )
}

export default ContractToast
