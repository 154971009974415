import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

import { withRouter } from '../../components/AppRouter/utils'
import PageHeader from '../../components/PageHeader/PageHeader.component'
import UserFormContainer from '../../components/forms/UserForm/UserFormContainer.component'
import {
  contractSetEntityName, contractGetItemUserDataStart,
  contractGetFormFieldsValuesStart
} from '../../redux/contract/contract.actions'

const UserPage = ({ router: { params: { userId } } }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(contractSetEntityName('user'))
    dispatch(contractGetFormFieldsValuesStart({ entityName: 'user' }))
    if(userId) dispatch(contractGetItemUserDataStart(userId))
  }, [dispatch, userId])

  return (
    <div className="page">
      <PageHeader pageTitle="Utenti" icon="users-gear" />
      <Container className="loader-container">
        <Row className="justify-content-center">
          <Col xs={12} lg={12}>
            <UserFormContainer />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(UserPage)
