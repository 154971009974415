import { all, call } from 'redux-saga/effects'

import { userSagas } from './user/user.sagas'
import { contractSagas } from './contract/contract.sagas'
import { salesOffersSagas } from './salesOffers/salesOffers.sagas'

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(contractSagas),
    call(salesOffersSagas),
  ])
}
