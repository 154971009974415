import { all, call, takeLatest, takeEvery, put, select, delay } from 'redux-saga/effects'

import ContractTypes from './contract.types'
import * as contractActions from './contract.actions'
import { 
  selectList, selectListUrl, selectContractId, selectEntityName, 
  selectCadastralData,
  selectSupplyPoints
} from '../contract/contract.selectors'
import {
  getFilters, fetchListData, submitDeleteItem, convertArrayToObject,
  fetchReports, submitReport, fetchNotes, submitNote, fetchItemHistory,
  submitStatusUpdate, fetchFormFieldsValues, fetchUserData, submitForm,
  fetchProposalData, appendContractIdToUrl, fetchBankAccountData,
  fetchBankAccountDetailData, fetchCadastralData, fetchCadastralDetail,
  fetchPodData, fetchPdrData, blockAll, validateAll, fetchAttachments, fetchAttachment,
  fetchContractPods, fetchContractPdrs, fetchOtpData, fetchOtpCode, fetchPdfUrl,
  fetchCustomerData, getSupplyPointsByClientCode, getSupplyPointData, fetchActiveUserAttachments,
  setupCustomerData, meshSupplyPoints, activeSupplyPointsFirst, getActiveUserValidateBlockAll,
  isPdrsListNeeded,
  fetchContractSupplyPointscorrelation
} from './contract.utils'
import { abLogin, getUserEntities } from '../user/user.utils'
import { userShowRefreshTokenToastVisibility } from '../user/user.actions'
import { selectFirebaseUser } from '../user/user.selectors'
import { refreshFirebaseToken } from '../../firebase/firebase-utils'
import { errorMessages } from '../../api/utils'

export function* refreshAccessToken() {
  const firebaseUser = yield select(selectFirebaseUser)
  const accessToken = yield call(refreshFirebaseToken, firebaseUser)
  return accessToken
}

export function* initToastCloseCountdown() {
  yield delay(3000)
  yield put(contractActions.toggleUtilityToastVisibility())
}

export function* onToastCountdownCloseStart() {
  yield takeLatest(
    ContractTypes.TOAST_COUNTDOWN_CLOSE_START,
    initToastCloseCountdown
  )
}

export function* getListFilters() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const filters = yield getFilters(accessToken)
    yield put(contractActions.contractGetListFiltersSuccess(filters))
  }
  catch(error) {
    yield put(contractActions.contractGetListFiltersFailure(error))
  }
}

export function* onContractGetListFiltersStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_LIST_FILTERS_START,
    getListFilters
  )
}

export function* getListData({ payload: { apiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const listParams = yield select(selectList)
    let listData = yield call(fetchListData, apiUrl, accessToken, listParams)
    listData.items = yield call(convertArrayToObject, listData.items)
    yield put(contractActions.contractGetListDataSuccess(listData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetListDataFailure(error))
  }
}

export function* onContractGetListDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_LIST_DATA_START,
    getListData
  )
}

export function* deleteItem({ payload: { apiUrl, itemId } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const listParams = yield select(selectList)
    const listUrl = yield select(selectListUrl)
    yield call(submitDeleteItem, apiUrl, accessToken)
    let listData = yield call(fetchListData, listUrl, accessToken, listParams)
    listData.items = yield call(convertArrayToObject, listData.items)
    yield put(contractActions.contractDeleteItemSuccess(listData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractDeleteItemFailure(error))
  }
}

export function* onContractDeleteItemsStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_DELETE_ITEM_START,
    deleteItem
  )
}

export function* getReports({ payload: { apiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const reports = yield call(fetchReports, apiUrl, accessToken)
    yield put(contractActions.contractGetReportsSuccess(reports))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetReportsFailure(error))
  }
}

export function* onContractGetReportsStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_REPORTS_START,
    getReports
  )
}

export function* submitContractReport({ payload: { submitUrl, values, fetchUrl, fetchListApiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const listQueryParams = yield select(selectList)
    yield call(submitReport, submitUrl, accessToken, values)
    const reports = yield call(fetchReports, fetchUrl, accessToken)
    let listData = yield call(fetchListData, fetchListApiUrl, accessToken, listQueryParams)
    listData.items = yield call(convertArrayToObject, listData.items)
    yield put(contractActions.contractSubmitReportSuccess({ reports, items: listData.items }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitReportFailure(error))
  }
}

export function* onContractSubmitReportStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_SUBMIT_REPORT_START,
    submitContractReport
  )
}

export function* getNotes({ payload: { apiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const notes = yield call(fetchNotes, apiUrl, accessToken)
    yield put(contractActions.contractGetNotesSuccess(notes))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetNotesFailure(error))
  }
}

export function* onContractGetNotesStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_NOTES_START,
    getNotes
  )
}

export function* submitContractNote({ payload: { submitUrl, values, fetchUrl, fetchListApiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const listQueryParams = yield select(selectList)
    yield call(submitNote, submitUrl, accessToken, values)
    const notes = yield call(fetchNotes, fetchUrl, accessToken)
    let listData = yield call(fetchListData, fetchListApiUrl, accessToken, listQueryParams)
    listData.items = yield call(convertArrayToObject, listData.items)
    yield put(contractActions.contractSubmitNoteSuccess({ notes, items: listData.items }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitNoteFailure(error))
  }
}

export function* onContractSubmitNoteStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_SUBMIT_NOTE_START,
    submitContractNote
  )
}

export function* getHistoryItemData({ payload: { itemId, entityName, type } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const history = yield call(fetchItemHistory, itemId, entityName, type, accessToken)
    yield put(contractActions.contractGetItemHistoryDataSuccess(history))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetItemHistoryDataFailure(error))
  }
}

export function* onContractGetHistoryItemDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ITEM_HISTORY_DATA_START,
    getHistoryItemData
  )
}

export function* updateItemStatus({ payload: { submitUrl, values, itemId, cadastralDataId = null, supplyType = null } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const stateCadastralData = yield select(selectCadastralData)
    const stateSupplyPoints = yield select(selectSupplyPoints)
    yield call(submitStatusUpdate, submitUrl, accessToken, values)
    if(stateCadastralData && contractId) {
      const { canValidateAll, canBlockAll } = yield call(fetchCadastralData, accessToken, entityName, contractId)
      yield put(contractActions.contractUpdateItemStatusSuccess({
        itemId, statusId: values.status, cadastralDataId, supplyType, canValidateAll, canBlockAll
      }))
    }
    else if(Object.keys(stateSupplyPoints).length) {
      const podSupplyPoints = yield call(getSupplyPointsByClientCode, contractId, entityName, 'pods', accessToken)
      const pdrSupplyPoints = yield call(getSupplyPointsByClientCode, contractId, entityName, 'pdrs', accessToken)
      const validateBlockAll = yield call(getActiveUserValidateBlockAll, contractId, entityName, accessToken)
      yield put(contractActions.contractUpdateItemStatusSuccess({ 
        supplyPoints: { 
          validateAll: validateBlockAll.supplyPoints.validateAll,
          blockAll: validateBlockAll.supplyPoints.blockAll,  
          pods: activeSupplyPointsFirst(podSupplyPoints.items), 
          pdrs: activeSupplyPointsFirst(pdrSupplyPoints.items)
        } 
      }))
    }
    else {
      yield put(contractActions.contractUpdateItemStatusSuccess({ itemId, statusId: values.status, cadastralDataId, supplyType }))
    }
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractUpdateItemStatusFailure(error))
  }
}

export function* onContractUpdateItemStatusStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_UPDATE_ITEM_STATUS_START,
    updateItemStatus
  )
}

export function* getFormFieldsValues({ payload: { entityName } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const fieldsValues = yield call(fetchFormFieldsValues, accessToken, entityName)
    yield put(contractActions.contractGetFormFieldsValuesSuccess(fieldsValues))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetFormFieldsValuesFailure(error))
  }
}

export function* onContractGetFormFieldsValuesStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_FORM_FIELDS_VALUES_START,
    getFormFieldsValues
  )
}

export function* getUserData() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const userId = yield select(selectContractId)
    const userData = yield call(fetchUserData, accessToken, userId)
    yield put(contractActions.contractGetItemUserDataSuccess(userData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetItemUserDataFailure(error))
  }
}

export function* onContractGetItemUserDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ITEM_USER_DATA_START,
    getUserData
  )
}

export function* submitContractForm({ payload: { apiUrl, values, method, navigate, location } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const response = yield call(submitForm, apiUrl, accessToken, values, method)
    if(method === 'POST') yield call(appendContractIdToUrl, navigate, location, response.id)
    const userData = yield call(fetchUserData, accessToken, response.id)
    const [ loggedInUser, userEntities ] = yield all([
      call(abLogin, accessToken),
      call(getUserEntities, accessToken)
    ])
    yield put(contractActions.contractUserSubmitFormSuccess({ userData, loggedInUser, userEntities }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractUserSubmitFormFailure(error))
  }
}

export function* onContractUserSubmitFormStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_USER_SUBMIT_FORM_START,
    submitContractForm
  )
}

export function* getCustomerData({ payload: { customerCode } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const customerData = yield call(fetchCustomerData, accessToken, customerCode)
    yield put(contractActions.contractGetCustomerDataSuccess(customerData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetCustomerDataFailure(error))
  }
}

export function* onContractGetCustomerDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_CUSTOMER_DATA_START,
    getCustomerData
  )
}

export function* getActiveUserItemProposalData() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, contractId)
    const customerData = yield call(setupCustomerData, proposalData)
    yield put(contractActions.contractGetActiveUserItemProposalDataSuccess({ proposalData, customerData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetActiveUserItemProposalDataFailure(error))
  }
}

export function* onContractGetActiveUserItemProposalDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_START,
    getActiveUserItemProposalData
  )
}

export function* getItemProposalData() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, contractId)
    yield put(contractActions.contractGetItemProposalDataSuccess(proposalData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetItemProposalDataFailure(error))
  }
}

export function* onContractGetItemProposalDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ITEM_PROPOSAL_DATA_START,
    getItemProposalData
  )
}

export function* submitProposal({ payload: { apiUrl, values, method, navigate, location } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const response = yield call(submitForm, apiUrl, accessToken, values, method)
    const entityName = yield select(selectEntityName)
    //If is POST submit updates the url appending the contractId
    if(method === 'POST') {
      yield call(appendContractIdToUrl, navigate, location, response.id)
      yield put(contractActions.contractProposalSubmitFormSuccess())
    }
    else {
      const proposalData = yield call(fetchProposalData, accessToken, entityName, response.id)
      yield put(contractActions.contractProposalSubmitFormSuccess(proposalData))
    }
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractProposalSubmitFormFailure(error))
  }
}

export function* onContractProposalSubmitFormStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_PROPOSAL_SUBMIT_FORM_START,
    submitProposal
  )
}

export function* getItemBankAccountData() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const bankAccountData = yield call(fetchBankAccountData, accessToken, entityName, contractId)
    bankAccountData.items = yield call(convertArrayToObject, bankAccountData.items)
    yield put(contractActions.contractGetItemBankAccountDataSuccess(bankAccountData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetItemBankAccountDataFailure(error))
  }
}

export function* onContractGetItemBankAccountDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_START,
    getItemBankAccountData
  )
}

export function* getItemBankAccountDetail({ payload: { bankAccountDetailId } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const bankAccountDetailData = yield call(fetchBankAccountDetailData, accessToken, entityName, bankAccountDetailId, contractId)
    yield put(contractActions.contractGetItemBankAccountDetailSuccess(bankAccountDetailData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetItemBankAccountDetailFailure(error))
  }
}

export function* onContractGetItemBankAccountDetailStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_START,
    getItemBankAccountDetail
  )
}

export function* submitBankAccount({ payload: { apiUrl, values, method } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(submitForm, apiUrl, accessToken, values, method)
    const bankAccountData = yield call(fetchBankAccountData, accessToken, entityName, selectedContractId)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractBankAccountSubmitFormSuccess({ proposalData, bankAccountData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractBankAccountSubmitFormFailure(error))
  }
}

export function* onContractBankAccountSubmitFormStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_START,
    submitBankAccount
  )
}

export function* getCadastralData() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, contractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    const bankAccountData = yield call(fetchBankAccountData, accessToken, entityName, contractId)
    bankAccountData.items = yield call(convertArrayToObject, bankAccountData.items)
    yield put(contractActions.contractGetCadastralDataSuccess({ cadastralData, bankAccountData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetCadastralDataFailure(error))
  }
}

export function* onContractGetCadastralDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_CADASTRAL_DATA_START,
    getCadastralData
  )
}

export function* submitCadastralData({ payload: { apiUrl, values, method } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(submitForm, apiUrl, accessToken, values, method)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, selectedContractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractCadastralSubmitFormSuccess({ proposalData, cadastralData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractCadastralSubmitFormFailure(error))
  }
}

export function* onContractCadastralSubmitFormStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_CADASTRAL_SUBMIT_FORM_START,
    submitCadastralData
  )
}

export function* getCadastralDetail({ payload: { cadastralDetailId } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const cadastralDetail = yield call(fetchCadastralDetail, accessToken, entityName, cadastralDetailId, contractId)
    yield put(contractActions.contractGetCadastralDetailSuccess(cadastralDetail))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetCadastralDetailFailure(error))
  }
}

export function* onContractGetCadastralDetailStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_CADASTRAL_DETAIL_START,
    getCadastralDetail
  )
}

export function* deleteCadastralData({ payload: { apiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(submitDeleteItem, apiUrl, accessToken)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, selectedContractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractDeleteCadastralDataSuccess({ proposalData, cadastralData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractDeleteCadastralDataFailure(error))
  }
}

export function* onContractDeleteCadastralDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_DELETE_CADASTRAL_DATA_START,
    deleteCadastralData
  )
}

export function* submitPod({ payload: { apiUrl, values, method } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(submitForm, apiUrl, accessToken, values, method)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, selectedContractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractPodSubmitFormSuccess({ cadastralData, proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractPodSubmitFormFailure(error))
  }
}

export function* onContractPodSubmitFormStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_POD_SUBMIT_FORM_START,
    submitPod
  )
}

export function* getPodData({ payload: { podId } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const podData = yield call(fetchPodData, accessToken, entityName, podId)
    yield put(contractActions.contractGetPodDataSuccess(podData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetPodDataFailure(error))
  }
}

export function* onContractGetPodDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_POD_DATA_START,
    getPodData
  )
}

export function* deletePod({ payload: { apiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    yield call(submitDeleteItem, apiUrl, accessToken)
    const contractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, contractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractDeletePodSuccess({ cadastralData, proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractDeletePodFailure(error))
  }
}

export function* onContractDeletePodStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_DELETE_POD_START,
    deletePod
  )
}

export function* getPdrData({ payload: { pdrId } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const pdrData = yield call(fetchPdrData, accessToken, entityName, pdrId)
    yield put(contractActions.contractGetPdrDataSuccess(pdrData))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetPdrDataFailure(error))
  }
}

export function* onContractGetPdrDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_PDR_DATA_START,
    getPdrData
  )
}

export function* submitPdr({ payload: { apiUrl, values, method } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(submitForm, apiUrl, accessToken, values, method)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, selectedContractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractPdrSubmitFormSuccess({ cadastralData, proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractPdrSubmitFormFailure(error))
  }
}

export function* onContractPdrSubmitFormStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_PDR_SUBMIT_FORM_START,
    submitPdr
  )
}

export function* deletePdr({ payload: { apiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    yield call(submitDeleteItem, apiUrl, accessToken)
    const entityName = yield select(selectEntityName)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, selectedContractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractDeletePdrSuccess({ cadastralData, proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractDeletePdrFailure(error))
  }
}

export function* onContractDeletePdrStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_DELETE_PDR_START,
    deletePdr
  )
}

export function* deleteActiveUserSupplyPoint({ payload: { apiUrl } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    yield call(submitDeleteItem, apiUrl, accessToken)
    const entityName = yield select(selectEntityName)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    const podSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pods', accessToken)
    const pdrSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pdrs', accessToken)
    const validateBlockAll = yield call(getActiveUserValidateBlockAll, selectedContractId, entityName, accessToken)
    yield put(contractActions.contractDeleteActiveUserSupplyPointSuccess({ 
      proposalData, 
      supplyPoints: { 
        validateAll: validateBlockAll.supplyPoints.validateAll,
        blockAll: validateBlockAll.supplyPoints.blockAll,
        pods: activeSupplyPointsFirst(podSupplyPoints.items), 
        pdrs: activeSupplyPointsFirst(pdrSupplyPoints.items)
      } 
    }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractDeleteActiveUserSupplyPointFailure(error))
  }
}

export function* onContractDeleteActiveUserSupplyPointStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_START,
    deleteActiveUserSupplyPoint
  )
}

export function* getActiveUserSupplyPointData({ payload }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    if(payload.id) {
      const supplyPointData = yield call(getSupplyPointData, payload, entityName, accessToken)
      yield put(contractActions.contractGetActiveUserSupplyPointDataSuccess(supplyPointData))
    }
    else {
      yield put(contractActions.contractGetActiveUserSupplyPointDataSuccess({ ...payload }))
    }
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetActiveUserSupplyPointDataFailure(error))
  }
}

export function* onContractGetActiveUserSupplyPointDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_START,
    getActiveUserSupplyPointData
  )
} 

export function* submitActiveUserSupplyPointData({ payload: { apiUrl, values, method } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    yield call(submitForm, apiUrl, accessToken, values, method)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    const podSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pods', accessToken)
    const pdrSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pdrs', accessToken)
    const validateBlockAll = yield call(getActiveUserValidateBlockAll, selectedContractId, entityName, accessToken)
    yield put(contractActions.contractSubmitActiveUserSupplyPointDataSuccess({ 
      proposalData, 
      supplyPoints: { 
        validateAll: validateBlockAll.supplyPoints.validateAll,
        blockAll: validateBlockAll.supplyPoints.blockAll,
        pods: activeSupplyPointsFirst(podSupplyPoints.items), 
        pdrs: activeSupplyPointsFirst(pdrSupplyPoints.items) 
      }  
    }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitActiveUserSupplyPointDataFailure(error))
  }
}

export function* onContractSubmitActiveUserSupplyPointDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_START,
    submitActiveUserSupplyPointData
  )
}

export function* fetchSupplyPointsMeshed(contractId, entityName, accessToken) {
  const supplyPoints = yield all([
    call(getSupplyPointsByClientCode, contractId, entityName, 'pods', accessToken),
    call(getSupplyPointsByClientCode, contractId, entityName, 'pdrs', accessToken)
  ])
  const meshedSupplyPoints = yield meshSupplyPoints(supplyPoints, true)
  return meshedSupplyPoints
}

export function* getActiveUserSupplyPoints() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    const podSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pods', accessToken)
    const pdrSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pdrs', accessToken)
    const validateBlockAll = yield call(getActiveUserValidateBlockAll, selectedContractId, entityName, accessToken)
    yield put(contractActions.contractGetActiveUserSupplyPointsSuccess({ 
      supplyPoints: { 
        validateAll: validateBlockAll.supplyPoints.validateAll,
        blockAll: validateBlockAll.supplyPoints.blockAll,
        pods: activeSupplyPointsFirst(podSupplyPoints.items), 
        pdrs: activeSupplyPointsFirst(pdrSupplyPoints.items) 
      } 
    }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetActiveUserSupplyPointsFailure(error))
  }
}

export function* onContractGetSupplyPointsStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_START,
    getActiveUserSupplyPoints
  )
}

export function* blockAllSupplyPoints() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(blockAll, accessToken, entityName, selectedContractId)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, selectedContractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    yield put(contractActions.contractBlockAllSupplyPointsSuccess({ cadastralData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractBlockAllSupplyPointsFailure(error))
  }
}

export function* onContractBlockAllSupplyPointStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_BLOCK_ALL_SUPPLY_POINTS_START,
    blockAllSupplyPoints
  )
}

export function* validateAllSupplyPoints() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(validateAll, accessToken, entityName, selectedContractId)
    const cadastralData = yield call(fetchCadastralData, accessToken, entityName, selectedContractId)
    cadastralData.items = yield call(convertArrayToObject, cadastralData.items)
    yield put(contractActions.contractValidateAllSupplyPointsSuccess({ cadastralData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractValidateAllSupplyPointsFailure(error))
  }
}

export function* onContractValidateAllSupplyPointStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_START,
    validateAllSupplyPoints
  )
}

export function* validateAllActiveUserSupplyPoints() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(validateAll, accessToken, entityName, selectedContractId)
    const validateBlockAll = yield call(getActiveUserValidateBlockAll, selectedContractId, entityName, accessToken)
    const podSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pods', accessToken)
    const pdrSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pdrs', accessToken)
    yield put(contractActions.contractValidateAllActiveUserSupplyPointsSuccess({ 
      supplyPoints: { 
        validateAll: validateBlockAll.supplyPoints.validateAll,
        blockAll: validateBlockAll.supplyPoints.blockAll,
        pods: activeSupplyPointsFirst(podSupplyPoints.items), 
        pdrs: activeSupplyPointsFirst(pdrSupplyPoints.items) 
      } 
    }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractValidateAllActiveUserSupplyPointsFailure(error))
  }
}

export function* onContractValidateAllActiveUserSupplyPointsStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_START,
    validateAllActiveUserSupplyPoints
  )
}

export function* blockAllActiveUserSupplyPoints() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const selectedContractId = yield select(selectContractId)
    const entityName = yield select(selectEntityName)
    yield call(blockAll, accessToken, entityName, selectedContractId)
    const validateBlockAll = yield call(getActiveUserValidateBlockAll, selectedContractId, entityName, accessToken)
    const podSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pods', accessToken)
    const pdrSupplyPoints = yield call(getSupplyPointsByClientCode, selectedContractId, entityName, 'pdrs', accessToken)
    yield put(contractActions.contractBlockAllActiveUserSupplyPointsSuccess({ 
      supplyPoints: { 
        validateAll: validateBlockAll.supplyPoints.validateAll,
        blockAll: validateBlockAll.supplyPoints.blockAll,
        pods: activeSupplyPointsFirst(podSupplyPoints.items), 
        pdrs: activeSupplyPointsFirst(pdrSupplyPoints.items) 
      } 
    }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractBlockAllActiveUserSupplyPointsFailure(error))
  }
}

export function* onContractBlockAllActiveUserSupplyPointsStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_START,
    blockAllActiveUserSupplyPoints
  )
}

export function* getAttachments() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    const attachmentsData = yield call(fetchAttachments, accessToken, entityName, selectedContractId)
    const attachmentsDataAsObject = yield call(convertArrayToObject, attachmentsData)
    const contractPods = yield call(fetchContractPods, entityName, selectedContractId, accessToken)
    const contractSupplyPointCorrelations = yield call(fetchContractSupplyPointscorrelation, entityName, selectedContractId, accessToken)
    //Some contracts don't need PDRs, check if need to fetch them...
    let contractPdrs = []
    if(isPdrsListNeeded(entityName)) {
      contractPdrs = yield call(fetchContractPdrs, entityName, selectedContractId, accessToken)
    }
    yield put(contractActions.contractGetAttachmentsDataSuccess({ attachmentsDataAsObject, contractSupplyPointCorrelations, contractPods, contractPdrs }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetAttachmentsDataFailure(error))
  }
}

export function* onContractGetAttachmentsDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ATTACHMENTS_DATA_START,
    getAttachments
  )
}

export function* getActiveUserAttachments() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    const activeUserAttachmentsData = yield call(fetchActiveUserAttachments, accessToken, entityName, selectedContractId)
    const activeUserAttachmentsDataAsObject = yield call(convertArrayToObject, activeUserAttachmentsData)
    const attachmentsData = yield call(fetchAttachments, accessToken, entityName, selectedContractId)
    const attachmentsDataAsObject = yield call(convertArrayToObject, attachmentsData)
    const contractSupplyPointCorrelations = yield call(fetchContractSupplyPointscorrelation, entityName, selectedContractId, accessToken)
    const contractPods = yield call(fetchContractPods, entityName, selectedContractId, accessToken)
    //Some contracts don't need PDRs, check if need to fetch them...
    let contractPdrs = []
    if(isPdrsListNeeded(entityName)) {
      contractPdrs = yield call(fetchContractPdrs, entityName, selectedContractId, accessToken)
    }
    yield put(contractActions.contractGetActiveUserAttachmentsSuccess({ 
      activeUserAttachmentsDataAsObject, 
      attachmentsDataAsObject,
      contractSupplyPointCorrelations,
      contractPods,
      contractPdrs
    }))
  }
  catch(error) {
    yield put(contractActions.contractGetActiveUserAttachmentsFailure(error))
  }
}

export function* onContractGetActiveUserAttachmentsStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_START,
    getActiveUserAttachments
  )
}

export function* submitAtthacmentForm({ payload: { values } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const attachmentsIds = yield all(values.map((attachment, i) => call(submitForm, attachment.apiUrl, accessToken, attachment, attachment.method)))
    const attachmentsData = yield all(attachmentsIds.map(({ id }) => call(fetchAttachment, accessToken, entityName, id)))
    const attachmentsDataAsObject = yield call(convertArrayToObject, attachmentsData)
    yield put(contractActions.contractSubmitAttachmentFormSuccess(attachmentsDataAsObject))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitAttachmentFormFailure(error))
  }
}

export function* onContractSubmitAttachmentFormStart() {
  yield takeEvery(
    ContractTypes.CONTRACT_SUBMIT_ATTACHMENT_FORM_START,
    submitAtthacmentForm
  )
}

export function* submitActiveUserAttachments({ payload: { values } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    yield all(values.map(attachment => call(submitForm, attachment.apiUrl, accessToken, attachment)))
    const activeUserAttachmentsData = yield call(fetchActiveUserAttachments, accessToken, entityName, selectedContractId)
    const activeUserAttachmentsDataAsObject = yield call(convertArrayToObject, activeUserAttachmentsData)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractSubmitActiveUserAttachmentsSuccess({ activeUserAttachmentsDataAsObject, proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitActiveUserAttachmentsFailure(error))
  }
}

export function* onContractSubmitActiveUserAttachmentsStart() {
  yield takeEvery(
    ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_START,
    submitActiveUserAttachments
  )
}

export function* deleteAttachment({ payload: { apiUrl, itemId } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    yield call(submitDeleteItem, apiUrl, accessToken)
    yield put(contractActions.contractDeleteAttachmentSuccess(itemId))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractDeleteAttachmentFailure(error))
  }
}

export function* onContractDeleteAttachmentStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_DELETE_ATTACHMENT_START,
    deleteAttachment
  )
}

export function* getOtpData() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    const otpData = yield call(fetchOtpData, entityName, selectedContractId, accessToken)
    otpData.signers = yield call(convertArrayToObject, otpData.signers)
    const attachmentsData = yield call(fetchAttachments, accessToken, entityName, selectedContractId)
    const attachmentsDataAsObject = yield call(convertArrayToObject, attachmentsData)
    const contractSupplyPointCorrelations = yield call(fetchContractSupplyPointscorrelation, entityName, selectedContractId, accessToken)
    const contractPods = yield call(fetchContractPods, entityName, selectedContractId, accessToken)
    //Some contracts don't need PDRs, check if need to fetch them...
    let contractPdrs = []
    if(isPdrsListNeeded(entityName)) {
      contractPdrs = yield call(fetchContractPdrs, entityName, selectedContractId, accessToken)
    }
    yield put(contractActions.contractGetOtpDataSuccess({ otpData, attachmentsDataAsObject, contractSupplyPointCorrelations,  contractPods, contractPdrs }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetOtpDataFailure(error))
  }
}

export function* onContractGetOtpDataStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_OTP_DATA_START,
    getOtpData
  )
}

export function* getOtpCode({ payload: values }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    yield call(fetchOtpCode, entityName, accessToken, values)
    yield put(contractActions.contractGetOtpCodeSuccess())
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetOtpCodeFailure(error))
  }
}

export function* onContractGetOtpCodeStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_OTP_CODE_START,
    getOtpCode
  )
}

export function* submitSignature({ payload: { apiUrl, values } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    yield call(submitForm, apiUrl, accessToken, values)
    const otpData = yield call(fetchOtpData, entityName, selectedContractId, accessToken)
    otpData.signers = yield call(convertArrayToObject, otpData.signers)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractSubmitSignatureSuccess({ otpData, proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitSignatureFailure(error))
  }
}

export function* onContractSubmitSignatureStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_SUBMIT_SIGNATURE_START,
    submitSignature
  )
}

export function* submitPaperSignature({ payload: { paperContract, attachments } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    //Paper contract submit
    yield call(submitForm, paperContract.apiUrl, accessToken, paperContract.values)
    //Other attachments submit
    if(attachments) {
      yield all(attachments.map((attachment, i) => call(submitForm, attachment.apiUrl, accessToken, attachment, attachment.method)))
    }
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractSubmitPaperSignatureSuccess({ proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitPaperSignatureFailure(error))
  }
}

export function* onContractSubmitPaperSignatureStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_SUBMIT_PAPER_SIGNATURE_START,
    submitPaperSignature
  )
}

export function* getPdfUrl() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    const pdfUrl = yield call(fetchPdfUrl, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractGetPdfUrlSuccess(pdfUrl))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractGetPdfUrlFailure(error))
  }
}

export function* onContractGetPdfUrlStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_GET_PDF_URL_START,
    getPdfUrl
  )
}

export function* massiveCreationStart({ payload: { apiUrl, values, resolve, reject } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    yield call(submitForm, apiUrl, accessToken, values)
    yield put(contractActions.contractUserMassiveCreationSuccess())
    resolve()
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractUserMassiveCreationFailure(error))
  }
}

export function* onContractUserMassiveCreationStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_USER_MASSIVE_CREATION_START,
    massiveCreationStart
  )
}

export function* attachmentsIntegrationStart({ payload: { apiUrl, values } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    yield call(submitForm, apiUrl, accessToken, values)
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractSubmitAttachmentsIntegrationSuccess({ proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractSubmitAttachmentsIntegrationFailure(error))
  }
}

export function* onContractAttachmentsIntegrationStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_START,
    attachmentsIntegrationStart
  )
}

export function* closeAttachmentsIntegration({ payload: { apiUrl, contractLink } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const entityName = yield select(selectEntityName)
    const selectedContractId = yield select(selectContractId)
    yield call(submitForm, apiUrl, accessToken, { contractLink })
    const proposalData = yield call(fetchProposalData, accessToken, entityName, selectedContractId)
    yield put(contractActions.contractCloseAttachmentsIntegrationSuccess({ proposalData }))
  }
  catch(error) {
    if(error.message.indexOf(errorMessages.NOT_AUTHORIZED_ERROR) !== -1) {
      yield put(userShowRefreshTokenToastVisibility())
    }
    yield put(contractActions.contractCloseAttachmentsIntegrationFailure(error))
  }
}

export function* onContractCloseAttachmentsIntegrationStart() {
  yield takeLatest(
    ContractTypes.CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_START,
    closeAttachmentsIntegration
  )
}

export function* contractSagas() {
  yield all([
    call(onContractGetListFiltersStart),
    call(onContractGetListDataStart),
    call(onContractDeleteItemsStart),
    call(onContractGetReportsStart),
    call(onContractSubmitReportStart),
    call(onContractGetNotesStart),
    call(onContractSubmitNoteStart),
    call(onContractGetHistoryItemDataStart),
    call(onContractUpdateItemStatusStart),
    call(onContractGetFormFieldsValuesStart),
    call(onContractGetItemUserDataStart),
    call(onContractUserSubmitFormStart),
    call(onToastCountdownCloseStart),
    call(onContractGetActiveUserItemProposalDataStart),
    call(onContractGetItemProposalDataStart),
    call(onContractProposalSubmitFormStart),
    call(onContractGetItemBankAccountDataStart),
    call(onContractBankAccountSubmitFormStart),
    call(onContractGetItemBankAccountDetailStart),
    call(onContractGetCadastralDataStart),
    call(onContractCadastralSubmitFormStart),
    call(onContractGetCadastralDetailStart),
    call(onContractDeleteCadastralDataStart),
    call(onContractPodSubmitFormStart),
    call(onContractGetPodDataStart),
    call(onContractDeletePodStart),
    call(onContractPdrSubmitFormStart),
    call(onContractGetPdrDataStart),
    call(onContractDeletePdrStart),
    call(onContractBlockAllSupplyPointStart),
    call(onContractValidateAllSupplyPointStart),
    call(onContractGetAttachmentsDataStart),
    call(onContractGetActiveUserAttachmentsStart),
    call(onContractSubmitAttachmentFormStart),
    call(onContractDeleteAttachmentStart),
    call(onContractGetOtpDataStart),
    call(onContractGetOtpCodeStart),
    call(onContractSubmitSignatureStart),
    call(onContractSubmitPaperSignatureStart),
    call(onContractGetPdfUrlStart),
    call(onContractUserMassiveCreationStart),
    call(onContractGetCustomerDataStart),
    call(onContractGetSupplyPointsStart),
    call(onContractGetActiveUserSupplyPointDataStart),
    call(onContractSubmitActiveUserSupplyPointDataStart),
    call(onContractDeleteActiveUserSupplyPointStart),
    call(onContractSubmitActiveUserAttachmentsStart),
    call(onContractValidateAllActiveUserSupplyPointsStart),
    call(onContractBlockAllActiveUserSupplyPointsStart),
    call(onContractAttachmentsIntegrationStart),
    call(onContractCloseAttachmentsIntegrationStart),
  ])
}
