import styled from 'styled-components'

const ResetPasswordStyle = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: calc(100vh - 80px);

  .form-container {
    padding: 2rem 1rem;
    margin: 2.5rem;
    border: 3px solid #d8e4f1;
    border-radius: .75rem;
    width: 400px;
    background-color: #fafbfd;

    @media (max-width: 576px) {
      margin: 2.5rem 1rem;
    }
  }

  .login-icon {
    display: flex;
    margin: 0 auto 1.5rem;
    color: #337ab7;
    font-size: 4rem;
    justify-content: center;
  }

  .lead {
    line-height: 1.2;
    font-weight: 600;
    font-size: 1rem;
  }

  .reset-feedback {
    .title {
      font-size: 1rem;
      font-weight: 600;
    }

    .description {
      font-weight: 400;
      font-size: .85rem;
    }
  }
`

export default ResetPasswordStyle
