import React from 'react'
import { useSelector } from 'react-redux'

import UserForm from './UserForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingUserData } from '../../../redux/contract/contract.selectors'

const UserFormWithSpinner = WithSpinner(UserForm)

const UserFormContainer = () => {
  const loadingUserData = useSelector(selectIsFetchingUserData)

  return (
    <UserFormWithSpinner isLoading={loadingUserData} />
  )
}

export default UserFormContainer
