import React from 'react'
import { useSelector } from 'react-redux'

import { selectCadastralData } from '../../../../redux/contract/contract.selectors'
import CadastralValidateAllModal from '../../CadastralValidateAllModal/CadastralValidateAllModal.component'
import CadastralBlockAllModal from '../../CadastralBlockAllModal/CadastralBlockAllModal.component'

const CadastralDashboardHeader = () => {
  const cadastralData = useSelector(selectCadastralData)

  return(
    <div className="cadastral-header">
      <h1 className="title">Elenco dei punti di fornitura</h1>

      <div className="buttons-container">
        {cadastralData && cadastralData.canBlockAll === true &&
          <CadastralBlockAllModal />
        }
        {cadastralData && cadastralData.canValidateAll === true &&
          <CadastralValidateAllModal />
        }
      </div>
    </div>
  )
}

export default CadastralDashboardHeader
