import UserTypes from './user.types'

export const toggleUtilityModalVisibility = () => ({
  type: UserTypes.TOGGLE_USER_UTILITY_MODAL_VISIBILITY
})

export const userToggleResetPasswordModalVisibility = () => ({
  type: UserTypes.USER_TOGGLE_RESET_PASSWORD_MODAL_VISIBILITY
})

export const userChangeLoginStep = data => ({
  type: UserTypes.USER_CHANGE_LOGIN_STEP,
  payload: data
})

export const userShowRefreshTokenToastVisibility = () => ({
  type: UserTypes.USER_SHOW_REFRESH_TOKEN_TOAST_VISIBILITY
})

export const userSetLoginData = data => ({
  type: UserTypes.USER_SET_LOGIN_DATA,
  payload: data
})

export const userCheckEmailStart = data => ({
  type: UserTypes.USER_CHECK_EMAIL_START,
  payload: data
})

export const userCheckEmailSuccess = data => ({
  type: UserTypes.USER_CHECK_EMAIL_SUCCESS,
  payload: data
})

export const userCheckEmailMustChangePassword = data => ({
  type: UserTypes.USER_CHECK_EMAIL_MUST_CHANGE_PASSWORD,
  payload: data
})

export const userCheckEmailFailure = error => ({
  type: UserTypes.USER_CHECK_EMAIL_FAILURE,
  payload: error
})

export const userLoginStart = data => ({
  type: UserTypes.USER_LOGIN_START,
  payload: data
})

export const userLoginSuccess = data => ({
  type: UserTypes.USER_LOGIN_SUCCESS,
  payload: data
})

export const userLoginFailure = error => ({
  type: UserTypes.USER_LOGIN_FAILURE,
  payload: error
})

export const userLogoutStart = () => ({
  type: UserTypes.USER_LOGOUT_START
})

export const userLogoutSuccess = () => ({
  type: UserTypes.USER_LOGOUT_SUCCESS
})

export const userLogoutFailure = error => ({
  type: UserTypes.USER_LOGOUT_FAILURE,
  payload: error
})

export const userSendResetPasswordEmailStart = () => ({
  type: UserTypes.USER_SEND_RESET_PASSWORD_EMAIL_START
})

export const userSendResetPasswordEmailSuccess = () => ({
  type: UserTypes.USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS
})

export const userSendResetPasswordEmailFailure = error => ({
  type: UserTypes.USER_SEND_RESET_PASSWORD_EMAIL_FAILURE,
  payload: error
})

export const userSetNewPasswordStart = data => ({
  type: UserTypes.USER_SET_NEW_PASSWORD_START,
  payload: data
})

export const userSetNewPasswordSuccess = () => ({
  type: UserTypes.USER_SET_NEW_PASSWORD_SUCCESS
})

export const userSetNewPasswordFailure = error => ({
  type: UserTypes.USER_SET_NEW_PASSWORD_FAILURE,
  payload: error
})

export const userCheckSessionStart = () => ({
  type: UserTypes.USER_CHECK_SESSION_START
})

export const userCheckSessionSuccess = data => ({
  type: UserTypes.USER_CHECK_SESSION_SUCCESS,
  payload: data
})

export const userCheckSessionNoUser = () => ({
  type: UserTypes.USER_CHECK_SESSION_NO_USER
})

export const userCheckSessionFailure = error => ({
  type: UserTypes.USER_CHECK_SESSION_FAILURE,
  payload: error
})

export const userRefreshTokenStart = () => ({
  type: UserTypes.USER_REFRESH_TOKEN_START
})

export const userRefreshTokenSuccess = data => ({
  type: UserTypes.USER_REFRESH_TOKEN_SUCCESS,
  payload: data
})

export const userRefreshTokenFailure = error => ({
  type: UserTypes.USER_REFRESH_TOKEN_FAILURE,
  payload: error
})

export const userGetActiveEntitiesStart = () => ({
  type: UserTypes.USER_GET_ACTIVE_ENTITIES_START
})

export const userGetActiveEntitiesSuccess = data => ({
  type: UserTypes.USER_GET_ACTIVE_ENTITIES_SUCCESS,
  payload: data
})

export const userGetActiveEntitiesFailure = error => ({
  type: UserTypes.USER_GET_ACTIVE_ENTITIES_FAILURE,
  payload: error
})

export const userGetSignatureTypesStart = data => ({
  type: UserTypes.USER_GET_SIGNATURE_TYPES_START,
  payload: data
})

export const userGetSignatureTypesSuccess = data => ({
  type: UserTypes.USER_GET_SIGNATURE_TYPES_SUCCESS,
  payload: data
})

export const userGetSignatureTypesFailure= error => ({
  type: UserTypes.USER_GET_SIGNATURE_TYPES_FAILURE,
  payload: error
})

export const userUpdateMaintenanceState = data => ({
  type: UserTypes.USER_UPDATE_MAINTENANCE_STATE,
  payload: data
})