import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PageHeader from '../../components/PageHeader/PageHeader.component'
import UserListValidator from '../../components/lists/user/UserListValidator/UserListValidator.component'
import ScrollToTop from '../../hooks/navigation/useScrollToTop'
import { contractSetEntityName } from '../../redux/contract/contract.actions'

const TransferList = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(contractSetEntityName('user'))
  }, [dispatch])

  return (
    <div className="page">
      <ScrollToTop />
      <PageHeader pageTitle="Utenti" icon="list" />
      <UserListValidator />
    </div>
  )
}

export default TransferList
