import React from 'react'
import { ListGroupItem } from 'reactstrap'
import moment from 'moment-timezone'

function Communication({ sender, recipient, message, sendingDate }) {
  return(
    <ListGroupItem>
      <p>
        Messaggio inviato il <strong>{ moment.utc(sendingDate).tz('Europe/Rome').format('DD-MM-YYYY HH:mm') }</strong>
        <br />
        da <strong>{sender}</strong>
        <br />
        a <strong>{recipient}</strong>
      </p>
      <blockquote>
        "{message}"
      </blockquote>
    </ListGroupItem>
  )
}

export default Communication
