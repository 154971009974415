import { Field } from "react-final-form"

import { ErrorFieldStyle } from "./style"

const ErrorField = ({ name }) => (
  <ErrorFieldStyle>
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span>{error}</span> : null
      }
    />
  </ErrorFieldStyle>
)

export default ErrorField