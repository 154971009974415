function buildExcelFile(file64) {
  //Convert base64 string to byte array and then to blob
  const byteCharacters = atob(file64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return byteArray
}

export function downloadFile(body64, title) {
  const byteArray = buildExcelFile(body64)
  //Build a link and trigger click on it to download the file
  const a = window.document.createElement('a')
  a.href = window.URL.createObjectURL(new Blob([byteArray], { type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
  a.download = title
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
