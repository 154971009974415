import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAPIUrl, fetchData } from '../../../../api'
import { contractShowErrorMessage } from '../../../../redux/contract/contract.actions'
import { 
  selectActiveUserSupplyPointModalvisibility, selectContract 
} from '../../../../redux/contract/contract.selectors'
import { selectUserData } from '../../../../redux/user/user.selectors'

export function fetchElectricContractPriceList(entityName, contractId, podId, electricUseType, email, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/offers?FornitureType=E&podId=${podId || ''}&ElectricUseType=${electricUseType}&userEmail=${encodeURIComponent(email)}&openingCausal=${entityName}&contractId=${contractId}`,
    accessToken
  )
}

export function fetchGasContractPriceList(entityName, contractId, pdrId, gasUseTypeId, logicalPoint, email, accessToken) {
  return fetchData(
    `${getAPIUrl('abStorePortal')}/form-items/offers?FornitureType=G&pdrId=${pdrId || ''}&GasUseType=${gasUseTypeId}&remiCode=${logicalPoint}&userEmail=${encodeURIComponent(email)}&openingCausal=${entityName}&contractId=${contractId}`,
    accessToken
  )
}

export function useFetchContractPriceListOnMount() {
  const dispatch = useDispatch()
  const [ contractPriceList, setContractPriceList ] = useState([])
  const { email, accessToken } = useSelector(selectUserData)
  const { entityName, activeUserSupplyPointData, selectedContractId } = useSelector(selectContract)
  const modalVisibility = useSelector(selectActiveUserSupplyPointModalvisibility)

  useEffect(() => {
    if(
      modalVisibility && 
      activeUserSupplyPointData && 
      activeUserSupplyPointData.electricUseTypeId
    ) {
      if(activeUserSupplyPointData.supplyType === 'POD') {
        fetchElectricContractPriceList(
          entityName, 
          selectedContractId, 
          activeUserSupplyPointData.id, 
          activeUserSupplyPointData.electricUseTypeId.value, 
          email, 
          accessToken
        )
          .then(response => {
            setContractPriceList(response.data)
          })
          .catch(error => dispatch(contractShowErrorMessage({ message: error.message })))
      }
      else {
        fetchGasContractPriceList(
          entityName, 
          selectedContractId, 
          activeUserSupplyPointData.id, 
          activeUserSupplyPointData.gasUseTypeId.value, 
          activeUserSupplyPointData.logicalPoint?.value,
          email, 
          accessToken
        )
          .then(response => {
            setContractPriceList(response.data)
          })
          .catch(error => dispatch(contractShowErrorMessage({ message: error.message })))
      }
    }
  }, [
    dispatch, 
    email, 
    accessToken, 
    entityName, 
    activeUserSupplyPointData,
    modalVisibility,
    selectedContractId
  ])

  return [ contractPriceList, setContractPriceList ]
}