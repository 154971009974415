import React from 'react'
import { useSelector } from 'react-redux'
import { Toast, ToastBody, ToastHeader, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ContractToastStyle } from './style'
import { selectRefreshUserTokenToastVisibility } from '../../redux/user/user.selectors'

const RefreshUserTokenToast = () => {
  const isOpen = useSelector(selectRefreshUserTokenToastVisibility)

  const refreshPage = () => window.location.reload()

  return (
    <ContractToastStyle>
      <Toast
        isOpen={isOpen}
        className="danger"
      >
        <ToastHeader>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className="mr-2"
            size="sm"
          />
          <span>Attenzione!</span>
        </ToastHeader>
        <ToastBody>
          La sessione utente è scaduta.<br />
          È necessario ricaricare la pagina!<br />
          <Button
            className="mt-3"
            color="danger"
            onClick={refreshPage}
          >
            Ricarica
          </Button>
        </ToastBody>
      </Toast>
    </ContractToastStyle>
  )
}

export default RefreshUserTokenToast
