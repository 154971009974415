import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner, Button } from "reactstrap"

import SupplyPointItemStyle from './style'
import CanI from "../../../auth/CanI/CanI.component"
import DeleteItem from "../../../DeleteItem/DeleteItem.component"
import SupplyPointStatusHistoryModal from "../../../SupplyPointStatusHistoryModal/SupplyPointStatusHistoryModal.component"
import SupplyPointStatusUpdate from "../../../SupplyPointStatusUpdate/SupplyPointStatusUpdate.component"
import { contractGetActiveUserSupplyPointDataStart, contractDeleteActiveUserSupplyPointStart } from "../../../../redux/contract/contract.actions"
import { selectIsFetchingData, selectContract, selectIsDeleting, selectContractStatusId } from "../../../../redux/contract/contract.selectors"
import { getSupplyPointStatusIcon, getItemStatusLabel, ContractStatusMap } from '../../../../redux/contract/contract.utils'
import { UserRoles } from '../../../../redux/user/user.utils'
import { selectUserRole } from "../../../../redux/user/user.selectors"
import { getSupplyPointIcon, capitalizeWord } from './utils'

const SupplyPointItem = ({ item }) => {
  const dispatch = useDispatch()
  const { entityName } = useSelector(selectContract)
  const role = useSelector(selectUserRole)
  const isFetchingSupplyPointTechOpData = useSelector(state => selectIsFetchingData(`supplyPointTechOp-${item.id}`)(state))
  const isDeleting = useSelector(state => selectIsDeleting(`supplyPointTechOp-${item.id}`)(state))
  const contractStatus = useSelector(selectContractStatusId)

  return (
    <SupplyPointItemStyle>
      <div className="text-container">
        <FontAwesomeIcon 
          icon={getSupplyPointIcon(item.supplyType)}
          className="mr-2"
        />
        {item.supplyType.toUpperCase()} - {item.supplyNo}
        <div className="more-info">
          {!!item.statusId &&
            <FontAwesomeIcon
              className="mr-2 active-user-supply-status-icon"
              icon={getSupplyPointStatusIcon(item.statusId)}
              title={getItemStatusLabel(item.statusId)}
            />
          }
          {item.address}
          {item.advice?.show &&
            <p className="advice">{item.advice.message}</p>
          }
        </div>
      </div>
      <div className="context-menu">
        {
          (role === UserRoles.USER_ROLE_ADMIN || role === UserRoles.USER_ROLE_VERIFIER) && 
          item.id &&
          contractStatus >= ContractStatusMap.DONE_STATUS &&
            <CanI doWhat="GET" withPermissions={item.links} entityName={`${entityName}${capitalizeWord(item.supplyType)}`}>
              {({ action }) => (
                <SupplyPointStatusHistoryModal 
                  itemId={item.id} 
                  buttonLabel="Storico cambiamenti di stato" 
                  supplyType={item.supplyType}
                />
              )}
            </CanI>
        }
        <CanI doWhat={"PATCH"} withPermissions={item.links} entityName={`${entityName}${capitalizeWord(item.supplyType)}`}>
          {({ action }) => (
            <SupplyPointStatusUpdate
              itemId={item.id}
              supplyType={item.supplyType}
              statusId={item.statusId}
              actionUrl={action}
              buttonProps={{
                color: 'secondary',
                title: 'Modifica lo stato del contratto di fornitura',
                label: 'Modifica stato'
              }}
            />
          )}
        </CanI>
        <CanI doWhat="GET" withPermissions={item.links} entityName={`${entityName}${capitalizeWord(item.supplyType)}`}>
          {() => (
            <>
              <Button
                size="sm"
                color="secondary"
                outline
                onClick={() => {
                  dispatch(contractGetActiveUserSupplyPointDataStart(item))
                }}
                disabled={isFetchingSupplyPointTechOpData}
              >
                {isFetchingSupplyPointTechOpData ? (
                  <Spinner size="sm" className="mr-2" />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'edit']} className="mr-2" />
                )}
                Dettagli
              </Button>
            </>
          )}
        </CanI>
        <CanI doWhat="DELETE" withPermissions={item.links} entityName={`${entityName}${capitalizeWord(item.supplyType)}`}>
          {({ action }) => (
            <DeleteItem
              buttonLabel="Elimina"
              actionUrl={action}
              actionFunction={contractDeleteActiveUserSupplyPointStart}
              itemId={item.id}
              isDeleting={isDeleting}
            />
          )}
        </CanI>
      </div>
    </SupplyPointItemStyle>
  )
}

export default SupplyPointItem