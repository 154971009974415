import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import HomePage from '../../pages/HomePage/HomePage.component'
import ContractsList from '../../pages/ContractsList/ContractsList.component'
import ContractsFormPage from '../../pages/ContractsFormPage/ContractsFormPage.component'
import UserList from '../../pages/UserList/UserList.component'
import UserMassiveCreationPage from '../../pages/UserMassiveCreationPage/UserMassiveCreationPage.component'
import UserPage from '../../pages/UserPage/UserPage.component'
import ActiveUsersFormPage from '../../pages/ActiveUsersFormPage/ActiveUsersFormPage.component'
import NoMatchPage from '../../pages/NoMatchPage/NoMatchPage.component'
import FirebaseAuthPage from '../../pages/FirebaseAuthPage/FirebaseAuthPage.component'
import PrivateRoute from './PrivateRoute.component'
import SalesOffersListPage from '../../pages/SalesOffersListPage/SalesOffersListPage.component'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import { selectIsLoading, selectIsUserLoggedIn } from '../../redux/user/user.selectors'
import AllList from '../../pages/AllList/AllList.component'

const HomePageContainer = WithSpinner(HomePage)
const AllListContainer = WithSpinner(AllList)
const UserListContainer = WithSpinner(UserList)
const UserMassiveCreationPageContainer = WithSpinner(UserMassiveCreationPage)
const UserPageContainer = WithSpinner(UserPage)
const SalesOffersListPageContainer = WithSpinner(SalesOffersListPage)
const ContractsListContainer = WithSpinner(ContractsList)
const ContractsFormPageContainer = WithSpinner(ContractsFormPage)
const ActiveUsersFormPageContainer = WithSpinner(ActiveUsersFormPage)
// const FirebaseAuthPageContainer = WithSpinner(FirebaseAuthPage)

const AppRouter = () => {
  const userLoggedIn = useSelector(selectIsUserLoggedIn)
  const isLoading = useSelector(selectIsLoading)

  return (
    <Routes>
      <Route 
        path="/" 
        element={<HomePageContainer isLoading={isLoading} />} 
      />

      {/*All contracts*/}
      <Route 
        path="/contratti-ot/all"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <AllListContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      />

      {/*Gestione utenti*/}
      <Route
        path="/utenti/all"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <UserListContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      />
      <Route
        path="/utenti/creazione-massiva"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <UserMassiveCreationPageContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      />
      <Route
        path="/utenti"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <UserPageContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      >
        <Route
          path=":userId"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <UserPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
      </Route>
      
      {/*New clients lists*/}
      <Route
        path="/nuovi-clienti/:pathEntityName/all"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <ContractsListContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      />

      {/*New clients forms*/}
      <Route
        path="/nuovi-clienti/:pathEntityName"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <ContractsFormPageContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      >
        <Route 
          path=":contractId" 
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <ContractsFormPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
      </Route>

      {/* Active users lists */}
      <Route
        path="/clienti-attivi/:pathEntityName/all"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <ContractsListContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      />

      {/* Active users forms*/}
      <Route
        path="/clienti-attivi/:pathEntityName"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <ActiveUsersFormPageContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      >
        <Route 
          path=":contractId"
          element={
            <PrivateRoute redirectTo="/" condition={userLoggedIn}>
              <ActiveUsersFormPageContainer isLoading={isLoading} />
            </PrivateRoute>
          }
        />
      </Route>

      {/* Sale documents list*/}
      <Route
        path="/documenti"
        element={
          <PrivateRoute redirectTo="/" condition={userLoggedIn}>
            <SalesOffersListPageContainer isLoading={isLoading} />
          </PrivateRoute>
        }
      />

      <Route
        path={"/firebase-auth/*"}
        element={<FirebaseAuthPage />}
      />

      <Route
        path="*"
        element={<NoMatchPage />} 
      />
    </Routes>
  )
}

export default AppRouter
