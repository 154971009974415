import React, { useState, useEffect } from 'react'
import { detect } from 'detect-browser'
import version_parser from 'version-parser'
import { BrowserRouter as Router } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'

import AppRouter from './components/AppRouter/AppRouter.component'
import Header from './components/Header/Header.component'
import Footer from './components/Footer/Footer.component'
import MaintenancePage from './pages/MaintenancePage/MaintenancePage.component'
import UserModal from './components/UserModal/UserModal.component'
import MaintenanceAlert from './components/MaintenanceAlert/MaintenanceAlert.component'
import RefreshUserTokenToast from './components/RefreshUserTokenToast/RefreshUserTokenToast.component'
import ContractModal from './components/ContractModal/ContractModal.component'
import ContractToast from './components/ContractToast/ContractToast.component'
import SalesOffersModal from './components/SalesOffersModal/SalesOffersModal.component'
import UpdateYourBrowserBitch from './components/UpdateYourBrowserBitch/UpdateYourBrowserBitch.component'
import WithSpinner from './components/WithSpinner/WithSpinner.component'
import { userCheckSessionStart } from './redux/user/user.actions'
import { selectIsLoading, selectUserMaintenanceState } from './redux/user/user.selectors'

//Font awesome library
library.add(fas)

//Utility modal context
const AppRouterWithSpinner = WithSpinner(AppRouter)

const App = () => {
  const dispatch = useDispatch()
  const maintenanceState = useSelector(selectUserMaintenanceState)
  const isCheckingUserSession = useSelector(selectIsLoading)
  useEffect(() => {
    dispatch(userCheckSessionStart())
  }, [dispatch])

  //Check browser
  const [ browserCheck, setBrowserCheck ] = useState(true)
  useEffect(() => {
    const { name, version } = detect()
    const v1 = new version_parser.Version("9.0.0")
    const v2 = new version_parser.Version(version)

    if(name === 'ie' && v1.gt(v2)) {
      setBrowserCheck(false)
    }
  }, [])

  return (
    <div className="App">
      {maintenanceState?.is_under_maintenance === true ? (
        <MaintenancePage />
      ) : (
        <>
          <Router>
            <Header />
            <main className="page-container">
              {browserCheck === true ?
                <AppRouterWithSpinner isLoading={isCheckingUserSession} />
                :
                <UpdateYourBrowserBitch />
              }
            </main>
            <Footer />
          </Router>
          <UserModal />
          <RefreshUserTokenToast />
          <ContractModal />
          <ContractToast />
          <SalesOffersModal />
          {maintenanceState?.show_maintenance_alert === true &&
            <MaintenanceAlert />
          }
        </>
      )}
    </div>
  )
}

export default App
