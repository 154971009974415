import React from 'react'
import { Row, Col } from 'reactstrap'

import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import { required } from '../../validations'

const PodFAttachmentForm = ({ podData, canEdit, formValues, values }) => (
  <>
    <Row form>
      <Col md={6}>
        <ExtendedSelectField
          name="scope"
          isMulti={false}
          fieldLabel="Finalità uso energia elettrica*"
          placeholder="Finalità uso energia elettrica*"
          options={formValues?.scope || []}
          defaultValue={podData?.scope || null}
          isDisabled={canEdit ? false : true}
          validate={required}
        />
      </Col>
      {values && parseInt(values.scope?.value) === 1 &&
        <Col md={6}>
          <ExtendedSelectField
            name="domesticScope"
            isMulti={false}
            fieldLabel="Tipologia uso domestico*"
            placeholder="Tipologia uso domestico*"
            options={formValues?.domesticScope || []}
            defaultValue={podData?.domesticScope || null}
            isDisabled={canEdit ? false : true}
            validate={required}
          />
        </Col>
      }
      {values && parseInt(values.scope?.value) === 3 &&
        <Col md={6}>
          <ExtendedSelectField
            name="manifacturingScope"
            isMulti={false}
            fieldLabel="Tipologia esercizio d'impresa*"
            placeholder="Tipologia esercizio d'impresa*"
            options={formValues?.manifacturingScope || []}
            defaultValue={podData?.manifacturingScope || null}
            isDisabled={canEdit ? false : true}
            validate={required}
          />
        </Col>
      }
    </Row>
    { values && parseInt(values.scope?.value) === 3 && 
      parseInt(values.manifacturingScope?.value) === 4 &&
      <Row form>
        <Col>
          <CustomFormField
            id="manifacturingScopeOther"
            name="manifacturingScopeOther"
            type="text"
            disabled={canEdit ? false : true}
            placeholder="Altro (specificare)*"
            fieldLabel="Altro (specificare)*"
            validate={required}
            maxLength="80"
            defaultValue={podData?.manifacturingScopeOther || null}
          />
        </Col>
      </Row>
    }
  </>
)

export default PodFAttachmentForm
