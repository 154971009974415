import { getAPIUrl, fetchData, submitRequest } from '../../api'
import { isCorrelatonNeeded } from '../../components/forms/AttachmentsDropzone/AttachmentRow/utils'
import { LIST_DEFAULT_ITEMS_PER_PAGE } from '../../pages/utils'

export function isPdrsListNeeded(entityName) {
  return (
    entityName !== 'newConnection'
  ) ? true : false
}

export function setupCustomerData({ customerType, fullName, fiscalCode, vatNumber, email, customerCode }) {
  return {
    customerType,
    fullName,
    fiscalCode,
    vatNumber,
    email,
    customerCode
  }
}

export function getActiveUserValidateBlockAll(selectedContractId, entityName, accessToken) {
  return fetchData(`${getAPIUrl()}/${getEntityUriName(entityName)}/supply-points/${selectedContractId}/validate-block-all`, accessToken)
    .then(response => response)
}

export function getSupplyPointData(item, entityName, accessToken) {
  return fetchData(`${getAPIUrl()}/${getEntityUriName(entityName)}/${item.supplyType.toLowerCase()}s/${item.id}`, accessToken)
    .then(response => response)
}

export function getSupplyPointsByClientCode(selectedContractId, entityName, type, accessToken) {
  return fetchData(`${getAPIUrl()}/${getEntityUriName(entityName)}/${type}/customers/${selectedContractId}`, accessToken)
    .then(response => response)
}

export function submitForm(apiUrl, accessToken, values, method = 'POST') {
  return submitRequest(apiUrl, accessToken, values, method)
    .then(response => response)
}

export function fetchPdfUrl(accessToken, entityName, selectedContractId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/attachments/signed-contracts?id=${selectedContractId}`, accessToken)
    .then(response => response)
}

export function fetchOtpCode(entityName, accessToken, { values: { signerId, ...values } }) {
  return submitRequest(
    `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/signers/${signerId}/otp`,
    accessToken,
    values
  )
}

export function fetchOtpData(entityName, selectedContractId, accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/signers?contractId=${selectedContractId}`, accessToken)
    .then(response => response)
}

export function fetchContractPods(entityName, selectedContractId, accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pods/${getEntityUriName(entityName)}/${selectedContractId}`, accessToken)
    .then(response => response)
}

export function fetchContractPdrs(entityName, selectedContractId, accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pdrs/${getEntityUriName(entityName)}/${selectedContractId}`, accessToken)
    .then(response => response)
}

export function fetchContractSupplyPointscorrelation(entityName, selectedContractId, accessToken) {
  if(isCorrelatonNeeded(entityName)){
    return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/attachments/supply-points-correlation?contractId=${selectedContractId}`, accessToken)
    .then(response => response)
  }
  return []
}

export function fetchAttachments(accessToken, entityName, selectedContractId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/attachments?contractId=${selectedContractId}`, accessToken)
  .then(response => response)
}

export function fetchActiveUserAttachments(accessToken, entityName, selectedContractId) {
  return fetchData(`${getAPIUrl()}/${getEntityUriName(entityName)}/attachments/mandatory?contractId=${selectedContractId}`, accessToken)
    .then(response => response)
}

export function fetchAttachment(accessToken, entityName, attachmentId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/attachments/${attachmentId}`, accessToken)
  .then(response => response)
}

export function validateAll(accessToken, entityName, selectedContractId) {
  return submitRequest(
    `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/${selectedContractId}?supplyPointStatus=3`,
    accessToken,
    null,
    'PATCH'
  )
  .then(response => response)
}

export function blockAll(accessToken, entityName, selectedContractId) {
  return submitRequest(
    `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/${selectedContractId}?supplyPointStatus=4`,
    accessToken,
    null,
    'PATCH'
  )
  .then(response => response)
}

export function fetchPodData(accessToken, entityName, podId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pods/${podId}`, accessToken)
    .then(response => response)
}

export function fetchPdrData(accessToken, entityName, pdrId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pdrs/${pdrId}`, accessToken)
    .then(response => response)
}

export function fetchCadastralDetail(accessToken, entityName, itemId, contractId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/cadastral-data/${itemId}?contractId=${contractId}`, accessToken)
    .then(response => response)
}

export function fetchCadastralData(accessToken, entityName, contractId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/cadastral-data?contractId=${contractId}`, accessToken)
    .then(response => response)
}

export function fetchBankAccountDetailData(accessToken, entityName, itemId, contractId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/bank-account-details/${itemId}?contractId=${contractId}`, accessToken)
    .then(response => response)
}

export function fetchBankAccountData(accessToken, entityName, contractId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/bank-account-details?contractId=${contractId}`, accessToken)
    .then(response => response)
}

export function fetchProposalData(accessToken, entityName, contractId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/${contractId}/`, accessToken)
    .then(response => response)
}

export function fetchCustomerData(accessToken, customerCode) {
  return fetchData(`${getAPIUrl()}/customers/${customerCode}`, accessToken)
    .then(response => response)
}

export function fetchUserData(accessToken, userId) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName('user')}/${userId}/`, accessToken)
    .then(response => response)
}

export function meshSupplyPoints(supplyPoints, activeSupplyPointsFirst = false) {
  const meshedSupplyPoints = supplyPoints.reduce((accumulator, item) => {
    return accumulator.concat(item.items)
  }, [])

  if(activeSupplyPointsFirst === true) {
    let activeSupplyPoints = []
    let otherSupplyPoints = []
    meshedSupplyPoints.forEach(item => {
      if(item.statusId >= 1) {
        activeSupplyPoints.push(item)
      }
      else {
        otherSupplyPoints.push(item)
      }
    })
    return activeSupplyPoints.concat(otherSupplyPoints)
  }

  return meshedSupplyPoints
}

export function activeSupplyPointsFirst(supplyPoints) {
  let activeSupplyPoints = []
  let otherSupplyPoints = []
  supplyPoints.forEach(item => {
    if(item.statusId >= 1) {
      activeSupplyPoints.push(item)
    }
    else {
      otherSupplyPoints.push(item)
    }
  })
  return activeSupplyPoints.concat(otherSupplyPoints)
}

export function updateSupplyPointItemStatus(cadastralData, { cadastralDataId, supplyType, statusId }) {
  if(cadastralDataId && supplyType && statusId) {
    cadastralData.items[cadastralDataId][supplyType].supplyPointStatusId = parseInt(statusId)
  }
  return cadastralData.items
}

export function updateItemStatus({ itemId, statusId }, itemsList) {
  if(itemsList && itemsList[itemId]) itemsList[itemId].supplyPointStatus = statusId
  return itemsList
}

export function submitStatusUpdate(apiUrl, accessToken, values) {
  return submitRequest(apiUrl, accessToken, values, 'PATCH')
    .then(response => response)
}

export function fetchItemHistory(itemId, entityName, type, accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/${type}s/${itemId}/logs`, accessToken)
    .then(response => response.items)
}

export function submitNote(apiUrl, accessToken, values) {
  return submitRequest(apiUrl, accessToken, values)
    .then(response => response)
}

export function fetchNotes(fetchUrl, accessToken) {
  return fetchData(fetchUrl, accessToken)
    .then(response => response.notes)
}

export function submitReport(apiUrl, accessToken, values) {
  return submitRequest(apiUrl, accessToken, values)
    .then(response => response)
}

export function fetchReports(fetchUrl, accessToken) {
  return fetchData(fetchUrl, accessToken)
    .then(response => response.reports)
}

export function fetchListData(fetchUrl, accessToken, listData) {
  const { page, filters, sorts } = listData
  const queryString = `pageSize=${LIST_DEFAULT_ITEMS_PER_PAGE}&page=${page}${filters ? "&" + filters : ''}&${sorts}`
  return fetchData(`${fetchUrl}${queryString}`, accessToken)
    .then(response => response)
}

export function fetchFormFieldsValues(accessToken, entityName) {
  return fetchData(`${getAPIUrl('abStorePortal')}/form-items?contractType=${entityName}`, accessToken)
    .then(response => response.data)
}

export function getFilters(accessToken) {
  return fetchData(`${getAPIUrl('abStorePortal')}/form-items/filters`, accessToken)
    .then(response => response.data)
}

export function submitDeleteItem(apiUrl, accessToken) {
  return submitRequest(apiUrl, accessToken, null, 'DELETE')
    .then(response => response)
}

export function appendContractIdToUrl(history, location, contractId) {
  history(`${location.pathname}/${contractId}`)
}

export function deleteItemById(itemsObjectList, itemId) {
  const { [itemId]: remove, ...rest } = itemsObjectList
  return rest
}

export function setUpCadastralData(dataArray) {
  return dataArray.reduce((accumulator, item) => {
    item.detailModalVisibility = false
    accumulator[item.id] = item
    return accumulator
  }, {})
}

export function convertArrayToObject(dataArray) {
  return dataArray.reduce((accumulator, item) => {
    accumulator[item.id] = item
    return accumulator
  }, {})
}

export function setIdAsKey(obj) {
  for(let property in obj) {
    const newPropertyName = obj[property].id
    obj[newPropertyName] = obj[property]
    delete obj[property]
  }

  return obj
}

export function getFormStep(contractStep) {
  const { hasPod, hasPdr, hasCadastralData, hasPaymentMode, hasProposal } = contractStep
  if(hasPod || hasPdr) return ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP
  if(hasCadastralData) return ContractTabsMap.SUPPLY_POINTS_STEP
  if(hasPaymentMode) return ContractTabsMap.CADASTRAL_STEP
  if(hasProposal) return ContractTabsMap.PAYMENT_STEP

  return ContractTabsMap.PROPOSAL_STEP
}

export function getActiveUserFormStep(contractStep) {
  const { hasAttachments, hasPod, hasPdr, hasCadastralData, hasPaymentMode, hasProposal } = contractStep
  if(hasAttachments) return ContractTabsMap.DOWNLOAD_CONTRACT_PDF_STEP
  if(hasPod || hasPdr) return ContractTabsMap.ATTACHMENTS_STEP
  if(hasCadastralData) return ContractTabsMap.SUPPLY_POINTS_STEP
  if(hasPaymentMode) return ContractTabsMap.CADASTRAL_STEP
  if(hasProposal) return ContractTabsMap.PAYMENT_STEP

  return ContractTabsMap.PROPOSAL_STEP
}

export function updateUtilityModal(modalData) {
  return {
    visibility: true,
    modalTitle: "Si è verificato un problema",
    modalIcon: ['fas', 'exclamation-triangle'],
    modalDescription: modalData.message || ""
  }
}

export function updateUtilityToast(toastData) {
  return {
    isOpen: true,
    toastColor: toastData.toastColor || 'success',
    toastHeader: toastData.toastHeader || "",
    toastIcon: toastData.toastIcon || ['fas', 'check-circle'],
    toastBody: toastData.toastBody || ""
  }
}

export function buildFetchUrl(entityName) {
  return `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}?`
}

export function getContractStatusIcon(supplyPointStatusId) {
  switch(parseInt(supplyPointStatusId)) {
    case 2:
      return "signature"

    case 3:
      return "gears"

    case 4:
      return "arrows-rotate"

    case 5:
      return "hand"

    case 6:
      return "circle-half-stroke"

    case 7:
      return "circle-xmark"

    default:
      return "door-open"
  }
}

export function getSupplyPointStatusIcon(supplyPointStatusId) {
  switch(parseInt(supplyPointStatusId)) {
    case 2:
      return "gears"

    case 3:
      return "circle-check"

    case 4:
      return "hand"

    case 5:
      return "arrows-rotate"
    
    case 6:
      return "circle-xmark"

    default:
      return "signature"
  }
}

export function getItemStatusLabel(supplyPointStatusId) {
  switch(supplyPointStatusId) {
    case 2:
      return "In lavorazione"

    case 3:
      return "Validato"

    case 4:
      return "Bloccato"

    case 5:
      return "Sincronizzato"

    case 6:
      return "K.O."

    default:
      return "Inserito"
  }
}

export function getEntityLabelFromPath(pathName) {
  switch(pathName) {
    case 'cambio-fornitore':
      return 'Cambio fornitore'

    case 'voltura':
      return 'Voltura'

    case 'subentro':
      return 'Subentro'

    case 'nuova-attivazione':
      return 'Nuova attivazione'

    case 'nuova-connessione':
      return 'Nuova connessione'

    case 'voltura-contestuale-ee':
      return 'Voltura contestuale EE'

    case 'voltura-contestuale-gas':
      return 'Voltura contestuale Gas'

    case 'utenti':
      return 'Utenti'

    case 'disattivazione':
      return 'Disattivazione'

    case 'cambio-listino':
      return 'Cambio listino'

    case 'modifiche-contrattuali':
      return 'Modifiche contrattuali'

    case 'verifica-impianti':
      return 'Verifica impianti'

    case 'spostamento-gruppi-di-misura':
      return 'Spostamento gruppi di misura'

    default:
      return 'all'
  }
}

export const getContractTypeLabel = contractType => {
  switch(contractType) {
    case 'changeSupplier':
      return 'Cambio fornitore'

    case 'transfer':
      return 'Voltura'

    case 'contextualTransferEe':
        return 'Voltura contestuale EE'

    case 'contextualTransferGas':
      return 'Voltura contestuale Gas'

    case 'takeover':
      return 'Subentro'

    case 'newActivation':
      return 'Nuova attivazione'

    case 'newConnection':
      return 'Nuova connessione'

    case 'deactivation':
      return 'Disattivazione'

    case 'priceListSwitch':
      return 'Cambio listino'

    case 'contractualChanges':
      return 'Modifiche contrattuali'

    case 'measureGroupShift':
      return 'Spostamento gruppi di misura'

    case 'plantVerify':
      return 'Verifica impianti'

    default:
      return ''
  }
}

export function getEntityUriName(entityName) {
  switch(entityName) {
    case 'changeSupplier':
      return 'change-suppliers'

    case 'transfer':
      return 'transfers'

    case 'takeover':
      return 'takeovers'

    case 'newActivation':
      return 'new-activations'

    case 'newConnection':
      return 'new-connections'

    case 'contextualTransferEe':
      return 'contextual-transfers/ee'

    case 'contextualTransferGas':
      return 'contextual-transfers/gas'

    case 'user':
      return 'users'

    case 'deactivation':
      return 'deactivations'

    case 'priceListSwitch':
      return 'price-list-switches'

    case 'contractualChanges':
      return 'contractual-changes'

    case 'measureGroupShift':
      return 'measure-group-shift'

    case 'plantVerify':
      return 'plant-verify'

    default:
      return 'contracts'
  }
}

export function getEntityNameFromPath(pathName) {
  switch(pathName) {
    case 'cambio-fornitore':
      return 'changeSupplier'

    case 'voltura':
      return 'transfer'

    case 'subentro':
      return 'takeover'

    case 'nuova-attivazione':
      return 'newActivation'

    case 'nuova-connessione':
      return 'newConnection'

    case 'voltura-contestuale-ee':
      return 'contextualTransferEe'

    case 'voltura-contestuale-gas':
      return 'contextualTransferGas'

    case 'utenti':
      return 'user'

    case 'disattivazione':
      return 'deactivation'

    case 'cambio-listino':
      return 'priceListSwitch'

    case 'modifiche-contrattuali':
      return 'contractualChanges'

    case 'verifica-impianti':
      return 'plantVerify'

    case 'spostamento-gruppi-di-misura':
      return 'measureGroupShift'

    default:
      return 'all'
  }
}

export function getEntityPath(entityName) {
  switch(entityName) {
    case 'changeSupplier':
      return 'nuovi-clienti/cambio-fornitore'

    case 'transfer':
      return 'nuovi-clienti/voltura'

    case 'takeover':
      return 'nuovi-clienti/subentro'

    case 'newActivation':
      return 'nuovi-clienti/nuova-attivazione'

    case 'newConnection':
      return 'nuovi-clienti/nuova-connessione'

    case 'contextualTransferEe':
      return 'nuovi-clienti/voltura-contestuale-ee'

    case 'contextualTransferGas':
      return 'nuovi-clienti/voltura-contestuale-gas'

    case 'deactivation':
      return 'clienti-attivi/disattivazione'

    case 'priceListSwitch':
      return 'clienti-attivi/cambio-listino'
    
    case 'user':
      return 'utenti'

    case 'contractualChanges':
      return 'clienti-attivi/modifiche-contrattuali'

    case 'plantVerify':
      return 'clienti-attivi/verifica-impianti'

    case 'measureGroupShift':
      return 'clienti-attivi/spostamento-gruppi-di-misura'

    default:
      return 'contratti-ot'
  }
}

export const getEntityPluralName = contractType => {
  switch(contractType) {
    case 'changeSupplier':
      return 'changeSupplierAll'

    case 'transfer':
      return 'transferAll'

    case 'contextualTransferEe':
        return 'contextualTransferEeAll'

    case 'contextualTransferGas':
      return 'contextualTransferGasAll'

    case 'takeover':
      return 'takeoverAll'

    case 'newActivation':
      return 'newActivationAll'

    case 'newConnection':
      return 'newConnectionAll'

    case 'deactivation':
      return 'deactivationAll'

    case 'priceListSwitch':
      return 'priceListSwitchAll'

    case 'contractualChanges':
      return 'contractualChangesAll'

    case 'user':
      return 'userAll'

    case 'plantVerify':
      return 'plantVerifyAll'

    case 'measureGroupShift':
      return 'measureGroupShiftAll'

    default:
      return ''
  }
}

export function getItemUrls(type) {
  switch(type) {
    case 'changeSupplier':
      return {
        create: 'nuovi-clienti/cambio-fornitore',
        list: 'nuovi-clienti/cambio-fornitore/all'
      }

    case 'transfer':
      return {
        create: 'nuovi-clienti/voltura',
        list: 'nuovi-clienti/voltura/all'
      }

    case 'takeover':
      return {
        create: 'nuovi-clienti/subentro',
        list: 'nuovi-clienti/subentro/all'
      }

    case 'newActivation':
      return {
        create: 'nuovi-clienti/nuova-attivazione',
        list: 'nuovi-clienti/nuova-attivazione/all'
      }

    case 'newConnection':
      return {
        create: 'nuovi-clienti/nuova-connessione',
        list: 'nuovi-clienti/nuova-connessione/all'
      }

    case 'contextualTransferEe':
      return {
        create: 'nuovi-clienti/voltura-contestuale-ee',
        list: 'nuovi-clienti/voltura-contestuale-ee/all'
      }

    case 'contextualTransferGas':
      return {
        create: 'nuovi-clienti/voltura-contestuale-gas',
        list: 'nuovi-clienti/voltura-contestuale-gas/all'
      }
    
    case 'deactivation':
      return {
        create: 'clienti-attivi/disattivazione',
        list: 'clienti-attivi/disattivazione/all'
      }

    case 'priceListSwitch':
      return {
        create: 'clienti-attivi/cambio-listino',
        list: 'clienti-attivi/cambio-listino/all'
      }

    case 'user':
      return {
        create: 'utenti',
        list: 'utenti/all',
      }
    
    case 'contractualChanges':
      return {
        create: 'clienti-attivi/modifiche-contrattuali',
        list: 'clienti-attivi/modifiche-contrattuali/all'
      }

    case 'plantVerify':
      return {
        create: 'clienti-attivi/verifica-impianti',
        list: 'clienti-attivi/verifica-impianti/all'
      }

    case 'measureGroupShift':
    return {
      create: 'clienti-attivi/spostamento-gruppi-di-misura',
      list: 'clienti-attivi/spostamento-gruppi-di-misura/all'
    }

    default:
      return ''
  }
}

export function isActiveUserContract(entityName) {
  return (
    entityName === 'deactivation' ||
    entityName === 'priceListSwitch' ||
    entityName === 'contractualChanges' ||
    entityName === 'measureGroupShift' ||
    entityName === 'plantVerify'
  ) ? true : false
}

export const getUserScoreLabel = userPassed => {
  switch(userPassed) {
    case true:
      return "Cliente affidabile"

    case false:
      return "Cliente non affidabile"

    default:
      return "Nessuna informazione sul cliente"
  }
}

export const ContractTabsMap = {
  PROPOSAL_STEP: 1,
  PAYMENT_STEP: 2,
  CADASTRAL_STEP: 3,
  SUPPLY_POINTS_STEP: 3,
  ATTACHMENTS_STEP: 5,
  DOWNLOAD_CONTRACT_PDF_STEP: 6
}

export const ContractStatusMap = {
  ONGOING_STATUS: 1,
  DONE_STATUS: 2,
  ELABORATION_STATUS: 3,
  SYNC_STATUS: 4,
  BLOCKED_STATUS: 5,
  KO_STATUS: 7,
}

export const AsyncStatusEnum = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}
