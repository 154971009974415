export const getSupplyPointIcon = supplyType => {
  switch(supplyType.toLowerCase()) {
    case 'pod':
      return ['fas', 'bolt']

    default:
      return ['fas', 'fire']
  }
}

export const capitalizeWord = word => {
  const lowerCase = word.toLowerCase()
  return word.charAt(0).toUpperCase() + lowerCase.slice(1)
}