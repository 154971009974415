import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { Spinner, Table, Container, Row, Col } from 'reactstrap'

import { withRouter } from '../../../AppRouter/utils'
import ProposalFilters from '../ProposalFilters/ProposalFilters.component'
import ContractLegend from '../../ContractLegend/ContractLegend.component'
import ThTable from '../../ThTable/ThTable.component'
import ContractTableRows from '../ContractTableRows/ContractTableRows.component'
import DownloadExcelButton from '../../../DownloadExcelButton/DownloadExcelButton.component'
import ListPagination from '../../ListPagination/ListPagination.component'
import { 
  buildFetchUrl, buildExcelExportUrl, buildPdpExcelExportUrl, 
  isReportColNeeded, isUserScoreNeeded
} from './utils'
import { selectUserRole } from '../../../../redux/user/user.selectors'
import { getEntityNameFromPath, isActiveUserContract } from '../../../../redux/contract/contract.utils'
import {
  contractGetListFiltersStart, contractGetListDataStart, contractResetList
} from '../../../../redux/contract/contract.actions'
import {
  selectList, selectIsFetchingData,
  selectItemsListAsArray, selectListExcelExportUrl
} from '../../../../redux/contract/contract.selectors'
import {
  LIST_DEFAULT_ITEMS_PER_PAGE as itemsPerPage,
  LIST_DEFAULT_MAX_PAGINATION_NUMBER as maxPaginationNumbers
} from '../../../../pages/utils'

const ProposalList = ({ router: { params: { pathEntityName } } }) => {
  const entityName = getEntityNameFromPath(pathEntityName)
  const dispatch = useDispatch()
  const apiUrl = useMemo(() => buildFetchUrl(entityName, itemsPerPage), [entityName])
  const excelExportUrl = useSelector(state => selectListExcelExportUrl(buildExcelExportUrl(entityName, itemsPerPage))(state))
  const pdpExcelExportUrl = useSelector(state => selectListExcelExportUrl(buildPdpExcelExportUrl(entityName, itemsPerPage))(state))
  const role = useSelector(selectUserRole)

  //Populate filters and populate list on mount
  useEffect(() => {
    dispatch(contractResetList())
    dispatch(contractGetListFiltersStart())
    dispatch(contractGetListDataStart({ apiUrl, entityName }))

    return () => setSortParam('-insertDate')
    // eslint-disable-next-line
  }, [entityName])

  const isFetchingData = useSelector(state => selectIsFetchingData('list')(state))
  const items = useSelector(selectItemsListAsArray)
  const { count } = useSelector(selectList)

  //Handle sorting
  const [ sortParam, setSortParam ] = useState('-insertDate')

  //Build table rows
  const tableRows = items.map(item => {
    return(
      <ContractTableRows
        key={item.id}
        {...item}
        sortParam={sortParam}
      />
    )
  })

  return(
    <Container className="mb-5">
      <Row form>
        <Col>
          <ProposalFilters
            apiUrl={apiUrl}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ContractLegend />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12}>
          <div className={classnames({'table-container': true, 'loading': isFetchingData})}>
            {isFetchingData &&
              <div className="element-loader-container">
                <Spinner color="secondary" />
              </div>
            }
            <Table responsive>
              <thead>
                <tr>
                  <ThTable 
                    isSortable={false}
                    isActive={sortParam.indexOf('statusId') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="statusId"
                    label="Status"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('proposalCode') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="proposalCode"
                    label="Codice proposta"
                    apiUrl={apiUrl}
                  />
                  {isActiveUserContract(entityName) &&
                    <ThTable 
                      isSortable
                      isActive={sortParam.indexOf('customerCode') !== -1}
                      activeSortParam={sortParam}
                      setSortParam={setSortParam}
                      sortingName="customerCode"
                      label="Codice cliente"
                      apiUrl={apiUrl}
                    />
                  }
                  {!isActiveUserContract(entityName) &&
                    <ThTable label="Tipologia cliente" />
                  }
                  {
                    !isActiveUserContract(entityName) &&
                    isUserScoreNeeded(role) &&
                    <ThTable label="Valutazione" />
                  }
                  <ThTable label="Nome cliente" />
                  <ThTable 
                    isSortable={false}
                    isActive={sortParam.indexOf('customerEmail') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="customerEmail"
                    label="Email cliente"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable={false}
                    isActive={sortParam.indexOf('agent') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="agent"
                    label="Nome agente"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('signingDate') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="signingDate"
                    label="Data firma"
                    apiUrl={apiUrl}
                  />
                  <ThTable 
                    isSortable
                    isActive={sortParam.indexOf('insertDate') !== -1}
                    activeSortParam={sortParam}
                    setSortParam={setSortParam}
                    sortingName="insertDate"
                    label="Data caricamento"
                    apiUrl={apiUrl}
                  />
                  <ThTable label="Utenze" />
                  <ThTable label="Stato integrazione" />
                  {isReportColNeeded(role) && 
                    <ThTable label="Note" />
                  }
                  <ThTable label="Comunicazioni" />
                  <ThTable label="" />
                </tr>
              </thead>
              <tbody>
                {tableRows}

                {!tableRows.length && !isFetchingData &&
                  <tr>
                    <td colSpan="100%" className="text-center">
                      <strong>Nessun elemento trovato</strong>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col xs={9}>
          <ListPagination
            totalItems={count}
            maxPaginationNumbers={maxPaginationNumbers}
            itemsPerPage={itemsPerPage}
            apiUrl={apiUrl}
          />
        </Col>
        <Col xs={3}>
          <p className="total-items"><strong>Elementi totali:</strong> {count}</p>
        </Col>
      </Row>
      <Row>
        <Col className="excel-download-container">
          <DownloadExcelButton
            downloadLink={excelExportUrl}
            label="Esporta excel"
          />
          <DownloadExcelButton
            downloadLink={pdpExcelExportUrl}
            label="Esporta excel PDP"
            className="ml-2"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default withRouter(ProposalList)
