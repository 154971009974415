import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'

import CanI from '../../../auth/CanI/CanI.component'
import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomReadOnlyField from '../../inputFields/CustomReadOnlyField/CustomReadOnlyField.component'
import { required } from '../../validations'
import { canI } from '../../../auth/CanI/utils'
import { buildPodOptions, buildPdrOptions, buildSupplyPointCorrelationOptions, isCorrelatonNeeded } from './utils'
import DeleteItem from '../../../DeleteItem/DeleteItem.component'
import { contractDeleteAttachmentStart, contractRemoveAttachmentFromQueue } from '../../../../redux/contract/contract.actions'
import { selectContract, selectIsSubmitting, selectIsDeleting, selectAttachmentsFormFieldsValues } from '../../../../redux/contract/contract.selectors'

const AttachmentRow = ({ files, name, index, callbackFunction = null }) => {
  const dispatch = useDispatch()
  const file = files[index] || null
  const isSubmitting = useSelector(state => selectIsSubmitting('attachment')(state))
  const { entityName, contractSupplyPointCorrelations, contractPods, contractPdrs } = useSelector(selectContract)
  const isDeleting = useSelector(state => selectIsDeleting('attachment')(state))
  const formValues = useSelector(selectAttachmentsFormFieldsValues)

  //Populate pod pdr select
  const podOptions = buildPodOptions(contractPods)
  const pdrOptions = buildPdrOptions(contractPdrs)
  const supplyPointCorrelationOptions = buildSupplyPointCorrelationOptions(contractSupplyPointCorrelations)
  const supplyOptions = podOptions.concat(pdrOptions)
  const defaultAttachmentTypeId = file?.attachmentTypeId || null
  const defaultSupplyPointId = file?.supplyPointId || null
  const defaultSupplyPointCorrelations = file?.supplyPointCorrelations || null
  const defaultInsertDate = file?.insertDate || null

  //Check permissions
  let canEdit = true
  if(file?.links) canEdit = canI(`${entityName}AttachmentUPDATE`, file.links).abilityCheck

  return(
    <Row className="attachment-row" form>
      <Col md={11}>
        <Row>
          <Col md={5}>
          <CustomFormField
            name={`${name}.id`}
            type="hidden"
            defaultValue={file?.id}
          />
          <CustomReadOnlyField
            name={`${name}.fileName`}
            type="text"
            placeholder="File"
            fieldLabel="File"
            defaultValue={file?.userFileName}
          />
          {defaultInsertDate &&
            <p className="attachment-datetime">
              Caricato il &nbsp;
              <strong>{moment(defaultInsertDate).format('DD/MM/YYYY')}</strong> &nbsp;
              alle <strong>{moment.utc(defaultInsertDate).tz('Europe/Rome').format('HH:mm')}</strong>
            </p>
          }
        </Col>
        {(isSubmitting && !file?.id) || isDeleting ? (
          <Col md={6} className="align-self-center">
            <p className="small text-center my-0">Operazione in corso...</p>
          </Col>
        ) : (
          <>
            <Col>
              {parseInt(file?.attachmentTypeId?.value) !== 11 ? (
                <ExtendedSelectField
                  options={formValues?.attachmentType || []}
                  name={`${name}.attachmentTypeId`}
                  disabled={canEdit ? false : true}
                  placeholder="Tipologia*"
                  fieldLabel="Tipologia*"
                  validate={required}
                  defaultValue={defaultAttachmentTypeId}
                />
              ) : (
                <CustomFormField
                  type="hidden"
                  name={`${name}.attachmentTypeId`}
                  defaultValue={file?.attachmentTypeId || null}
                />
              )}
            </Col>
            {parseInt(file?.attachmentTypeId?.value) !== 11 
            && !isCorrelatonNeeded(entityName) 
            &&
              <Col md={4}>
                <ExtendedSelectField
                  options={supplyOptions}
                  isClearable
                  name={`${name}.supplyPointId`}
                  disabled={canEdit ? false : true}
                  placeholder="Punto di prelievo"
                  fieldLabel="Punto di prelievo"
                  defaultValue={defaultSupplyPointId}
                />
              </Col>
            }
          </>
        )}
        </Row>
        {parseInt(file?.attachmentTypeId?.value) !== 11 
        && isCorrelatonNeeded(entityName)
        && (!isSubmitting && !isDeleting) 
        &&
          <Row>
            <Col>
              
                  <ExtendedSelectField
                      isMulti
                    options={supplyPointCorrelationOptions}
                    isClearable
                    name={`${name}.supplyPointCorrelations`}
                    disabled={canEdit ? false : true}
                    placeholder="Punto di prelievo"
                    fieldLabel="Punto di prelievo"
                    defaultValue={defaultSupplyPointCorrelations}
                    getOptionLabel={option =>
                        <>
                          <strong>{option.label}</strong>: {option.address}
                        </>
                      }
                  />
            </Col>
          </Row>
        }
      </Col>
      <Col md={1} className="text-center">
        <div className="action-buttons">
          {file?.internalFileURL &&
            <Button
              className="attachment-button mx-1"
              color="warning"
              outline
              size="sm"
              href={file?.internalFileURL}
              title="Scarica allegato"
            >
              <FontAwesomeIcon icon="eye" />
            </Button>
          }
          {file?.links ? (
            <CanI doWhat="DELETE" withPermissions={file?.links || []} entityName={`${entityName}Attachment`}>
              {({ action }) => (
                <DeleteItem
                  buttonLabel=""
                  size="sm"
                  actionUrl={action}
                  itemId={file?.id}
                  actionFunction={contractDeleteAttachmentStart}
                  isDeleting={isDeleting}
                  callbackFunction={callbackFunction}
                />
              )}
            </CanI>
          ) : (
            <>
              <Button
                size="sm"
                color="danger"
                title="Elimina"
                outline
                onClick={() => {
                  callbackFunction()
                  dispatch(contractRemoveAttachmentFromQueue(file.tempId))}
                }
              >
                <FontAwesomeIcon size="sm" icon="trash-alt" />
              </Button>
            </>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default AttachmentRow
