import React from 'react'
import { FormFeedback, Label, Input } from 'reactstrap'

import Emoji from '../../../Emoji/Emoji.component'

const RadioInput = ({ input, meta, fieldLabel, defaultValue, disabled, noValidationMessage }) => {
  const { error, touched } = meta
  const inputInvalid = error && touched ? true : false
  const inputValid = !error && touched ? true : false
  const disabledInput = disabled ? {disabled: "disabled"} : ''

  return(
    <Label check>
      <Input
        {...input}
        {...disabledInput}
        valid={inputValid}
        invalid={inputInvalid}
      />{' '}
      {fieldLabel}
      {inputInvalid && !noValidationMessage &&
        <FormFeedback>
          {error}
          {' '}
          <Emoji
            label="police officer"
            symbol="👮"
          />
        </FormFeedback>
      }
    </Label>
  )
}

export default RadioInput
