import styled from 'styled-components'

export const ContractToastStyle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  margin: .5rem .5rem 0 0;

  .toast {
    &.success {
      .toast-header {
        background-color: #6fbd6f;
        color: #f1f1f1;
      }
    }
  }

  .toast-header {
    background-color: #dc3545;
    color: #f1f1f1;    
  }

  .toast-body {
    font-size: .9rem;
  }
`
