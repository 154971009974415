import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PodForm from '../PodForm/PodForm.component'
import { selectPodDetailModalvisibility } from '../../../redux/contract/contract.selectors'
import { contractTogglePodDetailModal } from '../../../redux/contract/contract.actions'

const PodDetailModal = ({ cadastralItem, item }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectPodDetailModalvisibility)
  const toggle = () => dispatch(contractTogglePodDetailModal())

  return(
    <Modal 
      className="status-modal" 
      size="lg" 
      backdrop="static" 
      isOpen={isOpen} 
      toggle={toggle}
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="bolt" className="mr-2" />
        POD
      </ModalHeader>
      <ModalBody>
        <PodForm />
      </ModalBody>
    </Modal>
  )
}

export default PodDetailModal
