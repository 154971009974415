import React from 'react'
import { Jumbotron } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UpdateYourBrowserBitch = () => {
  return(
    <div className="thank-you-item container mt-5">
      <Jumbotron>
        <h1 className="title">Aggiorna il tuo browser</h1>
        <hr className="separator my-2" />
        <p className="description">
          Il tuo browser non è aggiornato e non è compatibile con questa applicazione.<br />
          Puoi scaricare dei browser compatibili ai seguenti indirizzi:
        </p>
        <p>
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon="external-link-alt" className="mr-2" />
            Google Chrome
          </a>
        </p>
        <p>
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon="external-link-alt" className="mr-2" />
            Firefox
          </a>
        </p>
      </Jumbotron>
    </div>
  )
}

export default UpdateYourBrowserBitch
