import _ from 'lodash'

import { getAPIUrl } from '../../../../api'

export function setQueryString(values) {
  let queryParams = []
  _.forEach(values, (value, key) => {
    if(key !== 'statusId') {
      if(key === 'offer') {
        value = _.replace(value, new RegExp('#', 'g'), '')
      }
      queryParams.push(`${key}@=${value}`)
    }
    else {
      queryParams.push(`${key}==${value}`)
    }
  })

  return queryParams
}

export function buildFetchUrl(itemsPerPage) {
  return `${getAPIUrl('abStorePortal')}/contracts/pdrs?`
}

export function buildExcelExportUrl(itemsPerPage) {
  return `${getAPIUrl('abStorePortal')}/contracts/pdrs/excels?pageSize=${itemsPerPage}`
}
