import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import classnames from 'classnames'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { euPhoneCheck } from '../validations'
import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import CustomReadOnlyField from '../inputFields/CustomReadOnlyField/CustomReadOnlyField.component'
import { selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractGetOtpCodeStart } from '../../../redux/contract/contract.actions'

const focusOnError = createDecorator()

const OtpSigner = ({ signer }) => {
  const dispatch = useDispatch()
  const { id, mobilePhone, name, surname, displayName, fiscalCode, signed } = signer

  const isSubmitting = useSelector(state => selectIsSubmitting('otpCode')(state))
  const onSubmit = values => {
    dispatch(contractGetOtpCodeStart({ values }))
  }

  return(
    <div>
      <Form
        onSubmit={onSubmit}
        decorators={[focusOnError]}
        initialValues={{
          name,
          surname,
          signerId: id
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values, reset }) => (
          <form
            className="two-cols-form"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="grouped">
              <div className={classnames({ [`grouped-header`]: true, signed: signed })}>
                <FontAwesomeIcon icon="address-card" className="mr-2" />
                {signed ? (
                  <><strong>{`${displayName}`}</strong> ha firmato</>
                ) : (
                  <>Dati di <strong>{`${displayName}`}</strong></>
                )}
                {signed &&
                  <FontAwesomeIcon icon="check-circle" className="ml-2" />
                }
              </div>
              <div className="grouped-body">
                <Row form>
                  <Col>
                    <CustomReadOnlyField
                      id="displayName"
                      name="displayName"
                      type="text"
                      placeholder="Nominativo"
                      fieldLabel="Nominativo"
                      defaultValue={displayName}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <CustomReadOnlyField
                      id="fiscalCode"
                      name="fiscalCode"
                      type="text"
                      placeholder="Codice fiscale/P.IVA"
                      fieldLabel="Codice fiscale/P.IVA"
                      style={{ textTransform: 'uppercase' }}
                      defaultValue={fiscalCode}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="mobilePhone"
                      name="mobilePhone"
                      type="number"
                      className="phone-number"
                      placeholder="Cellulare*"
                      fieldLabel="Cellulare*"
                      validate={euPhoneCheck(true)}
                      defaultValue={mobilePhone}
                      disabled={signed || isSubmitting}
                      maxLength="20"
                    />
                  </Col>
                </Row>
              </div>

              {!signed &&
                <div className="my-3 text-right">
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <Spinner size="sm" color="light" className="mr-2" />
                    ) : (
                      <FontAwesomeIcon icon="sms" className="mr-2" />
                    )}
                    Invia SMS
                  </Button>
                </div>
              }
            </div>
          </form>
        )
      }
      </Form>
    </div>
  )
}

export default OtpSigner
