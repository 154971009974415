import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { Input, Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import ExtendedAutocompleteFormField from '../../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import ExtendedCustomZipCodeSelect from '../../inputFields/ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component'
import WhenFieldChanges from '../../WhenFieldChanges/WhenFieldChanges.component'
import PropertyDeclaration from '../PropertyDeclaration/PropertyDeclaration.component'
import { required } from '../../validations'
import { canI } from '../../../auth/CanI/utils'
import { getAPIUrl, fetchTowns } from '../../../../api'
import { updateFieldValue, isPropertyDeclarationRequired } from './utils'
import { selectUserData } from '../../../../redux/user/user.selectors'
import { selectContract, selectCadastralFormFieldsValues, selectIsSubmitting }from '../../../../redux/contract/contract.selectors'
import { contractCadastralSubmitFormStart } from '../../../../redux/contract/contract.actions'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'

//Focus on firt field with an error
const focusOnError = createDecorator()

const CadastralForm = () => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector(selectUserData)
  const { entityName, selectedContractId, cadastralDetailData } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('cadastral')(state))
  const formValues = useSelector(selectCadastralFormFieldsValues)
  let canEdit = true
  if(cadastralDetailData) {
    canEdit = canI(`${entityName}CadastralDataUPDATE`, cadastralDetailData.links).abilityCheck
  }

  //Submit form
  const onSubmit = async values => {
    const method = cadastralDetailData && canEdit === true ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/cadastral-data${cadastralDetailData ? `/${cadastralDetailData.id}` : ''}`
    dispatch(contractCadastralSubmitFormStart({ apiUrl, values, method }))
  }

  return(
    <Form
      onSubmit={onSubmit}
      mutators={{updateFieldValue}}
      subscription={{
        values: true
      }}
      decorators={[focusOnError]}
    >
      {({ handleSubmit, form, values, reset }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <WhenFieldChanges
            field="townName"
            set="postalCode"
            to={null}
          />
          <Field
            name="contractId"
            type="hidden"
            defaultValue={selectedContractId}
          >
            {(fieldState) => {
              const { input } = fieldState
              return(
                <Input {...input} />
              )
            }}
          </Field>

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="list" className="mr-2" />
              Dati fornitura
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={2}>
                  <ExtendedSelectField
                    name="toponymId"
                    isMulti={false}
                    fieldLabel="Toponimo*"
                    placeholder="Toponimo*"
                    options={formValues?.supplyToponym || []}
                    defaultValue={cadastralDetailData?.toponymId || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                <Col md={8}>
                  <CustomFormField
                    id="streetName"
                    name="streetName"
                    type="text"
                    maxLength="35"
                    disabled={canEdit ? false : true}
                    placeholder="Indirizzo*"
                    fieldLabel="Indirizzo*"
                    validate={required}
                    defaultValue={cadastralDetailData?.streetName || null}
                  />
                </Col>
                <Col md={2}>
                  <CustomFormField
                    id="streetNo"
                    name="streetNo"
                    type="text"
                    maxLength="4"
                    disabled={canEdit ? false : true}
                    placeholder="Civico*"
                    fieldLabel="Civico*"
                    validate={required}
                    defaultValue={cadastralDetailData?.streetNo || null}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={10}>
                  <ExtendedAutocompleteFormField
                    id="townName"
                    name="townName"
                    fieldLabel="Comune*"
                    placeholder="Comune*"
                    fetchFunction={fetchTowns(accessToken)}
                    disabled={canEdit ? false : true}
                    defaultValue={cadastralDetailData?.townName || null}
                    validate={required}
                    getOptionLabel={option => `${option.town} (${option.province})`}
                    getOptionValue={option => ({ town: option.town, province: option.province })}
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                    }
                  />
                </Col>
                <Col md={2}>
                  <ExtendedCustomZipCodeSelect
                    name="postalCode"
                    isMulti={false}
                    placeholder="CAP*"
                    fieldLabel="CAP*"
                    validate={required}
                    defaultValue={cadastralDetailData?.postalCode || null}
                    isDisabled={canEdit ? false : true}
                    fieldToListen="townName"
                    fieldToListenDefaultValue={cadastralDetailData?.townName || null}
                  />
                </Col>
              </Row>
            </div>
          </div>

          {isPropertyDeclarationRequired(entityName) &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="list" className="mr-2" />
                Dichiarazione sostitutiva di atto notorio relativa alla proprietà o titolarità e Allegato H
              </div>
              <div className="grouped-body">
                <PropertyDeclaration
                  canEdit={canEdit}
                  cadastralDetailData={cadastralDetailData}
                  values={values}
                  formValues={formValues}
                />
              </div>
            </div>
          }

          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || canEdit === false}>
              {isSubmitting ? (
                <Spinner size="sm" className="mr-2" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-2" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default CadastralForm
