import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import UserMassiveCreationForm from '../../components/forms/UserMassiveCreationForm/UserMassiveCreationForm.component'

import PageHeader from '../../components/PageHeader/PageHeader.component'
import {
  contractSetEntityName, contractGetFormFieldsValuesStart
} from '../../redux/contract/contract.actions'

const UserMassiveCreationPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(contractSetEntityName('user'))
    dispatch(contractGetFormFieldsValuesStart({ entityName: 'user' }))
  }, [dispatch])

  return (
    <div className="page">
      <PageHeader pageTitle="Creazione massiva utenti" icon="users-gear" />
      <Container className="loader-container">
        <Row className="justify-content-center">
          <Col xs={12} lg={12}>
            <UserMassiveCreationForm />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserMassiveCreationPage
