import { Spinner } from 'reactstrap'

import InputLoaderStyle from './style'

const InputLoader = ({ loaderText }) => (
  <InputLoaderStyle>
    <Spinner 
      size="sm"
      color="secondary"
    />
    <span>{loaderText || "Caricamento..."}</span>
  </InputLoaderStyle>
)

export default InputLoader