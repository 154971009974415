import React, { useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import ExtendedAutocompleteFormField from '../../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import WhenFieldChanges from '../../WhenFieldChanges/WhenFieldChanges.component'
import {
  required, positiveNumber, euPhoneCheck, emailCheck, vatNumber, vatNumberAsFiscalCode
} from '../../validations'
import CustomerTypes, { 
  getCustomerType, isPreviousHolderRequired, isNewHolderRequired, isMeterReadingRequired,
  isNewHolderTitleNeeded, isAccountHolderAtecoCodeNeeded, isAnnualConsumptionNeeded,
  isSupplyStartDateNeeded, isOldAccountHolderPersonTypeNeeded, isOldAccountHolderClientCodeNeeded,
  isVendorNameNeeded, fetchVendors, isTemporaryConnectionNeeded, isIndustrySectorRequired
} from './utils'
import { selectContract } from '../../../../redux/contract/contract.selectors'
import { selectUserData } from '../../../../redux/user/user.selectors'

const PodSupplyForm = ({ customerType, formValues, values, podData, canEdit }) => {
  const defaultMeterReading = useMemo(() => ({ label: "No", value: "2" }), [])
  const defaultDisconnectable = useMemo(() => ({ label: "Sì", value: "1" }), [])
  const { entityName } = useSelector(selectContract)
  const { accessToken } = useSelector(selectUserData)

  return (
    <>
      <Row form>
        <Col md={12}>
          <ExtendedSelectField
            name="as"
            isMulti={false}
            fieldLabel="Il richiedente in qualità di*"
            placeholder="Il richiedente in qualità di*"
            options={formValues?.as || []}
            defaultValue={podData?.as || null}
            isDisabled={canEdit ? false : true}
            validate={required}
          />
        </Col>
        {parseInt(values.as?.value) === 6 &&
          <Col md={12}>
            <CustomFormField
              id="asOther"
              name="asOther"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="Altro (specificare)*"
              fieldLabel="Altro (specificare)*"
              maxLength="14"
              validate={required}
              defaultValue={podData?.asOther || null}
            />
          </Col>
        }
        {isMeterReadingRequired(entityName) &&
          <Col>
            <ExtendedSelectField
              name="meterReading"
              isMulti={false}
              fieldLabel="Lettura contatore*"
              placeholder="Lettura contatore*"
              options={formValues?.meterReading || []}
              defaultValue={podData?.meterReading || defaultMeterReading}
              isDisabled={canEdit ? false : true}
              validate={required}
              fieldDescription="Selezionare 'Sì' solo per i contatori di energia elettrica trattati monorari ai sensi del TIS"
            />
          </Col>
        }
      </Row>
      { 
        isMeterReadingRequired(entityName) &&
        parseInt(values.meterReading?.value) === 1 &&
        <Row form>
          <Col md={6}>
            <CustomDatePicker
              id="meterReadingDate"
              name="meterReadingDate"
              className="form-control"
              type="date"
              dateFormat="dd/MM/yyyy"
              placeholder="Data lettura contatore*"
              fieldLabel="Data lettura contatore*"
              adjustDateOnChange
              validate={required}
              maxDate={new Date()}
              defaultValue={podData?.meterReadingDate || null}
              disabled={canEdit ? false : true}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="activeEnergy"
              name="activeEnergy"
              type="number"
              maxLength="80"
              disabled={canEdit ? false : true}
              min="0"
              placeholder="Energia attiva (A0) in kWh*"
              fieldLabel="Energia attiva (A0) in kWh*"
              validate={positiveNumber(true)}
              defaultValue={podData?.activeEnergy || null}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="reactiveEnergy"
              name="reactiveEnergy"
              type="number"
              maxLength="80"
              disabled={canEdit ? false : true}
              min="0"
              placeholder="Energia reattiva (R0) in kvart*"
              fieldLabel="Energia reattiva (R0) in kvart*"
              validate={positiveNumber(true)}
              defaultValue={podData?.reactiveEnergy || null}
            />
          </Col>
          <Col md={6}>
            <CustomFormField
              id="activePower"
              name="activePower"
              type="number"
              maxLength="80"
              disabled={canEdit ? false : true}
              min="0"
              placeholder="Potenza attiva (P0) in kW*"
              fieldLabel="Potenza attiva (P0) in kW*"
              validate={positiveNumber(true)}
              defaultValue={podData?.activePower || null}
            />
          </Col>
        </Row>
      }
      <Row form>
        {isAnnualConsumptionNeeded(entityName) &&
          <Col>
            <CustomFormField
              id="annualConsumption"
              name="annualConsumption"
              type="number"
              step=".01"
              maxLength="80"
              disabled={canEdit ? false : true}
              min="0"
              placeholder="Consumo kWh / anno*"
              fieldLabel="Consumo kWh / anno*"
              validate={positiveNumber(true)}
              defaultValue={podData?.annualConsumption || null}
            />
          </Col>
        }
        {isVendorNameNeeded(entityName) &&
          <Col>
            <ExtendedAutocompleteFormField
              id="vendorName"
              name="vendorName"
              fieldLabel="Attuale fornitore*"
              placeholder="Attuale fornitore*"
              fetchFunction={fetchVendors(entityName, accessToken)}
              defaultValue={podData?.vendorName || null}
              disabled={canEdit ? false : true}
              validate={required}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere dell'attuale fornitore per trovarlo nella lista</span>
              }
            />
          </Col>
        }
        <Col md={12}>
          <ExtendedSelectField
            name="contractType"
            isMulti={false}
            fieldLabel="Tipologia contratto*"
            placeholder="Tipologia contratto*"
            options={formValues?.contractType || []}
            defaultValue={podData?.contractType || null}
            isDisabled={canEdit ? false : true}
            validate={required}
          />
          <WhenFieldChanges
            field="contractType"
            set="electricExciseClassId"
            to={null}
          />
        </Col>
        <Col md={12}>
          <ExtendedSelectField
            name="disconnectable"
            isMulti={false}
            fieldLabel="Disalimentabile*"
            placeholder="Disalimentabile*"
            options={formValues?.disconnectable || []}
            defaultValue={podData?.disconnectable || defaultDisconnectable}
            isDisabled={canEdit ? false : true}
            validate={required}
            fieldDescription="Casistiche: apparecchiature medico-terapeutiche necessarie per l’esistenza in vita / pubblica utilità / art. 18.1c Del. AEEG 04/08"
          />
        </Col>
        {parseInt(values.disconnectable?.value) === 2 &&
          <Col md={12}>
            <CustomFormField
              id="disconnectableReason"
              name="disconnectableReason"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="Motivazione*"
              fieldLabel="Non disalimentabile: motivazione?*"
              maxLength="80"
              validate={required}
              defaultValue={podData?.disconnectableReason || null}
            />
          </Col>
        }
        {isSupplyStartDateNeeded(entityName) &&
          <Col md={12}>
            <CustomDatePicker
              id="supplyStartDate"
              name="supplyStartDate"
              className="form-control"
              type="date"
              dateFormat="dd/MM/yyyy"
              fieldLabel="Fornitura in possesso da*"
              placeholder="Fornitura in possesso da*"
              adjustDateOnChange
              validate={required}
              maxDate={new Date()}
              defaultValue={podData?.supplyStartDate || null}
              disabled={canEdit ? false : true}
            />
          </Col>
        }
        {isTemporaryConnectionNeeded(entityName) &&
          <>
            <Col md={12}>
              <ExtendedSelectField
                name="temporaryConnection"
                fieldLabel="È una connessione temporanea?*"
                placeholder="È una connessione temporanea?*"
                options={formValues?.temporaryConnection || []}
                defaultValue={podData?.temporaryConnection || null}
                isDisabled={canEdit ? false : true}
                validate={required}
              />
            </Col>
            {parseInt(values.temporaryConnection?.value) === 1 &&
              <>
                <Col md={6}>
                  <CustomDatePicker
                    name="temporaryConnectionFrom"
                    className="form-control"
                    type="date"
                    dateFormat="dd/MM/yyyy"
                    fieldLabel="Periodo da*"
                    placeholder="Periodo da*"
                    adjustDateOnChange
                    validate={required}
                    minDate={new Date()}
                    defaultValue={podData?.temporaryConnectionFrom || null}
                    disabled={canEdit ? false : true}
                  />
                </Col>
                <Col md={6}>
                  <CustomDatePicker
                    name="temporaryConnectionTo"
                    className="form-control"
                    type="date"
                    dateFormat="dd/MM/yyyy"
                    fieldLabel="Periodo a*"
                    placeholder="Periodo a*"
                    adjustDateOnChange
                    validate={required}
                    minDate={new Date()}
                    defaultValue={podData?.temporaryConnectionTo || null}
                    disabled={canEdit ? false : true}
                  />
                </Col>
                <Col>
                  <ExtendedSelectField
                    name="temporaryConnectionUse"
                    fieldLabel="Uso dell'energia*"
                    placeholder="Uso dell'energia*"
                    options={formValues?.temporaryConnectionUse || []}
                    defaultValue={podData?.temporaryConnectionUse || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
              </>
            }
          </>
        }
      </Row>

      {isPreviousHolderRequired(entityName) &&
        <>
          <Row form>
            <Col md="12">
              <legend className="col-form-label">Dati vecchio intestatario:</legend>
              <p className="small text-muted">
                A seconda dei dati inseriti alcuni campi saranno da compilare obbligatoriamente. 
                Ad esempio il campo "Partita IVA" diventa obbligatorio se si inserisce una "Ragione Sociale", 
                diventa invece NON obbligatorio inserendo "Nome" e "Cognome" del vecchio intestatario.
              </p>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <ExtendedSelectField
                name="oldAccountHolderCustomerType"
                fieldLabel="Tipologia cliente vecchio intestatario*"
                placeholder="Tiopologia cliente vecchio intestatario*"
                options={formValues?.oldAccountHolderCustomerType || []}
                defaultValue={podData?.oldAccountHolderCustomerType || null}
                isDisabled={canEdit ? false : true}
                validate={required}
              />
            </Col>
            {isOldAccountHolderClientCodeNeeded(entityName) &&
              <Col md={12}>
                <CustomFormField
                  id="oldAccountHolderClientCode"
                  name="oldAccountHolderClientCode"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Codice cliente"
                  fieldLabel="Codice cliente"
                  maxLength="20"
                  defaultValue={podData?.oldAccountHolderClientCode || null}
                />
              </Col>
            }
            {parseInt(values?.oldAccountHolderCustomerType?.value) === 2 &&
              <>
                <Col md={6}>
                  <CustomFormField
                    id="oldAccountHolderClientBusinessName"
                    name="oldAccountHolderClientBusinessName"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Ragione Sociale*"
                    fieldLabel="Ragione Sociale*"
                    maxLength="80"
                    validate={required}
                    defaultValue={podData?.oldAccountHolderClientBusinessName || null}
                  />
                  <WhenFieldChanges
                    field="oldAccountHolderClientBusinessName"
                    set="oldAccountHolderPersonType"
                    to={null}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    id="oldAccountHolderVatNumber"
                    name="oldAccountHolderVatNumber"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Partita IVA*"
                    fieldLabel="Partita IVA*"
                    maxLength="11"
                    validate={vatNumber(true)}
                    defaultValue={podData?.oldAccountHolderVatNumber || null}
                  />
                </Col>
              </>
            }
            {parseInt(values?.oldAccountHolderCustomerType?.value) === 1 &&
              <>
                <Col md={6}>
                  <CustomFormField
                    id="oldAccountHolderName"
                    name="oldAccountHolderName"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Nome*"
                    fieldLabel="Nome*"
                    validate={required}
                    maxLength="80"
                    defaultValue={podData?.oldAccountHolderName || null}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    id="oldAccountHolderSurname"
                    name="oldAccountHolderSurname"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Cognome*"
                    fieldLabel="Cognome*"
                    validate={required}
                    maxLength="80"
                    defaultValue={podData?.oldAccountHolderSurname || null}
                  />
                </Col>
                <Col md={12}>
                  <CustomFormField
                    id="oldAccountHolderFiscalCode"
                    name="oldAccountHolderFiscalCode"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Codice fiscale*"
                    fieldLabel="Codice fiscale*"
                    style={{ textTransform: 'uppercase' }}
                    maxLength="16"
                    validate={vatNumberAsFiscalCode(true)}
                    defaultValue={podData?.oldAccountHolderFiscalCode || null}
                  />
                </Col>
              </>
            }
            {isOldAccountHolderPersonTypeNeeded(entityName) &&
              <>
                <Col md={6}>
                  <ExtendedSelectField
                    name="oldAccountHolderPersonType"
                    isMulti={false}
                    fieldLabel="Il vecchio intestatario è*"
                    placeholder="Il vecchio intestatario è*"
                    options={values?.oldAccountHolderVatNumber ? (
                      (formValues?.oldAccountHolderPersonTypeBUS || [])
                    ) : (
                      (formValues?.oldAccountHolderPersonTypeDOM || [])
                    )}
                    defaultValue={podData?.oldAccountHolderPersonType || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                {parseInt(values?.oldAccountHolderPersonType?.value) === 1 &&
                  <Col>
                    <CustomFormField
                      id="oldAccountHolderPhone"
                      name="oldAccountHolderPhone"
                      type="number"
                      className="phone-number"
                      disabled={canEdit ? false : true}
                      placeholder="Telefono*"
                      fieldLabel="Telefono*"
                      maxLength="20"
                      defaultValue={podData?.oldAccountHolderPhone || null}
                      validate={euPhoneCheck(true)}
                    />
                  </Col>
                }
                {parseInt(values?.oldAccountHolderPersonType?.value) === 2 &&
                  <Col>
                    <ExtendedSelectField
                      name="unavailabilityReason"
                      isMulti={false}
                      fieldLabel="Motivo irreperibilità*"
                      placeholder="Motivo irreperibilità*"
                      options={formValues?.unavailabilityReason || []}
                      defaultValue={podData?.unavailabilityReason || null}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                    />
                  </Col>
                }
              </>
            }
            
            {
              isOldAccountHolderPersonTypeNeeded(entityName) &&
              parseInt(values?.oldAccountHolderPersonType?.value) === 3 &&
                <>
                  <Col md={6}>
                    <CustomDatePicker
                      id="deathDate"
                      name="deathDate"
                      className="form-control"
                      type="date"
                      dateFormat="dd/MM/yyyy"
                      placeholder="Data decesso*"
                      fieldLabel="Data decesso*"
                      adjustDateOnChange
                      validate={required}
                      maxDate={new Date()}
                      defaultValue={podData?.deathDate || null}
                      disabled={canEdit ? false : true}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="deathPlace"
                      name="deathPlace"
                      type="text"
                      disabled={canEdit ? false : true}
                      placeholder="Luogo decesso*"
                      fieldLabel="Luogo decesso*"
                      maxLength="80"
                      defaultValue={podData?.deathPlace || null}
                      validate={required}
                    />
                  </Col>
                  <Col>
                    <ExtendedSelectField
                      name="deceasedTie"
                      isMulti={false}
                      fieldLabel="Legame con il deceduto*"
                      placeholder="Legame con il deceduto*"
                      options={formValues?.deceasedTie || []}
                      defaultValue={podData?.deceasedTie || null}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                    />
                  </Col>
                  {values?.deceasedTie?.label.toLowerCase() === 'altro' &&
                    <Col md={12}>
                      <CustomFormField
                        id="otherTie"
                        name="otherTie"
                        type="text"
                        disabled={canEdit ? false : true}
                        placeholder="Altro legame*"
                        fieldLabel="Altro legame*"
                        maxLength="80"
                        defaultValue={podData?.otherTie || null}
                        validate={required}
                      />
                    </Col>
                  }
                </>
            }
          </Row>
        </>
      }

      {isNewHolderRequired(entityName) &&
        <>
          <Row form>
            <Col md="12">
              {isNewHolderTitleNeeded(entityName) ? (
                <legend className="col-form-label">Dati nuovo intestatario:</legend>
              ) : (
                <legend className="col-form-label">Dati intestatario:</legend>
              )}
            </Col>
          </Row>
          <Row form>

            {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
              <Col md={6}>
                <CustomFormField
                  id="accountHolderBusinessName"
                  name="accountHolderBusinessName"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Ragione Sociale*"
                  fieldLabel="Ragione Sociale*"
                  maxLength="80"
                  validate={required}
                  defaultValue={podData?.accountHolderBusinessName || null}
                />
              </Col>
            }

            {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) &&
              <>
                <Col md={6}>
                  <CustomFormField
                    id="accountHolderName"
                    name="accountHolderName"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Nome*"
                    fieldLabel="Nome*"
                    maxLength="80"
                    validate={required}
                    defaultValue={podData?.accountHolderName || null}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    id="accountHolderSurname"
                    name="accountHolderSurname"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder="Cognome*"
                    fieldLabel="Cognome*"
                    maxLength="80"
                    validate={required}
                    defaultValue={podData?.accountHolderSurname || null}
                  />
                </Col>
              </>
            }

            <Col md={6}>
              <CustomFormField
                id="accountHolderFiscalCode"
                name="accountHolderFiscalCode"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="Codice fiscale*"
                fieldLabel="Codice fiscale*"
                style={{ textTransform: 'uppercase' }}
                maxLength="80"
                validate={vatNumberAsFiscalCode(true)}
                defaultValue={podData?.accountHolderFiscalCode || null}
              />
            </Col>

            {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
              <Col md={6}>
                <CustomFormField
                  id="accountHolderVatNumber"
                  name="accountHolderVatNumber"
                  type="text"
                  disabled={canEdit ? false : true}
                  placeholder="Partita IVA*"
                  fieldLabel="Partita IVA*"
                  maxLength="11"
                  validate={vatNumber(true)}
                  defaultValue={podData?.accountHolderVatNumber || null}
                />
              </Col>
            }

            <Col md={6}>
              <CustomFormField
                id="accountHolderPhone"
                name="accountHolderPhone"
                type="number"
                className="phone-number"
                disabled={canEdit ? false : true}
                placeholder="Telefono*"
                fieldLabel="Telefono*"
                maxLength="20"
                defaultValue={podData?.accountHolderPhone || null}
                validate={euPhoneCheck(true)}
              />
            </Col>
            <Col md={6}>
              <CustomFormField
                id="accountHolderEmail"
                name="accountHolderEmail"
                type="text"
                disabled={canEdit ? false : true}
                placeholder="E-mail*"
                fieldLabel="E-mail*"
                maxLength="80"
                validate={emailCheck(true)}
                defaultValue={podData?.accountHolderEmail || null}
                style={{ textTransform: 'lowercase' }}
              />
            </Col>
          </Row>
        </>
      }

      <Row form>
        { 
          getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
          isAccountHolderAtecoCodeNeeded(entityName) &&
          <Col>
            <CustomFormField
              id="accountHolderAtecoCode"
              name="accountHolderAtecoCode"
              type="text"
              disabled={canEdit ? false : true}
              placeholder="Codice ATECO 2007 (nuovo intestatario)*"
              fieldLabel="Codice ATECO 2007 (nuovo intestatario)*"
              maxLength="8"
              validate={required}
              defaultValue={podData?.accountHolderAtecoCode || null}
            />
          </Col>
        }
        {
          (
            getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) ||
            parseInt(values?.contractType?.value) === 4
          ) &&
          <Col>
            <ExtendedSelectField
              name="industrySector"
              isMulti={false}
              fieldLabel={`Settore industriale/merceologico (nuovo intestatario)${isIndustrySectorRequired(entityName) ? "*" : ""}`}
              placeholder={`Settore industriale/merceologico (nuovo intestatario)${isIndustrySectorRequired(entityName) ? "*" : ""}`}
              options={formValues?.industrySector || []}
              defaultValue={podData?.industrySector || null}
              isDisabled={canEdit ? false : true}
              isClearable={!isIndustrySectorRequired(entityName)}
              validate={isIndustrySectorRequired(entityName) ? required : undefined}
            />
          </Col>
        }
      </Row>
    </>
  )
}

export default PodSupplyForm
