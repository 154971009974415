import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col } from 'reactstrap'

import {
  selectUsername, selectUserAvailableContracts, selectUserAvailableOt,
  selectUserAvailableNewCustomerEntities, selectUserAvailableOngoingCustomerEntities
} from '../../redux/user/user.selectors'
import EntityRow from '../../components/EntityRow/EntityRow.component'
import { scrollTo } from './utils'

const LandingPage = () => {
  const username = useSelector(selectUsername)
  const newCustomerEntities = useSelector(selectUserAvailableNewCustomerEntities)
  const ongoingCustomerEntites = useSelector(selectUserAvailableOngoingCustomerEntities)
  const userContracts = useSelector(selectUserAvailableContracts)
  const userOt = useSelector(selectUserAvailableOt)
  
  return (
    <div className="landing">
      <header className="page-header">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <p className="lead">Benvenuto <strong>{username}</strong></p>
            </Col>
            <Col xs={12} md={6}>
              <div className="other-links-container">
                <a className="other-links" href="https://postvendita.abenergie.it/" target="_blank" rel="noreferrer">
                  Vai al portale post vendita
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <main>
        <Container>
          {(userContracts.length + userOt.length) > 1 &&
          <Row>
            <Col>
              <div className="alert alert-primary all-contracts-banner" role="alert">
                <div className="text-container">
                  <span className="title"><FontAwesomeIcon icon="list" className="mr-1" />{' '}Contratti e O.T.</span>
                  <span className="desc">Elenco di tutti i contratti ed operazioni tecniche registrate sino ad oggi</span>
                </div>
                <Link className="btn btn-outline-secondary" to="/contratti-ot/all">
                  <FontAwesomeIcon icon="list" className="mr-1" />{' '}
                  Vedi tutti
                </Link>
              </div>
            </Col>
          </Row>
          } 
          {!!newCustomerEntities.length || !!ongoingCustomerEntites.length ? (
              <>
                <Row>
                  <Col>
                    <h1 className="main-title">Operazioni disponibili</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="contracts-shortcuts">
                      <h3 className="title">Categorie di operazioni:</h3>
                      <div className="links-container">
                        {!!newCustomerEntities.length &&
                          <a 
                            onClick={e => scrollTo(e, '#new-clients')} 
                            className="btn btn-outline-primary" 
                            href="#new-clients"
                          >
                              Nuovi punti di fornitura
                          </a>
                        }
                        {!!ongoingCustomerEntites.length === true &&
                          <a 
                            onClick={e => scrollTo(e, '#active-clients')} 
                            className="btn btn-outline-primary" 
                            href="#active-clients"
                          >
                            Punti di fornitura attivi
                          </a>
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <h5 className="">
                    Al momento la tua utenza non ha i permessi per poter effettuare operazioni su contratti e o.t.
                  </h5>
                </Col>
              </Row>
            )
          }
          {!!newCustomerEntities.length &&
            <section className="contracts-section new-clients" id="new-clients">
              <h3 className="section-subtitle">Operazioni disponibili per</h3>
              <h1 className="section-title">Nuovi punti di fornitura:</h1>
              <div className="contracts-container">
                {newCustomerEntities.map((code, i) => (
                  <EntityRow 
                    code={code}
                    key={i}
                  />
                ))}
              </div>
            </section>
          }
          {!!ongoingCustomerEntites.length &&
            <section className="contracts-section new-clients" id="active-clients">
              <h3 className="section-subtitle">Operazioni disponibili per</h3>
              <h1 className="section-title">Punti di fornitura attivi:</h1>
              <div className="contracts-container">
                {ongoingCustomerEntites.map((code, i) => (
                  <EntityRow 
                    code={code}
                    key={i}
                  />
                ))}
              </div>
            </section>
          }
          {(!!newCustomerEntities.length || !!ongoingCustomerEntites.length) &&
            <Row>
              <Col>
                <div className="contracts-shortcuts">
                  <h3 className="title">Categorie di operazioni:</h3>
                  <div className="links-container">
                    {!!newCustomerEntities.length &&
                      <a 
                        onClick={e => scrollTo(e, '#new-clients')} 
                        className="btn btn-outline-primary" 
                        href="#new-clients"
                      >
                          Nuovi punti di fornitura
                      </a>
                    }
                    {!!ongoingCustomerEntites.length &&
                      <a 
                        onClick={e => scrollTo(e, '#active-clients')} 
                        className="btn btn-outline-primary" 
                        href="#active-clients"
                      >
                        Punti di fornitura attivi
                      </a>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </main>
    </div>
  )
}

export default LandingPage
