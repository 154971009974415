export function getCustomerType(requestedCustomerType, contractCustomerType) {
  return requestedCustomerType === parseInt(contractCustomerType?.value) ? true : false
}

const CustomerTypes = {
  DOMESTIC_CUSTOMER: 1,
  BUSINESS_CUSTOMER: 2
}

export default CustomerTypes
