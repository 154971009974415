import React, { useState } from 'react'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button, Spinner, Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PaperSignatureFormStyle from './style'
import Dropzone from '../inputFields/Dropzone/Dropzone.component'
import CustomDatePicker from '../inputFields/CustomDatePicker/CustomDatePicker.component'
import AttachmentsDropzone from '../AttachmentsDropzone/AttachmentsDropzone.component'
import { getAPIUrl } from '../../../api'
import { selectContract, selectAttachmentsDataAsArray, selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractSubmitPaperSignatureStart } from '../../../redux/contract/contract.actions'
import { getEntityUriName } from '../../../redux/contract/contract.utils'
import { 
  setUpSubmitValues, setUpPapercontractSubmitValues, 
  calculateDaysBeforeNow, isAttachmentsFormNeeded, isAlertDescriptionNeeded
} from './utils'
import { noFutureDate } from '../validations'
import { maxSize } from '../AttachmentsDropzone/utils'

const focusOnError = createDecorator()

const PaperSignatureForm = () => {
  const dispatch = useDispatch()
  const { entityName, selectedContractId } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('signature')(state))
  const attachmentsData = useSelector(selectAttachmentsDataAsArray)
  const [ paperContract, setPaperContract ] = useState([])
  const [ validate, setValidate ] = useState(true)

  const onSubmit = values => {
    //Submit paper contract
    if(values.paperContract?.length) {
      const paperContractValues = setUpPapercontractSubmitValues({
        contractId: selectedContractId,
        fileBase64: paperContract[0].encoded,
        signingDate: values.signingDate,
        ...values.paperContract[0]
      })
      const paperSignatureApiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/${selectedContractId}/paper-contract`
      //Submit other attachments
      let attachmentsValues = null
      if(values.attachments?.length) {
        attachmentsValues = values.attachments.map((attachment, i) => {
          const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/attachments${attachment.id ? `/${attachment.id}` : ''}`
          const method = attachment.id ? 'PUT' : 'POST'
          return setUpSubmitValues(attachment, { encodedFile: attachmentsData[i].encoded, contractId: selectedContractId, method, apiUrl })
        })
      }
      dispatch(contractSubmitPaperSignatureStart({
        paperContract: {
          apiUrl: paperSignatureApiUrl,
          values: paperContractValues
        },
        attachments: attachmentsValues
      }))
    }
    else {
      setValidate(false)
    }
  }

  return (
    <PaperSignatureFormStyle>
      {isAlertDescriptionNeeded(entityName) &&
        <Alert color="warning">
          <h4 className="alert-heading">Nota bene</h4>
            <p className="alert-description">
              Nel caso in cui il file "Contratto firmato" sia già provvisto 
              di eventuali altre tipologie di allegato (ad es. documenti d'identità, bollette, documenti relativi alle accise, ecc.), 
              <strong>non</strong> è necessario caricare questi "altri" documenti anche nella sezione 
              "Aggiungi allegati al contratto" sottostante.        
            </p>
        </Alert>
      }
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          pristine: true
        }}
        mutators={{
          ...arrayMutators
        }}
        decorators={[focusOnError]}
        initialValues={{
          contractId: selectedContractId
        }}
      >
        {({ handleSubmit, form: { mutators }, submitting, pristine, values, reset }) => {
          return (
            <form
              className="two-cols-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="grouped">
                <div className="grouped-header">
                  <FontAwesomeIcon icon="file" className="mr-2" />
                  Carica il contratto firmato<sup>*</sup>
                </div>
                <div className="grouped-body">
                  <Row form>
                    <Col md={12}>
                      <Dropzone
                        maxSize={maxSize}
                        acceptedFileTypes={{ "application/pdf": [] }}
                        files={paperContract}
                        setFiles={setPaperContract}
                        inputName="paperContract"
                        setValidate={setValidate}
                        mutators={{
                          ...mutators
                        }}
                      />
                    </Col>
                    {validate === false &&
                      <p className="small px-2 text-danger">È obbligatorio caricare il contratto firmato.</p>
                    }
                  </Row>
                  <Row>
                    <Col>
                      <CustomDatePicker
                        className="form-control"
                        id="signingDate"
                        name="signingDate"
                        type="date"
                        dateFormat="dd/MM/yyyy"
                        placeholder="Data firma*"
                        fieldLabel="Data firma*"
                        adjustDateOnChange
                        defaultValue={attachmentsData?.signingDate || null}
                        validate={noFutureDate}
                        maxDate={new Date()}
                        minDate={calculateDaysBeforeNow(60)}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              {isAttachmentsFormNeeded(entityName) &&
                <div className="grouped">
                  <div className="grouped-header">
                    <FontAwesomeIcon icon="file" className="mr-2" />
                    Aggiungi allegati al contratto
                  </div>
                  <div className="grouped-body">
                    <Row form>
                      <Col md={12}>
                        <AttachmentsDropzone
                          inputName="attachments"
                          files={attachmentsData}
                          mutators={{
                            ...mutators
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              }
              <div className="text-right">
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                      <Spinner size="sm" color="light" className="mr-2" />
                    ) : (
                      <FontAwesomeIcon icon="signature" className="mr-2" />
                  )}
                  {' '}
                  <span>Finalizza contratto</span>
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </PaperSignatureFormStyle>
  )
}
export default PaperSignatureForm
