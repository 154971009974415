import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, Spinner, ListGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

import HistoryEventsList from '../HistoryEventsList/HistoryEventsList.component'
import { selectContract, selectIsFetchingData, selectEntityName } from '../../redux/contract/contract.selectors'
import { contractGetItemHistoryDataStart } from '../../redux/contract/contract.actions'

const SupplyPointStatusHistoryModal = ({ itemId, supplyType, otherClasses, buttonLabel, contractType = null }) => {
  const dispatch = useDispatch()
  const selectedEntityName = useSelector(selectEntityName)
  const { itemHistoryData } = useSelector(selectContract)
  const loading = useSelector(state => selectIsFetchingData('historyData')(state))
  const [ visibility, setVisibility ] = useState(false)
  const toggle = () => setVisibility(!visibility)

  useEffect(() => {
    const entityName = contractType || selectedEntityName
    if(visibility === true) dispatch(contractGetItemHistoryDataStart({ itemId, entityName, type: supplyType }))
  }, [visibility, dispatch, itemId, selectedEntityName, contractType, supplyType])

  return (
    <div
      className={`action-modal${otherClasses ? ` ${otherClasses}` : ''}`}
    >
      <Button
        title="Vedi lo storico del contratto"
        onClick={toggle}
        size="sm"
        color="secondary"
        outline
      >
        <FontAwesomeIcon size="sm" icon="history" />
        {buttonLabel &&
          <span className="btn-label">{buttonLabel}</span>
        }
      </Button>
      <Modal className="status-modal" isOpen={visibility} toggle={toggle}>
        <div className={classnames({'loading': loading})}>
          {loading &&
            <div className="element-loader-container">
              <Spinner color="secondary" />
            </div>
          }
          <ModalHeader toggle={toggle}>
            <FontAwesomeIcon className="mr-2" icon="history" />
            Storico dei cambiamenti di stato del contratto
          </ModalHeader>

          <ModalBody className="notes">
            <ListGroup>
              <HistoryEventsList events={itemHistoryData} />
            </ListGroup>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default SupplyPointStatusHistoryModal
