import { fetchData, getAPIUrl } from "../../api"

export function fetchSalesOffersList(accessToken, userId) {
  return fetchData(`${getAPIUrl()}/users/${userId}/sale-offers/active`, accessToken)
    .then(response => response)
}

export const AsyncStatusEnum = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export function updateUtilityModal(modalData) {
  return {
    visibility: true,
    modalTitle: "Si è verificato un problema",
    modalIcon: ['fas', 'exclamation-triangle'],
    modalDescription: modalData.message || ""
  }
}