import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import createDecorator from 'final-form-focus'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getAPIUrl } from '../../../../api'
import { abilityCheck } from '../../../auth/CanI/utils'
import { required } from '../../validations'
import WhenFieldChanges from "../../WhenFieldChanges/WhenFieldChanges.component"
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import { ReactComponent as PensoInVerdeIcon } from '../../../../assets/images/penso-verde.svg'
import { selectContract, selectIsSubmitting, selectPodFormFieldsValues, selectPdrFormFieldsValues } from '../../../../redux/contract/contract.selectors'
import { contractSubmitActiveUserSupplyPointDataStart, contractShowErrorMessage } from '../../../../redux/contract/contract.actions'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'
import { selectUserData } from '../../../../redux/user/user.selectors'
import { capitalizeWord } from '../../../../utils'
import { 
  fetchElectricContractPriceList, fetchGasContractPriceList, 
  useFetchContractPriceListOnMount 
} from './utils'
import InputLoader from '../../InputLoader/InputLoader.component'

const focusOnError = createDecorator()

const PriceListSwitchForm = () => {
  const dispatch = useDispatch()
  const { email, accessToken } = useSelector(selectUserData)
  const podFormValues = useSelector(selectPodFormFieldsValues)
  const pdrFormValues = useSelector(selectPdrFormFieldsValues)
  const { entityName, activeUserSupplyPointData, selectedContractId } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('supplyPointTechOp')(state))
  const [ isFetchingContractPriceList, setIsFetchingContractPriceList ] = useState(false)
  const [ contractPriceList, setContractPriceList ] = useFetchContractPriceListOnMount()

  let canEdit = true
  if(activeUserSupplyPointData?.id) {
    canEdit = abilityCheck(`${entityName}${capitalizeWord(activeUserSupplyPointData.supplyType)}UPDATE`, activeUserSupplyPointData.links)
  }

  const onSubmit = values => {
    const method = activeUserSupplyPointData?.id && canEdit === true ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl()}/${getEntityUriName(entityName)}/${activeUserSupplyPointData.supplyType.toLowerCase()}s${activeUserSupplyPointData?.id ? `/${activeUserSupplyPointData.id}` : ''}`
    dispatch(contractSubmitActiveUserSupplyPointDataStart({ apiUrl, values, method }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={{
        ...activeUserSupplyPointData,
        priceListSwitchId: selectedContractId
      }}
    >
      {({ handleSubmit, pristine, values }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="grouped">
            <div className="grouped-header">
              {activeUserSupplyPointData.supplyType === 'POD' &&
                <FontAwesomeIcon icon="bolt" className="mr-2" />
              }
              {activeUserSupplyPointData.supplyType === 'PDR' &&
                <FontAwesomeIcon icon="fire" className="mr-2" />
              }
              Cambio listino utenza
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={6}>
                 {activeUserSupplyPointData.supplyType === 'POD' &&
                    <>
                      <ExtendedSelectField
                        name="electricUseTypeId"
                        isMulti={false}
                        fieldLabel="Tipo uso*"
                        placeholder="Tipo uso*"
                        options={podFormValues?.electricUseType || []}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                        defaultValue={activeUserSupplyPointData?.electricUseTypeId || null}
                      />
                      <OnChange name="electricUseTypeId">
                        {objValue => {
                          if(objValue) {
                            setIsFetchingContractPriceList(true)
                            fetchElectricContractPriceList(
                              entityName, 
                              selectedContractId, 
                              activeUserSupplyPointData?.id, 
                              objValue.value, 
                              email, 
                              accessToken
                            )
                              .then(response => {
                                setContractPriceList(response.data)
                                setIsFetchingContractPriceList(false)
                              })
                              .catch(error => dispatch(contractShowErrorMessage({ message: error.message })))
                          }
                        }}
                      </OnChange>
                      <WhenFieldChanges
                        field="electricUseTypeId"
                        set="contractPriceListCode"
                        to={null}
                      />
                    </>
                  }
                  {activeUserSupplyPointData.supplyType === 'PDR' &&
                    <>
                      <ExtendedSelectField
                        name="gasUseTypeId"
                        isMulti={false}
                        fieldLabel="Tipo uso*"
                        placeholder="Tipo uso*"
                        options={pdrFormValues?.gasUseType || []}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                        defaultValue={activeUserSupplyPointData?.gasUseTypeId || null}
                      />
                      <OnChange name="gasUseTypeId">
                        {objValue => {
                          if(objValue) {
                            setIsFetchingContractPriceList(true)
                            fetchGasContractPriceList(
                              entityName, 
                              selectedContractId, 
                              activeUserSupplyPointData?.id, 
                              objValue.value, 
                              activeUserSupplyPointData?.logicalPoint?.value,
                              email, 
                              accessToken
                            )
                              .then(response => {
                                setContractPriceList(response.data)
                                setIsFetchingContractPriceList(false)
                              })
                              .catch(error => dispatch(contractShowErrorMessage({ message: error.message })))
                          }
                        }}
                      </OnChange>
                      <WhenFieldChanges
                        field="gasUseTypeId"
                        set="contractPriceListCode"
                        to={null}
                      />
                    </>
                  }
                </Col>
                <Col md={6}>
                  <ExtendedSelectField
                    name="contractPriceListCode"
                    isMulti={false}
                    fieldLabel="Offerta*"
                    placeholder="Offerta*"
                    defaultValue={activeUserSupplyPointData?.contractPriceListCode || null}
                    options={contractPriceList}
                    isDisabled={isFetchingContractPriceList ? true : (canEdit ? false : true)}
                    validate={required}
                    getOptionLabel={option =>
                      <>
                        {option.hasGreenEnergy === true &&
                          <PensoInVerdeIcon className="penso-in-verde-icon" />
                        }
                        <span>{option.label}</span>
                      </>
                    }
                  >
                  {isFetchingContractPriceList &&
                    <InputLoader />
                  }
                  </ExtendedSelectField>
                </Col>
              </Row>
            </div>
          </div>

          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || canEdit === false}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-1" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default PriceListSwitchForm