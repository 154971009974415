import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getAPIUrl } from '../../../../api'
import { abilityCheck } from '../../../auth/CanI/utils'
import { required } from '../../validations'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import { selectContract, selectSupplyPointFormFieldsValues, selectIsSubmitting } from '../../../../redux/contract/contract.selectors'
import { noPastDate }from '../../validations'
import { contractSubmitActiveUserSupplyPointDataStart } from '../../../../redux/contract/contract.actions'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'
import { capitalizeWord } from '../../../../utils'

const focusOnError = createDecorator()

const DeactivationForm = () => {
  const dispatch = useDispatch()
  const formValues = useSelector(selectSupplyPointFormFieldsValues)
  const { entityName, activeUserSupplyPointData, selectedContractId } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('supplyPointTechOp')(state))
  const todayDate = useMemo(() => new Date(), [])

  let canEdit = true
  if(activeUserSupplyPointData?.id) {
    canEdit = abilityCheck(`${entityName}${capitalizeWord(activeUserSupplyPointData.supplyType)}UPDATE`, activeUserSupplyPointData.links)
  }

  const onSubmit = values => {
    const method = activeUserSupplyPointData?.id && canEdit === true ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl()}/${getEntityUriName(entityName)}/${activeUserSupplyPointData.supplyType.toLowerCase()}s${activeUserSupplyPointData?.id ? `/${activeUserSupplyPointData.id}` : ''}`
    dispatch(contractSubmitActiveUserSupplyPointDataStart({ apiUrl, values, method }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={{
        ...activeUserSupplyPointData,
        deactivationId: selectedContractId
      }}
    >
      {({ handleSubmit, pristine, values }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="grouped">
            <div className="grouped-header">
              {activeUserSupplyPointData.supplyType === 'POD' &&
                <FontAwesomeIcon icon="bolt" className="mr-2" />
              }
              {activeUserSupplyPointData.supplyType === 'PDR' &&
                <FontAwesomeIcon icon="fire" className="mr-2" />
              }
              Disattivazione utenza
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={6}>
                  {activeUserSupplyPointData.supplyType === 'POD' &&
                    <ExtendedSelectField
                      name="socketDemolition"
                      isMulti={false}
                      fieldLabel="Con demolizione presa*"
                      placeholder="Con demolizione presa"
                      options={formValues?.socketDemolition || []}
                      defaultValue={activeUserSupplyPointData?.socketDemolition || null}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                    />
                  }
                  {activeUserSupplyPointData.supplyType === 'PDR' &&
                    <ExtendedSelectField
                      name="systemRemoval"
                      isMulti={false}
                      fieldLabel="Con rimozione impianti*"
                      placeholder="Con rimozione impianti"
                      options={formValues?.systemRemoval || []}
                      defaultValue={activeUserSupplyPointData?.systemRemoval || null}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                    />
                  }
                </Col>
                <Col md={6}>
                  <CustomDatePicker
                    id="doNotExecuteBefore"
                    name="doNotExecuteBefore"
                    type="date"
                    fieldLabel="Da effettuare non prima del"
                    adjustDateOnChange
                    validate={noPastDate(true)}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    initialValue={activeUserSupplyPointData?.doNotExecuteBefore || todayDate}
                    disabled={canEdit ? false : true}
                  />
                </Col>
              </Row>
            </div>
          </div>
          
          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || canEdit === false}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-1" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default DeactivationForm