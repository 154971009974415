import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ScrollToTop from '../../../hooks/navigation/useScrollToTop'
import BankAccountItems from './BankAccountItems/BankAccountItems.component'
import BankAccountDashboardControlButtons from './BankAccountDashboardControlButtons/BankAccountDashboardControlButtons.component'
import BankAccountDetailModal from './BankAccountDetailModal/BankAccountDetailModal.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingData, selectFormStep } from '../../../redux/contract/contract.selectors'
import { contractGetItemBankAccountDataStart } from '../../../redux/contract/contract.actions'
import { ContractTabsMap } from '../../../redux/contract/contract.utils'

const BankAccountItemsWithSpinner = WithSpinner(BankAccountItems)

const BankAccountDashboard = () => {
  const dispatch = useDispatch()
  const formStep = useSelector(selectFormStep)
  const loadingBankAccountData = useSelector(state => selectIsFetchingData('bankAccount')(state))

  //Get form data if opening already existing proposal form
  useEffect(() => {
    if(formStep > ContractTabsMap.PAYMENT_STEP) dispatch(contractGetItemBankAccountDataStart())
  }, [dispatch, formStep])

  return(
    <div className="form-dashboard">
      <ScrollToTop />
      <div className="list">
        <div className="cadastral-header">
          <h1 className="title">Elenco dei metodi di pagamento</h1>
        </div>
        <BankAccountItemsWithSpinner isLoading={loadingBankAccountData} />
        <BankAccountDetailModal />
        <BankAccountDashboardControlButtons />
      </div>
    </div>
  )
}

export default BankAccountDashboard
