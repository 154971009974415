import { useMemo } from "react"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row, Col } from 'reactstrap'

import CustomFormField from "../../inputFields/CustomFormField/CustomFormField.component"
import CustomReadOnlyField from "../../inputFields/CustomReadOnlyField/CustomReadOnlyField.component"
import ExtendedSelectField from "../../inputFields/ExtendedSelectField/ExtendedSelectField.component"
import ExtendedAutocompleteFormField from "../../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component"
import CustomDatePicker from "../../inputFields/CustomDatePicker/CustomDatePicker.component"
import { required } from "../../validations"
import { fetchTowns } from "../../../../api"
import { selectContract, selectUserFormFieldsValues, selectProposalFormFieldsValues } from "../../../../redux/contract/contract.selectors"
import { selectUserData } from "../../../../redux/user/user.selectors"
import { ContractStatusMap } from "../../../../redux/contract/contract.utils"
import CustomerTypes, { getCustomerType, getDefaultOption } from "../utils"
import * as utils from '../../../../utils'

const GeneralData = ({ canEdit, values }) => {
  const { selectedContractId, proposalData, selectedContractStatusId } = useSelector(selectContract)
  const { name, code, accessToken } = useSelector(selectUserData)
  const userFormValues = useSelector(selectUserFormFieldsValues)
  const formValues = useSelector(selectProposalFormFieldsValues)
  const defaultUserCode = useMemo(() => ({ value: code, label: name }), [code, name])
  const defaultLanguageValue = getDefaultOption(formValues.language) || null

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="file" className="mr-2" />
        Dati
      </div>
      <div className="grouped-body">
        <CustomFormField
          name="addressId"
          type="hidden"
          initialValue={proposalData?.addressId || null}
        />
        <CustomFormField
          name="contactId"
          type="hidden"
          initialValue={proposalData?.contactId || null}
        />
        <CustomFormField
          name="identityDocumentId"
          type="hidden"
          initialValue={proposalData?.identityDocumentId || null}
        />
        {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, values.customerType) &&
          <CustomFormField
            name="accountHolderPersonId"
            type="hidden"
            initialValue={proposalData?.accountHolderPersonId || null}
          />
        }
        {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, values.customerType) &&
          <>
            <CustomFormField
              name="accountHolderBusinessId"
              type="hidden"
              initialValue={proposalData ? proposalData.accountHolderBusinessId : null}
            />
            <CustomFormField
              name="legalUserId"
              type="hidden"
              initialValue={proposalData?.legalUserId || null}
            />
          </>
        }

        <Row form>
          <Col md={12}>
            <CustomReadOnlyField
              id="userLogged"
              name="userLogged"
              placeholder="Utente"
              fieldLabel="Utente"
              type="text"
              initialValue={proposalData?.userLogged || name}
            />
          </Col>
          <Col md={12}>
            <ExtendedSelectField
              name="userCode"
              isMulti={false}
              fieldLabel="Agente venditore**"
              placeholder="Agente venditore**"
              options={userFormValues?.userCode || []}
              initialValue={proposalData?.userCode || defaultUserCode}
              isDisabled={selectedContractId ? true : false}
              customFilterFunction={(candidate, input) => {
                if (input) {
                  const lowerCaseCandidate = candidate.data.label.toLowerCase()
                  return lowerCaseCandidate.includes(input.toLowerCase())
                }
                return true
              }}                    
              getOptionLabel={option =>
                <>{option.value} - {option.label}</>
              }
            />
            <p className="small text-muted">**Attenzione: una volta inserito non potrà essere più modificato!</p>                    
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <ExtendedSelectField
              name="customerType"
              isMulti={false}
              fieldLabel="Tipologia cliente*"
              placeholder="Tipologia cliente*"
              options={formValues?.customerType || []}
              initialValue={proposalData?.customerType || null}
              isDisabled={selectedContractId ? true : false}
              validate={required}
            />
            <p className="small text-muted">**Attenzione: una volta inserito non potrà essere più modificato!</p>
          </Col>
          <Col md={6}>
            <ExtendedAutocompleteFormField
              name="subscriptionTownName"
              fieldLabel="Comune*"
              placeholder="Comune*"
              fetchFunction={fetchTowns(accessToken)}
              disabled={canEdit ? false : true}
              initialValue={proposalData?.subscriptionTownName || null}
              validate={required}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => ({ town: option.town, province: option.province })}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <CustomDatePicker
              id="compilationDate"
              name="compilationDate"
              type="date"
              fieldLabel="Data caricamento"
              readOnly
              dateFormat="dd/MM/yyyy"
              className="form-control"
              initialValue={proposalData?.compilationDate || utils.getTodayDate()}
            />
          </Col>
          {selectedContractStatusId >= ContractStatusMap.DONE_STATUS &&
            <Col md={6}>
              <CustomDatePicker
                className="form-control"
                id="signingDate"
                name="signingDate"
                type="date"
                dateFormat="dd/MM/yyyy"
                fieldLabel="Data firma"
                initialValue={proposalData?.signingDate || null}
                readOnly
              />
            </Col>
          }
          <Col md={6}>
            <ExtendedSelectField
              name="language"
              isMulti={false}
              fieldLabel="Lingua contratto*"
              placeholder="Lingua contratto*"
              validate={required}
              options={formValues?.language || []}
              initialValue={proposalData?.language || defaultLanguageValue}
              isDisabled={canEdit ? false : true}
              fieldDescription="Questa opzione definisce la lingua con la quale sarà redatto il contratto in formato PDF dopo la firma."
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default GeneralData