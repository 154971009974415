import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { OnChange } from 'react-final-form-listeners'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import CustomDatePicker from '../inputFields/CustomDatePicker/CustomDatePicker.component'
import ExtendedSelectField from '../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import ExtendedCustomZipCodeSelect from '../inputFields/ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component'
import ExtendedAutocompleteFormField from '../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import { ReactComponent as PensoInVerdeIcon } from '../../../assets/images/penso-verde.svg'
import WhenFieldChanges from '../WhenFieldChanges/WhenFieldChanges.component'
import { canI } from '../../auth/CanI/utils'
import { required, emailCheck, requiredLength, positiveNumber, noPastDate } from '../validations'
import { getAPIUrl, fetchTowns } from '../../../api'
import PdrSupplyForm from './PdrSupplyForm/PdrSupplyForm.component'
import PdrFAttachmentForm from './PdrFAttachmentForm/PdrFAttachmentForm.component'
import PdrEAttachmentForm from './PdrEAttachmentForm/PdrEAttachmentForm.component'
import PdrOtherAttachementsDataForm from './PdrOtherAttachementsDataForm/PdrOtherAttachementsDataForm.component'
import { getEntityUriName } from '../../../redux/contract/contract.utils'
import { selectUserData } from '../../../redux/user/user.selectors'
import { selectContract, selectPdrFormFieldsValues, selectBankAccountDataAsArray, selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractPdrSubmitFormStart, contractShowErrorMessage } from '../../../redux/contract/contract.actions'
import CustomerTypes, { DefaultSelectValues, getCustomerType, isFAttachmentRequired,
  isEAttachmentRequired, isSupplyFormNeeded, fetchVendors, noValidationRequired,
  isOriginMarketNeeded, isPublicAuthorityNeeded,
  isServiceStartDateNeeded, isVendorNameNeeded, isResidentNeeded, isUseTypeNeeded,
  fetchGasUseTypeId, fetchRemiCodes, fetchContractPriceList, fetchVatClassList, fetchDistributors,
  isPotentialityNeeded, isGasExciseClassIdNeeded, isMeterReadNumberNeeded
} from './utils'
import { potentialityCalculators } from '../PdrPotentialityForm/utils'
import PdrPotentialityForm from '../PdrPotentialityForm/PdrPotentialityForm.component'

//Mutator for updating a form value
const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

//Focus on firt field with an error
const focusOnError = createDecorator()

const PdrForm = () => {
  const dispatch = useDispatch()
  const { email, accessToken } = useSelector(selectUserData)
  const { cadastralDetailData, pdrData, entityName, customerType, selectedContractId, pdrDetailModalVisibility } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('pdr')(state))
  const bankAccountData = useSelector(selectBankAccountDataAsArray)
  const formValues = useSelector(selectPdrFormFieldsValues)
  let canEdit = true
  if(pdrData) {
    canEdit = canI(`${entityName}PdrUPDATE`, pdrData.links).abilityCheck
  }

  //Handle async api calls (NO REDUX) errors
  const handleError = useCallback(error => dispatch(contractShowErrorMessage({ message: error.message })), [dispatch])
  
  //Get remi code
  const [ remiCodes, setRemiCodes ] = useState([])
  useEffect(() => {
    if(cadastralDetailData && pdrDetailModalVisibility === true) {
      fetchRemiCodes(cadastralDetailData.id, entityName, accessToken)
        .then(response => setRemiCodes(response.data))
        .catch(handleError)
    }
  }, [cadastralDetailData, accessToken, entityName, pdrDetailModalVisibility, handleError])

  //Fetch contract price list from API on values change
  const [ contractPriceList, setContractPriceList ] = useState([])
  useEffect(() => {
    if(pdrData && pdrData.gasUseTypeId && pdrDetailModalVisibility === true) {
      fetchContractPriceList(entityName, selectedContractId, pdrData.id, pdrData.gasUseTypeId.value, pdrData.logicalPoint.value, email, accessToken)
        .then(response => setContractPriceList(response.data))
        .catch(handleError)
    }
  }, [pdrData, email, entityName, selectedContractId, accessToken, pdrDetailModalVisibility, handleError])

  //Fetch vat class list from API on values change
  const [ useType, setUseType ] = useState(pdrData?.gasUseTypeId?.value || "")
  const [ vatClassList, setVatClassList ] = useState(formValues?.vatClass || [])
  useEffect(() => {
    if(useType && pdrDetailModalVisibility === true) {
      fetchVatClassList(customerType.value, entityName, useType, accessToken)
        .then(response => {
          setVatClassList(response.data)
        })
        .catch(handleError)
    }
  }, [customerType, entityName, useType, accessToken, pdrDetailModalVisibility, handleError])

  const onSubmit = values => {
    const method = pdrData && canEdit === true ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/pdrs${pdrData ? `/${pdrData.id}` : ''}`
    dispatch(contractPdrSubmitFormStart({ apiUrl, values, method }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{updateFieldValue}}
      decorators={[focusOnError, potentialityCalculators]}
      initialValues={{
        cadastralDataId: cadastralDetailData?.id || null,
        bankAccountDetailId: bankAccountData.length ? bankAccountData[0].id : null
      }}
      validate={values => {
        let errors = {}
        if((values.otherDeviceNo || values.otherDevicePotentiality) && !values.otherDevice) {
          errors.otherDevice = 'Required'
        }

        return errors
      }}
    >
      {({ handleSubmit, form, values, reset }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <WhenFieldChanges
            field="gasUseTypeId"
            set="contractPriceListCode"
            to={null}
          />
          <WhenFieldChanges
            field="gasUseTypeId"
            set="vatClassId"
            to={null}
          />
          <WhenFieldChanges
            field="gasUseTypeId"
            set="gasExciseClassId"
            to={null}
          />

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="file" className="mr-2" />
              Dati generali
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={6}>
                  <CustomFormField
                    id="no"
                    name="no"
                    type="text"
                    disabled={canEdit ? false : true}
                    placeholder={`Codice PDR${noValidationRequired(entityName) ? '*' : ''}`}
                    fieldLabel={`Codice PDR${noValidationRequired(entityName) ? '*' : ''}`}
                    maxLength="14"
                    validate={noValidationRequired(entityName) ? requiredLength(14) : requiredLength(14, false)}
                    defaultValue={pdrData?.no || null}
                  />
                </Col>
                {isMeterReadNumberNeeded(entityName) &&
                  <Col md={6}>
                    <CustomFormField
                      id="meterReadNumber"
                      name="meterReadNumber"
                      type="text"
                      maxLength="80"
                      disabled={canEdit ? false : true}
                      min="0"
                      placeholder="Matricola contatore*"
                      fieldLabel="Matricola contatore*"
                      validate={required}
                      defaultValue={pdrData?.meterReadNumber || null}
                    />
                  </Col>
                }
                {isVendorNameNeeded(entityName) &&
                  <Col md={6}>
                    <ExtendedAutocompleteFormField
                      id="vendorName"
                      name="vendorName"
                      fieldLabel="Attuale fornitore*"
                      placeholder="Attuale fornitore*"
                      fetchFunction={fetchVendors(entityName, accessToken)}
                      defaultValue={pdrData?.vendorName || null}
                      disabled={canEdit ? false : true}
                      validate={required}
                      noOptionsMessage={() => 
                        <span className="autocomplete-suggestion">Indica le prime lettere dell'attuale fornitore per trovarlo nella lista</span>
                      }
                    />
                  </Col>
                }
                <Col md={6}>
                  <CustomFormField
                    id="annualConsumption"
                    name="annualConsumption"
                    type="number"
                    maxLength="80"
                    disabled={canEdit ? false : true}
                    placeholder="Consumo annuo (smc)*"
                    fieldLabel="Consumo annuo (smc)*"
                    validate={positiveNumber(true)}
                    defaultValue={pdrData?.annualConsumption || null}
                  />
                </Col>
                <Col md={6}>
                  <ExtendedSelectField
                    name="gasUseCategoryCode"
                    isMulti={false}
                    fieldLabel="Categoria uso AEEG*"
                    placeholder="Categoria uso AEEG*"
                    options={formValues?.gasUseCategory || []}
                    defaultValue={pdrData?.gasUseCategoryCode || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                <Col md={6}>
                  <ExtendedAutocompleteFormField
                    id="distributorCode"
                    name="distributorCode"
                    fieldLabel="Distributore"
                    placeholder="..."
                    fetchFunction={fetchDistributors(entityName, cadastralDetailData?.id, accessToken)}
                    defaultValue={pdrData?.distributorCode || null}
                    disabled={canEdit ? false : true}
                    noOptionsMessage={() => 
                      <span className="autocomplete-suggestion">Indica le prime lettere del distributore per trovarlo nella lista</span>
                    }
                  />
                </Col>
                <Col md={6}>
                  <ExtendedSelectField
                    name="logicalPoint"
                    isMulti={false}
                    fieldLabel="Codice REMI*"
                    placeholder="Codice REMI*"
                    options={remiCodes}
                    defaultValue={pdrData?.logicalPoint || null}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                  <p className="small text-muted">
                    Se il Codice Remi digitato non è presente in elenco è da intendersi <strong>non sottoscrivibile</strong>
                  </p>
                  <OnChange name="logicalPoint">
                    {objValue => {
                      if(objValue) {
                        fetchContractPriceList(entityName, selectedContractId, pdrData?.id, values.gasUseTypeId?.value, objValue.value, email, accessToken)
                          .then(response => setContractPriceList(response.data))
                          .catch(error => console.error(error))
                      }
                    }}
                  </OnChange>
                </Col>

                {isServiceStartDateNeeded(entityName) &&
                  <Col md={6}>
                    <CustomDatePicker
                      id="serviceStartDate"
                      name="serviceStartDate"
                      className="form-control"
                      type="date"
                      adjustDateOnChange
                      dateFormat="dd/MM/yyyy"
                      placeholder="Inizio stimato fornitura"
                      fieldLabel="Inizio stimato fornitura"
                      defaultValue={pdrData?.serviceStartDate || null}
                      disabled={canEdit ? false : true}
                      validate={noPastDate(false)}
                    />
                  </Col>
                }

                {isOriginMarketNeeded(entityName) &&
                  <Col md={6}>
                    <ExtendedSelectField
                      name="originMarket"
                      isMulti={false}
                      fieldLabel="Mercato di provenienza*"
                      placeholder="Mercato di provenienza*"
                      defaultValue={pdrData?.originMarket || null}
                      options={formValues?.originMarket || []}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                    />
                  </Col>
                }

                {isPublicAuthorityNeeded(entityName) &&
                  <>
                    <Col md={3}>
                      <ExtendedSelectField
                        name="publicAuthority"
                        isMulti={false}
                        fieldLabel="Ente pubblico?*"
                        placeholder="Ente pubblico?*"
                        defaultValue={pdrData?.publicAuthority || null}
                        options={formValues?.publicAuthority || []}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                      />
                    </Col>
                    <Col md={3}>
                      <ExtendedSelectField
                        name="condominium"
                        isMulti={false}
                        fieldLabel="Condominio?*"
                        placeholder="Condominio?*"
                        defaultValue={pdrData?.condominium || null}
                        options={formValues?.condominium || []}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomFormField
                        id="supplyNumbers"
                        name="supplyNumbers"
                        type="number"
                        maxLength="20"
                        disabled={canEdit ? false : true}
                        placeholder="N° punti di prelievo*"
                        fieldLabel="N° punti di prelievo*"
                        validate={positiveNumber(true)}
                        defaultValue={pdrData?.supplyNumbers || null}
                      />
                    </Col>
                  </>
                }
              </Row>
              {isPotentialityNeeded(entityName) &&
                <PdrPotentialityForm 
                  pdrData={pdrData}
                  canEdit={canEdit}
                  values={values}
                />
              }
            </div>
          </div>

          {isSupplyFormNeeded(entityName) &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="list" className="mr-2" />
                Fornitura GAS
              </div>
              <div className="grouped-body">
                <PdrSupplyForm
                  entityName={entityName}
                  formValues={formValues}
                  values={values}
                  pdrData={pdrData}
                  canEdit={canEdit}
                  customerType={customerType}
                />
              </div>
            </div>
          }

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="list" className="mr-2" />
              Configurazione offerta
            </div>
            <div className="grouped-body">
              <Row form>
                {isUseTypeNeeded(entityName) ? (
                  <Col md={6}>
                    <ExtendedSelectField
                      name="gasUseTypeId"
                      isMulti={false}
                      fieldLabel="Tipo uso*"
                      placeholder="Tipo uso*"
                      options={formValues?.gasUseType || []}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                      defaultValue={pdrData?.gasUseTypeId || null}
                    />
                  </Col>
                ) : (
                  <>
                    <CustomFormField
                      name="gasUseTypeId"
                      type="hidden"
                      defaultValue={pdrData?.gasUseTypeId || null}
                    />
                    <OnChange name="contractType">
                      {objValue => {
                        if(objValue) {
                          fetchGasUseTypeId(objValue.value, entityName, accessToken)
                            .then(response => form.mutators.updateFieldValue("gasUseTypeId", response.data))
                            .catch(error => console.error(error))
                        }
                      }}
                    </OnChange>
                  </>
                )}
                <Col md={6}>
                  <ExtendedSelectField
                    name="contractPriceListCode"
                    isMulti={false}
                    fieldLabel="Offerta*"
                    placeholder="Offerta*"
                    defaultValue={pdrData?.contractPriceListCode || null}
                    options={contractPriceList}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                    getOptionLabel={option =>
                      <>
                        {option.hasGreenEnergy === true &&
                          <PensoInVerdeIcon className="penso-in-verde-icon" />
                        }
                        <span>{option.label}</span>
                      </>
                    }
                  />
                  <p className="small text-muted"><PensoInVerdeIcon className="penso-in-verde-icon" /> Indica gas 100% verde.</p>
                  <OnChange name="gasUseTypeId">
                    {objValue => {
                      if(objValue) {
                        fetchContractPriceList(entityName, selectedContractId, pdrData?.id, objValue.value, values.logicalPoint?.value, email, accessToken)
                          .then(response => setContractPriceList(response.data))
                          .catch(error => console.error(error))
                      }
                    }}
                  </OnChange>
                </Col>
                <Col md={6}>
                  <ExtendedSelectField
                    name="vatClassId"
                    isMulti={false}
                    fieldLabel="Classe IVA*"
                    placeholder="Classe IVA*"
                    defaultValue={pdrData?.vatClassId || null}
                    options={vatClassList}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                  <OnChange name="gasUseTypeId">
                    {objValue => setUseType(objValue?.value || null)}
                  </OnChange>
                </Col>
                { 
                  getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
                  isGasExciseClassIdNeeded(entityName, values) &&
                    <Col md={6}>
                      <ExtendedSelectField
                        name="gasExciseClassId"
                        isMulti={false}
                        fieldLabel="Classe accise"
                        placeholder="Classe accise"
                        defaultValue={pdrData?.gasExciseClassId || null}
                        options={formValues?.gasExciseClass || []}
                        isDisabled={canEdit ? false : true}
                      />
                    </Col>
                }

                {
                  getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) && 
                  isResidentNeeded(entityName) &&
                  <Col md={6}>
                    <ExtendedSelectField
                      name="isResident"
                      isMulti={false}
                      fieldLabel="Residente*"
                      placeholder="Residente*"
                      defaultValue={pdrData?.isResident || DefaultSelectValues.IS_NOT_RESIDENT}
                      options={formValues?.pdrResident || []}
                      isDisabled={canEdit ? false : true}
                      validate={required}
                    />
                  </Col>
                }
              </Row>
            </div>
          </div>

          {
            isFAttachmentRequired(entityName) &&
            parseInt(values.vatClassId?.value) === 2 &&
            (
              getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) ||
              (getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) &&
              values.gasUseTypeId &&
              parseInt(values.gasUseTypeId?.value) !== 1)
            ) &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="list" className="mr-2" />
                Allegato F
              </div>
              <div className="grouped-body">
                <PdrFAttachmentForm
                  formValues={formValues}
                  values={values}
                  pdrData={pdrData}
                  canEdit={canEdit}
                />
              </div>
            </div>
          }

          {
            isEAttachmentRequired(entityName) &&
            getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
            values.gasExciseClassId &&
            parseInt(values.gasExciseClassId?.value) !== 13 &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="list" className="mr-2" />
                Allegato E
              </div>
              <div className="grouped-body">
                <PdrEAttachmentForm
                  formValues={formValues}
                  values={values}
                  pdrData={pdrData}
                  canEdit={canEdit}
                  entityName={entityName}
                />
              </div>
            </div>
          }

          {
            getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
            ((values.gasExciseClassId && parseInt(values.gasExciseClassId?.value) !== 13) ||
             parseInt(values.vatClassId?.value) === 2) &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="list" className="mr-2" />
                Altri dati per allegati E ed F
              </div>
              <div className="grouped-body">
                <PdrOtherAttachementsDataForm
                  pdrData={pdrData}
                  formValues={formValues}
                  canEdit={canEdit}
                />
              </div>
            </div>
          }

          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="map-marker-alt" className="mr-2" />
              Recapito fatture e comunicazioni
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={6}>
                  <ExtendedSelectField
                    name="isInvoiceDifferentEmail"
                    isMulti={false}
                    fieldLabel="Vuoi ricevere la fattura ad un altro indirizzo email?*"
                    placeholder="Vuoi ricevere la fattura ad un altro indirizzo email?*"
                    defaultValue={pdrData?.isInvoiceDifferentEmail || DefaultSelectValues.IS_INVOICE_DIFFERENT_EMAIL}
                    options={formValues?.isInvoiceDifferentEmail || []}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
                {parseInt(values.isInvoiceDifferentEmail?.value) === 1 &&
                  <Col md={6}>
                    <CustomFormField
                      id="invoiceDifferentEmail"
                      name="invoiceDifferentEmail"
                      maxLength="80"
                      type="text"
                      disabled={canEdit ? false : true}
                      fieldLabel="E-mail*"
                      validate={emailCheck(true)}
                      defaultValue={pdrData?.invoiceDifferentEmail || null}
                      style={{ textTransform: 'lowercase' }}
                    />
                  </Col>
                }
              </Row>
              <Row form>
                <Col md={6}>
                  <ExtendedSelectField
                    name="billingShippingAddress"
                    isMulti={false}
                    fieldLabel="Desideri ricevere la fattura anche in formato cartaceo?*"
                    placeholder="Desideri ricevere la fattura anche in formato cartaceo?*"
                    options={formValues?.billingShippingAddress || []}
                    defaultValue={pdrData?.billingShippingAddress || DefaultSelectValues.BILLING_SHIPPING_ADDRESS}
                    isDisabled={canEdit ? false : true}
                    validate={required}
                  />
                </Col>
              </Row>
              {parseInt(values.billingShippingAddress?.value) === 3 &&
                <>
                  <Row form>
                    <Col md={6}>
                      <CustomFormField
                        id="atName"
                        name="atName"
                        type="text"
                        maxLength="80"
                        disabled={canEdit ? false : true}
                        placeholder="Presso*"
                        fieldLabel="Presso*"
                        validate={required}
                        defaultValue={pdrData?.atName || null}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={3}>
                      <ExtendedSelectField
                        name="toponymId"
                        isMulti={false}
                        fieldLabel="Toponimo*"
                        placeholder="Toponimo*"
                        options={formValues?.toponymIc || []}
                        defaultValue={pdrData?.toponymId || null}
                        isDisabled={canEdit ? false : true}
                        validate={required}
                      />
                    </Col>
                    <Col md={7}>
                      <CustomFormField
                        id="streetName"
                        name="streetName"
                        type="text"
                        maxLength="35"
                        disabled={canEdit ? false : true}
                        placeholder="Indirizzo*"
                        fieldLabel="Indirizzo*"
                        validate={required}
                        defaultValue={pdrData?.streetName || null}
                      />
                    </Col>
                    <Col md={2}>
                      <CustomFormField
                        id="streetNo"
                        name="streetNo"
                        type="text"
                        maxLength="4"
                        disabled={canEdit ? false : true}
                        placeholder="Civico*"
                        fieldLabel="Civico*"
                        validate={required}
                        defaultValue={pdrData?.streetNo || null}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={10}>
                      <WhenFieldChanges
                        field="townName"
                        set="postalCode"
                        to={null}
                      />
                      <ExtendedAutocompleteFormField
                        id="townName"
                        name="townName"
                        fieldLabel="Comune*"
                        placeholder="Comune*"
                        fetchFunction={fetchTowns(accessToken)}
                        disabled={canEdit ? false : true}
                        defaultValue={pdrData?.townName || null}
                        validate={required}
                        getOptionLabel={option => `${option.town} (${option.province})`}
                        getOptionValue={option => ({ town: option.town, province: option.province })}
                        noOptionsMessage={() => 
                          <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <ExtendedCustomZipCodeSelect
                        name="postalCode"
                        isMulti={false}
                        placeholder="CAP*"
                        fieldLabel="CAP*"
                        validate={required}
                        defaultValue={pdrData?.postalCode || null}
                        isDisabled={canEdit ? false : true}
                        fieldToListen="townName"
                        fieldToListenDefaultValue={pdrData?.townName || null}
                      />
                    </Col>
                  </Row>
                </>
              }
            </div>
          </div>

          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || !canEdit}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-2" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-2" />
              )}
              <span>Salva PDR</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default PdrForm
