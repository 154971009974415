import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/analytics'

import confJson from '../api/configuration.json'
import store from '../redux/store'
import { userUpdateMaintenanceState } from '../redux/user/user.actions'

const config = {
  "development": {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    apiKey: "AIzaSyAq72SnPJjGbvBIfvMr4fQJ31pZSa2K95s",
    authDomain: "store-portal-development.firebaseapp.com",
    databaseURL: "https://store-portal-development-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "store-portal-development",
    storageBucket: "store-portal-development.appspot.com",
    messagingSenderId: "319070797366",
    appId: "1:319070797366:web:6cededd79f4db8399e6297",
    measurementId: "G-FEFRH9EHLW"
  },
  "development2": {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    apiKey: "AIzaSyAq72SnPJjGbvBIfvMr4fQJ31pZSa2K95s",
    authDomain: "store-portal-development.firebaseapp.com",
    databaseURL: "https://store-portal-development-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "store-portal-development",
    storageBucket: "store-portal-development.appspot.com",
    messagingSenderId: "319070797366",
    appId: "1:319070797366:web:6cededd79f4db8399e6297",
    measurementId: "G-FEFRH9EHLW"
  },
  "staging": {
    apiKey: "AIzaSyDXN77O8_n5UP1SnrKgGr3HKwoMm0QKjeo",
    authDomain: "store-portal-staging.firebaseapp.com",
    databaseURL: "https://store-portal-staging-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "store-portal-staging",
    storageBucket: "store-portal-staging.appspot.com",
    messagingSenderId: "825280959066",
    appId: "1:825280959066:web:185ed1d0ff90638211fe63",
    measurementId: "G-92X71M10DG"
  },
  "production": {
    apiKey: "AIzaSyCgtTxeyGzmmBRBJZoJufZ2umfTJDiRblc",
    authDomain: "store-portal-prod.firebaseapp.com",
    databaseURL: "https://store-portal-prod-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "store-portal-prod",
    storageBucket: "store-portal-prod.appspot.com",
    messagingSenderId: "667022757632",
    appId: "1:667022757632:web:9366189759fee31e186de5",
    measurementId: "G-03M9Q6RES8"
  }
}

firebase.initializeApp(config[confJson.env])
if(confJson.env === 'production') firebase.analytics()
export const auth = firebase.auth()

const maintenanceRef = firebase.database().ref()

export const getMaintenanceStatus = () => {
  return new Promise((resolve, reject) => {    
    maintenanceRef.on('value', (snapshot) => {
      const data = snapshot.val()
      resolve && resolve(data)
    }, reject)
  })
}

export const getMaintenanceStatusUpdates = () => {
  maintenanceRef.on('value', (snapshot) => {
    const data = snapshot.val()
    store.dispatch(userUpdateMaintenanceState({ maintenanceState: data }))
  })
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export const refreshFirebaseToken = firebaseUser => firebaseUser.getIdToken()

export const confirmPasswordReset = (code, newPassword) => auth.confirmPasswordReset(code, newPassword)

export const signOut = () => auth.signOut()

export default firebase
