import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PageHeader from '../../components/PageHeader/PageHeader.component'
import ListContainer from '../../components/lists/all/ListContainer/ListContainer.component'
import ScrollToTop from '../../hooks/navigation/useScrollToTop'
import { contractSetEntityName } from '../../redux/contract/contract.actions'

const AllList = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(contractSetEntityName('all'))
  }, [dispatch])
  
  return (
    <div className="page">
      <ScrollToTop />
      <PageHeader pageTitle="Tutti i contratti" icon="list" />
      <ListContainer />
    </div>
  )
}

export default AllList
