import moment from 'moment'
import { isValidIBAN } from 'ibantools'

import {
  emailRegExpCheck, euPhoneRegExpCheck, itZipRegExpCheck, vatNumberRegExpCheck, codiceFISCALE,
  stringLengthRegExpCheck, isItalianIban, dateRegExpCheck, dateRegExpCheckEng, integerNumberRegExpCheck
} from '../../utils'
import { formLabels } from './formLabels'

export const composeValidators = (...validators) => (value, allValues) => {
  return validators.reduce((error, validator) => error || validator(value, allValues), undefined)
}

//Required field validation
export const required = value => {
  if(typeof value === 'string') {
    return value?.length && value.trim().length ? undefined : formLabels['REQUIRED']
  }
  return value ? undefined : formLabels['REQUIRED']
}

export const requiredArray = value => value?.length ? undefined : formLabels['REQUIRED']

export const requiredIfDomestic = (value, allValues) => {
  return !value && !allValues.oldAccountHolderClientBusinessName ? formLabels['REQUIRED'] : undefined
}

export const requiredIfBusiness = (value, allValues) => {
  return !value && !allValues.oldAccountHolderName && !allValues.oldAccountHolderSurname ? formLabels['REQUIRED'] : undefined
}

export const requiredIfBusinessName = (value, allValues) => {
  return !value && allValues.oldAccountHolderClientBusinessName && !allValues.oldAccountHolderName && !allValues.oldAccountHolderSurname ? formLabels['REQUIRED'] : undefined
}

export const clientCodeCheck = value => {
  const regExp = /^[Dd|Bb]\d{6}$/
  return regExp.test(String(value)) ? undefined : formLabels['CLIENT_CODE']
}

//Date field validation
export const dateFormat = required => value => {
  if(required) {
    if(dateRegExpCheck(value) || dateRegExpCheckEng(value)) {
      return undefined
    }
    else {
      return formLabels['BAD_FORMAT']
    }
  }
  else {
    if(value && (!dateRegExpCheck(value) && !dateRegExpCheckEng(value))) {
      return formLabels['BAD_FORMAT']
    }
  }
}

export const noFutureDate = value => {
  if(value) {
    const inputValue = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'])
    const now = moment()
    return inputValue.diff(now, 'hours') > 0 ? formLabels['NO_FUTURE_DATE'] : undefined
  }

  return formLabels['REQUIRED']
}

export const noPastDate = required => value => {
  const inputValue = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'])
  const now = moment()

  if(required) {
    return now.diff(inputValue, 'days') > 0 ? formLabels['NO_PAST_DATE'] : undefined
  }
  else {
    if(value) {
      return now.diff(inputValue, 'days') > 0 ? formLabels['NO_PAST_DATE'] : undefined
    }
  }
}

//Number must be positive
export const positiveNumber = required => value => {
  if(required) {
    if(value && value > 0) {
      return undefined
    }
    else {
      return formLabels['POSITIVE_NUMBER']
    }
  }
  else {
    if(value && value <= 0) {
      return formLabels['POSITIVE_NUMBER']
    }
  }
}

export const integerNumber = required => value => {
  if(required) {
    if(value && integerNumberRegExpCheck(value)) {
      return undefined
    }
    else {
      return formLabels['INTEGER_NUMBER']
    }  
  }
  else {
    if(value && !integerNumberRegExpCheck(value)) {
      return formLabels['INTEGER_NUMBER']
    }
  }
}

//String min length
export const requiredLength = (length, required = true) => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }

    if(value && stringLengthRegExpCheck(value, length)) {
      return undefined
    }
    else {
      return `Deve essere composto da ${length} caratteri!`
    }
  }
  else {
    if(value && !stringLengthRegExpCheck(value, length)) {
      return `Deve essere composto da ${length} caratteri!`
    }
  }
}

//Email validation
export const emailCheck = required => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }

    if(value && emailRegExpCheck(value)) {
      return undefined
    }
    else {
      return formLabels['INVALID_EMAIL']
    }
  }
  else {
    if(value && !emailRegExpCheck(value)) {
      return formLabels['INVALID_EMAIL']
    }
  }
}

//Check if the phone number is a valid EU number
export const euPhoneCheck = required => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }

    if(value && euPhoneRegExpCheck(value)) {
      return undefined
    }
    else {
      return formLabels['INVALID_PHONE']
    }
  }
  else {
    if(value && !euPhoneRegExpCheck(value)) {
      return formLabels['INVALID_PHONE']
    }
  }
}

//Check zip code
export const itZipCheck = (required = false) => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }

    if(value && itZipRegExpCheck(value)) {
      return undefined
    }
    else {
      return formLabels['INVALID_ZIPCODE']
    }
  }
  else {
    if(value && !itZipRegExpCheck(value)) {
      return formLabels['INVALID_ZIPCODE']
    }
  }
}

//Checkbox required
export const checkboxRequired = value => {
  if(value && value === true) {
    return undefined
  }
  else {
    return formLabels['REQUIRED']
  }
}

//Must be adult
export const mustBeAdult = value => {
  if(value) {
    const inputValue = moment(value).format('YYYY-MM-DD')
    return moment().diff(inputValue, 'years') >= 18 ? undefined : formLabels['ADULT_REQUIRED']
  }
  else {
    return formLabels['REQUIRED']
  }
}

//Fiscal code check
export const fiscalCodeCheck = required => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }
    else {
      if(value && codiceFISCALE(value)) {
        return undefined
      }
      else {
        return formLabels['INVALID_CF']
      }
    }
  }
  else {
    if(value && !codiceFISCALE(value)) {
      return formLabels['INVALID_CF']
    }
  }
}

//Vat number check
export const vatNumber = required => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }

    if(value && vatNumberRegExpCheck(value)) {
      return undefined
    }
    else {
      return formLabels['INVALID_VAT']
    }
  }
  else {
    if(value && !vatNumberRegExpCheck(value)) {
      return formLabels['INVALID_VAT']
    }
  }
}

//Check Fiscal code OR vat number in same field
export const vatNumberAsFiscalCode = required => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }
    else {
      if(value && (codiceFISCALE(value) || vatNumberRegExpCheck(value))) {
        return undefined
      }
      else {
        return formLabels['INVALID_CF_VAT']
      }
    }
  }
  else {
    if(value && (!codiceFISCALE(value) && !vatNumberRegExpCheck(value))) {
      return formLabels['INVALID_CF_VAT']
    }
  }
}

export const ibanCode = required => value => {
  if(required) {
    if(!value) {
      return formLabels['REQUIRED']
    }

    if(value && isItalianIban(value) && isValidIBAN(value)) {
      return undefined
    }
    else {
      return formLabels['INVALID_IBAN']
    }
  }
  else {
    if(value && (!isItalianIban(value) || !isValidIBAN(value))) {
      return formLabels['INVALID_IBAN']
    }
  }
}

export const minValue = min => value =>
  isNaN(value) || value > min ? undefined : `Il numero deve essere maggiore di ${min}`

export const greaterOrEqualValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Il numero deve essere uguale o maggiore di ${min}`
