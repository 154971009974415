import styled from 'styled-components'

export const HeaderStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  .navbar {
    background-color: rgba(0, 101, 171, 0);
    transition: background-color .3s ease;

    .navbar-brand {
      transform: scale(1.1);
      opacity: 0;
      transition: opacity .8s ease-out, transform 1s ease;

      .username {
        font-size: .75rem;
        display: none;
        text-align: center;
        margin-top: .15rem;

        @media (max-width: 767px) {
          display: block;
        }
      }
    }

    &.ready {
      background-color: rgba(0, 101, 171, 1);

      .navbar-brand {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`
