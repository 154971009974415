import React from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'react-final-form'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import classnames from 'classnames'
import AsyncSelect from 'react-select/async'

import Emoji from '../../../Emoji/Emoji.component'
import { customStyles } from './style'
import { selectUserToken } from '../../../../redux/user/user.selectors'

const ExtendedAutocompleteFormField = ({ children, ...props }) => {
  const accessToken = useSelector(selectUserToken)

  return (
    <FormGroup>
      <Field {...props}>
        {({ isMulti = false, isClearable = true, fetchFunction, fieldLabel, fieldDescription, noValidationMessage,
          input: { name, value, onChange, onBlur }, meta: { error, touched }, options, className, ...input }) => (
          <>
            {fieldLabel &&
              <Label for={name}>{fieldLabel}</Label>
            }
            <AsyncSelect
              styles={customStyles}
              className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
              value={value}
              isMulti={isMulti}
              isClearable={isClearable}
              onChange={value => {
                onChange(value)
              }}
              onBlur={onBlur}
              loadOptions={value => fetchFunction(value, accessToken)}
              {...input}
            />
            <FormFeedback className={classnames({ 'd-flex': error && touched })}>
              {error}
              {' '}
              <Emoji
                label="police officer"
                symbol="👮"
              />
            </FormFeedback>
            <FormFeedback valid className={classnames({ 'd-flex': !error && touched })}>
              {fieldLabel}
              {' '}
              <Emoji
                label="thumb up"
                symbol="👍🏻"
              />
            </FormFeedback>
          </>
        )}
      </Field>
    </FormGroup>
  )
}

export default ExtendedAutocompleteFormField
