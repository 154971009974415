import moment from 'moment'
import _ from 'lodash'

import { UserRoles } from '../../../../redux/user/user.utils'

export function parseDate(value) {
  return moment(value).format('YYYY-MM-DD')
}

export function setQueryString(values) {
  let queryParams = []

  _.forEach(values, (value, key) => {
    if(key === 'signingDateStart' || key === 'insertDateStart') {
      queryParams.push(`${key}=${parseDate(value)}`)
    }
    else if(key === 'signingDateEnd' || key === 'insertDateEnd') {
      queryParams.push(`${key}=${parseDate(value)} 23:59:59.999`)
    }
    else {
      queryParams.push(`${key}=${encodeURIComponent(value)}`)
    }
  })

  return queryParams
}

export function isActiveUserEntity(entityName) {
  return (
    entityName === 'deactivation'
  ) ? true : false
}

export function isValidatorUser(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_ADMIN ||
    userRole === UserRoles.USER_ROLE_VERIFIER ||
    userRole === UserRoles.USER_ROLE_READER ||
    userRole === UserRoles.USER_ROLE_SUPPORT
  ) ? true : false
}

export function isResponsibleUser(userRole) {
  return (
    userRole === UserRoles.USER_ROLE_RESPONSIBLE
  ) ? true : false
}