import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import AttachmentsForm from './AttachmentsForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingData } from '../../../redux/contract/contract.selectors'
import { contractGetAttachmentsDataStart } from '../../../redux/contract/contract.actions'

const AttachmentsFormWithSpinner = WithSpinner(AttachmentsForm)

const AttachmentsFormContainer = () => {
  const dispatch = useDispatch()
  const isFetchingAttachementsData = useSelector(state => selectIsFetchingData('attachment')(state))

  useEffect(() => {
    dispatch(contractGetAttachmentsDataStart())
  }, [dispatch])

  return (
    <AttachmentsFormWithSpinner isLoading={isFetchingAttachementsData} />
  )
}

export default AttachmentsFormContainer
