import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import CanI from '../../../auth/CanI/CanI.component'
import TdTable from '../../TdTable/TdTable.component'
import SupplyPointStatusHistoryModal from '../../../SupplyPointStatusHistoryModal/SupplyPointStatusHistoryModal.component'
import DeleteItem from '../../../DeleteItem/DeleteItem.component'
import SupplyPointStatusUpdate from '../../../SupplyPointStatusUpdate/SupplyPointStatusUpdate.component'
import { selectUserData } from '../../../../redux/user/user.selectors'
import { UserRoles } from '../../../../redux/user/user.utils'
import { selectIsDeleting, selectEntityName } from '../../../../redux/contract/contract.selectors'
import { contractDeleteItemStart } from '../../../../redux/contract/contract.actions'
import { getEntityPath, getSupplyPointStatusIcon, isActiveUserContract } from '../../../../redux/contract/contract.utils'

const PdrTableRows = ({
  id,
  contractId,
  proposalCode,
  supplyPointStatus,
  statusName,
  no,
  logicalPoint,
  links,
  contractPriceListCode,
  sortParam,
  customerCode,
  customerName,
  customerEmail,
  userAgentName,
  signingDate,
  insertDate
}) => {
  const { role } = useSelector(selectUserData)
  const entityName = useSelector(selectEntityName)
  const isDeleting = useSelector(state => selectIsDeleting(id)(state))

  return (
    <tr key={id} className={`${isDeleting === true ? 'is-deleting' : ''}`}>
      <TdTable
        activeSortParam={sortParam}
        sortingName="statusId"
        otherClasses={["text-center", "status"]}
      >
        <FontAwesomeIcon size="lg" className="mr-2" icon={getSupplyPointStatusIcon(supplyPointStatus)} title={statusName} />
      </TdTable>
      <TdTable 
        value={proposalCode}
        activeSortParam={sortParam}
        sortingName="proposalCode"
      />
      {isActiveUserContract(entityName) &&
        <TdTable
          value={customerCode}
          activeSortParam={sortParam}
          sortingName="customerCode"
        />
      }
      <TdTable value={customerName} />
      <TdTable
        value={customerEmail}
        activeSortParam={sortParam}
        sortingName="customerEmail"
      />
      <TdTable value={no} />
      <TdTable value={userAgentName} />
      <TdTable
        value={signingDate ? moment(signingDate).format('DD-MM-YYYY') : ""}
        activeSortParam={sortParam}
        sortingName="signingDate"
      />
      <TdTable
        value={insertDate ? moment(insertDate).format('DD-MM-YYYY') : ""}
        activeSortParam={sortParam}
        sortingName="insertDate"
      />
      {!isActiveUserContract(entityName) &&
        <TdTable
          value={logicalPoint}
          activeSortParam={sortParam}
          sortingName="logicalPoint"
        />
      }
      <TdTable
        value={contractPriceListCode}
        activeSortParam={sortParam}
        sortingName="offer"
      />
      <TdTable otherClasses={["actions-col"]}>
        <div className="buttons-container">
          {(role === UserRoles.USER_ROLE_ADMIN || role === UserRoles.USER_ROLE_VERIFIER) &&
            <CanI doWhat={"GET"} withPermissions={links} entityName={`${entityName}Pdr`}>
              {({ action }) => (
                <SupplyPointStatusHistoryModal
                  itemId={id}
                  actionUrl={action}
                  links={links}
                  supplyType="pdr"
                />
              )}
            </CanI>
          }
          <CanI doWhat={"PATCH"} withPermissions={links} entityName={`${entityName}Pdr`}>
            {({ action }) => (
              <SupplyPointStatusUpdate
                itemId={id}
                statusId={supplyPointStatus}
                actionUrl={action}
                supplyType="pdr"
                buttonProps={{
                  color: 'secondary',
                  title: 'Modifica lo stato del contratto di fornitura',
                  label: ''
                }}
              />
            )}
          </CanI>
          <CanI doWhat={"GET"} withPermissions={links} entityName={`${entityName}Pdr`}>
            {({ action }) => (
              <Link
                target="_blank"
                title="Vedi dettaglio"
                className="btn btn-outline-secondary btn-sm mx-1"
                to={`/${getEntityPath(entityName)}/${contractId}/`}
              >
                <FontAwesomeIcon size="sm" icon="eye" />
              </Link>
            )}
          </CanI>
          <CanI doWhat={"DELETE"} withPermissions={links} entityName={`${entityName}Pdr`}>
            {({ action }) => (
              <DeleteItem
                itemId={id}
                actionUrl={action}
                actionFunction={contractDeleteItemStart}
                isDeleting={isDeleting}
              />
            )}
          </CanI>
        </div>
      </TdTable>
    </tr>
  )
}

export default PdrTableRows
