import { useSelector, useDispatch } from 'react-redux'
import { Form } from "react-final-form"
import createDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Spinner } from 'reactstrap'

import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import { abilityCheck } from '../../../auth/CanI/utils'
import { getAPIUrl } from '../../../../api'
import { required } from '../../validations'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'
import { 
  selectContract, selectPodFormFieldsValues, selectPdrFormFieldsValues, selectIsSubmitting
} from '../../../../redux/contract/contract.selectors'
import { contractSubmitActiveUserSupplyPointDataStart } from '../../../../redux/contract/contract.actions'
import { capitalizeWord } from '../../../../utils'

const focusOnError = createDecorator()

const PlantVerifyForm = () => {
  const dispatch = useDispatch()
  const podFormValues = useSelector(selectPodFormFieldsValues)
  const pdrFormValues = useSelector(selectPdrFormFieldsValues)
  const { entityName, activeUserSupplyPointData, selectedContractId } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('supplyPointTechOp')(state))

  let canEdit = true
  if(activeUserSupplyPointData?.id) {
    canEdit = abilityCheck(`${entityName}${capitalizeWord(activeUserSupplyPointData.supplyType)}UPDATE`, activeUserSupplyPointData.links)
  }

  const onSubmit = values => {
    const method = activeUserSupplyPointData?.id && canEdit === true ? "PUT" : "POST"
    const apiUrl = `${getAPIUrl()}/${getEntityUriName(entityName)}/${activeUserSupplyPointData.supplyType.toLowerCase()}s${activeUserSupplyPointData?.id ? `/${activeUserSupplyPointData.id}` : ''}`
    dispatch(contractSubmitActiveUserSupplyPointDataStart({ apiUrl, values, method }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={{
        ...activeUserSupplyPointData,
        plantVerifyId: selectedContractId
      }}
    >
      {({ handleSubmit, values }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="grouped">
            <div className="grouped-header">
              {activeUserSupplyPointData.supplyType === 'POD' &&
                <FontAwesomeIcon icon="bolt" className="mr-2" />
              }
              {activeUserSupplyPointData.supplyType === 'PDR' &&
                <FontAwesomeIcon icon="fire" className="mr-2" />
              }
              Verifica impianti
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={12}>
                  <ExtendedSelectField
                    name="requestedVariation"
                    fieldLabel="Richiede la seguente variazione*"
                    placeholder="Richiede la seguente variazione"
                    options={
                      activeUserSupplyPointData.supplyType === 'POD' ? (
                        podFormValues?.verifyVariations || []
                      ) : (
                        pdrFormValues?.verifyVariations || []
                      )
                    }
                    isDisabled={canEdit ? false : true}
                    validate={required}
                    defaultValue={activeUserSupplyPointData?.requestedVariation || null}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || canEdit === false}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-1" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default PlantVerifyForm