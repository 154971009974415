import { createSelector } from 'reselect'

import { ContractTabsMap, ContractStatusMap, AsyncStatusEnum } from './contract.utils'

export const selectContract = state => state.contract

export const selectIsFetching = createSelector(
  [selectContract],
  contract => contract.isFetching
)

export const selectIsSubmitting = searchedEntityName => createSelector(
  [selectContract],
  contract => {
    const [ submittingEntityName, asyncStatus ] = contract.isSubmitting
    return searchedEntityName === submittingEntityName && asyncStatus === AsyncStatusEnum.LOADING ? true : false
  }
)

export const selectIsDeleting = searchedEntityName => createSelector(
  [selectContract],
  contract => {
    const [ deletingEntityName, asyncStatus ] = contract.isDeleting
    return searchedEntityName === deletingEntityName && asyncStatus === AsyncStatusEnum.LOADING ? true : false
  }
)

export const selectUtilityModal = createSelector(
  [selectContract],
  contract => contract.utilityModal
)

export const selectUtilityToast = createSelector(
  [selectContract],
  contract => contract.utilityToast
)

export const selectEntityName = createSelector(
  [selectContract],
  contract => contract.entityName
)

export const selectFormFilters = createSelector(
  [selectContract],
  contract => contract.formFilters
)

export const selectFormFieldsValues = createSelector(
  [selectContract],
  contract => contract.formFieldsValues
)

export const selectProposalFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => formFieldsValues?.proposal || null
)

export const selectCadastralFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => formFieldsValues?.cadastralData || null
)

export const selectPodFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => formFieldsValues?.pod || null
)

export const selectPdrFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => formFieldsValues?.pdr || null
)

export const selectSupplyPointFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => formFieldsValues?.supplyPoint || null
)

export const selectAttachmentsFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => formFieldsValues?.attachment || null
)

export const selectList = createSelector(
  [selectContract],
  contract => contract.selectedList
)

export const selectListUrl = createSelector(
  [selectContract],
  contract => contract.selectedListUrl
)

export const selectIsFetchingList = createSelector(
  [selectIsFetching, selectList],
  (isFetching, selectedList) => isFetching === AsyncStatusEnum.LOADING && !selectedList.items ? true : false
)

export const selectItemsListAsArray = createSelector(
  [selectList],
  ({ items }) => items ? Object.keys(items).map((key, i) => items[key]) : []
)

export const selectListExcelExportUrl = fetchUrl => createSelector(
  [selectList],
  ({ page, filters, sorts }) => (`${fetchUrl}&page=${page}${filters ? '&' + filters : ''}&${sorts}`)
)

export const selectIsFetchingReports = createSelector(
  [selectIsFetching, selectContract],
  (isFetching, contract) => isFetching === AsyncStatusEnum.LOADING && !contract.contractReports.length ? true : false
)

export const selectIsFetchingNotes = createSelector(
  [selectIsFetching, selectContract],
  (isFetching, contract) => isFetching === AsyncStatusEnum.LOADING && !contract.contractNotes.length ? true : false
)

export const selectIsFetchingHistory = createSelector(
  [selectIsFetching, selectContract],
  (isFetching, contract) => isFetching === AsyncStatusEnum.LOADING && !contract.itemHistoryData.length ? true : false
)

export const selectContractId = createSelector(
  [selectContract],
  contract => contract.selectedContractId
)

export const selectContractStatusId = createSelector(
  [selectContract],
  contract => contract.selectedContractStatusId
)

export const selectIsFetchingFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => !formFieldsValues ? true : false
)

export const selectFormStep = createSelector(
  [selectContract],
  contract => contract.formStep
)

export const selectUserFormFieldsValues = createSelector(
  [selectFormFieldsValues],
  formFieldsValues => {
    const userFormFieldsValues = formFieldsValues.user
    return userFormFieldsValues
  }
)

export const selectFormSelectedStep = createSelector(
  [selectContract],
  contract => contract.selectedStep
)

export const selectUserData = createSelector(
  [selectContract],
  contract => contract.userData
)

export const selectProposalData = createSelector(
  [selectContract],
  contract => contract.proposalData
)

export const selectBankAccountData = createSelector(
  [selectContract],
  contract => contract.bankAccountData
)

export const selectBankAccountDataAsArray = createSelector(
  [selectBankAccountData],
  bankAccountData => bankAccountData ? Object.keys(bankAccountData).map((key, i) => bankAccountData[key]) : []
)

export const selectCadastralData = createSelector(
  [selectContract],
  contract => contract.cadastralData || null
)

export const selectCadastralDataItems = createSelector(
  [selectCadastralData],
  cadastralData => cadastralData?.items || null
)

export const selectSupplyPoints = createSelector(
  [selectContract],
  contract => contract.supplyPoints || []
)

export const selectPodSupplyPoints = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints?.pods || []
)

export const selectPdrSupplyPoints = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints?.pdrs || []
)

export const selectValidateAllSupplyPoints = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints?.validateAll || false
)

export const selectBlockAllSupplyPoints = createSelector(
  [selectSupplyPoints],
  supplyPoints => supplyPoints?.blockAll || false
)

export const selectCadastralDataItemsAsArray = createSelector(
  [selectCadastralDataItems],
  cadastralDataItems => cadastralDataItems ? Object.keys(cadastralDataItems).map((key, i) => cadastralDataItems[key]) : []
)

export const selectBankAccountDetailModalVisibility = createSelector(
  [selectContract],
  contract => contract.bankAccountDetailModalVisibility
)

export const selectCadastralDetailModalVisibility = createSelector(
  [selectContract],
  contract => contract.cadastralDetailModalVisibility
)

export const selectPermissions = createSelector(
  [selectProposalData],
  proposalData => proposalData?.links || {}
)

export const selectIsFetchingData = searchedEntityName => createSelector(
  [selectContract],
  contract => {
    const [ fetchingEntityName, asyncStatus ] = contract.isFetching
    return searchedEntityName === fetchingEntityName && asyncStatus === AsyncStatusEnum.LOADING ? true : false
  }
)

export const selectIsFetchingUserData = createSelector(
  [selectIsFetchingFormFieldsValues, selectUserData, selectContractId],
  (isFetchingFormFieldsValues, userData, contractId) => (
    isFetchingFormFieldsValues === true || (userData === null && contractId !== null) ? true : false
  )
)

export const selectIsFetchingProposalData = createSelector(
  [selectIsFetching, selectIsFetchingFormFieldsValues, selectProposalData, selectContractId],
  (isFetching, isFetchingFormFieldsValues, proposalData, contractId) => {
    const [ , asyncStatus ] = isFetching
    return (
      isFetchingFormFieldsValues === true || 
      (
        proposalData === null && 
        contractId !== null && 
        asyncStatus === AsyncStatusEnum.LOADING
      )
    ) ? true : false
  }
)

export const selectIsFetchingActiveCustomerProposalData = createSelector(
  [selectIsFetching, selectIsFetchingFormFieldsValues, selectContractId],
  (isFetching, isFetchingFormFieldsValues, contractId) => {
    const [ fetchingEntityName , asyncStatus ] = isFetching
    return (
      isFetchingFormFieldsValues === true || 
      (
        contractId !== null && 
        fetchingEntityName === 'proposal' && 
        asyncStatus === AsyncStatusEnum.LOADING
      )
    ) ? true : false
  }
)

export const selectIsFetchingBankAccountData = createSelector(
  [selectIsFetching, selectFormStep, selectBankAccountDataAsArray],
  (isFetching, formStep, bankAccountData) =>
    isFetching === AsyncStatusEnum.LOADING &&
    formStep > ContractTabsMap.PAYMENT_STEP &&
    bankAccountData.length === 0 ? (
      true
    ) : (
      false
    )
)

export const selectIsFetchingBankAccountDetailData = createSelector(
  [selectIsFetching, selectContract],
  (isFetching, contract) => isFetching === AsyncStatusEnum.LOADING && contract.bankAccountDetailData === null ? true : false
)

export const selectIsFetchingCadastralData = createSelector(
  [selectIsFetching, selectCadastralData],
  (isFetching, cadastralData) => isFetching === AsyncStatusEnum.LOADING && cadastralData === null ? true : false
)

export const selectIsFetchingCadastralDetailData = createSelector(
  [selectIsFetching, selectContract],
  (isFetching, contract) => isFetching === AsyncStatusEnum.LOADING && contract.cadastralDetailData === null ? true : false
)

export const selectIsFetchingPodData = createSelector(
  [selectIsFetching, selectContract],
  (isFetching, contract) => isFetching === AsyncStatusEnum.LOADING && contract.podData === null ? true : false
)


export const selectIsFetchingPdrData = createSelector(
  [selectIsFetching, selectContract],
  (isFetching, contract) => isFetching === AsyncStatusEnum.LOADING && contract.pdrData === null ? true : false
)

export const selectIsFecthingAttachmentsData = createSelector(
  [selectContract],
  contract => (
    contract.isFetching === AsyncStatusEnum.LOADING ||
    (!contract.contractPods.length && !contract.contractPdrs.length )
  ) ? true : false
)

export const selectIsFetchingOtpData = createSelector(
  [selectContract],
  contract => contract.isFetching === AsyncStatusEnum.LOADING && contract.otpData === null ? true : false
)

export const selectActiveUserSupplyPointModalvisibility = createSelector(
  [selectContract],
  contract => contract.supplyPointModalVisibility
)

export const selectPodDetailModalvisibility = createSelector(
  [selectContract],
  contract => contract.podDetailModalVisibility
)

export const selectPdrDetailModalvisibility = createSelector(
  [selectContract],
  contract => contract.pdrDetailModalVisibility
)

export const selectAttachmentsData = createSelector(
  [selectContract],
  contract => contract?.attachmentsData || null
)

export const selectActiveUserAttachmentsData = createSelector(
  [selectContract],
  contract => contract?.activeUserAttachmentsData || null
)

export const selectAttachmentsQueue = createSelector(
  [selectContract],
  contract => contract?.attachmentsQueue || null
)

export const selectAttachmentsDataAsArray = createSelector(
  [selectAttachmentsData, selectAttachmentsQueue],
  (attachmentsData, attachmentsQueue) => {
    const attachments = attachmentsData ? Object.keys(attachmentsData).map((key, i) => attachmentsData[key]) : []
    const queue = attachmentsQueue ? Object.keys(attachmentsQueue).map((key, i) => attachmentsQueue[key]) : []
    return attachments.concat(queue)
  }
)

export const selectActiveUserAttachmentsDataAsArray = createSelector(
  [selectActiveUserAttachmentsData],
  activeUserAttachmentsData => activeUserAttachmentsData ? (
    Object.keys(activeUserAttachmentsData).map(key => activeUserAttachmentsData[key])
  ) : []
)

export const selectCiFrontAttachmentData = createSelector(
  [selectActiveUserAttachmentsDataAsArray],
  activeUserAttachmentsDataAsArray => {
    return activeUserAttachmentsDataAsArray.filter(attachment => parseInt(attachment.attachmentTypeId?.value) === 4) || null
  }
)

export const selectCiBackAttachmentData = createSelector(
  [selectActiveUserAttachmentsDataAsArray],
  activeUserAttachmentsDataAsArray => {
    return activeUserAttachmentsDataAsArray.filter(attachment => parseInt(attachment.attachmentTypeId?.value) === 5) || null
  }
)

export const selectOtpData = createSelector(
  [selectContract],
  contract => contract.otpData
)

export const selectOtpSignerId = createSelector(
  [selectContract],
  contract => contract.otpSignerId
)

export const selectOtpSigners = createSelector(
  [selectOtpData],
  otpData => otpData?.signers || null
)

export const selectOtpSignersAsArray = createSelector(
  [selectOtpSigners],
  otpSigners => otpSigners ? Object.keys(otpSigners).map((key, i) => otpSigners[key]) : []
)

export const selectUpcomingOtpSignersAsArray = createSelector(
  [selectOtpSignersAsArray],
  otpSigners => otpSigners.filter(signer => signer.signed === false)
)

export const selectOtpSignatureModalVisibility = createSelector(
  [selectContract],
  contract => contract.otpSignatureModalVisibility
)

export const selectIsFecthingPdfUrl = createSelector(
  [selectContract],
  ({ selectedContractStatusId, isFetching, contractPdfUrl }) => (
    (selectedContractStatusId >= ContractStatusMap.DONE_STATUS && !contractPdfUrl) || isFetching === AsyncStatusEnum.LOADING ? true : false
  )
)

export const selectSignatureTypeModalVisibility = createSelector(
  [selectContract],
  contract => contract.signatureTypeModalVisibility
)

export const selectContractFinalizationModalVisibility = createSelector(
  [selectContract],
  contract => contract.contractFinalizationModalVisibility
)

export const selectSignatureType = createSelector(
  [selectContract],
  contract => contract.selectedSignatureType
)

export const selectAttachmentsIntegratinHistory = createSelector(
  [selectProposalData],
  proposalData => proposalData?.attachmentsIntegrationHistory?.length ? (
    proposalData.attachmentsIntegrationHistory
  ) : (
    []
  )
)