import styled from 'styled-components'

const SupplyPointItemStyle = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border: none;
  box-shadow: 0px 3px 10px #00000029;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 991.9px) {
    flex-wrap: wrap;
  }
  
  .text-container {
    font-size: 18px;
    color: #337ab7;
    font-weight: bold;
    text-transform: uppercase;
    flex-grow: 1;
    line-height: 1.2;

    sup {
      font-size: 10px;
      margin-left: .5rem;
      font-weight: bold;
      padding: .1rem .5rem;
      background-color: #ffc525;
      border-radius: 10px;
      vertical-align: super;
    }

    svg {
      font-size: 1rem;
    }

    .more-info {
      margin-top: .75rem;
      font-size: 14px;
      text-transform: none;
      
      @media (max-width: 576px) {
        margin-top: 0;
      }

      .advice {
        color: #333;
        margin-top: .75rem;
        margin-bottom: 0;
      }

      button {
        padding: 0;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .context-menu {
    display: flex;

    @media (max-width: 991.9px) {
      margin-top: 1.5rem;
    }

    @media (max-width: 767.9px) {
      flex-wrap: wrap;
    }

    .btn {
      margin-left: .5rem;

      @media (max-width: 991.9px) {
        margin-left: 0;
        margin-right: .5rem;
      }

      @media (max-width: 767.9px) {
        margin-top: .5rem;
      }
    }
  }
`

export default SupplyPointItemStyle