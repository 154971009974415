import React from 'react'
import { useSelector } from 'react-redux'
import { Jumbotron, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ThankYouItemStyle } from './style'
import { selectContract } from '../../redux/contract/contract.selectors'

const ThankYouItem = ({ title, message, buttonLabel, icon }) => {
  const { contractPdfUrl } = useSelector(selectContract)

  return(
    <ThankYouItemStyle className="thank-you-item">
      <Jumbotron>
        <h1 className="title">{title}</h1>
        <div className="separator my-3"></div>
        <p className="description">{message}</p>
        {buttonLabel && contractPdfUrl ? (
          <p className="lead mb-0">
            <Button
              target="_blank"
              href={contractPdfUrl}
              className="btn ab-button"
              rel="noopener noreferrer"
            >
              {icon &&
                <FontAwesomeIcon icon={icon} size="lg" className="mr-2" />
              }
              {buttonLabel}
            </Button>
          </p>
        ) : (
          <p>Si è verificato un errore e il contratto non è al momento scaricabile. Ti invitiamo a provare più tardi.</p>
        )}
      </Jumbotron>
    </ThankYouItemStyle>
  )
}

export default ThankYouItem
