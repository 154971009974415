import { useSelector } from 'react-redux'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import WhenFieldChanges from '../WhenFieldChanges/WhenFieldChanges.component'
import { selectContract, selectProposalFormFieldsValues, selectIsSubmitting} from '../../../redux/contract/contract.selectors'
import { required, euPhoneCheck, fiscalCodeCheck, emailCheck } from '../validations'
import { canI } from '../../auth/CanI/utils'

const ApplicantData = ({ values }) => {
  const { entityName, customerData, proposalData } = useSelector(selectContract)
  const isSubmittingProposalData = useSelector(state => selectIsSubmitting('proposal')(state))
  const formValues = useSelector(selectProposalFormFieldsValues)
  const canEdit = proposalData ? canI(`${entityName}UPDATE`, proposalData.links).abilityCheck : true

  return (
    <>
      {customerData &&
        <>
          <div className="grouped">
            <div className="grouped-header">
              <FontAwesomeIcon icon="user" className="mr-2" />
              Il richiedente:
            </div>
            <div className="grouped-body">
              <Row form>
                <Col md={6}>
                  <CustomFormField
                    name="applicantName"
                    fieldLabel="Nome*"
                    placeholder="Nome"
                    validate={required}
                    disabled={canEdit ? false : true}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    name="applicantSurname"
                    fieldLabel="Cognome*"
                    placeholder="Cognome"
                    validate={required}
                    disabled={canEdit ? false : true}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <CustomFormField
                    name="applicantFiscalCode"
                    fieldLabel="Codice fiscale*"
                    placeholder="Codice fiscale"
                    validate={fiscalCodeCheck(true)}
                    style={{ textTransform: 'uppercase' }}
                    disabled={canEdit ? false : true}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    name="applicantPhone"
                    fieldLabel="Telefono*"
                    placeholder="Telefono"
                    type="number"
                    className="phone-number"
                    validate={euPhoneCheck(true)}
                    disabled={canEdit ? false : true}
                  />
                </Col>
                <Col md={6}>
                  <CustomFormField
                    name="applicantEmail"
                    fieldLabel="E-mail"
                    placeholder="E-mail"
                    validate={emailCheck(false)}
                    disabled={canEdit ? false : true}
                  />
                </Col>
                <Col md={6}>
                  <ExtendedSelectField
                    name="applicantRole"
                    isMulti={false}
                    fieldLabel="In qualità di*"
                    placeholder="In qualità di"
                    options={formValues?.applicantRoles || []}
                    validate={required}
                    isDisabled={canEdit ? false : true}
                  />
                  <WhenFieldChanges
                    field="applicantRole"
                    set="applicantRoleOther"
                    to={parseInt(values.applicantRole?.value) === 6 ? values.applicantRoleOther : null}
                  />
                </Col>
                {parseInt(values?.applicantRole?.value) === 6 &&
                  <Col md={12}>
                    <CustomFormField
                      id="applicantRoleOther"
                      name="applicantRoleOther"
                      type="text"
                      maxLength="80"
                      validate={required}
                      disabled={canEdit ? false : true}
                      placeholder="Altro(specificare)*"
                      fieldLabel="Altro(specificare)*"
                    />
                  </Col>
                }
              </Row>
            </div>
          </div>
          <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="comments" className="mr-2" />
                Riferimento interno
              </div>
              <div className="grouped-body privacy">
                <Row form>
                  <Col md={12}>
                    <CustomFormField
                      id="reference"
                      name="reference"
                      type="textarea"
                      maxLength="512"
                      disabled={canEdit ? false : true}
                      placeholder="Riferimento interno"
                      fieldLabel="Riferimento interno"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          <div className="text-right">
            <Button
              type="submit"
              disabled={isSubmittingProposalData || !canEdit}
            >
              {isSubmittingProposalData ? (
                <Spinner size="sm" color="save" className="mr-2" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-2" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </>
      }
    </>
  )
}

export default ApplicantData