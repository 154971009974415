import React from 'react'
import { useSelector } from 'react-redux'

import ActiveUserProposalForm from './ActiveUserProposalForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingActiveCustomerProposalData } from '../../../redux/contract/contract.selectors'

const ActiveUserProposalFormWithSpinner = WithSpinner(ActiveUserProposalForm)

const ActiveUserProposalFormContainer = () => {
  const isFetchingProposalData = useSelector(selectIsFetchingActiveCustomerProposalData)

  return (
    <ActiveUserProposalFormWithSpinner isLoading={isFetchingProposalData} />
  )
}

export default ActiveUserProposalFormContainer
