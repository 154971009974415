import React from 'react'
import { FormGroup, Label, FormFeedback, Input } from 'reactstrap'
import Emoji from '../../../Emoji/Emoji.component'

const LabelledTextInput = (props) => {
  const { input, meta, fieldLabel, disabled, noValidationMessage, maxLength } = props
  const { name } = input
  const { error, touched } = meta
  const inputInvalid = error && touched ? true : false
  const inputValid = !error && touched ? true : false
  const disabledInput = disabled ? {disabled: "disabled"} : ''

  return(
    <FormGroup>
      <Label for={name}>{fieldLabel}</Label>
      <Input maxLength={maxLength} {...input} placeholder={fieldLabel} valid={inputValid} invalid={inputInvalid} {...disabledInput} />
      {!noValidationMessage ?
        <>
          {inputInvalid ?
            <FormFeedback>
              {error}
              {' '}
              <Emoji
                label="police officer"
                symbol="👮"
              />
            </FormFeedback>
            :
            <FormFeedback valid>
              {fieldLabel}
              {' '}
              <Emoji
                label="thumb up"
                symbol="👍🏻"
              />
            </FormFeedback>
          }
        </>
        :
        null
      }
    </FormGroup>
  )
}

export default LabelledTextInput
