import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'react-final-form'
import Select from 'react-select'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import { OnChange } from 'react-final-form-listeners'

import { getAPIUrl } from '../../../../api'
import { debounce, fetchData } from './utils'
import { selectUserData } from '../../../../redux/user/user.selectors'
import Emoji from '../../../Emoji/Emoji.component'
import { customStyles } from './style'

const ExtendedCustomZipCodeSelect = ({ fieldToListen, fieldToListenDefaultValue, ...props }) => {
  const [ options, setOptions ] = useState([])
  const { accessToken } = useSelector(selectUserData)

  const updateValues = useCallback(value => {
    setOptions([])
    fetchData(
      `${getAPIUrl('abStorePortal')}/form-items/postal-codes?townName=${value.town}`,
      accessToken
    )
    .then(({ data }) => setOptions(data.map(value => ({ value: value, label: value }))))
    .catch(error => console.error(error.message))
  }, [accessToken])

  useEffect(() => {
    if(fieldToListenDefaultValue) updateValues(fieldToListenDefaultValue)
  }, [fieldToListenDefaultValue, updateValues])

  return (
    <FormGroup>
      <Field {...props}>
        {({ isMulti, fieldLabel, fieldDescription, noValidationMessage, input: { name, value, onChange, onBlur }, meta: { error, touched }, className, ...input }) => (
          <>
            {fieldLabel &&
              <Label for={input.name}>{fieldLabel}</Label>
            }
            <Select
              styles={customStyles}
              className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
              value={value}
              isMulti={isMulti}
              options={options}
              onChange={value => onChange(value)}
              onBlur={onBlur}
              {...input}
            />
            {error && touched ?
              <FormFeedback>
                {error}
                {' '}
                <Emoji
                  label="police officer"
                  symbol="👮"
                />
              </FormFeedback>
              :
              <FormFeedback valid>
                {fieldLabel}
                {' '}
                <Emoji
                  label="thumb up"
                  symbol="👍🏻"
                />
              </FormFeedback>
            }
            <OnChange name={fieldToListen}>
              {debounce(value => {
                if(value?.town && value.town.length >= 2) {
                  updateValues(value)
                }
              }, 300)}
            </OnChange>
          </>
        )}
      </Field>
    </FormGroup>
  )
}

export default ExtendedCustomZipCodeSelect
