import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ScrollToTop from '../../../hooks/navigation/useScrollToTop'
import CadastralDashboardHeader from './CadastralDashboardHeader/CadastralDashboardHeader.component'
import CadastralItemsList from './CadastralItemsList/CadastralItemsList.component'
import CadastralDashboardControlButtons from './CadastralDashboardControlButtons/CadastralDashboardControlButtons.component'
import CadastralDetailModal from './CadastralDetailModal/CadastralDetailModal.component'
import PodDetailModal from '../PodDetailModal/PodDetailModal.component'
import PdrDetailModal from '../PdrDetailModal/PdrDetailModal.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingData, selectIsDeleting } from '../../../redux/contract/contract.selectors'
import { contractGetCadastralDataStart } from '../../../redux/contract/contract.actions'

const CadastralItemsListWithSpinner = WithSpinner(CadastralItemsList)

const CadastralDashboard = () => {
  const dispatch = useDispatch()
  const loadingCadastralData = useSelector(state => selectIsFetchingData('cadastral')(state))
  const deletingCadastralData = useSelector(state => selectIsDeleting('cadastral')(state))

  useEffect(() => {
    dispatch(contractGetCadastralDataStart())
  }, [dispatch])

  return (
    <div className="form-dashboard">
      <ScrollToTop />
      <div className="list">
        <CadastralDashboardHeader />
        <CadastralItemsListWithSpinner isLoading={loadingCadastralData || deletingCadastralData} />
      </div>
      <CadastralDetailModal />
      <PodDetailModal />
      <PdrDetailModal />
      <CadastralDashboardControlButtons />
    </div>
  )
}

export default CadastralDashboard
