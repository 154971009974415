import React from 'react'

import './style.scss'

const WithSpinner = WrappedComponent => {
  const Spinner = ({ isLoading, ...otherProps }) => {
    return isLoading ? (
      <div className="spinner-overlay">
        <div className="spinner-container" />
        <span className="spinner-label">Caricamento...</span>
      </div>
    ) : (
      <WrappedComponent {...otherProps} />
    )
  }

  return Spinner
}

export default WithSpinner
