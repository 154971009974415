import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Alert, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SignContractAlertStyle } from './style'
import { userGetSignatureTypesStart } from '../../redux/user/user.actions'
import { selectIsFetchingSignatureTypes, selectUserSignatureTypes } from '../../redux/user/user.selectors'
import { selectContract } from '../../redux/contract/contract.selectors'
import { contractSetSelectedSignatureType } from '../../redux/contract/contract.actions'

const SignContractAlert = () => {
  const dispatch = useDispatch()
  const { selectedContractId, entityName } = useSelector(selectContract)
  const isFetchingSignatureTypes = useSelector(selectIsFetchingSignatureTypes)
  const userSignatureTypes = useSelector(selectUserSignatureTypes)

  useEffect(() => {
    dispatch(userGetSignatureTypesStart({ contractId: selectedContractId, openingCausal: entityName }))
  }, [dispatch, selectedContractId, entityName])

  return (
    <SignContractAlertStyle>
      <Alert color="success">
        <h4 className="alert-heading">Il contratto ora può essere finalizzato!</h4>
        <p className="alert-description">
          Sono stati inseriti i dati necessari alla sottoscrizione del contratto.
          È possibile procedere con la finalizzazione selezionando un metodo tra quelli disponibili, cliccando sul pulsante corrispondente qui in basso.
        </p>
        <hr />
        {isFetchingSignatureTypes ? (
          <div className="signature-types-loader">
            <Spinner size="sm" />
            <span className="ml-2 small loader-text">Caricamento dei metodi di finalizzazione in corso...</span>
          </div>
        ) : (
          <div className="signature-type-container mb-0">
            {userSignatureTypes.map((item, i) => (
              <Button
                className="ab-button"
                key={i}
                onClick={() => dispatch(contractSetSelectedSignatureType({ signatureType: parseInt(item.value) }))}
              >
                <FontAwesomeIcon icon={parseInt(item.value) === 1 ? ['fas', 'mobile-alt'] : ['fas', 'file-upload']} className="mr-2" />
                {item.label}
              </Button>
            ))}
            {!userSignatureTypes.length &&
              <p className="small"><FontAwesomeIcon icon="exclamation-triangle" size="lg" className="mr-2" /> Nessun metodo di finalizzazione disponibile.</p>
            }
          </div>
        )}
      </Alert>
    </SignContractAlertStyle>
  )
}

export default SignContractAlert
