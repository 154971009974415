import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { passwordFormat } from './utils'
import CustomFormField from '../../forms/inputFields/CustomFormField/CustomFormField.component'
import { selectIsSubmitting } from '../../../redux/user/user.selectors'
import { userSetNewPasswordStart } from '../../../redux/user/user.actions'

const ResetPasswordForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)

  const [ inputType, setInputType ] = useState('password')
  const togglePasswordVisibility = () => {
    if(inputType === 'password') {
      setInputType('text')
    }
    else {
      setInputType('password')
    }
  }

  const onSubmit = values => dispatch(userSetNewPasswordStart(values))

  return(
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
        <Row>
          <Col>
            <CustomFormField
              id="newPassword"
              name="newPassword"
              type={inputType}
              validate={passwordFormat}
              placeholder="Nuova password"
              focusOnMount={true}
            />
            <div className="my-3 d-flex justify-content-between">
              <Button outline className="show-pwd" onClick={togglePasswordVisibility}>
                {inputType === 'password' ? (
                  <>
                    <FontAwesomeIcon className="mr-1" icon={['fas', 'eye']} />
                    <span>Mostra password</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon className="mr-1" icon={['fas', 'eye-slash']} />
                    <span>Nascondi password</span>
                  </>
                )}
              </Button>

              <Button type="submit" disabled={isSubmitting} size="lg">
                <span>Conferma</span>
                {isSubmitting ? (
                  <Spinner size="sm" color="light" className="ml-1" />
                ) : (
                  <FontAwesomeIcon icon="check" className="ml-1" />
                )}
              </Button>
            </div>
          </Col>
        </Row>
        </form>
      )}
    </Form>
  )
}

export default ResetPasswordForm
