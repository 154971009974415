import ContractTypes from './contract.types'

export const toggleUtilityModalVisibility = () => ({
  type: ContractTypes.TOGGLE_CONTRACT_UTILITY_MODAL_VISIBILITY
})

export const toggleUtilityToastVisibility = () => ({
  type: ContractTypes.TOGGLE_CONTRACT_UTILITY_TOAST_VISIBILITY
})

export const toastCountdownCloseStart = () => ({
  type: ContractTypes.TOAST_COUNTDOWN_CLOSE_START
})

export const contractShowErrorMessage = data => ({
  type: ContractTypes.CONTRACT_SHOW_ERROR_MESSAGE,
  payload: data
})

export const contractReset = () => ({
  type: ContractTypes.CONTRACT_RESET
})

export const contractSetEntityName = data => ({
  type: ContractTypes.CONTRACT_SET_ENTITY_NAME,
  payload: data
})

export const contractChangeFormStep = data => ({
  type: ContractTypes.CONTRACT_CHANGE_FORM_STEP,
  payload: data
})

export const contractGetFormFieldsValuesStart = data => ({
  type: ContractTypes.CONTRACT_GET_FORM_FIELDS_VALUES_START,
  payload: data
})

export const contractGetFormFieldsValuesSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_FORM_FIELDS_VALUES_SUCCESS,
  payload: data
})

export const contractGetFormFieldsValuesFailure = error => ({
  type: ContractTypes.CONTRACT_GET_FORM_FIELDS_VALUES_FAILURE,
  payload: error
})

export const contractGetItemUserDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_USER_DATA_START,
  payload: data
})

export const contractGetItemUserDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_USER_DATA_SUCCESS,
  payload: data
})

export const contractGetItemUserDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ITEM_USER_DATA_FAILURE,
  payload: error
})

export const contractGetCustomerDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_CUSTOMER_DATA_START,
  payload: data
})

export const contractGetCustomerDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_CUSTOMER_DATA_SUCCESS,
  payload: data
})

export const contractGetCustomerDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_CUSTOMER_DATA_FAILURE,
  payload: error
})

export const contractGetActiveUserItemProposalDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_START,
  payload: data
})

export const contractGetActiveUserItemProposalDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_SUCCESS,
  payload: data
})

export const contractGetActiveUserItemProposalDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_ITEM_PROPOSAL_DATA_FAILURE,
  payload: error
})

export const contractGetItemProposalDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_PROPOSAL_DATA_START,
  payload: data
})

export const contractGetItemProposalDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_PROPOSAL_DATA_SUCCESS,
  payload: data
})

export const contractGetItemProposalDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ITEM_PROPOSAL_DATA_FAILURE,
  payload: error
})

export const contractGetItemBankAccountDataStart = () => ({
  type: ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_START
})

export const contractGetItemBankAccountDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_SUCCESS,
  payload: data
})

export const contractGetItemBankAccountDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DATA_FAILURE,
  payload: error
})

export const contractToggleBankAccountDetailModal = () => ({
  type: ContractTypes.CONTRACT_TOGGLE_BANK_ACCOUNT_DETAIL_MODAL
})

export const contractGetItemBankAccountDetailStart = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_START,
  payload: data
})

export const contractGetItemBankAccountDetailSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_SUCCESS,
  payload: data
})

export const contractGetItemBankAccountDetailFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ITEM_BANK_ACCOUNT_DETAIL_FAILURE,
  payload: error
})

export const contractResetBankAccountData = () => ({
  type: ContractTypes.CONTRACT_RESET_BANK_ACCOUNT_DATA
})

export const contractGetActiveUserSupplyPointsStart = () => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_START
})

export const contractGetActiveUserSupplyPointsSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_SUCCESS,
  payload: data
})

export const contractGetActiveUserSupplyPointsFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINTS_FAILURE,
  payload: error
})

export const contractGetActiveUserSupplyPointDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_START,
  payload: data
})

export const contractGetActiveUserSupplyPointDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS,
  payload: data
})

export const contractGetActiveUserSupplyPointDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE,
  payload: error
})

export const contractSubmitActiveUserSupplyPointDataStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_START,
  payload: data
})

export const contractSubmitActiveUserSupplyPointDataSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_SUCCESS,
  payload: data
})

export const contractSubmitActiveUserSupplyPointDataFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_SUPPLY_POINT_DATA_FAILURE,
  payload: error
})

export const contractDeleteActiveUserSupplyPointStart = data => ({
  type: ContractTypes.CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_START,
  payload: data
})

export const contractDeleteActiveUserSupplyPointSuccess = data => ({
  type: ContractTypes.CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_SUCCESS,
  payload: data
})

export const contractDeleteActiveUserSupplyPointFailure = error => ({
  type: ContractTypes.CONTRACT_DELETE_ACTIVE_USER_SUPPLY_POINT_FAILURE,
  payload: error
})

export const contractGetCadastralDataStart = () => ({
  type: ContractTypes.CONTRACT_GET_CADASTRAL_DATA_START
})

export const contractGetCadastralDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_CADASTRAL_DATA_SUCCESS,
  payload: data
})

export const contractGetCadastralDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_CADASTRAL_DATA_FAILURE,
  payload: error
})

export const contractDeleteCadastralDataStart = data => ({
  type: ContractTypes.CONTRACT_DELETE_CADASTRAL_DATA_START,
  payload: data
})

export const contractDeleteCadastralDataSuccess = data => ({
  type: ContractTypes.CONTRACT_DELETE_CADASTRAL_DATA_SUCCESS,
  payload: data
})

export const contractDeleteCadastralDataFailure = error => ({
  type: ContractTypes.CONTRACT_DELETE_CADASTRAL_DATA_FAILURE,
  payload: error
})

export const contractGetCadastralDetailStart = data => ({
  type: ContractTypes.CONTRACT_GET_CADASTRAL_DETAIL_START,
  payload: data
})

export const contractGetCadastralDetailSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_CADASTRAL_DETAIL_SUCCESS,
  payload: data
})

export const contractGetCadastralDetailFailure = error => ({
  type: ContractTypes.CONTRACT_GET_CADASTRAL_DETAIL_FAILURE,
  payload: error
})

export const contractCadastralSubmitFormStart = data => ({
  type: ContractTypes.CONTRACT_CADASTRAL_SUBMIT_FORM_START,
  payload: data
})

export const contractCadastralSubmitFormSuccess = data => ({
  type: ContractTypes.CONTRACT_CADASTRAL_SUBMIT_FORM_SUCCESS,
  payload: data
})

export const contractCadastralSubmitFormFailure = error => ({
  type: ContractTypes.CONTRACT_CADASTRAL_SUBMIT_FORM_FAILURE,
  payload: error
})

export const contractPodSubmitFormStart = data => ({
  type: ContractTypes.CONTRACT_POD_SUBMIT_FORM_START,
  payload: data
})

export const contractPodSubmitFormSuccess = data => ({
  type: ContractTypes.CONTRACT_POD_SUBMIT_FORM_SUCCESS,
  payload: data
})

export const contractPodSubmitFormFailure = error => ({
  type: ContractTypes.CONTRACT_POD_SUBMIT_FORM_FAILURE,
  payload: error
})

export const contractGetPodDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_POD_DATA_START,
  payload: data
})

export const contractGetPodDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_POD_DATA_SUCCESS,
  payload: data
})

export const contractGetPodDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_POD_DATA_FAILURE,
  payload: error
})

export const contractDeletePodStart = data => ({
  type: ContractTypes.CONTRACT_DELETE_POD_START,
  payload: data
})

export const contractDeletePodSuccess = data => ({
  type: ContractTypes.CONTRACT_DELETE_POD_SUCCESS,
  payload: data
})

export const contractDeletePodFailure = error => ({
  type: ContractTypes.CONTRACT_DELETE_POD_FAILURE,
  payload: error
})

export const contractResetPodData = () => ({
  type: ContractTypes.CONTRACT_RESET_POD_DATA
})

export const contractToggleActiveUserSupplyPointModal = data => ({
  type: ContractTypes.CONTRACT_TOGGLE_ACTIVE_USER_SUPPLY_POINT_MODAL,
  payload: data
})

export const contractTogglePodDetailModal = data => ({
  type: ContractTypes.CONTRACT_TOGGLE_POD_DETAIL_MODAL,
  payload: data
})

export const contractPdrSubmitFormStart = data => ({
  type: ContractTypes.CONTRACT_PDR_SUBMIT_FORM_START,
  payload: data
})

export const contractPdrSubmitFormSuccess = data => ({
  type: ContractTypes.CONTRACT_PDR_SUBMIT_FORM_SUCCESS,
  payload: data
})

export const contractPdrSubmitFormFailure = error => ({
  type: ContractTypes.CONTRACT_PDR_SUBMIT_FORM_FAILURE,
  payload: error
})

export const contractGetPdrDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_PDR_DATA_START,
  payload: data
})

export const contractGetPdrDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_PDR_DATA_SUCCESS,
  payload: data
})

export const contractGetPdrDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_PDR_DATA_FAILURE,
  payload: error
})

export const contractDeletePdrStart = data => ({
  type: ContractTypes.CONTRACT_DELETE_PDR_START,
  payload: data
})

export const contractDeletePdrSuccess = data => ({
  type: ContractTypes.CONTRACT_DELETE_PDR_SUCCESS,
  payload: data
})

export const contractDeletePdrFailure = error => ({
  type: ContractTypes.CONTRACT_DELETE_PDR_FAILURE,
  payload: error
})

export const contractResetPdrData = () => ({
  type: ContractTypes.CONTRACT_RESET_PDR_DATA
})

export const contractTogglePdrDetailModal = data => ({
  type: ContractTypes.CONTRACT_TOGGLE_PDR_DETAIL_MODAL,
  payload: data
})

export const contractValidateAllActiveUserSupplyPointsStart = () => ({
  type: ContractTypes.CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_START
})

export const contractValidateAllActiveUserSupplyPointsSuccess = data => ({
  type: ContractTypes.CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS,
  payload: data
})

export const contractValidateAllActiveUserSupplyPointsFailure = error => ({
  type: ContractTypes.CONTRACT_VALIDATE_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE,
  payload: error
})

export const contractBlockAllActiveUserSupplyPointsStart = () => ({
  type: ContractTypes.CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_START
})

export const contractBlockAllActiveUserSupplyPointsSuccess = data => ({
  type: ContractTypes.CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_SUCCESS,
  payload: data
})

export const contractBlockAllActiveUserSupplyPointsFailure = error => ({
  type: ContractTypes.CONTRACT_BLOCK_ALL_ACTIVE_USER_SUPPLY_POINTS_FAILURE,
  payload: error
})

export const contractBlockAllSupplyPointsStart = () => ({
  type: ContractTypes.CONTRACT_BLOCK_ALL_SUPPLY_POINTS_START
})

export const contractBlockAllSupplyPointsSuccess = data => ({
  type: ContractTypes.CONTRACT_BLOCK_ALL_SUPPLY_POINTS_SUCCESS,
  payload: data
})

export const contractBlockAllSupplyPointsFailure = error => ({
  type: ContractTypes.CONTRACT_BLOCK_ALL_SUPPLY_POINTS_FAILURE,
  payload: error
})

export const contractValidateAllSupplyPointsStart = () => ({
  type: ContractTypes.CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_START
})

export const contractValidateAllSupplyPointsSuccess = data => ({
  type: ContractTypes.CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_SUCCESS,
  payload: data
})

export const contractValidateAllSupplyPointsFailure = error => ({
  type: ContractTypes.CONTRACT_VALIDATE_ALL_SUPPLY_POINTS_FAILURE,
  payload: error
})

export const contractToggleCadastralDetailModal = () => ({
  type: ContractTypes.CONTRACT_TOGGLE_CADASTRAL_DETAIL_MODAL
})

export const contractResetCadastralData = () => ({
  type: ContractTypes.CONTRACT_RESET_CADASTRAL_DATA
})

export const contractGetAttachmentsDataStart = () => ({
  type: ContractTypes.CONTRACT_GET_ATTACHMENTS_DATA_START
})

export const contractGetAttachmentsDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ATTACHMENTS_DATA_SUCCESS,
  payload: data
})

export const contractGetAttachmentsDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ATTACHMENTS_DATA_FAILURE,
  payload: error
})

export const contractAddNewAttachmentToQueue = data => ({
  type: ContractTypes.CONTRACT_ADD_NEW_ATTACHMENT_TO_QUEUE,
  payload: data
})

export const contractRemoveAttachmentFromQueue = data => ({
  type: ContractTypes.CONTRACT_REMOVE_ATTACHMENT_FROM_QUEUE,
  payload: data
})

export const contractSubmitAttachmentFormStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ATTACHMENT_FORM_START,
  payload: data
})

export const contractSubmitAttachmentFormSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ATTACHMENT_FORM_SUCCESS,
  payload: data
})

export const contractSubmitAttachmentFormFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_ATTACHMENT_FORM_FAILURE,
  payload: error
})

export const contractGetActiveUserAttachmentsStart = () => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_START
})

export const contractGetActiveUserAttachmentsSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_SUCCESS,
  payload: data
})

export const contractGetActiveUserAttachmentsFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ACTIVE_USER_ATTACHMENTS_FAILURE,
  payload: error
})

export const contractSubmitActiveUserAttachmentsStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_START,
  payload: data
})

export const contractSubmitActiveUserAttachmentsSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_SUCCESS,
  payload: data
})

export const contractSubmitActiveUserAttachmentsFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_ACTIVE_USER_ATTACHMENTS_FAILURE,
  payload: error
})

export const contractDeleteAttachmentStart = data => ({
  type: ContractTypes.CONTRACT_DELETE_ATTACHMENT_START,
  payload: data
})

export const contractDeleteAttachmentSuccess = data => ({
  type: ContractTypes.CONTRACT_DELETE_ATTACHMENT_SUCCESS,
  payload: data
})

export const contractDeleteAttachmentFailure = error => ({
  type: ContractTypes.CONTRACT_DELETE_ATTACHMENT_FAILURE,
  payload: error
})

export const contractToggleContractFinalizationModalVisibility = () => ({
  type: ContractTypes.CONTRACT_TOGGLE_CONTRACT_FINALIZATION_MODAL_VISIBILITY
})

export const contractSetSelectedSignatureType = data => ({
  type: ContractTypes.CONTRACT_SET_SELECTED_SIGNATURE_TYPE,
  payload: data
})

export const contractGetOtpDataStart = () => ({
  type: ContractTypes.CONTRACT_GET_OTP_DATA_START
})

export const contractGetOtpDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_OTP_DATA_SUCCESS,
  payload: data
})

export const contractGetOtpDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_OTP_DATA_FAILURE,
  payload: error
})

export const contractGetOtpCodeStart = data => ({
  type: ContractTypes.CONTRACT_GET_OTP_CODE_START,
  payload: data
})

export const contractGetOtpCodeSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_OTP_CODE_SUCCESS,
  payload: data
})

export const contractGetOtpCodeFailure = error => ({
  type: ContractTypes.CONTRACT_GET_OTP_CODE_FAILURE,
  payload: error
})

export const contractSubmitSignatureStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_SIGNATURE_START,
  payload: data
})

export const contractSubmitSignatureSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_SIGNATURE_SUCCESS,
  payload: data
})

export const contractSubmitSignatureFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_SIGNATURE_FAILURE,
  payload: error
})

export const contractGetPdfUrlStart = () => ({
  type: ContractTypes.CONTRACT_GET_PDF_URL_START
})

export const contractGetPdfUrlSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_PDF_URL_SUCCESS,
  payload: data
})

export const contractGetPdfUrlFailure = error => ({
  type: ContractTypes.CONTRACT_GET_PDF_URL_FAILURE,
  payload: error
})

export const contractToggleOtpSignatureModalVisiblity = () => ({
  type: ContractTypes.CONTRACT_TOGGLE_OTP_SIGNATURE_MODAL_VISIBILITY
})

export const contractSubmitPaperSignatureStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_PAPER_SIGNATURE_START,
  payload: data
})

export const contractSubmitPaperSignatureSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_PAPER_SIGNATURE_SUCCESS,
  payload: data
})

export const contractSubmitPaperSignatureFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_PAPER_SIGNATURE_FAILURE,
  payload: error
})

export const contractGetListFiltersStart = () => ({
  type: ContractTypes.CONTRACT_GET_LIST_FILTERS_START
})

export const contractGetListFiltersSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_LIST_FILTERS_SUCCESS,
  payload: data
})

export const contractGetListFiltersFailure = error => ({
  type: ContractTypes.CONTRACT_GET_LIST_FILTERS_FAILURE,
  payload: error
})

export const contractGetListDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_LIST_DATA_START,
  payload: data
})

export const contractGetListDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_LIST_DATA_SUCCESS,
  payload: data
})

export const contractGetListDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_LIST_DATA_FAILURE,
  payload: error
})

export const contractDeleteItemStart = data => ({
  type: ContractTypes.CONTRACT_DELETE_ITEM_START,
  payload: data
})

export const contractDeleteItemSuccess = data => ({
  type: ContractTypes.CONTRACT_DELETE_ITEM_SUCCESS,
  payload: data
})

export const contractDeleteItemFailure = error => ({
  type: ContractTypes.CONTRACT_DELETE_ITEM_FAILURE,
  payload: error
})

export const contractUpdateListFilters = filters => ({
  type: ContractTypes.CONTRACT_UPDATE_LIST_FILTERS,
  payload: filters
})

export const contractUpdateListPage = page => ({
  type: ContractTypes.CONTRACT_UPDATE_LIST_PAGE,
  payload: page
})

export const contractUpdateListSorting = sorts => ({
  type: ContractTypes.CONTRACT_UPDATE_LIST_SORTS,
  payload: sorts
})

export const contractResetList = () => ({
  type: ContractTypes.CONTRACT_RESET_LIST
})

export const contractGetReportsStart = data => ({
  type: ContractTypes.CONTRACT_GET_REPORTS_START,
  payload: data
})

export const contractGetReportsSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_REPORTS_SUCCESS,
  payload: data
})

export const contractGetReportsFailure = error => ({
  type: ContractTypes.CONTRACT_GET_REPORTS_FAILURE,
  payload: error
})

export const contractSubmitReportStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_REPORT_START,
  payload: data
})

export const contractSubmitReportSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_REPORT_SUCCESS,
  payload: data
})

export const contractSubmitReportFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_REPORT_FAILURE,
  payload: error
})

export const contractGetNotesStart = data => ({
  type: ContractTypes.CONTRACT_GET_NOTES_START,
  payload: data
})

export const contractGetNotesSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_NOTES_SUCCESS,
  payload: data
})

export const contractGetNotesFailure = error => ({
  type: ContractTypes.CONTRACT_GET_NOTES_FAILURE,
  payload: error
})

export const contractSubmitNoteStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_NOTE_START,
  payload: data
})

export const contractSubmitNoteSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_NOTE_SUCCESS,
  payload: data
})

export const contractSubmitNoteFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_NOTE_FAILURE,
  payload: error
})

export const contractGetItemHistoryDataStart = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_HISTORY_DATA_START,
  payload: data
})

export const contractGetItemHistoryDataSuccess = data => ({
  type: ContractTypes.CONTRACT_GET_ITEM_HISTORY_DATA_SUCCESS,
  payload: data
})

export const contractGetItemHistoryDataFailure = error => ({
  type: ContractTypes.CONTRACT_GET_ITEM_HISTORY_DATA_FAILURE,
  payload: error
})

export const contractUpdateItemStatusStart = data => ({
  type: ContractTypes.CONTRACT_UPDATE_ITEM_STATUS_START,
  payload: data
})

export const contractUpdateItemStatusSuccess = data => ({
  type: ContractTypes.CONTRACT_UPDATE_ITEM_STATUS_SUCCESS,
  payload: data
})

export const contractUpdateItemStatusFailure = error => ({
  type: ContractTypes.CONTRACT_UPDATE_ITEM_STATUS_FAILURE,
  payload: error
})

/*FORM SUBMIT*/
export const contractUserSubmitFormStart = data => ({
  type: ContractTypes.CONTRACT_USER_SUBMIT_FORM_START,
  payload: data
})

export const contractUserSubmitFormSuccess = data => ({
  type: ContractTypes.CONTRACT_USER_SUBMIT_FORM_SUCCESS,
  payload: data
})

export const contractUserSubmitFormFailure = error => ({
  type: ContractTypes.CONTRACT_USER_SUBMIT_FORM_FAILURE,
  payload: error
})

export const contractProposalSubmitFormStart = data => ({
  type: ContractTypes.CONTRACT_PROPOSAL_SUBMIT_FORM_START,
  payload: data
})

export const contractProposalSubmitFormSuccess = (data = null) => ({
  type: ContractTypes.CONTRACT_PROPOSAL_SUBMIT_FORM_SUCCESS,
  payload: data
})

export const contractProposalSubmitFormFailure = error => ({
  type: ContractTypes.CONTRACT_PROPOSAL_SUBMIT_FORM_FAILURE,
  payload: error
})

export const contractBankAccountSubmitFormStart = data => ({
  type: ContractTypes.CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_START,
  payload: data
})

export const contractBankAccountSubmitFormSuccess = data => ({
  type: ContractTypes.CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_SUCCESS,
  payload: data
})

export const contractBankAccountSubmitFormFailure = error => ({
  type: ContractTypes.CONTRACT_BANK_ACCOUNT_SUBMIT_FORM_FAILURE,
  payload: error
})

export const contractUserMassiveCreationStart = data => ({
  type: ContractTypes.CONTRACT_USER_MASSIVE_CREATION_START,
  payload: data
})

export const contractUserMassiveCreationSuccess = () => ({
  type: ContractTypes.CONTRACT_USER_MASSIVE_CREATION_SUCCESS
})

export const contractUserMassiveCreationFailure = error => ({
  type: ContractTypes.CONTRACT_USER_MASSIVE_CREATION_FAILURE,
  payload: error
})

export const contractToggleAttachmentsIntegrationModal = () => ({
  type: ContractTypes.CONTRACT_TOGGLE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY
})

export const contractSubmitAttachmentsIntegrationStart = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_START,
  payload: data
})

export const contractSubmitAttachmentsIntegrationSuccess = data => ({
  type: ContractTypes.CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_SUCCESS,
  payload: data
})

export const contractSubmitAttachmentsIntegrationFailure = error => ({
  type: ContractTypes.CONTRACT_SUBMIT_ATTACHMENTS_INTEGRATION_FAILURE,
  payload: error
})

export const contractToggleCloseAttachmentsIntegrationModalVisibility = () => ({
  type: ContractTypes.CONTRACT_TOGGLE_CLOSE_ATTACHMENTS_INTEGRATION_MODAL_VISIBILITY
})

export const contractCloseAttachmentsIntegrationStart = data => ({
  type: ContractTypes.CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_START,
  payload: data
})

export const contractCloseAttachmentsIntegrationSuccess = data => ({
  type: ContractTypes.CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_SUCCESS,
  payload: data
})

export const contractCloseAttachmentsIntegrationFailure = error => ({
  type: ContractTypes.CONTRACT_CLOSE_ATTACHMENTS_INTEGRATION_FAILURE,
  payload: error
})