import { detect } from 'detect-browser'

import { fetchData, getAPIUrl } from "../../api"

export const detectBrowser = () => detect()

export const a2b = (a) => {
  let b, c, d, e = {}, f = 0, g = 0, h = "", i = String.fromCharCode, j = a.length
  for (b = 0; 64 > b; b++) e["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(b)] = b
  for (c = 0; j > c; c++) for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8; ) ((d = 255 && f >>> (g -= 8)) || j - 2 > c) && (h += i(d))
  return h
}

export const downloadFile = (accessToken, saleOfferId) => {
  return fetchData(`${getAPIUrl()}/users/sale-offers/${saleOfferId}/pdf`, accessToken)
    .then(({ data: { file, filename } }) => {
      return downloadAttachment(file, filename)
    })
}

export const downloadAttachment = attachment => {
  const binary = a2b(attachment.replace(/\s/g, ''))
	const len = binary.length
	const buffer = new ArrayBuffer(len)
	const view = new Uint8Array(buffer)
	for (let i = 0; i < len; i++) {
		view[i] = binary.charCodeAt(i)
	}

	// Create the blob object with content-type "application/pdf"
	const blob = new Blob( [view], { type: "application/pdf" })
	const url = URL.createObjectURL(blob)
  const browser = detectBrowser()
  if(browser.name === 'safari') {
    //Is iOS
    window.location.href = url
  }

  window.open(url)
}

export const getSupplyPointIcon = supplyType => {
  switch(supplyType) {
    case 'E':
      return ['fas', 'bolt']

    default:
      return ['fas', 'fire']
  }
}