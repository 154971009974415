import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { FieldArray } from 'react-final-form-arrays'
import { v4 as uuidv4 } from 'uuid'

import CanI from '../../auth/CanI/CanI.component'
import AttachmentRow from './AttachmentRow/AttachmentRow.component'
import { selectContract, selectPermissions } from '../../../redux/contract/contract.selectors'
import { contractAddNewAttachmentToQueue } from '../../../redux/contract/contract.actions'
import { maxSize, handleFileDrop, checkFileTooLarge, formatBytes } from './utils'

const AttachmentsDropzone = ({ files, inputName = "dropzone", mutators: { push, pop } }) => {
  const dispatch = useDispatch()
  const { entityName } = useSelector(selectContract)
  const permissions = useSelector(selectPermissions)
  useEffect(() => {
    //Update form with default values
    if(files.length) {
      files.forEach(file => push(inputName))
    }// eslint-disable-next-line
  }, [])

  //Callback on file drop, encode the files in base64 format and update state
  const onDrop = useCallback(acceptedFiles => {
    handleFileDrop(acceptedFiles)
      .then(response => {
        dispatch(contractAddNewAttachmentToQueue(response.map(item => ({ ...item, tempId: uuidv4() }) )))
        acceptedFiles.forEach(file => push(inputName))
      })
  }, [push, inputName, dispatch])

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
      'application/msword': []
    },
    maxSize: maxSize,
    multiple: true,
    onDrop: onDrop
  })

  //Check file size
  const [ isFileTooLarge, setIsFileTooLarge ] = useState(false)
  useEffect(() => {
    if(checkFileTooLarge(fileRejections)) {
      setIsFileTooLarge(true)
    }
    else {
      setIsFileTooLarge(false)
    }
  }, [fileRejections])

  return (
    <>
      <FormGroup>
        <CanI doWhat="CREATE" withPermissions={permissions} entityName={`${entityName}Attachment`}>
          {() => (
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ?
                  <p>Lascia qui il file ...</p> :
                  <p>Clicca o trascina qui i file da caricare</p>
              }
              {isDragReject &&
                <p>Questo tipo di file non è valido.</p>
              }
              {isFileTooLarge &&
                <p className="text-danger">
                  La dimensione del file è troppo grande. Il limite è {formatBytes(maxSize)}.
                </p>
              }
            </div>
          )}
        </CanI>
        {files?.length > 0 && 
          <div className="dropzone-listgroup">
            <FieldArray name={inputName}>
              {({ fields }) =>
                fields.map((name, index) => (
                  <AttachmentRow
                    key={index}
                    name={name}
                    index={index}
                    files={files}
                    callbackFunction={() => pop(inputName)}
                  />
                ))
              }
            </FieldArray>
          </div>
        }
      </FormGroup>
    </>
  )
}

export default AttachmentsDropzone
