export const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

export function isPropertyDeclarationRequired(entityName) {
  return (
    entityName === 'transfer' ||
    entityName === 'contextualTransferEe' ||
    entityName === 'contextualTransferGas' ||
    entityName === 'newActivation' ||
    entityName === 'takeover' ||
    entityName === 'newConnection'
  ) ? true : false
}
