import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { Button, Spinner, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { required } from '../validations'
import { getAPIUrl } from '../../../api'
import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import { getEntityUriName } from '../../../redux/contract/contract.utils'
import { selectOtpSignatureModalVisibility, selectContract, selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractToggleOtpSignatureModalVisiblity, contractSubmitSignatureStart } from '../../../redux/contract/contract.actions'

const OtpSignatureModal = () => {
  const dispatch = useDispatch()
  const { otpSignerId, entityName } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('signature')(state))
  const visibility = useSelector(selectOtpSignatureModalVisibility)
  const toggle = () => dispatch(contractToggleOtpSignatureModalVisiblity())

  const signatureSubmit = values => {
    const apiUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/signers/${otpSignerId}/signatures`
    dispatch(contractSubmitSignatureStart({ apiUrl, values }))
  }

  return(
    <Modal className="form-modal" size="lg" backdrop="static" isOpen={visibility} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="file-signature" className="mr-2" />
        OTP di firma remota
      </ModalHeader>
      <ModalBody>
        <p className="description"><strong>Attenzione!</strong> L’operazione di conferma richiede alcuni secondi: attendi il termine dell’operazione.</p>
        <Form
          onSubmit={signatureSubmit}
          subscription={{
            submitting: true,
            pristine: true
          }}
        >
          {({ handleSubmit, form, submitting, pristine, values, reset }) => {
            return(
            <form
              className="two-cols-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="grouped">
                <div className="grouped-header">
                  <FontAwesomeIcon icon="info-circle" size="lg" className="mr-2" />
                  Inserisci il codice che ti è arrivato via SMS
                </div>
                <div className="grouped-body">
                  <Row form>
                    <Col md={12}>
                      <CustomFormField
                        id="otpCode"
                        name="otpCode"
                        type="text"
                        placeholder="Firma OTP*"
                        fieldLabel="Firma OTP*"
                        validate={required}
                        maxLength="6"
                        focusOnMount={true}
                        disabled={isSubmitting}
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="text-right">
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner size="sm" color="light" className="mr-2" />
                      <span>Firma in corso</span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon="file-signature" className="mr-2" />
                      <span>Firma</span>
                    </>
                  )}
                </Button>
              </div>
            </form>
          )}}
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default OtpSignatureModal
