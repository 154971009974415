import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ActiveUserAttachmentsForm from './ActiveUserAttachmentsForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { selectIsFetchingData } from '../../../redux/contract/contract.selectors'
import { contractGetActiveUserAttachmentsStart } from '../../../redux/contract/contract.actions'

const ActiveUserAttachmentsFormWithSpinner = WithSpinner(ActiveUserAttachmentsForm)

const AttachmentsFormContainer = () => {
  const dispatch = useDispatch()
  const isFetchingAttachementsData = useSelector(state => selectIsFetchingData('activeUserAttachments')(state))

  useEffect(() => {
    dispatch(contractGetActiveUserAttachmentsStart())
  }, [dispatch])

  return (
    <ActiveUserAttachmentsFormWithSpinner isLoading={isFetchingAttachementsData} />
  )
}

export default AttachmentsFormContainer
