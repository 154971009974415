import { Container } from 'reactstrap'
import styled from 'styled-components'

const SalesOffersListStyle = styled(Container)`
  margin-top: 2.5rem;
  padding-left: 1.25rem;
  padding-top: 1rem;
  position: relative;

  .section-title {
    font-size: 26px;
    font-weight: bold;
    color: #337ab7;
    margin: 0;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0.25rem;
    left: 0;
    top: 0;
    background-color: #ffc525;
    height: 100%;
  }

  .sale-offer-item {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    border: none;
    box-shadow: 0px 3px 10px #00000029;
    margin-bottom: 1.5rem;
    border-radius: 5px;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    .text-container {
      font-size: 18px;
      color: #337ab7;
      font-weight: bold;
      text-transform: uppercase;
      flex-grow: 1;
      line-height: 1.2;
      padding-right: 1rem;
  
      svg {
        font-size: 1rem;
      }
  
      .more-info {
        margin-top: .75rem;
        font-size: 14px;
        text-transform: none;
  
        
        @media (max-width: 576px) {
          margin-top: 0;
        }
  
        button {
          padding: 0;
          font-size: 14px;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    .context-menu {
      display: flex;
    
      @media (max-width: 767.9px) {
        flex-wrap: wrap;
      }
  
      .btn {
        margin-left: .5rem;
  
        @media (max-width: 991.9px) {
          margin-left: 0;
        }
  
        @media (max-width: 767.9px) {
          margin-top: .5rem;
        }
      }
    }
  }
`

export default SalesOffersListStyle