import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ActiveUserAttachmentsFormStyle from './style'
import ContractAttachmentsInfoAlert from '../ContractAttachmentsInfoAlert/ContractAttachmentsInfoAlert.component'
import Dropzone from '../inputFields/Dropzone/Dropzone.component'
import AttachmentsForm from '../AttachmentsForm/AttachmentsForm.component'
import { getAPIUrl } from '../../../api'
import { 
  selectContract, selectPermissions,
  selectIsSubmitting, selectCiBackAttachmentData, selectCiFrontAttachmentData
} from '../../../redux/contract/contract.selectors'
import { contractSubmitActiveUserAttachmentsStart } from '../../../redux/contract/contract.actions'
import { getEntityUriName } from '../../../redux/contract/contract.utils'
import { maxSize } from '../AttachmentsDropzone/utils'
import CanI from '../../auth/CanI/CanI.component'

const focusOnError = createDecorator()

const ActiveUserAttachmentsForm = () => {
  const dispatch = useDispatch()
  const { entityName, selectedContractId } = useSelector(selectContract)
  const permissions = useSelector(selectPermissions)
  const ciFrontAttachmentData = useSelector(selectCiFrontAttachmentData)
  const ciBackAttachmentData = useSelector(selectCiBackAttachmentData)
  const isSubmitting = useSelector(state => selectIsSubmitting('activeUserAttachments')(state))
  const [ ciFront, setCiFront ] = useState(ciFrontAttachmentData || [])
  const [ ciBack, setCiBack ] = useState(ciBackAttachmentData || [])
  const [ validateCiFront, setValidateCiFront ] = useState(true)
  const [ validateCiBack, setValidateCiBack ] = useState(true)

  useEffect(() => {
    if(ciFrontAttachmentData) setCiFront(ciFrontAttachmentData)
  }, [ciFrontAttachmentData])

  useEffect(() => {
    if(ciBackAttachmentData) setCiBack(ciBackAttachmentData)
  }, [ciBackAttachmentData])

  const onSubmit = values => {
    //Submit paper contract
    if(values.ciFront?.length && values.ciBack?.length) {
      let submitValues = []
      const ciUploadUrl = `${getAPIUrl('abStorePortal')}/${getEntityUriName(entityName)}/attachments/mandatory`
      if(!values.ciFront[0].id) {
        const ciFrontValues = {
          contractId: selectedContractId,
          fileBase64: ciFront[0].encoded,
          userFileName: values.ciFront[0].fileName,
          attachmentTypeId: { value: "4", label: "Carta identità fronte" },
          apiUrl: ciUploadUrl
        }
        submitValues.push(ciFrontValues)
      }

      if(!values.ciBack[0].id) {
        const ciBackValues = {
          contractId: selectedContractId,
          fileBase64: ciBack[0].encoded,
          userFileName: values.ciBack[0].fileName,
          attachmentTypeId: { value: "5", label: "Carta identità retro" },
          apiUrl: ciUploadUrl
        }
        submitValues.push(ciBackValues)
      }
      
      dispatch(contractSubmitActiveUserAttachmentsStart({ values: submitValues }))
    }
    else {
      if(!values.ciFront?.length) {
        setValidateCiFront(false)
      }
      if(!values.ciBack?.length) {
        setValidateCiBack(false)
      }
    }
  }

  return (
    <ActiveUserAttachmentsFormStyle>
      <ContractAttachmentsInfoAlert />
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          pristine: true
        }}
        mutators={{
          ...arrayMutators
        }}
        decorators={[focusOnError]}
        initialValues={{
          contractId: selectedContractId
        }}
      >
        {({ handleSubmit, form: { mutators }, submitting, pristine, values, reset }) => {
          return (
            <form
              className="two-cols-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="grouped">
                <div className="grouped-header">
                  <FontAwesomeIcon icon="id-badge" className="mr-2" />
                  Carica il fronte e retro della Carta d'identità
                </div>
                <div className="grouped-body">
                  <Row form className="justify-content-between">
                    <Col className="dropzone-container">
                      <legend className="col-form-label">Fronte C.I.<sup>*</sup></legend>
                      <Dropzone
                        maxSize={maxSize}
                        acceptedFileTypes={{
                          'image/jpeg': [],
                          'image/png': [], 
                          'application/pdf': []
                        }}
                        files={ciFront}
                        setFiles={setCiFront}
                        inputName="ciFront"
                        setValidate={setValidateCiFront}
                        hideFileSize={true}
                        hideRemoveButton={true}
                        openDialogWithBtn={true}
                        viewFileBtn={true}
                        mutators={{
                          ...mutators
                        }}
                      />
                      {validateCiFront === false &&
                        <p className="small px-2 text-danger">È obbligatorio caricare il fronte della C.I.</p>
                      }
                    </Col>
                    <Col className="dropzone-container">
                      <legend className="col-form-label">Retro C.I.<sup>*</sup></legend>
                      <Dropzone
                        maxSize={maxSize}
                        acceptedFileTypes={{
                          'image/jpeg': [],
                          'image/png': [], 
                          'application/pdf': []
                        }}
                        files={ciBack}
                        setFiles={setCiBack}
                        inputName="ciBack"
                        setValidate={setValidateCiBack}
                        hideFileSize={true}
                        hideRemoveButton={true}
                        openDialogWithBtn={true}
                        viewFileBtn={true}
                        mutators={{
                          ...mutators
                        }}
                      />
                      {validateCiBack === false &&
                        <p className="small px-2 text-danger">È obbligatorio caricare il retro della C.I.</p>
                      }
                    </Col>
                  </Row>
                </div>
              </div>
              <CanI doWhat="CREATE" withPermissions={permissions} entityName={`${entityName}MandatoryAttachment`}>
                {({ action }) => (
                  <div className="text-right">
                    <Button 
                      type="submit" 
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                          <Spinner size="sm" color="light" className="mr-2" />
                        ) : (
                          <FontAwesomeIcon icon="upload" className="mr-2" />
                      )}
                      {' '}
                      <span>Carica C.I.</span>
                    </Button>
                  </div>
                )}
              </CanI>
            </form>
          )
        }}
      </Form>
      <AttachmentsForm />
    </ActiveUserAttachmentsFormStyle>
  )
}
export default ActiveUserAttachmentsForm
