import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardTitle, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CanI from '../../auth/CanI/CanI.component'
import SupplyPointStatusHistoryModal from '../../SupplyPointStatusHistoryModal/SupplyPointStatusHistoryModal.component'
import SupplyPointStatusUpdate from '../../SupplyPointStatusUpdate/SupplyPointStatusUpdate.component'
import DeleteItem from '../../DeleteItem/DeleteItem.component'
import { selectContract, selectIsFetchingData, selectIsDeleting, selectContractStatusId } from '../../../redux/contract/contract.selectors'
import { selectUserRole } from '../../../redux/user/user.selectors'
import {
  contractDeletePdrStart, contractGetPdrDataStart,
  contractResetPdrData, contractTogglePdrDetailModal
} from '../../../redux/contract/contract.actions'
import { getSupplyPointStatusIcon, getItemStatusLabel, ContractStatusMap } from '../../../redux/contract/contract.utils'
import { UserRoles } from '../../../redux/user/user.utils'

const PdrItem = ({ item, cadastralItem }) => {
  const dispatch = useDispatch()
  const role = useSelector(selectUserRole)
  const { entityName } = useSelector(selectContract)
  const isFetchingPdrData = useSelector(state => selectIsFetchingData(`pdr-${item?.id}`)(state))
  const isDeleting = useSelector(state => selectIsDeleting(`pdr-${item?.id}`)(state))
  const contractStatus = useSelector(selectContractStatusId)
  const handleClick = () => {
    dispatch(contractResetPdrData())
    dispatch(contractTogglePdrDetailModal(cadastralItem))
  }

  return (
    <>
      <h3 className="title">
        <FontAwesomeIcon icon="fire" className="mr-2" />
        PDR
      </h3>

      {item ? (
        <Card key={item.id}>
          <CardBody>
            <CardTitle>
              <FontAwesomeIcon
                className="mr-2"
                icon={getSupplyPointStatusIcon(item.supplyPointStatusId)}
                title={getItemStatusLabel(item.supplyPointStatusId)}
              />
              {item.no}
            </CardTitle>
            {
              (role === UserRoles.USER_ROLE_ADMIN || role === UserRoles.USER_ROLE_VERIFIER) &&
              contractStatus >= ContractStatusMap.DONE_STATUS &&
              <CanI doWhat="GET" withPermissions={item.links} entityName={`${entityName}Pdr`}>
                {({ action }) => (
                  <SupplyPointStatusHistoryModal 
                    itemId={item.id} 
                    buttonLabel="Storico cambiamenti di stato" 
                    supplyType="pdr"
                  />
                )}
              </CanI>
            }
            <CanI doWhat="PATCH" withPermissions={item.links} entityName={`${entityName}Pdr`}>
              {({ action }) => (
                <SupplyPointStatusUpdate
                  supplyType="pdr"
                  cadastralDataId={cadastralItem.id}
                  statusId={item.supplyPointStatusId}
                  actionUrl={action}
                  buttonProps={{
                    color: 'secondary',
                    title: 'Modifica lo stato del contratto di fornitura',
                    label: 'Modifica stato contratto'
                  }}
                />
              )}
            </CanI>
            <Button
              color="secondary"
              outline
              size="sm"
              onClick={() => dispatch(contractGetPdrDataStart({ pdrId: item.id, cadastralItem }))}
              className="mr-2"
              disabled={isFetchingPdrData}
            >
              {isFetchingPdrData ? (
                <Spinner size="sm" />
              ) : (
                <FontAwesomeIcon icon="eye" className="see-more" />
              )}
              <span className="btn-label">Dettagli</span>
            </Button>
            <CanI doWhat="DELETE" withPermissions={item.links} entityName={`${entityName}Pdr`}>
              {({ action }) => (
                <DeleteItem
                  buttonLabel="Elimina"
                  size="sm"
                  actionUrl={action}
                  itemId={item.id}
                  actionFunction={contractDeletePdrStart}
                  rest={{ cadastralDataId: cadastralItem.id }}
                  isDeleting={isDeleting}
                />
              )}
            </CanI>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardBody>
            <p className="small">Nessun PDR associato a questo punto di fornitura</p>
            <CanI doWhat="CREATE" withPermissions={cadastralItem.links} entityName={`${entityName}Pdr`}>
              {({ action }) => (
                <Button
                  size="md"
                  color="secondary"
                  outline
                  onClick={handleClick}
                >
                  <FontAwesomeIcon icon={['fas', 'plus-circle']} className="mr-2" />
                  Aggiungi punto di fornitura
                </Button>
              )}
            </CanI>
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default PdrItem
