import React from 'react'
import { useSelector } from 'react-redux'

import ScrollToTop from '../../../hooks/navigation/useScrollToTop'
import OtpSigner from '../OtpSigner/OtpSigner.component'
import OtpSignatureModal from '../OtpSignatureModal/OtpSignatureModal.component'
import AttachmentsForm from '../AttachmentsForm/AttachmentsForm.component'
import { selectEntityName, selectOtpSignersAsArray } from '../../../redux/contract/contract.selectors'
import { isAttachmentsFormNeeded } from './utils'

const OtpForm = () => {
  const otpSigners = useSelector(selectOtpSignersAsArray)
  const entityName = useSelector(selectEntityName)

  return (
    <div className="otp-container loader-container">
      <ScrollToTop />
      <h6 className="mb-4">Finalizza il contratto apponendo la firma con OTP</h6>
      {otpSigners.map((item, i) => (
        <OtpSigner signer={item} key={item.id} />
      ))}
      <OtpSignatureModal />
      {isAttachmentsFormNeeded(entityName) &&
        <AttachmentsForm />
      }
    </div>
  )
}

export default OtpForm
