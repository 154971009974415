import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { Form, Field } from 'react-final-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, ModalHeader, ModalBody, Input, Button, Spinner } from 'reactstrap'

import LabelledTextInput from '../forms/inputFields/LabelledTextInput/LabelledTextInput.component'
import CanI from '../auth/CanI/CanI.component'
import { required } from '../forms/validations'
import CommunicationsList from './CommunicationsList.component'
import { selectContract, selectIsFetchingData, selectIsSubmitting } from '../../redux/contract/contract.selectors'
import { contractGetReportsStart, contractSubmitReportStart } from '../../redux/contract/contract.actions'
import { buildFetchUrl } from './utils'

const ChatModal = ({ itemId, itemType, links, reportsLength, otherClasses, actionUrl }) => {
  const dispatch = useDispatch()
  const { contractReports, entityName } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('report')(state))
  const isFetching = useSelector(state => selectIsFetchingData('reports')(state))
  const fetchListApiUrl = buildFetchUrl(entityName)

  const [ visibility, setVisibility ] = useState(false)
  const toggle = () => setVisibility(!visibility)
  useEffect(() => {
    if(visibility === true) dispatch(contractGetReportsStart({ apiUrl: actionUrl }))
  }, [visibility, actionUrl, dispatch])

  const sendMessage = (submitUrl, values) => {
    dispatch(contractSubmitReportStart({ submitUrl, values, fetchUrl: actionUrl, fetchListApiUrl }))
  }

  return (
    <div
      className={`action-modal${otherClasses ? ` ${otherClasses}` : ''}`}
    >
      <Button title="Comunicazioni" onClick={toggle} size="sm" color="link" outline className="mx-1">
        <FontAwesomeIcon
          icon="envelope"
          className={classnames({ 'active': reportsLength === true })}
        />
      </Button>
      <Modal className="form-modal" size="lg" isOpen={visibility} toggle={toggle}>
        <div className={classnames({'loading': isFetching})}>
          {isFetching &&
            <div className="element-loader-container">
              <Spinner color="secondary" />
            </div>
          }
          <ModalHeader toggle={toggle}>
            <FontAwesomeIcon className="mr-2" icon="envelope" />
            Elenco dei messaggi
          </ModalHeader>

          <ModalBody className="notes">
            <CommunicationsList
              items={contractReports}
            />
            <CanI doWhat={"CREATE"} withPermissions={links} entityName={`${itemType}Report`}>
              {({ action }) => (

                <Form
                  onSubmit={values => sendMessage(action, values)}
                  subscription={{
                    values: true
                  }}
                >
                  {({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                      autoComplete="off"
                      onSubmit={event => {
                        handleSubmit(event)
                        form.restart()
                      }}
                    >
                      <Field
                        name="contractId"
                        type="hidden"
                        initialValue={itemId}
                      >
                        {({ input }) => (
                          <Input {...input} />
                        )}
                      </Field>
                      <Field
                        name="description"
                        type="textarea"
                        fieldLabel="Invia un nuovo messaggio"
                        validate={required}
                      >
                        {(fieldState) => (
                          <LabelledTextInput {...fieldState} />
                        )}
                      </Field>
                      <div className="text-right">
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          className="ab-button"
                          type="submit"
                        >
                          {isSubmitting &&
                            <Spinner color="light" size="sm" />
                          }
                          {!isSubmitting &&
                            <FontAwesomeIcon icon="paper-plane" className="mr-1" />
                          }
                          {' '}
                          Invia
                        </Button>
                      </div>
                    </form>
                  )}
                </Form>
              )}
            </CanI>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default ChatModal
