import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, Row, Col, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'react-final-form'

import { required } from '../validations'
import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import { selectContract, selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractToggleAttachmentsIntegrationModal, contractSubmitAttachmentsIntegrationStart } from '../../../redux/contract/contract.actions'

const AttachmentsIntegrationModal = ({ requestUrl }) => {
  const dispatch = useDispatch()
  const { attachmentsIntegrationModalVisibility } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('attachmentsIntegration')(state))
  const toggle = () => dispatch(contractToggleAttachmentsIntegrationModal())

  const integrationSubmit = values => {
    dispatch(contractSubmitAttachmentsIntegrationStart({ apiUrl: requestUrl, values }))
  }

  return (
    <Modal 
      className="form-modal"
      size="lg" 
      backdrop="static" 
      isOpen={attachmentsIntegrationModalVisibility} 
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="paperclip" className="mr-2" />
        Integrazione allegati
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={integrationSubmit}
          subscription={{
            submitting: true,
            pristine: true
          }}
          initialValues={{
            contractLink: window.location.href
          }}
        >
          {({ handleSubmit }) => (
            <form
              className="two-cols-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="grouped">
                <div className="grouped-header">
                  <FontAwesomeIcon icon="info-circle" size="lg" className="mr-2" />
                  Inserisci le motivazioni della richiesta di integrazione
                </div>
                <div className="grouped-body">
                  <Row form>
                    <Col md={12}>
                      <CustomFormField
                        name="integrationCauses"
                        type="textarea"
                        maxLength="1024"
                        validate={required}
                        placeholder="Motivazioni richiesta"
                        fieldLabel="Motivazioni richiesta"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="text-right">
                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <Spinner size="sm" color="light" className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon="paper-plane" className="mr-2" />
                  )}
                  <span>Invia</span>
                </Button>
              </div>
            </form>
          )}
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default AttachmentsIntegrationModal