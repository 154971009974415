import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SalesOffersList from './SalesOffersList.component'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import { selectIsFetchingData } from '../../redux/salesOffers/salesOffers.selectors'
import { salesOffersReset, salesOffersGetActiveListStart } from '../../redux/salesOffers/salesOffers.actions'

const SalesOffersListWithSpinner = WithSpinner(SalesOffersList)

const SalesOffersListContainer = () => {
  const dispatch = useDispatch()
  const isFetchingSalesOffers = useSelector(state => selectIsFetchingData('salesOffers')(state))

  useEffect(() => {
    dispatch(salesOffersReset())
    dispatch(salesOffersGetActiveListStart())
  }, [dispatch])

  return (
    <SalesOffersListWithSpinner isLoading={isFetchingSalesOffers} />
  )
}

export default SalesOffersListContainer
