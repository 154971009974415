import React from 'react'
import moment from 'moment'
import { ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getSupplyPointStatusIcon } from '../../redux/contract/contract.utils'

const HistoryEventsList = ({ events }) => (
  <>
    {events.length ? events.map(event => {
      const {
        oldStatus,
        oldStatusName,
        newStatus,
        newStatusName,
        updateDate,
        user,
        id
      } = event

      return(
        <ListGroupItem key={id}>
          <p>
            Il <strong>{ moment(updateDate).format('DD/MM/YYYY HH:mm') }</strong> l'utente <strong>{user}</strong> ha modificato lo stato del contratto:
          </p>
          <div className="status-history">
            <span>
              <FontAwesomeIcon className="mr-2 status-icon" icon={getSupplyPointStatusIcon(oldStatus)} title="Inserito" />
              <strong>{oldStatusName}</strong>
            </span>
            <FontAwesomeIcon className="mx-4 icon" icon="long-arrow-alt-right" />
            <span>
              <strong>{newStatusName}</strong>
              <FontAwesomeIcon className="ml-2 status-icon" icon={getSupplyPointStatusIcon(newStatus)} title="Inserito" />
            </span>
          </div>
        </ListGroupItem>
      )
    }) : (
        <ListGroupItem>
          <div className="status-history">
              Nessun cambio di stato registrato per questo contratto.
          </div>
        </ListGroupItem>
      )
    }
  </>
)

export default HistoryEventsList
