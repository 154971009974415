import { useState } from 'react'
import { Col, Button } from 'reactstrap'
import { useSelector } from 'react-redux'

import { selectUserData } from '../../../../redux/user/user.selectors'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import ExtendedAutocompleteFormField from '../../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import WhenFieldChanges from '../../WhenFieldChanges/WhenFieldChanges.component'
import { required, noFutureDate, positiveNumber } from '../../validations'
import { fetchTowns } from '../../../../api'
import WorkSiteRealEstateInstructions from '../RealEstateInstructions/WorkSiteRealEstateInstructions.component'

const PodWorkSiteRealEstateForm = ({ podData, formValues, values, canEdit }) => {
  const { accessToken } = useSelector(selectUserData)
  const [ instructionsIsOpen, setInstructionsIsOpen ] = useState(false)
  const toggleInstructionsModal = () => setInstructionsIsOpen(!instructionsIsOpen)

  return (
    <>
      <WhenFieldChanges
        field="realEstateAuthType"
        set="authNo"
        to={null}
      />
      <WhenFieldChanges
        field="realEstateAuthType"
        set="authDate"
        to={null}
      />
      <WhenFieldChanges
        field="realEstateAuthType"
        set="authTownName"
        to={null}
      />
      <WhenFieldChanges
        field="realEstateAuthType"
        set="instructions"
        to={null}
      />
      <Col md={6}>
        <CustomFormField
          name="powerSupplyMonths"
          type="number"
          maxLength="80"
          disabled={canEdit ? false : true}
          placeholder="Mesi di fornitura di energia elettrica*"
          fieldLabel="Mesi di fornitura di energia elettrica*"
          validate={positiveNumber(true)}
          min="0"
          defaultValue={podData?.powerSupplyMonths || null}
        />
      </Col>
      <Col md={6}>
        <CustomFormField
          name="executedWorks"
          type="text"
          maxLength="128"
          disabled={canEdit ? false : true}
          placeholder="Esecuzione delle seguenti opere*"
          fieldLabel="Esecuzione delle seguenti opere*"
          defaultValue={podData?.executedWorks || null}
          validate={required}
        />
      </Col>
      <Col md={12}>
        <ExtendedSelectField
          name="realEstateAuthType"
          fieldLabel="Immobile realizzato tramite*"
          placeholder="Immobile realizzato tramite*"
          options={formValues?.realEstateAuthType || []}
          defaultValue={podData?.realEstateAuthType || null}
          isDisabled={canEdit ? false : true}
          validate={required}
        />
      </Col>
      {parseInt(values?.realEstateAuthType?.value) < 3 &&
        <>
          <Col md={12}>
            <CustomFormField
              name="authNo"
              type="text"
              maxLength="80"
              disabled={canEdit ? false : true}
              placeholder="Numero permesso*"
              fieldLabel="Numero permesso*"
              defaultValue={podData?.authNo || null}
              validate={required}
            />
          </Col>
          <Col md={6}>
            <CustomDatePicker
              name="authDate"
              className="form-control"
              type="date"
              dateFormat="dd/MM/yyyy"
              placeholder="Data permesso*"
              fieldLabel="Data permesso*"
              adjustDateOnChange
              defaultValue={podData?.authDate || null}
              disabled={canEdit ? false : true}
              validate={noFutureDate(true)}
              maxDate={new Date()}
            />
          </Col>
          <Col md={6}>
            <ExtendedAutocompleteFormField
              name="authTownName"
              fieldLabel="Rilasciato dal comune*"
              placeholder="Rilasciato dal comune*"
              fetchFunction={fetchTowns(accessToken)}
              disabled={canEdit ? false : true}
              initialValue={podData?.authTownName || null}
              validate={required}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => ({ town: option.town, province: option.province })}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
        </>
      }
      {parseInt(values?.realEstateAuthType?.value) === 7 &&
        <Col md={12}>
          <CustomFormField
            name="instructions"
            type="textarea"
            maxLength="1028"
            disabled={canEdit ? false : true}
            placeholder="Dichiarazione istruzioni*"
            fieldLabel="Dichiarazione istruzioni*"
            defaultValue={podData?.instructions || null}
            validate={required}
            fieldDescription={
              <>
                Per maggiori informazioni su come compilare questo campo clicca 
                <Button 
                  color="link" 
                  className="px-1"
                  onClick={toggleInstructionsModal}
                >
                  qui
                </Button>
              </>
            }
          />
        </Col>
      }
      <WorkSiteRealEstateInstructions 
        isOpen={instructionsIsOpen}
        toggle={toggleInstructionsModal}
      />
    </>
  )
}

export default PodWorkSiteRealEstateForm