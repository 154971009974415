import React, { useState } from 'react'
import {
  FormGroup, Label, Input, FormFeedback, Button,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomerTypes, { getCustomerType } from './utils'
import Emoji from '../../../Emoji/Emoji.component'
import DomesticText from './DomesticText.component'
import BusinessText from './BusinessText.component'

import GeneralConditionsCheckboxStyle from './style'

const GeneralConditionsCheckbox = ({ input, meta, disabled, customerType }) => {
  const { error, touched } = meta
  const inputInvalid = error && touched ? true : false
  const inputValid = !error && touched ? true : false
  const disabledInput = disabled ? {disabled: "disabled"} : ''

  //Modal state
  const [ modalVisibility, setModalVisibility ] = useState(false)
  const toggleModal = () => {
    setModalVisibility(!modalVisibility)
  }

  return(
    <GeneralConditionsCheckboxStyle>
      <FormGroup>
        {/*<OldCustomerWarningText /> - import OldCustomerWarningText from './OldCustomerWarningText.component'*/}
        <Label check>
          <Input {...input} valid={inputValid} invalid={inputInvalid} {...disabledInput} />{' '}
          Ho letto le <Button color="link" className="link-button" onClick={toggleModal} size="sm">condizioni generali di contratto</Button><sup>*</sup>
          {inputInvalid &&
            <FormFeedback>
              {error}
              {' '}
              <Emoji
                label="police officer"
                symbol="👮"
              />
            </FormFeedback>
          }
        </Label>

        {/*Privacy modal text*/}
        <Modal className="form-modal privacy-modal" size="lg" backdrop="static" isOpen={modalVisibility} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <FontAwesomeIcon icon="clipboard-check" className="mr-2" />
            CONDIZIONI GENERALI DI CONTRATTO
          </ModalHeader>
          <ModalBody>
            {getCustomerType(CustomerTypes.DOMESTIC_CUSTOMER, customerType) &&
              <DomesticText />
            }
            {getCustomerType(CustomerTypes.BUSINESS_CUSTOMER, customerType) &&
              <BusinessText />
            }
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggleModal}>Chiudi</Button>
          </ModalFooter>
        </Modal>
      </FormGroup>
    </GeneralConditionsCheckboxStyle>
  )
}

export default GeneralConditionsCheckbox
