import React from 'react'
import { useSelector } from 'react-redux'
import { UncontrolledAlert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectUserMaintenanceState } from '../../redux/user/user.selectors'

const MaintenanceAlert = () => {
  const maintenanceState = useSelector(selectUserMaintenanceState)

  return (
    <UncontrolledAlert id="maintenance-alert" color="warning" className="container">
      <div className="content">
        <FontAwesomeIcon icon="construction" size="lg" className="mr-2" />
        <span>{ maintenanceState.maintenance_alert_text }</span>
      </div>
    </UncontrolledAlert>
  )
}

export default MaintenanceAlert