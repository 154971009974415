import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CadastralForm from '../CadastralForm/CadastralForm.component'
import { selectCadastralDetailModalVisibility } from '../../../../redux/contract/contract.selectors'
import { contractToggleCadastralDetailModal } from '../../../../redux/contract/contract.actions'

const CadastralDetailModal = ({ otherClasses }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectCadastralDetailModalVisibility)
  const toggle = () => dispatch(contractToggleCadastralDetailModal())

  return (
    <div className={`action-modal${otherClasses ? ` ${otherClasses}` : ''}`}>
      <Modal className="status-modal" size="lg" backdrop="static" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon icon="map-marker-alt" className="mr-2" />
          Punti di fornitura
        </ModalHeader>
        <ModalBody>
          <CadastralForm />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CadastralDetailModal
