import { Form } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import createDecorator from 'final-form-focus'
import { Button, Spinner, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { abilityCheck } from '../../../auth/CanI/utils'
import { 
  selectContract, selectPodFormFieldsValues, selectPdrFormFieldsValues, selectIsSubmitting 
} from '../../../../redux/contract/contract.selectors'
import { getEntityUriName } from '../../../../redux/contract/contract.utils'
import { contractSubmitActiveUserSupplyPointDataStart } from '../../../../redux/contract/contract.actions'
import { capitalizeWord } from '../../../../utils'
import WhenFieldChanges from "../../WhenFieldChanges/WhenFieldChanges.component"
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import { minValue, required } from '../../validations'
import { getAPIUrl } from '../../../../api'
import ErrorField from './ErrorField.component'
import { validateForm, hideValidationMessage } from './utils'

const focusOnError = createDecorator()

const ContractualChangesForm = () => {
  const dispatch = useDispatch()
  const podFormValues = useSelector(selectPodFormFieldsValues)
  const pdrFormValues = useSelector(selectPdrFormFieldsValues)
  const { entityName, activeUserSupplyPointData, selectedContractId } = useSelector(selectContract)
  const isSubmitting = useSelector(state => selectIsSubmitting('supplyPointTechOp')(state))

  let canEdit = true
  if(activeUserSupplyPointData?.id) {
    canEdit = abilityCheck(`${entityName}${capitalizeWord(activeUserSupplyPointData.supplyType)}UPDATE`, activeUserSupplyPointData.links)
  }

  const onSubmit = values => {
    const method = activeUserSupplyPointData?.id && canEdit === true ? 'PUT' : 'POST'
    const apiUrl = `${getAPIUrl()}/${getEntityUriName(entityName)}/${activeUserSupplyPointData.supplyType.toLowerCase()}s${activeUserSupplyPointData?.id ? `/${activeUserSupplyPointData.id}` : ''}`
    dispatch(contractSubmitActiveUserSupplyPointDataStart({ apiUrl, values, method }))
  }

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={{
        ...activeUserSupplyPointData,
        contractualChangesId: selectedContractId
      }}
      validate={values => validateForm(activeUserSupplyPointData.supplyType, values)}
    >
      {({ handleSubmit, values }) => (
        <form
          className="two-cols-form"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="grouped">
            <div className="grouped-header">
              {activeUserSupplyPointData.supplyType === 'POD' &&
                <FontAwesomeIcon icon="bolt" className="mr-2" />
              }
              {activeUserSupplyPointData.supplyType === 'PDR' &&
                <FontAwesomeIcon icon="fire" className="mr-2" />
              }
              Modifiche contrattuali
            </div>
            <div className="grouped-body">
              <Row form>
                {activeUserSupplyPointData.supplyType === 'POD' &&
                  <>
                    <Col md={12}>
                      <ExtendedSelectField
                        name="electricUseTypeId"
                        isClearable
                        isMulti={false}
                        noValidationMessage
                        fieldLabel="Tipologia contratto"
                        placeholder="Tipologia contratto"
                        options={podFormValues?.electricUseType || []}
                        isDisabled={canEdit ? false : true}
                        defaultValue={activeUserSupplyPointData?.electricUseTypeId || null}
                      />
                      <WhenFieldChanges
                        field="electricUseTypeId"
                        set="stair"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="electricUseTypeId"
                        set="floor"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="electricUseTypeId"
                        set="indoor"
                        to={null}
                      />
                      <WhenFieldChanges
                        field="electricUseTypeId"
                        set="from"
                        to={null}
                      />
                    </Col>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="requestedPowerValue"
                        isMulti={false}
                        isClearable
                        noValidationMessage
                        fieldLabel="Potenza richiesta totale in kW"
                        placeholder="Potenza richiesta totale in kW"
                        options={podFormValues?.powerValue || []}
                        defaultValue={activeUserSupplyPointData?.requestedPowerValue || null}
                        isDisabled={canEdit ? false : true}
                      />
                    </Col>
                    {parseInt(values.requestedPowerValue?.value) === 23 && (
                      <Col md={6}>
                        <CustomFormField
                          id="customPower"
                          name="customPower"
                          type="number"
                          step=".01"
                          maxLength="80"
                          disabled={canEdit ? false : true}
                          min="30"
                          placeholder="Specificare la potenza in KW*"
                          fieldLabel="Specificare la potenza in KW*"
                          validate={minValue(30)}
                          defaultValue={activeUserSupplyPointData?.customPower || null}
                        />
                      </Col>
                    )}
                    <Col>
                      <ExtendedSelectField
                        name="requestedConnectionVoltageId"
                        isMulti={false}
                        isClearable
                        noValidationMessage
                        fieldLabel="Tensione richiesta"
                        placeholder="Tensione richiesta"
                        options={podFormValues?.connectionVoltage || []}
                        defaultValue={activeUserSupplyPointData?.requestedConnectionVoltageId || null}
                        isDisabled={canEdit ? false : true}
                      />
                    </Col>
                  </>
                }
                {activeUserSupplyPointData.supplyType === 'PDR' &&
                  <>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="supplyUseType"
                        isMulti={false}
                        isClearable
                        fieldLabel="Utilizzo della fornitura"
                        placeholder="Utilizzo della fornitura"
                        options={pdrFormValues?.supplyUseType || []}
                        defaultValue={activeUserSupplyPointData?.supplyUseType || null}
                        isDisabled={canEdit ? false : true}
                        noValidationMessage
                      />
                    </Col>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="gasUseTypeId"
                        isMulti={false}
                        isClearable
                        fieldLabel="Tipo uso"
                        placeholder="Tipo uso"
                        options={pdrFormValues?.gasUseType || []}
                        isDisabled={canEdit ? false : true}
                        noValidationMessage
                        defaultValue={activeUserSupplyPointData?.gasUseTypeId || null}
                      />
                    </Col>
                    <Col md={12}>
                      <legend className="col-form-label">Variazione potenzialità impianto:</legend>
                    </Col>
                    <Col md={6}>
                      <CustomFormField
                        id="requestedTotalPower"
                        name="requestedTotalPower"
                        type="number"
                        maxLength="80"
                        disabled={canEdit ? false : true}
                        min="0"
                        placeholder="Potenzialità richiesta"
                        fieldLabel={`Potenzialità richiesta${values.requestedTotalPower || values.requestedTotalPowerMesureUnit ? '*' : ''}`}
                        noValidationMessage={hideValidationMessage(values)}
                        defaultValue={activeUserSupplyPointData?.requestedTotalPower || null}
                      />
                    </Col>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="requestedTotalPowerMesureUnit"
                        isMulti={false}
                        isClearable
                        fieldLabel={`Unità di misura${values.requestedTotalPower || values.requestedTotalPowerMesureUnit ? '*' : ''}`}
                        placeholder="Unità di misura"
                        options={pdrFormValues?.powerMesureUnit || []}
                        isDisabled={canEdit ? false : true}
                        noValidationMessage={hideValidationMessage(values)}
                        defaultValue={activeUserSupplyPointData?.requestedTotalPowerMesureUnit || null}
                      />
                    </Col>
                    <Col md={12}>
                      <legend className="col-form-label">Potenzialità attuale installata:</legend>
                    </Col>
                    <Col md={6}>
                      <CustomFormField
                        id="actualTotalPower"
                        name="actualTotalPower"
                        type="number"
                        maxLength="80"
                        disabled={canEdit ? false : true}
                        min="0"
                        placeholder="Potenzialità attuale"
                        fieldLabel={`Potenzialità attuale${values.actualTotalPowerMesureUnit || values.actualTotalPower ? '*' : ''}`}
                        noValidationMessage={hideValidationMessage(values)}
                        defaultValue={activeUserSupplyPointData?.actualTotalPower || null}
                      />
                    </Col>
                    <Col md={6}>
                      <ExtendedSelectField
                        name="actualTotalPowerMesureUnit"
                        isMulti={false}
                        isClearable
                        fieldLabel={`Unità di misura${values.actualTotalPowerMesureUnit || values.actualTotalPower ? '*' : ''}`}
                        placeholder="Unità di misura"
                        options={pdrFormValues?.powerMesureUnit || []}
                        isDisabled={canEdit ? false : true}
                        noValidationMessage={hideValidationMessage(values)}
                        defaultValue={activeUserSupplyPointData?.actualTotalPowerMesureUnit || null}
                      />
                    </Col>
                  </>
                }
                <Col md={12}>
                  <ErrorField name="noFieldsCompiled" />
                </Col>
              </Row>
            </div>
          </div>
          {parseInt(values.electricUseTypeId?.value) === 1 &&
            <div className="grouped">
              <div className="grouped-header">
                <FontAwesomeIcon icon="location-dot" className="mr-2" />
                Indirizzo di residenza
              </div>
              <div className="grouped-body">
                <Row form>
                  <Col md={6}>
                    <CustomFormField
                      id="stair"
                      name="stair"
                      type="text"
                      maxLength="50"
                      disabled={canEdit ? false : true}
                      placeholder="Scala"
                      fieldLabel="Scala"
                      defaultValue={activeUserSupplyPointData?.stair || null}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="floor"
                      name="floor"
                      type="text"
                      maxLength="50"
                      disabled={canEdit ? false : true}
                      placeholder="Piano"
                      fieldLabel="Piano"
                      defaultValue={activeUserSupplyPointData?.floor || null}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomFormField
                      id="indoor"
                      name="indoor"
                      type="text"
                      maxLength="50"
                      disabled={canEdit ? false : true}
                      placeholder="Interno"
                      fieldLabel="Interno"
                      defaultValue={activeUserSupplyPointData?.indoor || null}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomDatePicker
                      id="from"
                      name="from"
                      type="date"
                      fieldLabel="Residente dal*"
                      placeholder="Residente dal*"
                      adjustDateOnChange
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      maxDate={new Date()}
                      defaultValue={activeUserSupplyPointData?.from || null}
                      validate={required}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          }
          <div className="text-right">
            <Button type="submit" disabled={isSubmitting || canEdit === false}>
              {isSubmitting ? (
                <Spinner size="sm" color="light" className="mr-1" />
              ) : (
                <FontAwesomeIcon icon="save" className="mr-1" />
              )}
              <span>Salva</span>
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}

export default ContractualChangesForm