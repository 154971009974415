import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SalesOffersListStyle from './style'
import SalesOffersDownloadBtn from './SalesOffersDownloadBtn.component'
import { getSupplyPointIcon } from './utils'
import { selectSalesOffersList } from '../../redux/salesOffers/salesOffers.selectors'

const SalesOffersList = () => {
  const salesOffersList = useSelector(selectSalesOffersList)

  return (
    <>
      <SalesOffersListStyle>
        {!!salesOffersList.length === false &&
          <section className="list-container" id="pods">
            <h1 className="section-title">Nessun documento scaricabile al momento</h1>
          </section>
        }
        {salesOffersList.map((item, i) => (
          <div className="sale-offer-item" key={i}>
            <div className="text-container">
              <FontAwesomeIcon 
                icon={getSupplyPointIcon(item.fornitureType)}
                className="mr-2"
              />
              {item.name}
              <div className="more-info">
                {item.customerType} - {item.code}
              </div>
            </div>
            <div className="context-menu">
              <SalesOffersDownloadBtn 
                saleOfferId={item.id}
              />
            </div>
          </div>
        ))}
      </SalesOffersListStyle>
      {!!salesOffersList.length &&
        <div className="total-items container">
          <strong>Elementi totali:</strong>
          {salesOffersList.length}
        </div>
      }
    </>
  )
}

export default SalesOffersList