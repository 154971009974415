import React from 'react'
import { useSelector } from 'react-redux'
import { Navbar, Container } from 'reactstrap'
import { NavLink } from 'react-router-dom'

import { withRouter } from '../AppRouter/utils'
import { HeaderStyle } from './style'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import NavMenu from '../NavMenu/NavMenu.component'
import { selectIsUserLoggedIn, selectIsLoading, selectUsername } from '../../redux/user/user.selectors'

const Header = ({ router: { location: { pathname } } }) => {
  const isLoading = useSelector(selectIsLoading)
  const userLoggedIn = useSelector(selectIsUserLoggedIn)
  const username = useSelector(selectUsername)

  return(
    <HeaderStyle>
      <Navbar dark expand="md" className={`${isLoading === false ? ' ready' : ' waiting'}`}>
        <Container>
          <NavLink className="navbar-brand" end to="/">
            <Logo />
            {userLoggedIn && pathname !== '/' &&
              <span className="username">{username}</span>
            }
          </NavLink>
          {userLoggedIn === true && isLoading === false &&
            <NavMenu />
          }
        </Container>
      </Navbar>
    </HeaderStyle>
  )
}

export default withRouter(Header)
