import React, { useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getAPIUrl } from '../../../api'
import { getTemplateDownloadPath } from './utils'
import { UserMassiveCreationFormStyle } from './style'
import Dropzone from '../inputFields/Dropzone/Dropzone.component'
import { selectIsSubmitting } from '../../../redux/contract/contract.selectors'
import { contractUserMassiveCreationStart } from '../../../redux/contract/contract.actions'
import { maxSize } from '../AttachmentsDropzone/utils'

const UserMassiveCreationForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(state => selectIsSubmitting('user')(state))

  const [ validate, setValidate ] = useState(true)
  const [ excelFile, setExcelFile ] = useState([])

  const onSubmit = values => {
    if(values.massiveCreationExcel?.length) {
      const apiUrl = `${getAPIUrl('abStorePortal')}/users/massive-insertions`
      return new Promise((resolve, reject) => {
        dispatch(contractUserMassiveCreationStart({ 
          apiUrl,
          values: {
            fileBase64: excelFile[0].encoded,
            userFileName: excelFile[0].userFileName
          }, 
          resolve, 
          reject }))
      })
    }
    else {
      setValidate(false)
    }
  }

  return (
    <UserMassiveCreationFormStyle>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">1</span>
          Scarica il template del file excel
        </div>
        <div className="grouped-body text-center">
          
          <Button 
            color="secondary" 
            target="_blank" 
            href={`${getTemplateDownloadPath('abStorePortal')}/templates/v1/excels/Modello.xlsx`}
          >
            <FontAwesomeIcon icon="download" className="mr-2" />
            <span>Scarica template</span>
          </Button>
        </div>
      </div>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">2</span>
          Compila il file excel
        </div>
        <div className="grouped-body">
          Compila il file seguendo l'impostazione indicata nel template, così che durante il processo 
          di importazione non si verifichino errori dovuti a una scorretta formattazione dei campi e dei relativi valori richiesti.<br />
          <strong>Nota bene</strong>: la prima riga del template fornito non sarà processata, ricordati quindi 
          di non modificarla/sovrascriverla perché tali valori non saranno comunque considerati.
        </div>
      </div>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form
              className="two-cols-form"
              autoComplete="off"
              onSubmit={event => {
                handleSubmit(event)
                form.reset()
              }}
            >
              <div className="grouped">
                <div className="grouped-header">
                  <span className="step">3</span>
                  Carica il file excel<sup>*</sup>
                </div>
                <div className="grouped-body">
                  <Row form>
                    <Col md={12}>
                      <Dropzone
                        acceptedFileTypes={{
                          'application/vnd.ms-excel': [], 
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
                        }}
                        files={excelFile}
                        setFiles={setExcelFile}
                        inputName="massiveCreationExcel"
                        setValidate={setValidate}
                        maxSize={maxSize}
                        mutators={{
                          ...form.mutators
                        }}
                      />
                    </Col>
                    {validate === false &&
                      <p className="px-2 text-danger">È obbligatorio caricare un file excel.</p>
                    }
                  </Row>
                  <div className="text-right">
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                          <Spinner size="sm" color="light" className="mr-2" />
                        ) : (
                          <FontAwesomeIcon icon="upload" className="mr-2" />
                      )}
                      {' '}
                      <span>Carica file</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        }
      </Form>
      <div className="grouped">
        <div className="grouped-header">
          <span className="step">4</span>
          Ricevi la notifica ad importazione avvenuta
        </div>
        <div className="grouped-body">
          Caricato il file, un processo in backgroud elaborerà la richiesta e gestirà l'importazione 
          massiva degli utenti (il completamento dell'operazione può richiedere diversi minuti).<br />
          Una volta terminato, ti invieremo un'email per notificarne l'esito.
        </div>
      </div>
    </UserMassiveCreationFormStyle>
  )
}

export default UserMassiveCreationForm