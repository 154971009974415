import { useState } from 'react'
import { Col, Button } from 'reactstrap'
import { useSelector } from 'react-redux'

import { selectUserData } from '../../../../redux/user/user.selectors'
import ExtendedSelectField from '../../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import CustomFormField from '../../inputFields/CustomFormField/CustomFormField.component'
import CustomDatePicker from '../../inputFields/CustomDatePicker/CustomDatePicker.component'
import ExtendedAutocompleteFormField from '../../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import WhenFieldChanges from '../../WhenFieldChanges/WhenFieldChanges.component'
import { required, noFutureDate } from '../../validations'
import { fetchTowns } from '../../../../api'
import OtherUseRealEstateInstructions from '../RealEstateInstructions/OtherUseRealEstateInstructions.component'

const PodOtherUseRealEstateForm = ({ podData, formValues, values, extendedPodDataNeeded, canEdit }) => {
  const { accessToken } = useSelector(selectUserData)
  const [ instructionsIsOpen, setInstructionsIsOpen ] = useState(false)
  const toggleInstructionsModal = () => setInstructionsIsOpen(!instructionsIsOpen)

  return (
    <>
      <WhenFieldChanges
        field="realEstateAuthType"
        set="authNo"
        to={null}
      />
      <WhenFieldChanges
        field="realEstateAuthType"
        set="authDate"
        to={null}
      />
      <WhenFieldChanges
        field="realEstateAuthType"
        set="authTownName"
        to={null}
      />
      <WhenFieldChanges
        field="realEstateAuthType"
        set="instructions"
        to={null}
      />
      <WhenFieldChanges
        field="plantPresence"
        set="plantAuthNo"
        to={null}
      />
      <WhenFieldChanges
        field="plantPresence"
        set="plantAuthDate"
        to={null}
      />
      <WhenFieldChanges
        field="plantPresence"
        set="plantAuthTownName"
        to={null}
      />
      <Col md={12}>
        <ExtendedSelectField
          name="realEstateAuthType"
          fieldLabel="Immobile realizzato tramite*"
          placeholder="Immobile realizzato tramite*"
          options={formValues?.realEstateAuthType || []}
          defaultValue={podData?.realEstateAuthType || null}
          isDisabled={canEdit ? false : true}
          validate={required}
        />
      </Col>
      {parseInt(values?.realEstateAuthType?.value) < 3 &&
        <>
          <Col md={12}>
            <CustomFormField
              name="authNo"
              type="text"
              maxLength="80"
              disabled={canEdit ? false : true}
              placeholder="Numero permesso*"
              fieldLabel="Numero permesso*"
              defaultValue={podData?.authNo || null}
              validate={required}
            />
          </Col>
          <Col md={6}>
            <CustomDatePicker
              name="authDate"
              className="form-control"
              type="date"
              dateFormat="dd/MM/yyyy"
              placeholder="Data permesso*"
              fieldLabel="Data permesso*"
              adjustDateOnChange
              defaultValue={podData?.authDate || null}
              disabled={canEdit ? false : true}
              validate={noFutureDate(true)}
              maxDate={new Date()}
            />
          </Col>
          <Col md={6}>
            <ExtendedAutocompleteFormField
              name="authTownName"
              fieldLabel="Rilasciato dal comune*"
              placeholder="Rilasciato dal comune*"
              fetchFunction={fetchTowns(accessToken)}
              disabled={canEdit ? false : true}
              initialValue={podData?.authTownName || null}
              validate={required}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => ({ town: option.town, province: option.province })}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
        </>
      }
      {parseInt(values?.realEstateAuthType?.value) === 7 &&
        <Col md={12}>
          <CustomFormField
            name="instructions"
            type="textarea"
            maxLength="1028"
            disabled={canEdit ? false : true}
            placeholder="Dichiarazione istruzioni*"
            fieldLabel="Dichiarazione istruzioni*"
            defaultValue={podData?.instructions || null}
            validate={required}
            fieldDescription={
              <>
                Per maggiori informazioni su come compilare questo campo clicca 
                <Button 
                  color="link" 
                  className="px-1"
                  onClick={toggleInstructionsModal}
                >
                  qui
                </Button>
              </>
            }
          />
        </Col>
      }
      <Col md={12}>
        <ExtendedSelectField
          name="plantPresence"
          fieldLabel="Presenza impianti"
          placeholder="Presenza impianti"
          options={formValues?.plantPresence || []}
          defaultValue={podData?.plantPresence || null}
          isDisabled={canEdit ? false : true}
        />
      </Col>
      {parseInt(values?.plantPresence?.value) === 1 &&
        <>
          <Col md={6}>
            <CustomFormField
              name="plantAuthNo"
              type="text"
              maxLength="80"
              disabled={canEdit ? false : true}
              placeholder="Numero autorizzazione*"
              fieldLabel="Numero autorizzazione*"
              defaultValue={podData?.plantAuthNo || null}
              validate={required}
            />
          </Col>
          <Col md={6}>
            <CustomDatePicker
              name="plantAuthDate"
              className="form-control"
              type="date"
              dateFormat="dd/MM/yyyy"
              placeholder="Data autorizzazione*"
              fieldLabel="Data autorizzazione*"
              adjustDateOnChange
              defaultValue={podData?.plantAuthDate || null}
              disabled={canEdit ? false : true}
              validate={noFutureDate(true)}
              maxDate={new Date()}
            />
          </Col>
          <Col md={12}>
            <ExtendedAutocompleteFormField
              name="plantAuthTownName"
              fieldLabel="Rilasciata dal comune*"
              placeholder="Rilasciata dal comune*"
              fetchFunction={fetchTowns(accessToken)}
              disabled={canEdit ? false : true}
              initialValue={podData?.plantAuthTownName || null}
              validate={required}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => ({ town: option.town, province: option.province })}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
        </>
      }
      {extendedPodDataNeeded === true &&
        <>
          <Col md={6}>
            <CustomDatePicker
              name="authDeclarationDate"
              className="form-control"
              type="date"
              dateFormat="dd/MM/yyyy"
              placeholder="Certificato di agibilità ottenuto il*"
              fieldLabel="Certificato di agibilità ottenuto il*"
              adjustDateOnChange
              defaultValue={podData?.authDeclarationDate || null}
              disabled={canEdit ? false : true}
              validate={noFutureDate(true)}
              maxDate={new Date()}
            />
          </Col>
          <Col md={6}>
            <ExtendedAutocompleteFormField
              name="authDeclarationTownName"
              fieldLabel="Certificato di agibilità ottenuto dal comune*"
              placeholder="Certificato di agibilità ottenuto dal comune*"
              fetchFunction={fetchTowns(accessToken)}
              disabled={canEdit ? false : true}
              initialValue={podData?.authDeclarationTownName || null}
              validate={required}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => ({ town: option.town, province: option.province })}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
          <Col md={12}>
            <ExtendedSelectField
              name="authDeclarationAsl"
              fieldLabel="Certificazione ASL*"
              placeholder="Certificazione ASL*"
              options={formValues?.authDeclarationAsl || []}
              defaultValue={podData?.authDeclarationAsl || null}
              isDisabled={canEdit ? false : true}
              validate={required}
            />
          </Col>
        </>
      }
      <Col md={12}>
        <CustomDatePicker
          name="constructionStartDate"
          className="form-control"
          type="date"
          dateFormat="dd/MM/yyyy"
          placeholder="Data inizio lavori*"
          fieldLabel="Data inizio lavori*"
          adjustDateOnChange
          defaultValue={podData?.constructionStartDate || null}
          disabled={canEdit ? false : true}
          validate={noFutureDate(true)}
          maxDate={new Date()}
        />
      </Col>
      <OtherUseRealEstateInstructions 
        isOpen={instructionsIsOpen}
        toggle={toggleInstructionsModal}
      />
    </>
  )
}

export default PodOtherUseRealEstateForm