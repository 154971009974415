import styled from 'styled-components'

export const WayneKnightGif = styled.figure`
  text-align: center;
  margin-bottom: 2.5rem;

  figcaption { 
    font-family: monospace;
    font-size: 1.15rem;
  }
`