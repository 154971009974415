import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DeleteItem = ({ itemId, isDeleting, buttonLabel, otherClasses, size, actionUrl, actionFunction, callbackFunction = null, rest }) => {
  const dispatch = useDispatch()
  const [ visibility, setVisibility ] = useState(false)
  const toggle = () => setVisibility(!visibility)
  const deleteItem = () => {
    dispatch(actionFunction({ apiUrl: actionUrl, itemId, ...rest }))
    if(callbackFunction) callbackFunction()
    toggle()
  }

  return (
    <div
      className={`action-modal${otherClasses ? ` ${otherClasses}` : ''}`}
    >
      <Button 
        title="Elimina" 
        onClick={toggle} 
        size={size ? size : 'sm'} 
        color="danger" 
        outline
        disabled={isDeleting}
      >
          {isDeleting ? (
            <Spinner size="sm" />
          ) : (
            <FontAwesomeIcon size="sm" icon="trash-alt" />
          )}
          {buttonLabel &&
            <span className="btn-label">{buttonLabel}</span>
          }
      </Button>
      <Modal size="sm" isOpen={visibility} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon icon="exclamation-triangle" />
        </ModalHeader>
        <ModalBody>
          Eliminare definitivamente questo elemento?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={deleteItem}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <Spinner color="light" size="sm" />
            ) : (
              <FontAwesomeIcon icon="trash-alt" />
            )}
            {' '} Elimina
          </Button>
          <Button color="secondary" onClick={toggle}>Annulla</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DeleteItem
