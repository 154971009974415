import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'

import ScrollToTop from '../../../hooks/navigation/useScrollToTop'
import SupplyPointItem from './SupplyPointItem/SupplyPointItem.component'
import ActiveUserSupplyPointModal from '../ActiveUserSupplyPointModal/ActiveUserSupplyPointModal.component'
import SupplyPointsListStyle from './style'
import { 
  selectPodSupplyPoints, selectPdrSupplyPoints,
  selectValidateAllSupplyPoints, selectBlockAllSupplyPoints
} from '../../../redux/contract/contract.selectors'
import { scrollTo } from './utils'
import CadastralBlockAllModal from '../CadastralBlockAllModal/CadastralBlockAllModal.component'
import CadastralValidateAllModal from '../CadastralValidateAllModal/CadastralValidateAllModal.component'

const SupplyPointsList = () => {
  const podSupplyPoints = useSelector(selectPodSupplyPoints)
  const pdrSupplyPoints = useSelector(selectPdrSupplyPoints)
  const canValidateAll = useSelector(selectValidateAllSupplyPoints)
  const canBlockAll = useSelector(selectBlockAllSupplyPoints)

  return (
    <SupplyPointsListStyle>
      <ScrollToTop />
      {!podSupplyPoints.length && !pdrSupplyPoints.length &&
        <section className="list-container" id="pods">
          <h1 className="mt-5 section-title">Nessun punto di fornitura attivo</h1>
        </section>
      }

      {(canValidateAll || canBlockAll) &&
        <div className="validate-block-all-container">
          {canValidateAll &&
            <CadastralValidateAllModal />
          }
          {canBlockAll &&
            <CadastralBlockAllModal />
          }
        </div>
      }

      {!!podSupplyPoints.length && !!pdrSupplyPoints.length &&
        <Row>
          <Col>
            <div className="contracts-shortcuts">
              <h3 className="title">Categorie punti di fornitura:</h3>
              <div className="links-container">
                <a 
                  onClick={e => scrollTo(e, '#pods')} 
                  className="btn btn-outline-primary" 
                  href="#new-clients"
                >
                  Punti di fornitura energia elettrica
                </a>
                <a 
                  onClick={e => scrollTo(e, '#pdrs')} 
                  className="btn btn-outline-primary" 
                  href="#active-clients"
                >
                  Punti di fornitura gas
                </a>
              </div>
            </div>
          </Col>
        </Row>
      }

      <ActiveUserSupplyPointModal />
      {!!podSupplyPoints.length &&
        <section className="list-container" id="pods">
          <h1 className="section-title">Punti fornitura energia elettrica:</h1>
          <div className="supply-point-container">
            {podSupplyPoints.map(item => (
              <SupplyPointItem 
                key={item.supplyNo} 
                item={item}
              />
            ))}
          </div>
        </section>
      }

      {!!pdrSupplyPoints.length &&
        <section className="list-container" id="pdrs">
          <h1 className="section-title">Punti fornitura gas:</h1>
          <div className="supply-point-container">
            {pdrSupplyPoints.map(item => (
              <SupplyPointItem 
                key={item.supplyNo} 
                item={item}
              />
            ))}
          </div>
        </section>
      }
    </SupplyPointsListStyle>
  )
}

export default SupplyPointsList