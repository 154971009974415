import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Spinner, Input, FormGroup, FormFeedback, Label } from 'reactstrap'

import Emoji from '../../Emoji/Emoji.component'
import { WayneKnightGif } from './style'
import { selectIsSubmitting, selectWayneKnightGifVisibility } from '../../../redux/user/user.selectors'
import { userLoginStart, userToggleResetPasswordModalVisibility, userChangeLoginStep } from '../../../redux/user/user.actions'
import { LoginSteps } from '../../../redux/user/user.utils'
import wayneKnight from '../../../assets/images/wayne-knight.gif'

const focusOnError = createDecorator()

const LoginPasswordForm = () => {
  const inputRef = useRef(null)
  useEffect(() => inputRef.current.focus(), [])

  const dispatch = useDispatch()
  const wayneKnightGifModalVisibility = useSelector(selectWayneKnightGifVisibility)
  const isSubmitting = useSelector(selectIsSubmitting)
  const onSubmit = values => {
    if(!values.password) {
      return { password: "Campo obbligatorio" }
    }
    dispatch(userLoginStart(values))
  }

  return (
    <div>
      {wayneKnightGifModalVisibility ? (
        <WayneKnightGif>
          <img src={wayneKnight} alt="Wayne Knight gif" />
          <figcaption>You didn't say the magic word!</figcaption>
        </WayneKnightGif>
      ) : (
        <div className="login-icon">
          <FontAwesomeIcon icon="lock-alt" />
        </div>
      )}
      <p className="lead">Inserisci la tua password:</p>
      <Form
        onSubmit={onSubmit}
        decorators={[focusOnError]}
        subscription={{ values: true }}
      >
        {({ handleSubmit, form, submitting, pristine, values, reset }) => {
          return (
            <form
              className="two-cols-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Field id="password" name="password">
                      {({ input, meta: { submitError, submitSucceeded } }) => (
                        <>
                          <Label for="password">Password</Label>
                          <Input
                            innerRef={inputRef}
                            maxLength="80"
                            type="password"
                            disabled={isSubmitting}
                            invalid={submitError ? true : false}
                            {...input}
                          />
                          <FormFeedback>
                            {submitError}
                            {' '}
                            <Emoji
                              label="police officer"
                              symbol="👮"
                            />
                          </FormFeedback>
                        </>
                      )}
                    </Field>
                  </FormGroup>
                  <div>
                    <p className="small text-right">
                      <Button
                        color="link"
                        className="link-button"
                        onClick={() => dispatch(userToggleResetPasswordModalVisibility())}
                        size="sm"
                      >
                          Hai dimenticato la password?
                      </Button>
                    </p>
                    <div className="mt-3 d-flex justify-content-between">
                      <Button
                        size="lg"
                        outline
                        onClick={() => dispatch(userChangeLoginStep(LoginSteps.USER_EMAIL_STEP))}
                      >
                        <FontAwesomeIcon icon="step-backward" className="mr-1" />
                        Indietro
                      </Button>
                      <Button type="submit" disabled={isSubmitting} size="lg">
                        <span>Login</span>
                        {isSubmitting ? (
                          <Spinner size="sm" color="light" className="ml-1" />
                        ) : (
                          <FontAwesomeIcon icon="sign-in-alt" className="ml-1" />
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

export default LoginPasswordForm
