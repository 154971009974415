//!IMPORTANT This component presumes you're using react-final-form
import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, FormFeedback, Label, FormText } from 'reactstrap'

import Emoji from '../../../Emoji/Emoji.component'

const CustomSelectField = ({ children, ...props }) => (
  <FormGroup>
    <Field {...props}>
      {({ fieldLabel, fieldDescription, noValidationMessage, input, meta: { error, touched }, options, ...otherProps }) => (
        <>
          {fieldLabel &&
            <Label for={input.name}>{fieldLabel}</Label>
          }
          <Input
            invalid={error && touched ? true : false}
            valid={!error && touched ? true : false}
            {...input}
            {...otherProps}
          >
            <option value="">...</option>
            {options.map((opt, i) => (
              <option key={i} value={opt.value}>{opt.label}</option>
            ))}
          </Input>
          {fieldDescription &&
            <FormText color="muted">
              {fieldDescription}
            </FormText>
          }
          {!noValidationMessage &&
            <>
            {error && touched ?
              <FormFeedback>
                {error}
                {' '}
                <Emoji
                  label="police officer"
                  symbol="👮"
                />
              </FormFeedback>
              :
              <FormFeedback valid>
                {fieldLabel}
                {' '}
                <Emoji
                  label="thumb up"
                  symbol="👍🏻"
                />
              </FormFeedback>
            }
            </>
          }
        </>
      )}
    </Field>
    {children}
  </FormGroup>
)

export default CustomSelectField
