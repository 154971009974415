import React, { useState } from 'react'
import classnames from 'classnames'
import {
  Container, Row, Col,
  Nav, NavItem, NavLink
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProposalList from '../ProposalList/ProposalList.component'
import PodList from '../PodList/PodList.component'
import PdrList from '../PdrList/PdrList.component'

const AllList = () => {
  //Selected tab state
  const [ selectedTab, setSelectedTab ] = useState('proposal')

  return(
    <>
      <Container className="loader-container">
        <Row>
          <Col xs={12}>
            <Nav tabs className="subscription-nav mb-0">
              <NavItem>
                <NavLink
                  onClick={e => setSelectedTab('proposal')}
                  className={classnames({ active: selectedTab === "proposal"})}
                >
                  <FontAwesomeIcon icon="address-card" className="mr-2" />
                  Proposta
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={e => setSelectedTab('pod')}
                  className={classnames({ active: selectedTab === "pod"})}
                >
                  <FontAwesomeIcon icon="bolt" className="mr-2" />
                  POD
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={e => setSelectedTab('pdr')}
                  className={classnames({ active: selectedTab === "pdr"})}
                >
                  <FontAwesomeIcon icon="fire" className="mr-2" />
                  PDR
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>

      {selectedTab === 'proposal' &&
        <ProposalList />
      }

      {selectedTab === 'pod' &&
        <PodList />
      }

      {selectedTab === 'pdr' &&
        <PdrList />
      }
    </>
  )
}

export default AllList
