import React from 'react'

import { withRouter } from "../AppRouter/utils"
import { checkRoute } from './utils'

const DropdownActiveCheck = (props) => {
  //Route props
  const { router: { location: { pathname } }, children, searchedValue } = props
  //Contracts voice active?
  const isActive = checkRoute(pathname, searchedValue) ? {className: "active"} : ""

  return(
    <div>
      {children(isActive)}
    </div>
  )
}

export default withRouter(DropdownActiveCheck)
