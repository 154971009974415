import { useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CustomReadOnlyField from '../inputFields/CustomReadOnlyField/CustomReadOnlyField.component'
import ExtendedSelectField from '../inputFields/ExtendedSelectField/ExtendedSelectField.component'
import ExtendedAutocompleteFormField from '../inputFields/ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import CustomDatePicker from '../inputFields/CustomDatePicker/CustomDatePicker.component'
import { selectUserFormFieldsValues, selectProposalFormFieldsValues, selectContract } from '../../../redux/contract/contract.selectors'
import { selectUserData } from '../../../redux/user/user.selectors'
import { ContractStatusMap } from '../../../redux/contract/contract.utils'
import { canI } from '../../../components/auth/CanI/utils'
import { fetchTowns } from '../../../api'
import { required } from '../validations'

const AgentData = () => {
  const userFormValues = useSelector(selectUserFormFieldsValues)
  const { entityName, selectedContractId, proposalData, selectedContractStatusId } = useSelector(selectContract)
  const formValues = useSelector(selectProposalFormFieldsValues)
  const { accessToken } = useSelector(selectUserData)
  const canEdit = proposalData ? canI(`${entityName}UPDATE`, proposalData.links).abilityCheck : true

  return (
    <div className="grouped">
      <div className="grouped-header">
        <FontAwesomeIcon icon="file" className="mr-2" />
        Dati
      </div>
      <div className="grouped-body">
        <Row form>
          <Col md={6}>
            <CustomReadOnlyField
              id="userLogged"
              name="userLogged"
              placeholder="Utente"
              fieldLabel="Utente"
              type="text"
            />
          </Col>
          <Col md={6}>
            <ExtendedSelectField
              name="userCode"
              isMulti={false}
              fieldLabel="Agente venditore**"
              placeholder="Agente venditore"
              options={userFormValues?.userCode || []}
              isDisabled={selectedContractId ? true : false}
              customFilterFunction={(candidate, input) => {
                if (input) {
                  const lowerCaseCandidate = candidate.data.label.toLowerCase()
                  return lowerCaseCandidate.includes(input.toLowerCase())
                }
                return true
              }}                    
              getOptionLabel={option =>
                <>{option.value} - {option.label}</>
              }
            />
            <p className="small text-muted">**Attenzione: una volta inserito non potrà essere più modificato!</p>                    
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <ExtendedAutocompleteFormField
              name="subscriptionTownName"
              fieldLabel="Comune*"
              placeholder="Comune"
              fetchFunction={fetchTowns(accessToken)}
              isDisabled={canEdit ? false : true}
              validate={required}
              getOptionLabel={option => `${option.town} (${option.province})`}
              getOptionValue={option => ({ town: option.town, province: option.province })}
              noOptionsMessage={() => 
                <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
              }
            />
          </Col>
          <Col md={6}>
            <ExtendedSelectField
              name="language"
              isMulti={false}
              fieldLabel="Lingua contratto*"
              placeholder="Lingua contratto"
              validate={required}
              options={formValues?.language || []}
              isDisabled={canEdit ? false : true}
              fieldDescription="Questa opzione definisce la lingua con la quale sarà redatto il contratto in formato PDF dopo la firma."
            />
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <CustomDatePicker
              id="compilationDate"
              name="compilationDate"
              type="date"
              fieldLabel="Data caricamento"
              readOnly
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </Col>
          {selectedContractStatusId >= ContractStatusMap.DONE_STATUS &&
            <Col md={6}>
              <CustomDatePicker
                className="form-control"
                id="signingDate"
                name="signingDate"
                type="date"
                dateFormat="dd/MM/yyyy"
                fieldLabel="Data firma"
                readOnly
              />
            </Col>
          }
        </Row>
      </div>
    </div>
  )
}

export default AgentData