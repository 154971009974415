import moment from 'moment'

export const capitalizeWord = word => {
  const lowerCase = word.toLowerCase()
  return word.charAt(0).toUpperCase() + lowerCase.slice(1)
}

//Check if datepicker is supported by browser
export const isDateSupported = () => {
	var input = document.createElement('input')
	var value = 'a'
	input.setAttribute('type', 'date')
	input.setAttribute('value', value)
	return (input.value !== value)
}

export const formatFormDate = (value, format = 'YYYY-MM-DD') => {
	return moment(value, ["DD-MM-YYYY", "YYYY-MM-DD"]).format(format)
}

//Date validation in dd-mm-yyyy format
export const dateRegExpCheckEng = value => {
  const re = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
  return re.test(String(value).toLowerCase())
}
export const dateRegExpCheck = value => {// eslint-disable-next-line
  const re = /^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$/
  return re.test(String(value).toLowerCase())
}

//Email reg exp
export const emailRegExpCheck = email => {// eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

//EU phone reg exp
export const euPhoneRegExpCheck = phone => {// eslint-disable-next-line
  const re = /([^\d\+]|^|>)((((\+|00)(\d\d)\s*(\(0\))?)|0)?(\s*\d){7,10})([^\d\+]|$|<)$/
  return re.test(String(phone).toLowerCase())
}

//Returns an epoch int
export const buildEpochInterval = (interval) => {
  return new Date(new Date().getTime() + interval)
}

//Italian CAP reg exp
export const itZipRegExpCheck = zipCode => {// eslint-disable-next-line
  const re = /^\d{5}$/
  return re.test(String(zipCode).toLowerCase())
}

export const getTodayDate = () => {
  return moment().format('YYYY-MM-DD')
}

export const fiscalCodeRegExpCheck = value => {
  const re = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
  return re.test(String(value).toUpperCase())
}

export const codiceFISCALE = (cfins) =>	{
	var cf = cfins.toUpperCase();
	var cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
	if (!cfReg.test(cf))
	{
		return false;
	}

	var set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	var set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
	var setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	var setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
	var s = 0;
	for( var i = 1; i <= 13; i += 2 )
	  s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
	for( var j = 0; j <= 14; j += 2 )
	  s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(j) )));
	if ( s%26 !== cf.charCodeAt(15)-'A'.charCodeAt(0) )
	  return false;
	return true;
}

export const vatNumberRegExpCheck = value => {// eslint-disable-next-line
  const re = /^[0-9]{11}$/
  return re.test(String(value).toLowerCase())
}

export const isItalianIban = value => {
  const re = /^IT[a-zA-Z0-9]*$/
  return re.test(String(value.split(' ').join('').toUpperCase()))
}

//Check ONLY Italian IBAN
export const italianIbanCodeRegExpCheck = value => {
  // const re = /^IT\d{2}[ ][a-zA-Z]\d{3}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{4}[ ]\d{3}|IT\d{2}[a-zA-Z]\d{22}$/
  const re = /^IT\d{2}[a-zA-Z]\d{10}[a-zA-Z0-9]{12}$/
  return re.test(String(value.split(' ').join('').toUpperCase()))
}

//Check generic IBAN
export const ibanCodeRegExpCheck = value => {// eslint-disable-next-line
  const re = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/
  return re.test(String(value.toUpperCase()))
}

export const stringLengthRegExpCheck = (value, length) => {
  let regString = "^([a-zA-Z0-9_-]){*}$"
  regString = regString.replace('*', length)
  let re = new RegExp(regString)
  return re.test(String(value).toLowerCase())
}

export const integerNumberRegExpCheck = value => {
  const regExp = /^\d+$/
  const re = new RegExp(regExp)
  return re.test(value)
}

export const debounce = (func, wait, immediate) => {
	var timeout
	return function() {
		var context = this, args = arguments
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args)
		}
		var callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

//Camel case to Kebab case
export const camelToKebab = (string) => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}
