import styled from 'styled-components'

const GeneralConditionsCheckboxStyle = styled.div`
  .text {
    font-size: .8rem;
  }

  .form-check-label {
    margin-top: 1rem;
  }

  .form-check-input {
    margin-left: 0;
    margin-top: 0;
    margin-right: .3rem;
    position: static;
    vertical-align: middle;
  }
`

export default GeneralConditionsCheckboxStyle
