import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ListGroupItem, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CanI from '../../../auth/CanI/CanI.component'
// import DeleteItem from '../../DeleteItem/DeleteItem.component' //TODELETE
import { selectEntityName, selectIsFetchingData } from '../../../../redux/contract/contract.selectors'
import { contractGetItemBankAccountDetailStart } from '../../../../redux/contract/contract.actions'

const CadastralItem = ({ item }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(state => selectIsFetchingData('bankAccountDetail')(state))
  const entityName = useSelector(selectEntityName)

  return(
    <>
      <ListGroupItem className="cadastral" key={item.id} tag="div" action>
        <div className="cadastral-info">
          <div className="bank-account-preview">
              <FontAwesomeIcon icon={['fas', 'money-bill-wave']} className="mr-3" />
              <span>{item.name} {item.surname} <br /> {item.iban} - {item.fiscalCode}</span>
          </div>
          <div className="context-menu">
            <CanI doWhat="GET" withPermissions={item.links} entityName={`${entityName}BankAccountDetail`}>
              {({ action }) => (
                <Button
                  size="sm"
                  color="secondary"
                  outline
                  onClick={() => dispatch(contractGetItemBankAccountDetailStart({ bankAccountDetailId: item.id }))}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <Spinner size="sm" className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon={['fas', 'eye']} className="mr-2" />
                  )}
                  Dettagli
                </Button>
              )}
            </CanI>
            {/*TODELETE
            <CanI doWhat="DELETE" withPermissions={item.links} entityName={`${entityName}BankAccountDetail`}>
              {({ action }) => (
                <DeleteItem
                  buttonLabel="Elimina"
                  actionUrl={action}
                  actionFunction={deleteItem}
                  updateFunction={reRender}
                />
              )}
            </CanI>*/}
          </div>
        </div>
      </ListGroupItem>
    </>
  )
}

export default CadastralItem
