import styled from 'styled-components'

const DropzoneStyle = styled.div`
  .dropzone-listgroup {
    &.single-file {
      margin: 0;
    }
  }

  .actions-container {
    @media (max-width: 991.9px) {
      > * {
        margin-bottom: .5rem;
      }
    }
  }
`

export default DropzoneStyle
