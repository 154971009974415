import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Spinner } from 'reactstrap'

import CustomFormField from '../inputFields/CustomFormField/CustomFormField.component'
import { emailCheck } from '../validations'
import { selectIsSubmitting } from '../../../redux/user/user.selectors'
import { userCheckEmailStart } from '../../../redux/user/user.actions'

const focusOnError = createDecorator()

const LoginEmailForm = () => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(selectIsSubmitting)
  const onSubmit = values => dispatch(userCheckEmailStart(values))

  return (
    <div>
      <div className="login-icon">
        <FontAwesomeIcon icon="at" />
      </div>
      <p className="lead">Inserisci la tua email per procedere:</p>

      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          pristine: true
        }}
        decorators={[focusOnError]}
      >
        {({ handleSubmit, form, submitting, pristine, values, reset }) => {
          return (
            <form
              className="two-cols-form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col>
                  <CustomFormField
                    name="email"
                    type="text"
                    placeholder="Email*"
                    fieldLabel="Email*"
                    validate={emailCheck(true)}
                    disabled={isSubmitting}
                    maxLength="80"
                    focusOnMount={true}
                    style={{ textTransform: 'lowercase' }}
                  />
                  <div className="mt-3 d-flex justify-content-between">
                    <Button type="submit" disabled={isSubmitting} size="lg">
                      <span>Avanti</span>
                      {isSubmitting ? (
                        <Spinner size="sm" color="light" className="ml-1" />
                      ) : (
                        <FontAwesomeIcon icon="sign-in-alt" className="ml-1" />
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          )
        }}
      </Form>
    </div>
  )
}

export default LoginEmailForm
