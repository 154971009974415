import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PaginationComponent from "react-reactstrap-pagination"

import { selectList } from '../../../redux/contract/contract.selectors'
import { contractGetListDataStart, contractUpdateListPage } from '../../../redux/contract/contract.actions'

const ListPagination = ({ totalItems, itemsPerPage, apiUrl }) => {
  const dispatch = useDispatch()
  const { page } = useSelector(selectList)
  const handlePageSelection = selectedPage => {
    dispatch(contractUpdateListPage(selectedPage))
    dispatch(contractGetListDataStart({ apiUrl }))
  }

  return(
    <PaginationComponent
      totalItems={totalItems}
      pageSize={itemsPerPage}
      onSelect={handlePageSelection}
      maxPaginationNumbers={10}
      defaultActivePage={page}
      firstPageText='I<'
      lastPageText='>I'
      previousPageText='<'
      nextPageText='>'
    />
  )
}

export default ListPagination
