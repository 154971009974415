import styled from 'styled-components'

export const SignContractAlertStyle = styled.div`
  margin-bottom: 2rem;

  .alert-heading {
    font-size: 1.15rem;
    font-weight: bold;
  }

  .alert-description {
    font-size: .9rem;
  }

  .signature-types-loader {
    display: flex;
    align-items: center;

    .loader-text {
      font-weight: bold;
    }
  }

  .signature-type-container {
    display: flex;

    p { 
      margin: 0;
    }

    button {
      margin-right: .5rem;
    }
  }
`
