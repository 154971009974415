import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import OtpFormContainer from '../OtpForm/OtpFormContainer.component'
import PaperSignatureFormContainer from '../PaperSignatureForm/PaperSignatureFormContainer.component'
import { SignatureTypes } from '../../../redux/user/user.utils'
import { selectContractFinalizationModalVisibility, selectSignatureType } from '../../../redux/contract/contract.selectors'
import { contractToggleContractFinalizationModalVisibility } from '../../../redux/contract/contract.actions'
import ContractAttachmentsInfoAlert from '../ContractAttachmentsInfoAlert/ContractAttachmentsInfoAlert.component'

const ContractFinalizationModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectContractFinalizationModalVisibility)
  const toggle = () => dispatch(contractToggleContractFinalizationModalVisibility())
  const signatureType = useSelector(selectSignatureType)

  return (
    <Modal modalClassName="signature-modal status-modal" size="lg" backdrop="static" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon="signature" className="mr-2" />
        Finalizzazione contratto
      </ModalHeader>
      <ModalBody>
        <ContractAttachmentsInfoAlert />
        {signatureType && signatureType === SignatureTypes.OTP_SIGNATURE &&
          <OtpFormContainer />
        }
        {signatureType && signatureType === SignatureTypes.PAPER_SIGNATURE &&
          <PaperSignatureFormContainer />
        }
      </ModalBody>
    </Modal>
  )
}

export default ContractFinalizationModal
