import { all, call, takeLatest, put, select, delay } from 'redux-saga/effects'

import UserTypes from './user.types'
import * as userActions from './user.actions'
import { selectUserToken, selectFirebaseUser, selectLoginData } from './user.selectors'
import { 
  getCurrentUser, refreshFirebaseToken, signOut, confirmPasswordReset, 
  getMaintenanceStatus, getMaintenanceStatusUpdates 
} from '../../firebase/firebase-utils'
import { auth } from '../../firebase/firebase-utils'
import { 
  abLogin, getUserEntities, fetchSignatureTypes, checkEmail, 
  sendResetPasswordEmail, setUserMustChangePasswordValue 
} from './user.utils'

export function* isUserAuthenticated() {
  try {
    yield call(getMaintenanceStatusUpdates)
    const maintenance = yield call(getMaintenanceStatus)
    const user = yield getCurrentUser()
    if(!user) {
      yield put(userActions.userCheckSessionNoUser())
      return
    }
    const accessToken = yield user.getIdToken()
    //Save user token in cookie
    document.cookie = `abUserToken=${accessToken}; domain=abenergie.it; secure; max-age=1800; SameSite=strict`
    const [ userData, userEntities ] = yield all([
      call(abLogin, accessToken),
      call(getUserEntities, accessToken)
    ])
    yield put(userActions.userCheckSessionSuccess({ firebaseUser: user, userData, userEntities, maintenanceState: maintenance }))
  }
  catch(error) {
    yield put(userActions.userCheckSessionFailure(error))
  }
}

export function* onCheckUserSession() {
  yield takeLatest(
    UserTypes.USER_CHECK_SESSION_START,
    isUserAuthenticated
  )
}

export function* refreshToken() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const newToken = yield call(refreshFirebaseToken, firebaseUser)
    yield put(userActions.userRefreshTokenSuccess({ newToken }))
  }
  catch(error) {
    yield put(userActions.userRefreshTokenFailure(error))
  }
}

export function* onRefreshTokenStart() {
  yield takeLatest(
    UserTypes.USER_REFRESH_TOKEN_START,
    refreshToken
  )
}

export function* userLogOut() {
  try {
    yield call(signOut)
    document.cookie = `abUserToken=; domain=abenergie.it; expires=Thu, 01 Jan 1970 00:00:00 GMT`
    yield put(userActions.userLogoutSuccess())
  }
  catch(error) {
    yield put(userActions.userLogoutFailure(error))
  }
}

export function* onUserLogoutStart() {
  yield takeLatest(
    UserTypes.USER_LOGOUT_START,
    userLogOut
  )
}

export function* userLogin({ payload: { password } }) {
  try {
    //Check if the user exists in AB Db
    const { email } = yield select(selectLoginData)
    const { user } = yield auth.signInWithEmailAndPassword(email, password)
    const accessToken = yield user.getIdToken()
    //Save user token in cookie
    document.cookie = `abUserToken=${accessToken}; domain=abenergie.it; secure; max-age=1800; SameSite=strict`
    yield call(getMaintenanceStatusUpdates)
    const maintenance = yield call(getMaintenanceStatus)
    const [ userData, userEntities ] = yield all([
      call(abLogin, accessToken),
      call(getUserEntities, accessToken)
    ])
    yield put(userActions.userLoginSuccess({ firebaseUser: user, userData, userEntities, maintenanceState: maintenance }))
  }
  catch(error) {
    yield put(userActions.userLoginFailure(error))
  }
}

export function* onUserLoginStart() {
  yield takeLatest(
    UserTypes.USER_LOGIN_START,
    userLogin
  )
}

export function* sendUserResetPassword() {
  try {
    const loginData = yield select(selectLoginData)
    yield call(sendResetPasswordEmail, loginData)
    yield put(userActions.userSendResetPasswordEmailSuccess())
  }
  catch(error) {
    yield put(userActions.userSendResetPasswordEmailFailure(error))
  }
}

export function* onUserSendResetPasswordEmailStart() {
  yield takeLatest(
    UserTypes.USER_SEND_RESET_PASSWORD_EMAIL_START,
    sendUserResetPassword
  )
}

//Update user token every 30 minutes
export function* refreshUserTokenPeriodically() {
  while (true) {
    yield delay(600000)
    const userToken = yield select(selectUserToken)
    if(userToken) {
      yield call(refreshToken)
    }
  }
}

export function* getSignatureTypes({ payload: { contractId, openingCausal } }) {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const accessToken = yield call(refreshFirebaseToken, firebaseUser)
    const signatureTypes = yield call(fetchSignatureTypes, contractId, openingCausal, accessToken)
    yield put(userActions.userGetSignatureTypesSuccess(signatureTypes))
  }
  catch(error) {
    yield put(userActions.userGetSignatureTypesFailure(error))
  }
}

export function* onUserGetSignatureTypesStart() {
  yield takeLatest(
    UserTypes.USER_GET_SIGNATURE_TYPES_START,
    getSignatureTypes
  )
}

export function* checkUserEmail({ payload: { email } }) {
  try {
    const { mustChangePassword, ...response } = yield call(checkEmail, email)
    if(mustChangePassword === false) {
      yield put(userActions.userCheckEmailSuccess(response))
    }
    else {
      const resetPasswordEmailResponse = yield call(sendResetPasswordEmail, response)
      yield put(userActions.userCheckEmailMustChangePassword(resetPasswordEmailResponse))
    }
  }
  catch(error) {
    yield put(userActions.userCheckEmailFailure(error))
  }
}

export function* onUserCheckEmailStart() {
  yield takeLatest(
    UserTypes.USER_CHECK_EMAIL_START,
    checkUserEmail
  )
}

export function* setNewPassword({ payload: { newPassword } }) {
  try {
    const { oobCode, continueUrl } = yield select(selectLoginData)
    yield call(confirmPasswordReset, oobCode, newPassword)
    if(continueUrl) {
      yield call(setUserMustChangePasswordValue, continueUrl, { mustChangePassword: false })
    }
    yield put(userActions.userSetNewPasswordSuccess())
  }
  catch(error) {
    yield put(userActions.userSetNewPasswordFailure(error))
  }
}

export function* onUserSetNewPasswordStart() {
  yield takeLatest(
    UserTypes.USER_SET_NEW_PASSWORD_START,
    setNewPassword
  )
}

export function* userSagas() {
  yield all([
    call(onCheckUserSession),
    call(onUserLoginStart),
    call(onUserSendResetPasswordEmailStart),
    call(onRefreshTokenStart),
    call(onUserLogoutStart),
    // call(refreshUserTokenPeriodically),
    call(onUserGetSignatureTypesStart),
    call(onUserCheckEmailStart),
    call(onUserSetNewPasswordStart),
  ])
}
