import React from 'react'
import { useSelector } from 'react-redux'
import { ListGroup } from 'reactstrap'

import CadastralItem from '../CadastralItem/CadastralItem.component'
import { selectCadastralDataItemsAsArray } from '../../../../redux/contract/contract.selectors'

const CadastralItemsList = () => {
  const cadastralData = useSelector(selectCadastralDataItemsAsArray)

  return(
    <>
      {cadastralData.length ?
        <ListGroup>
          {cadastralData.map(cadastralItem => (
            <CadastralItem key={cadastralItem.id} item={cadastralItem} />
          ))}
        </ListGroup>
      :
        <p>Nessun <strong>punto di fornitura</strong> presente</p>
      }
    </>
  )
}

export default CadastralItemsList
