import React, { useState, useEffect, useCallback } from 'react'
import { FormGroup, ListGroup, ListGroupItem, Button } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { FieldArray } from 'react-final-form-arrays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DropzoneStyle from './style'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import CustomReadOnlyField from '../CustomReadOnlyField/CustomReadOnlyField.component'
import { handleFileDrop, formatBytes } from './utils'
import { checkFileTooLarge } from './utils'

const Dropzone = ({ 
  files, setFiles, setValidate, inputName = 'dropzone', acceptedFileTypes, maxSize,
  hideRemoveButton = false, openDialogWithBtn = false, viewFileBtn = false,
  multipleFiles = false, hideFileSize = false, mutators: { push, pop },
}) => {
  useEffect(() => {
    //Update form with default values
    if(files.length) {
      files.forEach(file => push(inputName))
    }// eslint-disable-next-line
  }, [])
  
  //Callback on file drop, encode the file in base64
  const onDrop = useCallback(acceptedFiles => {
    setValidate(true)
    if(!multipleFiles) {
      pop(inputName)
    }
    handleFileDrop(acceptedFiles)
      .then(response => {
        let updatedFilesList = multipleFiles ? files.concat(response) : response
        setFiles(updatedFilesList)
        if(multipleFiles) {
          acceptedFiles.forEach(file => push(inputName))
        }
        else if(acceptedFiles.length) {
          push(inputName)
        }
      })
  }, [setFiles, files, multipleFiles, push, pop, inputName, setValidate])
  
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections, open } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: maxSize,
    multiple: multipleFiles,
    onDrop: onDrop
  })
  //Check file size
  const [ isFileTooLarge, setIsFileTooLarge ] = useState(false)
  useEffect(() => {
    if(checkFileTooLarge(fileRejections)) {
      setIsFileTooLarge(true)
    }
    else {
      setIsFileTooLarge(false)
    }
  }, [fileRejections, maxSize])

  return (
    <DropzoneStyle>
      <FormGroup>
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ?
              <p>Lascia qui il file ...</p> :
              <p>Clicca o trascina qui i file da caricare</p>
          }
          {isDragReject &&
            <p>Questo tipo di file non è valido.</p>
          }
          {isFileTooLarge &&
            <p className="text-danger">
              La dimensione del file è troppo grande. Il limite è {formatBytes(maxSize)}.
            </p>
          }
        </div>
        {files?.length > 0 && 
          <div className={`dropzone-listgroup${multipleFiles ? '' : ' single-file'}`}>
            <ListGroup>
              <FieldArray name={inputName}>
                {({ fields }) =>
                  fields.map((name, index) => (
                    <ListGroupItem key={name}>
                      <CustomFormField
                        name={`${name}.id`}
                        type="hidden"
                        defaultValue={files.length && files[index].id}
                      />
                      <CustomReadOnlyField
                        name={`${name}.fileName`}
                        type="text"
                        placeholder="File"
                        fieldLabel="File"
                        defaultValue={files.length && files[index].userFileName}
                      />
                      {hideFileSize === false &&
                        <CustomReadOnlyField
                          name={`${name}.size`}
                          type="text"
                          placeholder="Dimensione"
                          fieldLabel="Dimensione"
                          defaultValue={files.length && formatBytes(files[index].size)}
                        />
                      }
                      <div className="actions-container">
                        {openDialogWithBtn === true && files.length &&
                          <Button
                            onClick={open}
                            className="mr-2 ab-button"
                          >
                            <FontAwesomeIcon icon="folder-open" className="mr-2" />
                            Carica file
                          </Button>
                        }
                        {viewFileBtn === true && files.length && files[index].internalFileURL &&
                          <Button
                            className="mr-2"
                            outline
                            href={files[index].internalFileURL}
                          >
                            <FontAwesomeIcon icon="eye" className="mr-2" />
                            Scarica allegato
                          </Button>
                        }
                        {hideRemoveButton === false &&
                          <Button
                            color="danger"
                            onClick={() => {
                              fields.remove(index)
                              setFiles(files.filter((item, i) => index !== i))
                            }}
                          >
                            <FontAwesomeIcon icon="times" className="mr-2" />
                            <span>Rimuovi</span>
                          </Button>
                        }
                      </div>
                    </ListGroupItem>
                  ))
                }
              </FieldArray>
            </ListGroup>
          </div>
        }
      </FormGroup>
    </DropzoneStyle>
  )
}

export default Dropzone
